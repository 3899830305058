// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import restPersona from '../restcall/restPersona';
import SchedaGiocatore from '../comp/SchedaGiocatore';
const SchedaGiocPage = (props) => {
  const navigation = useNavigate();
  const location=useLocation();
  const [selPerson, setSelperson] =  useState();
  

useEffect(() => {
  console.log(location.state.selpersona)
restPersona.getInstance().getPersona(location.state.selpersona._id).then((json)=>
    {
      setSelperson(json);
    })
}, [location.state.selpersona._id]);





    return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'


      }}>

{selPerson &&
        
    <div style={{ flex: 1 ,margin:'0.5rem',justifyContent:'center',alignItems:'center',justifyItems:'center',width:'100%'}}>
    <label className='Titolo5' style={{ flex: 1,textAlign:'center',width:'100%'}} >{selPerson.cognome} {selPerson.nome}</label>

       </div>    
    



        }
        
         {selPerson &&
<SchedaGiocatore selPersona={selPerson} gruppo={location.state.gruppo}  style={{flex:10,minHeight:'100%',width:'100%'}} />

}
    </div>
  );
} 
export default SchedaGiocPage;