import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import DettaglioPersona from "../comp/DettaglioPersona";

import Listarecapiti from "../comp/Listarecapiti";
import ListaVisite from "../comp/ListaVisite";
import ListaTesseramenti from "../comp/ListaTesseramenti";
import ListaDoc from "../comp/ListaDoc";
import ListaPagamenti from "../comp/ListaPagamenti";
import ListaOrdini from "../comp/ListaOrdini";
import DettaglioPersonaGrp from "../comp/DettaglioPersonaGrp";
import restGiocGruppi from "../restcall/restGiocGruppi";

const DettPersone = () => {
  const location = useLocation();
  const [azione, setAzione] = useState(0); // 0 = DettPersone, 1 = Recapiti, 2 = Visite



  return (
    <div>
      <div style={{ display: 'flex', margin: '0.3em'}}>
      <label className="Titolo5">Dettaglio di: {location.state.selpersona.cognome} {location.state.selpersona.nome}</label>
</div>
      {azione == 0 &&
        <DettaglioPersona selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
      {azione == 1 &&
        <Listarecapiti selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
      {azione == 2 &&
        <ListaVisite selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
      {azione == 3 &&
        <ListaTesseramenti selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
      {azione == 4 &&
        <ListaDoc selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
      {azione == 5 &&
        <ListaPagamenti selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
      {azione == 6 &&
        <ListaOrdini selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
       {azione == 7 &&
        <DettaglioPersonaGrp selPersona={location.state.selpersona}  updateAzione={setAzione}/>
      }
    </div>
  );
};

export default DettPersone;
