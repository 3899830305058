// screens/Home.js

import React, { useEffect, useState,useRef } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import datiGen from '../util/datiGen.js';
import moment from 'moment/min/moment-with-locales';
import restGruppi from '../restcall/restGruppi.js';
import gruppo from '../obj/gruppo.js';
import DettGruppo from './DettGruppo.js';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import logo from '../img/logo-vigor3.png';
import { jsPDF } from "jspdf";


import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer, mdiContentSaveAll, mdiCloseBox,
  mdiCheckAll,
  mdiPrinter,
  mdiSoccerField,
  mdiShapeCirclePlus,
  mdiPlusThick,
  mdiSortAscending,
  mdiSortDescending,
  mdiPrinterCheck,
  mdiShare,
  mdiRefresh,
  mdiShareVariant,
  mdiFilterMenu,
  mdiAccountCheck,
  mdiRunFast,
  mdiGamepadVariantOutline,
  mdiContentDuplicate,
  mdiDownload,
  mdiDownloadCircle,
  mdiDownloadBox,
  mdiFileDocument

} from '@mdi/js';
import { OverlayPanel } from 'primereact/overlaypanel';
import restAttivita from '../restcall/restAttivita.js';
import restConfigurazioniTest from '../restcall/restConfigurazioniTest.js';
import Attivita from '../obj/Attivita.js';
import elenchi from '../util/elenchi.js';
import { InputText } from 'primereact/inputtext';
import Stampe from '../util/Stampe.js';
//import DettGruppo from './DettGruppo.js';



const ListaAttivita = (props) => {
  
  const navigate = useNavigate();
  const abilUpd = datiGen.getInstance().possoaccederev2('GRUPPIUPD');
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [dettGrpVisible, setDettGrpVisible] = useState(false);
  const [daSalvare, setDaSalvare] = useState(false);
  const [mostraValidi, setMostraValidi] = useState(true);

  const abilAttUPD = datiGen.getInstance().possoaccederev2('ATTIVITAUPD', props.gruppo);
  const abilGiocAtt = datiGen.getInstance().possoaccederev2('GiocAttivita', props.gruppo);
  const abilAllPres = datiGen.getInstance().possoaccederev2('ALLGIOCATTIVITAUPD');
  const abilTABPARTITA = datiGen.getInstance().possoaccederev2('TABPARTITA', props.gruppo);
  const abilcommPARTITA = datiGen.getInstance().possoaccederev2('COMMPARTITA', props.gruppo);
  const abilEsecTest = datiGen.getInstance().possoaccederev2('ESECTEST', props.gruppo);

  const [date_iatt, setDate_iatt] = useState(new Date());
  const [show_iatt, setShow_iatt] = useState(false);
  const [show_iattc, setShow_iattc] = useState(false);
  const [date_fatt, setDate_fatt] = useState(new Date());
  const [show_fatt, setShow_fatt] = useState(false);
  const [show_fattc, setShow_fattc] = useState(false);
  const [date_ritr, setDate_ritr] = useState(new Date());
  const [show_ritr, setShow_ritr] = useState(false);
  const [show_ritrc, setShow_ritrc] = useState(false);
  const [daNotificare, setDaNotificare] = React.useState(false);
  const [dettAttVisible, setDettAttVisible] = useState(false);
  const [tabelVisible, setTabelVisible] = useState(false);
  const [listaGruppiSQ, setListaGruppiSQ] = useState([]);
  const [listaTipoTest, setListaTipoTest] = useState([]);
  const [tmpInd, setTmpInd] = useState();
  const [date_filtroI, setDate_filtroI] = useState(datiGen.getInstance().getRicercadtDa());
  const [show_filtroI, setShow_filtroI] = useState(false);
  const [date_filtroF, setDate_filtroF] = useState(datiGen.getInstance().getRicercadtA());
  const [show_filtroF, setShow_filtroF] = useState(false);
  const [indFiltro, setindFiltro] = useState(datiGen.getInstance().getRicercaIndirizzo());
  const [tipoFiltro, setTipoFiltro] = useState(datiGen.getInstance().getRicercaTipoAtt());
  const [tipoPartitaFiltro, setTipoPartitaFiltro] = useState(datiGen.getInstance().getRicercaTipoPartita());
  const [ordinamentoAtt, setOrdinamentoAtt] = useState(datiGen.getInstance().getOrdinamentoAtt());
  const [gruppoFiltro, setGruppoFiltro] = useState(props.gruppo ? props.gruppo : datiGen.getInstance().getRicercaGruppi());
  const [show_filtro, setShow_filtro] = useState(false);
  const [tipogrp_Age, setTipoGrp_Age] = useState(props.gruppo ? '--' : datiGen.getInstance().getRicercaTipogrp());
  

  const [evPartTipo, setEvPartTipo] = useState('###');
  const [evPartMin, setEvPartMin] = useState(0);
  const [evPartGioc, setEvPartGioc] = useState('');
  const [evPartGioc2, setEvPartGioc2] = useState('');
  const [listaGiocatori, setListaGiocatori] = useState([]);
  const [edtCronaca, setEdtCronaca] = useState(0);
  const [evtest, setEvtest] = useState('');

  const [abilEditOrg, setAbilEditOrg] = useState('false');

  const [edtInd, setEdtInd] = React.useState(false);

  const menuPrint = useRef(null);

  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo, life: 3000, position:"center" });
  }
  const showWarn = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: testo, life: 3000,position:'center' });
  }
  const showError = () => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Message Content', life: 3000 });
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };



  let elabincorso = false;

  const getListaGrp = async () => {

    try {
      setLoading(true);
      var json = await restGruppi.getInstance().getGruppi(mostraValidi).then((json) => {
        setData(json);
        setDaSalvare(false);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  const confirm1 = () => {

    confirmDialog({
      message: 'Non tutte le modifiche sono state salvate, vuoi uscire comunque   XXXX?',
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => { setDettGrpVisible(false); setDaSalvare(false); }),
      reject: (() => { return; }),
    });
  };

  const confirmDuplicate = (item) => {

    confirmDialog({
      message: "Vuoi duplicare l'attività selezionata?",
      header: 'Conferma',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => { setSelected(item); duplica(item); }),
      reject: (() => { return; }),
    });
  };


  ////// da ripristinare dasalvare  con blocker
  const toggleOverlayChiudi = () => {


    if (daSalvare) {
      //console.log('ListaGruppi','DaSalvare',daSalvare)
      confirm1();
    }
    else {
      setDettGrpVisible(false);
    }

  };

  ////// da ripristinare dasalvare  con blocker
  const toggleOverlayApri = () => {


    setDettGrpVisible(true);


  };



  const renderItem = (item, index) => (
 
    <div className='listaItem' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} 
    >

      <div style={{ flexGrow: '2' }} 
      onClick={()=> {navigate("/DettAttivita", { state: { selAttivita : item,azione:0,gruppo:props.gruppo   }}); }   }
      >
        <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}     >
          <label style={{ flex: 1, fontWeight: 'bold' }}>
            {item.tipo.split('#')[2]} {' '} {item.gruppi ? getDesGruppi(item.gruppi) : ' '}</label>

        </div>
        
          <label >Inizio:{moment(item.dataAttivita).format('DD/MM/YYYY HH:mm')}</label>
          {item.tipo.split('#')[1] == 'P' &&
            <label style={{  whiteSpace: 'nowrap' }}> Ritrovo:{moment(item.RitrovoDataOra).format('DD/MM/YYYY HH:mm')}</label>
          }
        
        {item.tipo.split('#')[1] == 'P' && <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}     >
          <label style={{ flex: 3, fontWeight: 'bold' }}>{item.avversario}</label>

        </div>}
        {props.tipo !== 'giorno' &&
          <div style={{ flex: 1 }}>
            {item && item.indirizzo != '' && <label style={{ flex: 3 }}>{item.indirizzo}</label>}<br />
            {item.note != '' && <label style={{ flex: 3 }}>{item.note}</label>}

          </div>
        }
      </div>
      <div style={{ padding: 1, textAlign: 'end', flexGrow: 1, marginRight: '0.2rem',  minWidth: '1rem' }}>

 {       ((props.gruppo && abilGiocAtt) || abilAllPres) &&
          <Icon path={mdiAccountCheck} size={'1.8rem'}
            onClick={() => {  navigate('/giocAttPage', {state:{ gruppo: props.gruppo, selAttivita: item} });} }
          />
        }

   
        {abilEsecTest && item.tipo && item.tipo.split('#')[1] == 'T' &&
          <Icon path={mdiRunFast} size={'1.8rem'}
          onClick={()=> {      navigate("/EsecTestPage", { state: { attivita : item, gruppo:props.gruppo}});} }        
               />
        }



        {abilTABPARTITA && item.tipo && item.tipo.split('#')[1] == 'P' &&
          <Icon path={mdiGamepadVariantOutline} size={'1.8rem'}
          onClick={()=> {navigate("/DettAttivita", { state: { selAttivita : item,azione:1}});} }        
               />
        }
        {abilAttUPD && !daSalvare &&
          <Icon path={mdiContentDuplicate} size={'1.8rem'} onClick={() => { confirmDuplicate(item); }} />
        }
      </div>
    </div>

  )

  const leggi = (pdata) => {


    setData(pdata);
  }




  const getListaAttivita = async () => {

    try {
      setLoading(true);
console.log('GETLISTAATTIVITA' ,props.tipo);

      let d1 = props.tipo == 'giorno' ? moment().subtract(5, 'hours').toDate() : date_filtroI;
      let d2 = props.tipo == 'giorno' ? moment().add(2, 'days').toDate() : date_filtroF;
      let tf = props.tipo == 'giorno' ? '' : tipoFiltro;
      let gf = props.tipo == 'giorno' ? props.gruppo : gruppoFiltro;
      let tgf = props.tipo == 'giorno' ? '--' : tipogrp_Age;
      let ifiltro = props.tipo == 'giorno' ? '' : indFiltro;
      let tcfiltro = props.tipo == 'giorno' ? '' :tipoPartitaFiltro;


      if (!elabincorso) {
        elabincorso = true;
        setTimeout(() => {
          var json = restAttivita.getInstance().getAllAttivita(d1, d2, tf, gf, ifiltro, ordinamentoAtt, tgf,tcfiltro).then((json) => {
            setData(json);
            elabincorso = false;
          }).catch((err) => {
            console.error('getListaAttivita', err);
            elabincorso = false;
          })
        }, 200);

      }
      //  console.log('getListaAttivita', json);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getListaSQ = async () => {

    try {
      setLoading(true);
      var json = await restGruppi.getInstance().getGruppi();
      setListaGruppiSQ(json);

    } catch (error) {
      console.error(error);
      setListaGruppiSQ([]);
    } finally {
      setLoading(false);
    }
  }




  const getDesGruppi = (parr) => {
    var outdes = "";
    //console.log('listaGruppiSQ',listaGruppiSQ);
    if (listaGruppiSQ) {  //serve diritto gruppi
      listaGruppiSQ.forEach(ele => {
        if (parr.includes(ele._id)) {
          outdes = outdes.concat(ele.des, ' ');
        }

      });
    }
    return outdes.trim();

  }

  const getOrdineGruppi = (parr) => {

    var ordine = 99;
    //console.log('listaGruppiSQ',listaGruppiSQ);

    if (listaGruppiSQ) {  //serve diritto gruppi

      listaGruppiSQ.forEach(ele => {

        if (parr.includes(ele._id)) {

          if (ele.ordine && parseInt(ele.ordine) < ordine) { ordine = parseInt(ele.ordine); };
        }

      });
    }

    return ordine;

  }


  const getListaTest = async () => {
    //  console.log("lista test",listaTipoTest);

    try {
      setLoading(true);
      var json = await restConfigurazioniTest.getInstance().getAllConfigurazioniTest(true);
      setListaTipoTest(json);
      //    console.log("lista test",listaTipoTest);
    } catch (error) {
      console.error(error);
      setListaTipoTest([]);
    } finally {
      setLoading(false);
    }
  }


  const aggiungitest = async (valo) => {
    try {
      if (!selected.eltest)
        selected.eltest = [];
      const idtestese = performance.now().toString(36).toUpperCase();
      var arrele = { id: valo._id, nometest: valo.nometest, idtestese: idtestese };
      let x = { ...selected };
      x.eltest.push(arrele);
      setSelected(x); setDaSalvare(true);

      //  console.log('aggiugni test',selected.eltest);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }




  const eliminatest = async (index) => {
    try {
      let x = { ...selected };
      x.eltest.splice(index, 1);
      setSelected(x); setDaSalvare(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  const dettAtt = (pidatt, pgrp) => {
    restAttivita.getInstance().getAttivita(pidatt, pgrp).then((json) => {


      //console.log('dettapp',selected.gioc);
      //setListaGiocatori([]);
      listaGiocatori.length = 0;
      json.gioc.forEach((ele) => {
        listaGiocatori.push({ _id: ele.Giocatore[0]._id, nominativo: ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome });
      });


    })
  }
  useEffect(
    () => {
      getListaSQ();
    },
    []);
  useEffect(
    () => {
      getListaAttivita();
    },
    [date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro, tipogrp_Age, indFiltro,tipoPartitaFiltro]
  );
  useEffect(
    () => {

    },
    [show_filtro]
  );
  useEffect(
    () => {

    },
    [daSalvare]
  );
  useEffect(
    () => {
      // console.log('listaattivita', 'useeffect', 'edtcronaca,listagiocatori');
    },
    [edtCronaca, listaGiocatori]
  );



  const fAbilEditOrg = (papp) => {
    let x = false;
    let caso = 0;
    const lu = datiGen.getInstance().getElencoConf('USERS_ORG_AGE');
    const u = datiGen.getInstance().getUtenteCollegato().email;

    var incl = false;

    papp.gruppi.forEach((ele) => { if (lu.includes(ele + "#" + u)) { console.log((ele + "#" + u)); incl = true; } else { console.log((ele + "#" + u)); } })


    if (!papp.opecreaz || lu.includes(papp.opecreaz) == false) {
      caso = 1;
      x = true;
      setAbilEditOrg(true);    //se l'attività non è stata creata dall'organizzazione posso modificarla
    }
    else if (lu.includes(u) || incl) {
      x = true;
      caso = 2;
      setAbilEditOrg(true);  //se l'attività  è stata creata dall'organizzazione  e l'utente fa parte dell'organizzazione posso modificarla 
    }
    else {
      caso = 3;
      x = false;
      setAbilEditOrg(false);  //altrimenti non si può modificare
    }


  }

  const settipoobj = (item) => {
    item.__proto__ = Attivita.prototype;
    item.to_obj();
  }



  const aggiungi = async () => {

    let t = new Attivita();
    t.idsoc = datiGen.getInstance().getActiveSoc().sigla;
    if (props.gruppo) {
      t.gruppi = [props.gruppo];
    }
    setSelected(t);
    setAbilEditOrg(true);
    //toggleOverlay();
    setDaSalvare(true);
    setDaNotificare(true);
    await restAttivita.getInstance().aggiungi(t);

    getListaAttivita();


  }



  const duplica = async (patt) => {
    var grp = props.gruppo;

    restAttivita.getInstance().duplica(patt, grp).then(() => {

      getListaAttivita();

    });

  }


  const salvaNuovo = async (patt) => {
    var grp = props.gruppo;

    restAttivita.getInstance().aggiungi(patt, grp).then(() => {
      setDaSalvare(false);
      setSelected(null);
      setAbilEditOrg(false);
      setDettAttVisible(false);
      getListaAttivita();
      setDaNotificare(false);
    });

  }


  const createPDFVig = async (ord) => {

    const x = await reportVig(ord);
    //console.log(x);
    Stampe.getInstance().StampaHtlm(x, 'ProgrammaVig.pdf');

  
  }

 const printPDFVig = async (ord) => {
     await createPDFVig(ord);

  }

  let reportVig = async (ordine) => {
    var logov = logo;
    return new Promise((resolve, reject) => {
      try{
      var outatt = "";
      var texthtml;
      const intervallorighe = 30;
      var listaprint = [];
      var listaa;
      var listaatt = [];
      var dinizio, dfine;
      listaa = data.sort((ele1, ele2) => moment(ele1.dataAttivita).diff(moment(ele2.dataAttivita)));
      if (ordine && ordine === 'G')
        listaa = listaa.sort((ele1, ele2) => (getOrdineGruppi(ele1.gruppi) - getOrdineGruppi(ele2.gruppi)));
      if (listaa.length > 0) {
        dinizio = new moment(listaa[0].dataAttivita);
        dinizio = dinizio.startOf('hour');
        //creo le righe in tabella per tutto il giorno -- alla fine elimino le non usate
        var curs = new moment(dinizio);
        while (curs.add(intervallorighe).isSameOrBefore(moment(dinizio).endOf('day'))) {
          listaprint.push({ ora: moment(curs), campo11: "", campo7: "", altro: "" });
          curs = curs.add(intervallorighe, 'm');
        }
        //ciclo su tutte le attività filtrate
        listaa.every((ele, pos) => {
          //se l'attività è nel giorno inserisco le righe e l'attività nella colonna corretta
          if (moment(ele.dataAttivita).isBefore(dinizio.endOf('day'))) {
            //ciclo su tabella oraria
            listaprint.forEach((eleora, pos) => {
            
              if (moment(ele.dataAttivita).isBefore(moment(eleora.ora).add(intervallorighe,'m')) && moment(ele.dataFineAttivita).isAfter(eleora.ora)) {
                
                if (ele.indirizzo.startsWith("CS Vigorello - Campo 11")) {
                  if (eleora.campo11.length>2)
                  {
                    eleora.campo11=eleora.campo11+'<br/>'
                  }
                  
                  eleora.campo11 = eleora.campo11 +'<b>' +(ele.gruppi ? getDesGruppi(ele.gruppi) : ' ')+"</b><br/>" + (ele.tipo && ele.tipo.split('#')[2] ? ele.tipo.split('#')[1] == 'P' ?
                    (ele.TipoPartita && ele.TipoPartita!='undefined'  ? elenchi.getInstance().getElTipoPartita().find((elex) => elex.value == ele.TipoPartita) .label: 'Partita')
                    + ' ' + ele.avversario : ele.tipo.split('#')[2] : '');
                    
                }


                else if (ele.indirizzo.startsWith("CS Vigorello - Campo 7")) {
                  if (eleora.campo7.length>2)
                  {
                    eleora.campo7=eleora.campo7+'<br/>'
                  }
                  eleora.campo7 = eleora.campo7 +'<b>' +(ele.gruppi ? getDesGruppi(ele.gruppi) : ' ')+"</b><br/>" + (ele.tipo && ele.tipo.split('#')[2] ? ele.tipo.split('#')[1] == 'P' ?
                    (ele.TipoPartita ? elenchi.getInstance().getElTipoPartita().find((elex) => elex.value == ele.TipoPartita) .label: 'Partita')
                    + ' ' + ele.avversario : ele.tipo.split('#')[2] : '')                  ;
                }


                else if (ele.indirizzo.startsWith("CS Vigorello")) {
                  if (eleora.altro.length>2)
                  {
                    eleora.altro=eleora.altro+'<br/>'
                  }
                  eleora.altro = eleora.altro +'<b>' +(ele.gruppi ? getDesGruppi(ele.gruppi) : ' ')+"</b><br/>" + (ele.tipo && ele.tipo.split('#')[2] ? ele.tipo.split('#')[1] == 'P' ?
                    (ele.TipoPartita ? elenchi.getInstance().getElTipoPartita().find((elex) => elex.value == ele.TipoPartita) .label: 'Partita')
                    + ' ' + ele.avversario : ele.tipo.split('#')[2] : '');
                    
                }
                else {
                 // console.log("ciclo4", "no vigorello");
                }

              }
            });
          }
          else {
            
        
            return false;
          }
          return true;
        });
               
var esci=0;
        while(!esci && listaprint.length>0) {
        
            if (listaprint.slice(-1)[0].campo11.length<2 && listaprint.slice(-1)[0].campo7.length<2 && listaprint.slice(-1)[0].altro.length<2  )
            listaprint.pop();
            else
            esci=1;
        }    
      }
      //    data.sort((ele1, ele2) => moment(ele1.dataAttivita).diff(moment(ele2.dataAttivita))).forEach((ele, pos) => {
      listaprint.forEach((eleo, pos) => {
        outatt = outatt + ' <tr  style="background-color:' + (pos % 2 ? '#eeeeee' : 'transparent') + '"><td >' + moment(eleo.ora).format('HH:mm')
          + '</td><td style="font-size:11pt">' + eleo.campo11
          + '</td><td style="font-size:11pt">' + eleo.campo7 + '</td><td style="font-size:11pt">' + eleo.altro + '</td></tr>'
      });
          texthtml = `<html >
      <head>
    <style type="text/css">
      h1 { color:#F1F1F1 }
      table,th, td {
        border: 0.5px solid #cccccc;
        border-collapse: collapse;
        padding-left:5px;
        padding-right:5px;
        font-size:13pt;
        text-align:center;
      }
   
    </style>
    </head>
      
      <body style='text-align:center;'>
      <div style="height:100%;display:block;" >
      <div style="width:100%;display:flex;flex-direction:row">
    <div style="width:60%;">
      <img 
      src="`+
        logov
        + `" 
      style="height:120px;float:left;width:auto;object-fit:contain;" />
    </div>
    <div style='float:right;background-color:transparent;width:40%;text-align:left;'>
      <h2>A.S.D. Vigor Milano </h2>
      Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
    </div>
    
      </div>
    <h1 style='color:black'>Programma attività Vigorello `+ moment(date_filtroI).format('DD/MM/YYYY')  +`</h1>
    

    <table style='width:98%; table-layout: auto;margin: 0 auto;text-align:center,font-size:8pt'>
    <tr>
    <th>  Data    </th>
    <th>  Campo 11    </th>`+
        `<th>  Campo 7    </th>` +
        `<th>  Altro    </th>` +
        `
    </tr>
    `+
        outatt
        + `
</table>
    </div>
      </body>
      <html>`;
      resolve(texthtml);
    }
    catch(err)
    {
     resolve( `<div>Errore<br/>` + err + ` </div>`);
    }



    })

  }


  const reportConv = async (ordine) => {
    var logov = logo;
    return new Promise((resolve, reject) => {
try {


      var outatt = "";

      var texthtml;



      var listaa;

      listaa = data.sort((ele1, ele2) => moment(ele1.dataAttivita).diff(moment(ele2.dataAttivita)));

      if (ordine && ordine === 'G')
        listaa = listaa.sort((ele1, ele2) => (getOrdineGruppi(ele1.gruppi) - getOrdineGruppi(ele2.gruppi)));



      //    data.sort((ele1, ele2) => moment(ele1.dataAttivita).diff(moment(ele2.dataAttivita))).forEach((ele, pos) => {
      listaa.forEach((ele, pos) => {

        outatt = outatt + ' <tr  style="background-color:' + (pos % 2 ? '#eeeeee' : 'transparent') + '"><td>' + moment(ele.dataAttivita).format('DD/MM/YYYY HH:mm')
          + '</td><td>' + (ele.tipo && ele.tipo.split('#')[2] ? ele.tipo.split('#')[1] == 'P' ?
            (ele.TipoPartita ? elenchi.getInstance().getElTipoPartita().find((elex) => elex.value == ele.TipoPartita) : 'Partita').label
            + '<br/>' + ele.avversario : ele.tipo.split('#')[2] : '')
          + '</td><td>' + (ele.gruppi ? getDesGruppi(ele.gruppi) : ' ')
          + (!indFiltro ? '</td><td>' + ele.indirizzo + '</td></tr>' : ' ');
        //console.log(outconv);

      });




      texthtml = `<html>
      <head>
    <style type="text/css">
      h1 { color:#F1F1F1 }
      table,th, td {
        border: 0.5px solid #cccccc;
        border-collapse: collapse;
        padding-left:5px;
        padding-right:5px;
        font-size:13pt;
        text-align:center;
      }
   
    </style>
    </head>
      
      <body style='text-align:center;height:100%;'>
      <div style="height:100%;display:block; >
      <div style="width:100%;display:flex;flex-direction:row">
    <div style="width:60%;">
      <img 
      src="`+
        logov
        + `" 
      style="height:150px;float:left;width:auto;object-fit:contain;" />
    </div>
    <div style='float:right;background-color:transparent;width:40%;text-align:left;'>
      <h2>A.S.D. Vigor Milano</h2>
      Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
      Via IV Novembre 72, Cormano (MI)<br/>
      tel: 392 985 88 51 <br/>
    </div>
    
      </div>
    <h1 style='color:black'>Programma attività: </h1>
    <h3 style='color:gray'>dal `+ moment(date_filtroI).format('DD/MM/YYYY') + ` al ` + moment(date_filtroF).format('DD/MM/YYYY') + ` ` +
        (gruppoFiltro ? 'gruppo ' + getDesGruppi(gruppoFiltro) : ' ')
        + ` ` +
        (indFiltro ? 'Indirizzo/CS ' + indFiltro : ' ') + `
    </h3></br/>

    <table style='width:98%; table-layout: auto;margin: 0 auto;text-align:center'>
    <tr>
    <th>  Data    </th>
    <th>  Attività    </th>`+
        `<th>  Gruppo    </th>` +
        (!indFiltro ? `<th>  Indirizzo/CS    </th>` : ' ')
        + ` 
    </tr>
    `+
        outatt
        + `
</table>
    </div>
      </body>
      <html>`;

      resolve(texthtml);

    } catch(err)
    {
     resolve( `<div>Errore<br/>` + err + ` </div>`);
    }


    })
    

  }


  
  const reportWeekend = async (ordine) => {
    var logov = logo;
    return new Promise((resolve, reject) => {
try {
      var outatt = "";
      var texthtml;
      var listaa;

      console.log("report Programma",tipoFiltro,tipoPartitaFiltro,tipogrp_Age);
if (!tipoFiltro || tipoFiltro.split('#')[1] != 'P' )
{
  showWarn("Selezionare 'Partita' dal filtro tipo attività");
  return;
}
/*if (!tipoPartitaFiltro || tipoPartitaFiltro != 'C' )
  {
    showWarn("Selezionare 'Campionato' dal filtro tipo partita");
    return;
  }*/
  if (!tipogrp_Age || (tipogrp_Age != 'P' && tipogrp_Age != 'A' )  )
    {
      showWarn("Selezionare Agonistica o Preagonistica");
      return;
    }
  



      listaa = data.sort((ele1, ele2) => moment(ele1.dataAttivita).diff(moment(ele2.dataAttivita)));
      if (ordine && ordine === 'G')
        listaa = listaa.sort((ele1, ele2) => (getOrdineGruppi(ele1.gruppi) - getOrdineGruppi(ele2.gruppi)));

      Stampe.getInstance().reportWeekend(listaa,date_filtroI,date_filtroF,indFiltro,gruppoFiltro,listaGruppiSQ,tipogrp_Age        
      ).then((texthtml)   =>
      {
       // console.log("reportconv2","html",texthtml);
      resolve(texthtml);
      }).
      catch((err)=>
      {
       resolve( `<div>Errore<br/>` + err + ` </div>`);
      });
    } catch(err)
    {
     resolve( `<div>Errore<br/>` + err + ` </div>`);
    }


    })
    

  }

  const reportConv2 = async (ordine) => {
    var logov = logo;
    return new Promise((resolve, reject) => {
try {
      var outatt = "";
      var texthtml;
      var listaa;



      listaa = data.sort((ele1, ele2) => moment(ele1.dataAttivita).diff(moment(ele2.dataAttivita)));
      if (ordine && ordine === 'G')
        listaa = listaa.sort((ele1, ele2) => (getOrdineGruppi(ele1.gruppi) - getOrdineGruppi(ele2.gruppi)));

      Stampe.getInstance().reportConv2(listaa,date_filtroI,date_filtroF,indFiltro,gruppoFiltro,listaGruppiSQ        
      ).then((texthtml)   =>
      {
       // console.log("reportconv2","html",texthtml);
      resolve(texthtml);
      }).
      catch((err)=>
      {
       resolve( `<div>Errore<br/>` + err + ` </div>`);
      });
    } catch(err)
    {
     resolve( `<div>Errore<br/>` + err + ` </div>`);
    }


    })
    

  }



  const createPDF3 = async (ord) => {
    let x;
   x = await reportWeekend(ord);

     Stampe.getInstance().StampaHtlm(x, 'ProgrammaPartite.pdf',2);

  }
 

  const createPDF = async (ord,formato) => {
    let x;
if (formato && formato==2)
{
   x = await reportConv2(ord);

}
else
     x = await reportConv(ord);
     Stampe.getInstance().StampaHtlm(x, 'Programma.pdf',formato);

  }

  const printPDF3 = async (ord) => {
    let file = await createPDF3(ord,2);
  }


  const printPDF2 = async (ord) => {
    let file = await createPDF(ord,2);
  }


 
  const printPDF = async (ord) => {
    let file = await createPDF(ord,1);
  }

  const sharePDF = async(ord) => {
    
    const x = await reportConv(ord);
    //console.log(x);

    var doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    doc.html(x, {
        callback: function(doc) {
            try {
              const file =new File ([doc.output('blob')],"Programma.pdf", {type:"application/pdf"});
              navigator.share({title: "Programma",  files: [file]               }               );
               // The data was shared successfully.
               
      console.log("The file was successfully shared");
            } catch (e) {
              // The data could not be shared.
              showInfo("Installare l'app per utilizzare la funzione condivisione");
              console.error(`Error: ${e}`);
              console.error(`The file could not be shared: ${e}`);
            }
        },
        x: 15,
        y: 15,
        width: 170, //target width in the PDF document
        windowWidth: 650 //window width in CSS pixels
    });
  }
  const SharePDFVig = async(ord) => {

   
      const x = await reportVig(ord);

    //console.log(x);

    var doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    doc.html(x, {
        callback: function(doc) {
            try {
              const file =new File ([doc.output('blob')],"ProgrammaVig.pdf", {type:"application/pdf"});
              navigator.share({title: "ProgrammaVig",  files: [file]               }               );
               // The data was shared successfully.
               
      console.log("The file was successfully shared");
            } catch (e) {
              showInfo("Installare l'app per utilizzare la funzione condivisione");
              // The data could not be shared.
              console.error(`Error: ${e}`);
              console.error(`The file could not be shared: ${e}`);
            }
        },
        x: 15,
        y: 15,
        width: 170, //target width in the PDF document
        windowWidth: 650 //window width in CSS pixels
    });
  }


  const sharePDF3 = async(ord) => {

  

    const x = await reportWeekend(ord);
    //console.log(x);

    var doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    doc.html(x, {
        callback: function(doc) {
            try {
              const file =new File ([doc.output('blob')],"ProgrammaPartite.pdf", {type:"application/pdf"});
              navigator.share({title: "Programma",  files: [file]               }               );
               // The data was shared successfully.
               
      console.log("The file was successfully shared");
            } catch (e) {
              // The data could not be shared.
              showInfo("Installare l'app per utilizzare la funzione condivisione");
              console.error(`Error: ${e}`);
              console.error(`The file could not be shared: ${e}`);
            }
        },
        x: 0,
        y: 0,
        width: 210, //target width in the PDF document
        windowWidth: 680 //window width in CSS pixels
    });
  }

  const sharePDF2 = async(ord) => {

  

    const x = await reportConv2(ord);
    //console.log(x);

    var doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    doc.html(x, {
        callback: function(doc) {
            try {
              const file =new File ([doc.output('blob')],"Programma.pdf", {type:"application/pdf"});
              navigator.share({title: "Programma",  files: [file]               }               );
               // The data was shared successfully.
               
      console.log("The file was successfully shared");
            } catch (e) {
              // The data could not be shared.
              showInfo("Installare l'app per utilizzare la funzione condivisione");
              console.error(`Error: ${e}`);
              console.error(`The file could not be shared: ${e}`);
            }
        },
        x: 0,
        y: 0,
        width: 210, //target width in the PDF document
        windowWidth: 680 //window width in CSS pixels
    });
  }

  const ordina = () => {

    if (ordinamentoAtt === 'D') {
      setOrdinamentoAtt('A');
      datiGen.getInstance().setOrdinamentoAtt('A');
    }
    else {
      setOrdinamentoAtt('D');
      datiGen.getInstance().setOrdinamentoAtt('D');
    }
  }


  const headerElement = (
    <div >
      {props.tipo !== 'giorno' &&
        <div className="listazioni">
          <Button
            onClick={() => setShow_filtro(!show_filtro)}
          >
            <Icon path={mdiFilterMenu} size={0.8} />
          </Button>
          {abilAttUPD &&
            <Button
              onClick={() => aggiungi()}
            >
              <Icon path={mdiPlusThick} size={0.8} />
            </Button>
          }
          <Button

            onClick={() => ordina()}
            
          >
            {ordinamentoAtt == 'A' &&
              <Icon path={mdiSortAscending} size={0.8} />
            }
            {ordinamentoAtt != 'A' &&
              <Icon path={mdiSortDescending} size={0.8} />
            }
          </Button>
          {(!daSalvare) &&
            <Button
            onClick={(event) => menuPrint.current.toggle(event)}
              
            ><Icon path={mdiPrinter} size={0.8} />
            </Button>
          }
         
        

          <Button

            onClick={() => getListaAttivita()}
          >
            <Icon path={mdiRefresh} size={0.8} />
          </Button>
        </div>
      }


    </div>


  );

  const menuitemRenderer = (item) => (
    <div className='p-menuitem-content'>
        <a className="flex p-menuitem-link" style={{justifyContent:'space-between'}}>
        
            <span style={{flex:3}}>{item.label}</span>
            
            <Icon style={{flex:1}}path={mdiFileDocument} size={0.8} 
            onClick={item.az1}
            />  
            <Icon style={{flex:1}}path={mdiShareVariant} size={0.8} 
             onClick={item.az2}
            />  
            
        </a>
    </div>
);
let items = [
   
    {
        label: 'Stampa',
        items: [
           
             {
                label: 'Programma',
                
                az1:()=>{printPDF2()},
                az2:()=>{sharePDF2()},
                template: menuitemRenderer,
                
            },
           
              {
                  label: 'Programma Vigorello',
                  az1:()=>{printPDFVig()},
                  az2:()=>{SharePDFVig()},
                  template: menuitemRenderer,
                  
              },
              {
                label: 'Programma Gruppi',
                az1:()=>{printPDF2("G")},
                az2:()=>{sharePDF2("G")},
                template: menuitemRenderer,
                
            },
            {
              label: 'Programma Partite',
              az1:()=>{printPDF3("G")},
              az2:()=>{sharePDF3("G")},
              template: menuitemRenderer,
              
          },
             {
                label: 'Programma',
                
                az1:()=>{printPDF()},
                az2:()=>{sharePDF()},
                template: menuitemRenderer,
                
            },
        ]
    }
    
   
];





  return (

    <div>
            <Toast ref={toast} />

            {show_filtro &&
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} htmlFor='dt1'>Da</label>
          <Calendar id="dt1" value={date_filtroI} showIcon onChange={(e) => { setDate_filtroI(e.value); datiGen.getInstance().setRicercaDtDa(e.value); }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} htmlFor='dt2' >A</label>
          <Calendar id="dt2" value={date_filtroF} showIcon onChange={(e) => { setDate_filtroF(e.value); datiGen.getInstance().setRicercaDtA(e.value); }} />
        </div>
      </div>
}
      {show_filtro &&
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem' }}>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Tipo Attività:</label>
            <Dropdown
              value={tipoFiltro} showClear
              options={elenchi.getInstance().getElTipoatt()}
              style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
              disabled={!abilUpd}
              onChange={vcf => {
                setTipoFiltro(vcf.target.value);
                datiGen.getInstance().setRicercaTipoAtt(vcf.target.value);

              }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Gruppi:</label>

            <Dropdown
              value={listaGruppiSQ.find((el) => el._id == gruppoFiltro)}
              options={listaGruppiSQ} optionLabel="des" optionValue='_id'
              style={{}} showClear
              onChange={(item) => {
                console.log(item, listaGruppiSQ);
                if (item.value == undefined) {
                  setGruppoFiltro(item.value);
                  datiGen.getInstance().setRicercaGruppi(item.value);

                }
                if (item.value) {
                  setGruppoFiltro(item.value._id);
                  datiGen.getInstance().setRicercaGruppi(item.value._id);

                }
              }} />

          </div>
          { tipoFiltro && tipoFiltro.split('#')[1] == 'P' &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Tipo Partita:</label>
            <Dropdown
              value={tipoPartitaFiltro} showClear
              options={elenchi.getInstance().getEl("TIPOPARTITA")}
              style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
              disabled={!abilUpd}
              onChange={vcf => {
                setTipoPartitaFiltro(vcf.target.value);
                datiGen.getInstance().setRicercaTipoPartita(vcf.target.value);
              }} />
          </div>
}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Tipo Gruppo:</label>
            <Dropdown
              value={tipogrp_Age} showClear
              options={elenchi.getInstance().getEl("TIPOCATEG")}
              style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
              disabled={!abilUpd}
              onChange={vcf => {
                setTipoGrp_Age(vcf.target.value);
                datiGen.getInstance().setRicercaTipogrp(vcf.target.value);
              }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }}  >Indirizzo/CS</label>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
              <InputText value={indFiltro}
                style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
                onChange={(e) => { setindFiltro(e.target.value); datiGen.getInstance().setRicercaIndirizzo(e.target.value); }} />

              <Button style={{ margin: "0" }} label='V' onClick={() => { setindFiltro("CS Vigorello"); datiGen.getInstance().setRicercaIndirizzo("CS Vigorello"); }}></Button>
              <Button style={{ margin: "0" }} label='V11' onClick={() => { setindFiltro("CS Vigorello - Campo 11"); datiGen.getInstance().setRicercaIndirizzo("CS Vigorello - Campo 11"); }}></Button>
              <Button style={{ margin: "0" }} label='V7' onClick={() => { setindFiltro("CS Vigorello - Campo 7"); datiGen.getInstance().setRicercaIndirizzo("CS Vigorello - Campo 7"); }}></Button>
              <Button style={{ margin: "0" }} label='V5' onClick={() => { setindFiltro("CS Vigorello - Campo 5"); datiGen.getInstance().setRicercaIndirizzo("CS Vigorello - Campo 5"); }}></Button>
            </div>
          </div>



        </div>
      }

{!show_filtro &&
 <div style={{ flex: 1 ,margin:'0.5rem',justifyContent:'center',alignItems:'center',justifyItems:'center',width:'100%'}}>
 <label className='Titolo5' style={{ flex: 1,textAlign:'center',width:'100%'}} >Elenco attività</label>

    </div>

}
      <div style={{ flex: 1, width: '100%' }}>
        <DataView
          header={headerElement}
          value={data}
          itemTemplate={renderItem}
        />
      </div>

      
      <div className="card flex justify-content-center">
            <Menu model={items} className="w-full md:w-15rem" popup ref={menuPrint}/>
        </div>
    </div>



  );
}
export default ListaAttivita;