
import React, { useState, useEffect, useRef } from 'react';
import datiGen from '../util/datiGen.js';
import moment from 'moment'
import restConfigurazioniTest from '../restcall/restConfigurazioniTest.js';
import test_tipo from '../obj/test_tipo.js';
import test_tipoRisutati from '../obj/test_tipoRisutati.js';
import { useNavigate } from 'react-router';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import elenchi from '../util/elenchi.js';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Toast } from 'primereact/toast';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiContentSaveAll, mdiAccountSwitchOutline, mdiPlus, mdiAccountMinus,
  mdiAccountDetails, mdiAccountMultiple, mdiCheckAll,
  mdiCloseBox, mdiPencil, mdiDelete
} from '@mdi/js';

const TestConf = (props) => {
  const navigation = useNavigate();


  const abilTest = datiGen.getInstance().possoaccederev2('TESTCONF');


  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [dettTestVisible, setDettTestVisible] = useState(false);
  const [date_ival, setDate_ival] = useState(new Date());
  const [show_ival, setShow_ival] = useState(false);
  const [date_fval, setDate_fval] = useState(new Date());
  const [show_fval, setShow_fval] = useState(false);
  const [daSalvare, setDaSalvare] = React.useState(false);
  const [mostraValidi, setMostraValidi] = useState(true);
  const [newTipoRisTest, setNewTipoRisTest] = useState();
  const dlg = useRef(null);
  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo, life: 3000 });
  }
  const showWarn = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: testo, life: 3000 });
  }
  const showError = (testoerr) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: testoerr, life: 3000 });
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };
  const getListaTest = async () => {

    try {
      setLoading(true);
      var json = await restConfigurazioniTest.getInstance().getAllConfigurazioniTest(mostraValidi).then((json) => {
        setData(json);
        console.log('getlistatest', data);
        setDaSalvare(false);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  const toggleOverlay = () => {
    if (daSalvare) {
      console.log('toggle if');
      confirm_salva();
    }
    else {
      console.log('toggle else');
      setDettTestVisible(!dettTestVisible);
    }
  };


  //// da ripristinare per fare click dettaglio
  ///// onClick={() => { setSelected(item); toggleOverlay(); }}

  const confirm_salva = () => {
    confirmDialog({
      message: 'Non tutte le modifiche sono state salvate. Sei sicuro di voler cambiare pagina e perdere le modifiche?',
      header: 'Attenzione !!',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        setDettTestVisible(false); setDaSalvare(false);
      }
      ),
      reject: (() => { }),
    });
  };

  const renderItem = (item, index) => (

    <div className='listaItem'>
      <div style={{ flex: 2, flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' }}>
        {item && abilTest &&
          <div style={{ display: 'flex', flex: 5, flexDirection: 'row', justifyContent: 'space-around' }}     >
            <label style={{ fontWeight: 600 }} >{item.nometest} </label>
            <div style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-end', alignContent: 'flex-end', alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
              <Button
                onClick={() => { setSelected(item); toggleOverlay(); }}>
                <Icon path={mdiAccountMultiple} size={0.8} />
              </Button>
            </div>
          </div>
        }

      </div>
    </div>

  )



  const modifica = async (ptest) => {
    try {
      setLoading(true);
      ptest.tipoRisultati.forEach(element => {
        if (!element.codice || element.codice.lenght < 1)
          throw 'Scegliere un tipo risultato';
        else {
          console.log('element', element);
          element.capacita.forEach(elemcap => {
            if (elemcap == null || !elemcap.capacita || elemcap.capacita.lenght < 1)
             {showError('Scegliere una capacità per poter salvare')
              throw 'Scegliere una capacità ';
             }
          });
        }
      });
      var json = await restConfigurazioniTest.getInstance().modifica(ptest);
      getListaTest();
      setDettTestVisible(false);
      //console.log("DettSchedaGioc", "modifica", json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }


  }


  const elimina = async (ptest) => {
    try {
      setLoading(true);
      var json = await restConfigurazioniTest.getInstance().elimina(ptest);

      getListaTest();
      setDettTestVisible(false);
      //console.log("DettSchedaGioc", "modifica", json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }


  }


  const aggiungidb = async (ptest) => {
    try {
      setLoading(true);
      var json = await restConfigurazioniTest.getInstance().aggiungi(ptest);
      setDaSalvare(false);
      getListaTest();
      setDettTestVisible(false);
      //console.log("DettSchedaGioc", "modifica", json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }


  }


  const aggiungi = async () => {

    let t = new test_tipo();
    t.idsoc = datiGen.getInstance().getActiveSoc().sigla;
    t.nometest = '';

    setSelected(t);
    toggleOverlay();


  }

  const onChange_ival = (event, selectedDate) => {
    if (selectedDate) {
      setShow_ival(false);
      let x = { ...selected };
      x.ival = new Date(selectedDate);
      setSelected(x);
      setDaSalvare(true);
    }
  };
  const onChange_fval = (event, selectedDate) => {
    if (selectedDate) {
      setShow_fval(false);
      let x = { ...selected };
      x.fineval = new Date(selectedDate);
      setSelected(x);
      setDaSalvare(true);
    }
  };

  useEffect(() => {
    console.log('mostraValidi', mostraValidi);
    getListaTest();
  }, [mostraValidi]);

  useEffect(() => {
    console.log('mostraValidi', mostraValidi);
    getListaTest();
  }, []);




  const aggiungiris = async (valo) => {
    try {
      if (!selected.tipoRisultati)
        selected.tipoRisultati = [];
      // var   arr=[ valo._id,valo.nometest];
      let x = { ...selected };
      let tipor = new test_tipoRisutati();
      console.log('aggiungi ris', valo.value, valo.label);
      tipor.codice = valo.value;
      var val = elenchi.getInstance().getElRisultati().find((el) => el.value == valo.value)
      console.log('aggiungi ris prima ass', val);

      tipor.des = elenchi.getInstance().getElRisultati().find((el) => el.value == valo.value).label;
      console.log('aggiungi ris dopo ass', tipor.codice, tipor.des);

      tipor.capacita.push({ capacita: null, peso: 5 })
      x.tipoRisultati.push(tipor);
      setSelected(x);
      setDaSalvare(true);
        console.log('TEST CONF','aggiugni risultato al test',selected.eltest);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const aggiungicapacita = async (ptiporis) => {
    try {

      // var   arr=[ valo._id,valo.nometest];
      let x = { ...selected };
      ptiporis.capacita.push({ capacita: null, peso: 5 });
      setSelected(x);
      setDaSalvare(true);
      //  console.log('aggiugni test',selected.eltest);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  const confirm_eleris = (eler) => {

    confirmDialog({
      message: 'Il tipo risultato sarà eliminata dal test. Sei sicuro di voler procedere?',
      header: 'Attenzione !!',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        let x = { ...selected };
        const index = selected.tipoRisultati.indexOf(eler);
        if (index > -1) {
          selected.tipoRisultati.splice(index, 1);
          setSelected(x);
          setDaSalvare(true);
        }
      }),     ///// implemetnare salva
      reject: (() => { }),    ///// implementare esci
    });

  };

  const confirm_elimina = (eler) => {

    confirmDialog({
      message: 'Il test sarà eliminato. Sei sicuro di voler procedere?',
      header: 'Attenzione !!',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        elimina(eler);
      }),     ///// implemetnare salva
      reject: (() => { }),    ///// implementare esci
    });

  };



  const confirm_eliminaris = (eler) => {

    confirmDialog({
      message: 'Il tipo risultato sarà eliminata dal test. Sei sicuro di voler procedere??',
      header: 'Attenzione !!',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        let x = { ...selected };
        const index = selected.tipoRisultati.indexOf(eler);
        if (index > -1) {
          selected.tipoRisultati.splice(index, 1);
          setSelected(x);
          setDaSalvare(true);
        }
      }),     ///// implemetnare salva
      reject: (() => { }),    ///// implementare esci
    });

  };



  const confirm_eliminariscap = (elecap, ele) => {

    confirmDialog({
      message: 'La capacità associata sarà eliminata da questo risultato. Sei sicuro di voler procedere??',
      header: 'Attenzione !!',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        let x = { ...selected };
        const index = ele.capacita.indexOf(elecap);
        if (index > -1) {
          ele.capacita.splice(index, 1);
          setSelected(x);
          setDaSalvare(true);
        }
      }),     ///// implemetnare salva
      reject: (() => { }),    ///// implementare esci
    });

  };



  const renderTipoRis = (ele, pos) => (
    <div className='listaItem'  >
      {abilTest &&
      <div style={{ flex: 1, width: '100%', marginBottom: 2 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', alignItems: 'center' }}>
            
          <Button
            style={{ alignSelf: 'flex-start' }}
            onClick={() => {
              confirm_eliminaris(ele);
            }}
          >
            <Icon path={mdiAccountMinus} size={0.8} />
          </Button>


          <label style={{ fontWeight: 'bold' }}> {ele.des}</label>
          <Button
            style={{ marginRight: 40 }}
            onClick={() => { aggiungicapacita(ele); }}>
            <Icon path={mdiPlus} size={0.8} />
          </Button>
        </div>
        {ele.capacita && ele.capacita.map((elecap, icap) => (
          <div key={"cc" + icap} style={{
            display: 'flex', alignItems: 'center', flexDirection: 'row', borderBottomWidth: 0.3,
            borderBottomColor: '#0a568d', paddingBottom: 1, paddingTop: 1, width: '100%', columnGap: '0.4rem'
          }}>
            <Dropdown
            className='longInput'
              placeholder='Seleziona capacità'
              options={elenchi.getInstance().getCapacita()}
              optionLabel="label"
              optionValue="value"
              value={elecap.capacita}
              onChange={item => { let x = { ...selected }; elecap.capacita = item.value; setSelected(x); setDaSalvare(true); }}
            />
            <label style={{ fontWeight: 200 }}>Peso:</label>
            <IconField iconPosition="left"  >
              <InputIcon>
                <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
              </InputIcon>
              <InputText
                value={elecap.peso} className='mediumInput'
                onChange={vcf => { console.log('onchange teìxt e', vcf.target.value); let x = { ...selected }; elecap.peso = vcf.target.value; setSelected(x); setDaSalvare(true); }}
              >
              </InputText>
            </IconField>
            <Button
              onClick={() => { confirm_eliminariscap(elecap, ele); }}
            >
              <Icon path={mdiDelete} size={0.8} />
            </Button>
          </div>
        )
        )}

      </div>

      }
      <hr></hr>
    </div>
  )



  const headerElement = (
    <div className=" inline-flex align-items-center justify-content-center gap-2">
      <div className="listazioni" style={{ paddingLeft: "0.3em", justifyContent: 'space-between' }}  >
        <label style={{}} >{selected && selected.nometest}</label>
      {abilTest &&
        <div className="listazioni" style={{ paddingLeft: "0.3em" }}  >

        {selected && abilTest && (daSalvare) && !selected._id && (
                <Button
                                onClick={() => aggiungidb(selected)}
                >
                                <Icon path={mdiPlus} size={0.8} />
                </Button>
              )}
         {abilTest && daSalvare && selected._id &&
            <Button
              onClick={() => modifica(selected)}
            >
              <Icon path={mdiContentSaveAll} size={0.8} />
            </Button>
          }
          {abilTest && selected._id &&
            <Button
              onClick={() => confirm_elimina(selected)}
            >
              <Icon path={mdiDelete} size={0.8} />
            </Button>
          }
          <Button
            onClick={() => { setDettTestVisible(false); }}
          >
            <Icon path={mdiCloseBox} size={0.8} />
          </Button>
        </div>
}
      </div>
    </div>
  );





  return (
    <div>
      <ConfirmDialog />
      <Toast ref={toast} />

      {data && abilTest &&
        <div style={{ flex: 1, width: '100%' }}>
          <Dialog ref={dlg} header={headerElement} visible={dettTestVisible} style={{ width: '95vw', maxHeight: '95%', minHeight: '50%' }} closable={false}
            closeOnEscape={true}
            onHide={() => { if (!dettTestVisible) return; setDettTestVisible(false) }}>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%' }}>
              <div className='dati'>  <label style={{ fontWeight: 600 }}>Nome test:</label>
                <IconField iconPosition="left"  >
                  <InputIcon>
                    <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                  </InputIcon>
                  <InputText
                    value={selected.nometest}
                    onChange={vcf => { console.log("on change", vcf, vcf.value); let x = { ...selected }; x.nometest = vcf.target.value; setSelected(x); setDaSalvare('true'); }}
                  >
                  </InputText>
                </IconField>
              </div>

              <div className='dati'>  <label style={{ fontWeight: 600 }}>Descrizione test:</label>
                <IconField iconPosition="left"  >
                  <InputIcon>
                    <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                  </InputIcon>
                  <InputText
                    value={selected.descrizione}
                    onChange={vcf => { console.log("on change", vcf, vcf.value); let x = { ...selected }; x.descrizione = vcf.target.value; setSelected(x); setDaSalvare('true'); }}
                  >
                  </InputText>
                </IconField>
              </div>

              <div className="dati">
                <label htmlFor="datei">Data di inizio validità</label>
                <Calendar value={new Date(selected.ival)} onChange={(e) => { setSelected({ ...selected, ival: e.value }); setDaSalvare(true) }} id="datei" />

              </div>
              <div className="dati">
                <label htmlFor="datei">Data di fine validità</label>
                <Calendar value={new Date(selected.fineval)} onChange={(e) => { setSelected({ ...selected, fineval: e.value }); setDaSalvare(true) }} id="datei" />
              </div>

              <div className='dati'>  <label style={{ fontWeight: 600 }}>Giorni di validità:</label>
                <IconField iconPosition="left"  >
                  <InputIcon>
                    <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                  </InputIcon>
                  <InputText
                    value={selected.giornival}
                    onChange={vcf => { console.log("on change", vcf, vcf.value); let x = { ...selected }; x.giornival = vcf.target.value; setSelected(x); setDaSalvare('true'); }}
                  >
                  </InputText>
                </IconField>
              </div>

              <div className='dati' >
                <label style={{ fontWeight: 600 }} >Area di riferimento:</label>
                <Dropdown
                  options={elenchi.getInstance().getArea()}
                  optionLabel="label"
                  optionValue="value"
                  value={selected.area}
                  onChange={item => {
                    console.log(item, item.value);
                    let x = { ...selected };
                    x.area = item.value; setSelected(x); setDaSalvare(true);
                  }} />
              </div>

              <div style={{ backgroundColor: '#00adef50', width: '100%' }}>
                <div className='dati' style={{display:'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center',marginBottom:'0.2rem',marginTop:'0.2rem' }}>
                  <label style={{ color: '#0a568d', marginRight: '2rem', fontWeight: 'bold' }}>Nuovo tipo Risultato</label>
                  <Dropdown
                    placeholder='Seleziona risultato'
                    options={elenchi.getInstance().getElRisultati()}
                    optionLabel="label"
                    optionValue="value"
                    value={newTipoRisTest}
                    onChange={val => { aggiungiris(val); setNewTipoRisTest(val.target.value) }}
                    iconColor='#0a568d'
                  />
                </div>
                {selected && selected.tipoRisultati && selected.tipoRisultati.map((ele, pos) => renderTipoRis(ele, pos))
                }
              </div>
            </div>
          </Dialog>
        </div>
      }


      <div style={{ flex: 1, width: '100%' }}>
        <DataView
          header={
            <div className="listazioni">
              <span style={{ color: 'white' }}>Elenco Test</span>
              <Button
                onClick={() => aggiungi()}>
                <Icon path={mdiPlus} size={0.8} />
              </Button>
              <Button
                onClick={() => { setMostraValidi(!mostraValidi) }}>
                <Icon path={mdiCheckAll} size={0.8} />

              </Button>
              <Button
                onClick={() => getListaTest()}>
                <Icon path={mdiUpdate} size={0.8} />
              </Button>
            </div>
          }
          value={data}
          itemTemplate={renderItem}
          rows={25}

        />



      </div>

    </div>

  );
}
export default TestConf;