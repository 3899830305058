

export default class datiInit {

  constructor(datiGen) {

 
    this.restUrlServer= '#';
    this.amb='#';
    //utente
    //profiloutente
  }



  static myInstance = null;

  static getInstance() {
    if (datiInit.myInstance == null) {
      datiInit.myInstance = new datiInit();
      // Util.myInstance.inizializza();
      datiInit.myInstance.inizializza().then(() => console.log('Inizializing'));


    }



    return datiInit.myInstance;
  }


  inizializza() {
    return new Promise((resolve, reject) => {

     
      this.caricaamb();

    });
  }


  caricaamb()
{
  
  
  if (process.env.NODE_ENV === "development") {
    this.amb='DEV';

   //this.restUrlServer= '#';
   // this.restUrlServer= 'http://192.168.10.3:3000';
   
   this.restUrlServer= 'https://ws.vigormilano.it';
  //  this.restUrlServer= '#';
 //this.restUrlServer= 'http://localhost:3000';
//salva dd
   // this.restUrlServer= 'http://192.168.10.75:3000';
 ///this.restUrlServer= 'https://vigorapp.bs4.it';
  }
 
  if (process.env.NODE_ENV === "Production") {
    this.amb='PROD';
//  this.restUrlServer= 'https://vigorapp.bs4.it';

    this.restUrlServer= 'https://ws.vigormilano.it';
  }
  console.log('datiinit',this.amb,this.restUrlServer);
}

getRestUrlServer()
{
  return this.restUrlServer;
}

  getAmb()
  {
    return this.amb;
  }






}
