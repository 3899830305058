
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import ListaPersone from '../comp/ListaPersone';



const Persone = () => {

  const navigate = useNavigate();
  
  

/*
  useEffect(() => {


  }, []);
*/

  return (
    <div>


    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
     marginTop:"2rem"
    }}>
  
  
<ListaPersone />
  
  </div>
  </div>
  );
}



export default Persone;
