// screens/Home.js

import React, { useEffect, useState, useRef } from 'react';

import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';

import utilVisite from '../util/utilvisite.js';



import Person from '../obj/person.js';

import restPersona from '../restcall/restPersona';
import datiGen from '../util/datiGen.js';
import restGruppi from '../restcall/restGruppi.js';
import moment from 'moment'


import restGiocGruppi from '../restcall/restGiocGruppi.js';
import giocGruppoSQ from '../obj/giocGruppoSQ.js';

import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import 'primeicons/primeicons.css';
import { Toast } from 'primereact/toast';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { DataScroller } from 'primereact/datascroller';
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { t } from 'i18next';

import Icon from '@mdi/react';
import {
  mdiAccountNetwork, mdiFlagCheckered, mdiMenuOpen, mdiAccountMultiple, mdiAccountGroup, mdiSoccer, mdiCakeVariant,
  mdiHome,
  mdiLogout,
  mdiClose,
  mdiAccount,
  mdiSoccerField,
  mdiRunFast,
  mdiAccountMultiplePlus,
  mdiAccountTie,
  mdiClipboard,
  mdiClipboardPlus,
  mdiPlus,
  mdiRefresh,
  mdiSync

} from '@mdi/js';

const ListaPersone = () => {
  const navigate = useNavigate();

  

  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD');
  const abilSCHGIOC = datiGen.getInstance().possoaccederev2('SCHGIOC_UI');
  const abilSCHOSS = datiGen.getInstance().possoaccederev2('SCHOSS');
  const abilGIOCGRUPPO_ASS = datiGen.getInstance().possoaccederev2('GIOCGRUPPO_ASSOCIA');
  const abilTest = datiGen.getInstance().possoaccederev2('REPTEST');

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [riccog, setRiccog] = useState();
  const [ricnom, setRicnom] = useState();
  const [ricanno, setRicanno] = useState();
  const [assSQ, setassSQ] = useState();
  const [anagDAbil, setAnagDAbil] = useState(datiGen.getInstance().possoaccederev2('ANAGDETT'));
  const [listaGruppiSQ, setListaGruppiSQ] = useState();
  const [attivaAssSQ, setattivaAssSQ] = useState(false);
  const toast = useRef(null);
  const edtTimer = useRef(null);


  const getPersone = async (pcog, pnom, panno) => {
    try {
      setLoading(true);
      var json = await restPersona.getInstance().getPersone(pcog ? pcog : riccog, pnom ? pnom : ricnom, panno ? panno : ricanno);
      console.log("getPersone2", data);
      setData(json);


    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  const getPersonevis = async () => {
   

    try {
      //setLoading(true);
      var json = await restGiocGruppi.getInstance().getVisiteGruppo('***');
     
     if (json.esito==='OK' )
      
   //  console.log('getpersonevis_2',json);
     if (json.esito==='OK')
          utilVisite.getInstance().createPDF(json);
          else
          {
            showMessage({
              message: "Error",
              description:json.des,
              type:"danger",
              duration:5000,
            });
          }

    } catch (error) {
      console.error('getPersonevis','x654',error);
    } finally {
      //setLoading(false);
    }

  }

  const showMessage = (msg) => {
    toast.current.show({ severity: 'info', summary: msg.message, detail: msg.description });
  };
  const showNotifica = (titolo, testo) => {
    toast.current.show({ severity: 'info', summary: titolo, detail: testo });
  };
  const show = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo });
  };
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo });
  };
  const showAlert = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };
  const showError = (testo) => {
    toast.current.show({ severity: 'error', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };

  const getListaSQ = async () => {
    try {
      setLoading(true);
      var json = await restGruppi.getInstance().getGruppi();
      setListaGruppiSQ(json);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const renderItem = (item, index) => (
    <div className='listaItem'>
      <div style={{ flex: 2, flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' }}>

        <div>
          <label style={{fontWeight:'bold'}} >  {item.cognome}   {item.nome}</label><br />
          <label style={{ color: "gray" }}>{new moment(item.datanascita).format('DD/MM/YYYY')}</label>

        </div>

        <div style={{ flex: 2, flexDirection: 'row', alignSelf: 'flex-end', alignContent: 'flex-end', alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>

          {anagDAbil && !attivaAssSQ && 
            
            <Button

            onClick={() => navigate("/DettPersone", { state: { selpersona : item}})   }
            

            >
              <Icon path={mdiAccount} size={0.8} />
            </Button>
          }

          {abilSCHGIOC && !attivaAssSQ &&
            <Button

            onClick={() => navigate('/SchedaGiocPage', {state:{ selpersona: item} })}
            >
              <Icon path={mdiSoccer} size={0.8} />
            </Button>
          }
          {abilSCHOSS && !attivaAssSQ &&
            <Button

            onClick={() =>     navigate('/SchedaOssPage', {state:{ person_id: item._id} })    }  
            >
              <Icon path={mdiSoccerField} size={0.8} />
            </Button>
          }

          {abilTest &&
            <Button

            onClick={() => navigate('/ReportisticaTestGioc', {state:{ selpersona: item, idgioc: item._id, tipo: 'TestReport' }})}
            >
              <Icon path={mdiRunFast} size={0.8} />
            </Button>
          }


          {abilGIOCGRUPPO_ASS && attivaAssSQ && assSQ &&
            <Button

              onClick={() => { associaGrp(item, 'GIOC'); }}
            >
              <Icon path={mdiAccountMultiplePlus} size={0.8} />
            </Button>
        
        
          }
          {abilGIOCGRUPPO_ASS && attivaAssSQ && assSQ &&
            <Button
              
              onClick={() => { associaGrp(item, 'STAFF'); }}
            >
              <Icon path={mdiAccountTie} size={0.8} />
            </Button>
          }

        </div>
      </div>
    </div>
  )


  const aggiungi = async () => {
    const ids = datiGen.getInstance().getActiveSoc();
    //console.log("ListaPersone","ids",ids)
    navigate("/DettPersone", { state: { selpersona : new Person(ids.sigla, riccog, ricnom)}})
    setRicnom("");
    setRiccog("");


  }

  const associaGrp = async (item, ptipo) => { 
    console.log("ListaPersone","ids",item,ptipo)
    const ids = datiGen.getInstance().getActiveSoc();  

    if (!assSQ) {
      showMessage({
        message: "Attenzione",
        description: 'Selezionare il gruppo prima di procedere',
      });
      return;
    }

    var t = new giocGruppoSQ();
    t.idsoc = ids.sigla;
    t.idgioc = item._id;
    t.idgruppoSQ = assSQ._id;
    t.tipo = ptipo;
    try {

      var json = await restGiocGruppi.getInstance().aggiungi(t);
      if (json.esito == 'ko') {
        showMessage({
          message: "Attenzione",
          description: json.des,

        });
      }
      else {
        if (ptipo == 'GIOC') {
          showMessage({
            message: "Salvataggio eseguito",
            description: "Il giocatore " + item.cognome + " " + item.nome + "  è stato aggiunto correttamente al gruppo",
          });

        }
        else {
          showMessage({
            message: "Salvataggio eseguito",
            description: item.cognome + " " + item.nome + "  è stato aggiunto correttamente allo staff del gruppo",
          });
        }


      }
    } catch (error) {
      showMessage({
        message: "Attenzione",
        description: error,


      });
    } finally {

    }


  }


  useEffect(() => {

  }, [data]);

  useEffect(() => {
    setRiccog(datiGen.getInstance().getRicercaCog());
    setRicnom(datiGen.getInstance().getRicercaNom());
    setRicanno(datiGen.getInstance().getRicercaAnno());

    getPersone(datiGen.getInstance().getRicercaCog(), datiGen.getInstance().getRicercaNom(), datiGen.getInstance().getRicercaAnno());
    getListaSQ();

    return () => {
      if (edtTimer.current) clearTimeout(edtTimer.current);
    }

  }, []);



  const selezgruppo = async (val) => {
    setassSQ(val);   
  }


  return (
    
    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    }}>
      <Toast ref={toast} position='center' />

      <div style={{ flexDirection: 'row',  display:'flex',padding:'0.2rem',alignSelf:'center',justifyContent:'center'}}>
        <InputText placeholder='cognome' className="longInput"
          onChange={(vcognome) => {
            setRiccog(vcognome.target.value); datiGen.getInstance().setRicercaCog(vcognome.target.value);
            if (edtTimer.current) clearTimeout(edtTimer.current); edtTimer.current = setTimeout(() => { getPersone(vcognome.target.value); }, 500);
          }}
          onBlur={() => { if (edtTimer.current) clearTimeout(edtTimer.current); getPersone(); }}
          value={riccog}
        ></InputText>
        <InputText placeholder='nome' className="longInput"
          onChange={(vnome) => {
            setRicnom(vnome.target.value); datiGen.getInstance().setRicercaNom(vnome.target.value);
            if (edtTimer.current) clearTimeout(edtTimer.current); edtTimer.current = setTimeout(() => { getPersone(riccog, vnome.target.value); }, 500);
          }}
          value={ricnom}
          onBlur={() => { if (edtTimer.current) clearTimeout(edtTimer.current); getPersone(); }}
        ></InputText>
        <InputText placeholder='anno' className="smallInput"
          onChange={(vanno) => {
            setRicanno(vanno.target.value); datiGen.getInstance().setRicercaAnno(vanno.target.value);
            if (edtTimer.current) clearTimeout(edtTimer.current); edtTimer.current = setTimeout(() => { getPersone(riccog, ricnom, vanno.target.value); }, 500);
          }}
          onBlur={() => { if (edtTimer.current) clearTimeout(edtTimer.current); getPersone(); }}
          value={ricanno}
        ></InputText>
      </div>


      <div style={{ flex: 100, padding: 24, width: '100%' }}>
      {attivaAssSQ && abilGIOCGRUPPO_ASS &&
        <div style={{  flexDirection: 'row',width: '100%',display:'flex',alignItems: 'center', minHeight: 30 }} >
          <label style={{ flex: 1,textAlign:'right',marginRight:'0.3rem' }} >Gruppo:</label>

      <Dropdown value={assSQ} onChange={(e) => selezgruppo(e.value)} options={listaGruppiSQ} optionLabel="des" 
    placeholder="Seleziona il gruppo" showClear  className="w-full md:w-14rem" style={{ flex: 1 }} />


        </div>}
        <DataView 

          header=
          {<div className="listazioni">

            {abilAnagUPD &&
              <Button
              onClick={() => aggiungi()}
              >
                <Icon path={mdiPlus} size={0.8} />
              </Button>
            }
            {anagDAbil &&
              <Button
              onClick={() => getPersonevis()}
              >
                <Icon path={mdiClipboardPlus} size={0.8} />
              </Button>
            }
            <Button
              
              onClick={() => getPersone()    }
            >
              <Icon path={mdiSync} size={0.8} />
              </Button>
            {abilGIOCGRUPPO_ASS &&
              <Button
                onClick={() => setattivaAssSQ(!attivaAssSQ)}
                >
                <Icon path={mdiAccountMultiplePlus} size={0.8} />
                </Button>
                }
          </div>}
          value={data}

          itemTemplate={renderItem}
          rows={20}
        />


      </div>
    </div>
  );
}
export default ListaPersone;