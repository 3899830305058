// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';
import { useNavigate, useOutletContext,useLocation } from "react-router-dom";

import moment from "moment";

import DettaglioPersona from '../comp/DettaglioPersona';
import DettaglioSchedaOss from '../comp/DettaglioSchedaOss';
import restSchedaOss from '../restcall/restSchedaOss';
import restAttivita from '../restcall/restAttivita';
import GiocAtt from '../comp/GiocAtt';
import StaffAtt from '../comp/StaffAtt'; 


const GiocAttPage = (props) => {
  const navigation = useNavigate();
const location=useLocation();

  const [mostraStaff,setMostraStaff]=useState(false);

 

  useEffect(() => {
      console.log('gioc att page','use effect',location.state.gruppo);
  }, [location.state.gruppo,location.state.selAttivita,mostraStaff]);


const cambiapag=()=>
{
  setMostraStaff(!mostraStaff);
}


  return (

    <div style={{
      width:'100%',
      height:'100%',
      minHeight:'100%',
      flex:1,
      minWidth:'100%',
      maxWidth:'100%',
      justifyContent:'flex-start'


    }}>
      {!mostraStaff &&
        <GiocAtt gruppo={location.state.gruppo} attivita={location.state.selAttivita}  cambiaPagina={cambiapag}  />
      }
      {mostraStaff &&
        <StaffAtt gruppo={location.state.gruppo} attivita={location.state.selAttivita} cambiaPagina={cambiapag}/>
      }
    </div>
    
  )
} 
export default GiocAttPage;