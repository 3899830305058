import Stampe from './Stampe.js';
import visita from '../obj/Visita.js';
import moment from 'moment';
import logo from '../img/logobianco.png';

export default class utilVisite {

  constructor() {

  }

  


  static myInstance = null;

  static getInstance() {
    if (utilVisite.myInstance == null) {
      utilVisite.myInstance = new utilVisite();
      // Util.myInstance.inizializza();
     // utilVisite.myInstance.inizializza().then(() => console.log('Inizializing'));


    }



    return utilVisite.myInstance;
  }

/*
  inizializza() {
    return new Promise((resolve, reject) => {

     
    

    }, (error) => {

      reject(error);
    })
  }
*/


 createPDF =  (json)=> {
  
  //console.log('creapdf 11');

  const x=   this.eseRepVisite(json);
  //console.log('creapdf 12');
  
 Stampe.getInstance().StampaHtlm(x,'Visite.pdf');
  }


eseRepVisite =(json)=>
{
  var logov=logo;
  var outconv='';
  var texthtml
  console.log(json);var listav;
try
{
   listav = json.sort((ele1, ele2) => ele1.root.Giocatore.cognome.localeCompare(ele2.root.Giocatore.cognome));
}
catch(err)
{ console.log('errore in ordinamento lista');
   listav = json;
}

  listav.forEach(ele => {
   
    var visitefiltrate= ele.visite.filter((obj)=> obj.tipo === "idoneità");

    if (ele._id!=undefined && visitefiltrate!=undefined &&  ele.root.Giocatore && Object.keys(visitefiltrate).length >0 && visitefiltrate[0]!=undefined )
    {
      visitefiltrate[0].__proto__ =  visita.prototype; 
      visitefiltrate[0].to_obj() ;
  var coloretesto='black';
  if (visitefiltrate[0].isScaduta()) coloretesto='red';
  else if (visitefiltrate[0].isInscadenza()) coloretesto='orange';
  else coloretesto='black';
      outconv=outconv+ '<tr><td style="text-align:left;">'+ ele.root.Giocatore.cognome+'</td><td  style="text-align:left;"> '+ele.root.Giocatore.nome+'</td><td  style="text-align:left;"> '+ moment(ele.root.Giocatore.datanascita).format('DD/MM/YYYY')+'</td><td style="color:'+coloretesto+'" > '+   visitefiltrate[0].getScadenza().format('DD/MM/YYYY')+'</td></tr> ';
    }
    else 
    {
      if (ele._id && ele.root.Giocatore )
      {
      
      var coloret='red';
    outconv=outconv+ '<tr><td style="text-align:left;">'+ ele.root.Giocatore.cognome+'</td><td  style="text-align:left;"> '+ele.root.Giocatore.nome+'</td><td  style="text-align:left;"> '+ moment(ele.root.Giocatore.datanascita).format('DD/MM/YYYY')+'</td><td style="color:'+coloret+'" > '+   'NESSUNA VISITA '+'</td></tr> ';
    
      }
      else
      {
        console.log('creapdf','E5430',ele);
      }
    }
    
  }
  
  );
//console.log('listapersone','dopo for each',outconv);

texthtml=`<html>
<head>
<style type="text/css">
h1 { color:#F1F1F1 }
table,th, td {
  border: 0.5px solid #cccccc;
  border-collapse: collapse;
  padding-left:5px;
  padding-right:5px;
  font-size:20pt;
  text-align:center;
}
</style>
</head>

<body style='text-align:center'>
<div style="height:100%;display:block;" >
<div style="width:100%;display:flex;flex-direction:row">
  <div style="width:60%;">
  <img 
  src=" `+
  logov
  +`" 
  style="height:150px;float:left;width:auto;object-fit:contain;" />
  </div>
<div style='float:right;background-color:transparent;width:40%;text-align:left;'>
<h2>A.S.D. Vigor Milano</h2>
Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
Via IV Novembre 72, Cormano (MI)<br/>
tel: 392 985 88 51 <br/>
</div>

</div>
<h1 style='color:black'>VISITE IN SCADENZA E SCADUTE</h1>

<table style='width:98%; table-layout: auto;margin: 0 auto ;border:1'>
<tr>
<th style='text-align:left'>Cognome</th>
<th style='text-align:left'>Nome</th>
<th style='text-align:left'>Data Nascita</th>
<th style='text-align:left'>Data Visita</th>
</tr>
`+

outconv

+`


</table> 
<div style='text-align:left;'>  
<h2>NOTE</h2>
In rosso le visite scadute o non inserite<br/> In arancione le visite in scadenza<br/>In nero le visite in regola
</div>
</div>

</body>

<html>`;

return (texthtml  );

}



   


}