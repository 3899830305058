// screens/Home.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";

import { Button } from "primereact/button";

import Icon from '@mdi/react';
import { InputNumber } from "primereact/inputnumber";

import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { mdiCard, mdiClose, mdiContentSaveAll, mdiSoccer, mdiPlus,  mdiCalendarMultipleCheck, mdiCheckboxMarkedOutline, mdiClock, mdiTimerOffOutline, mdiTimelapse, mdiPencil, mdiDelete, mdiAccount, mdiAccountCheck } from '@mdi/js';
import { Dropdown } from 'primereact/dropdown';
import { DataView } from 'primereact/dataview';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Calendar } from 'primereact/calendar';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import Attivita from "../obj/Attivita";
import restAttivita from "../restcall/restAttivita";
import datiGen from "../util/datiGen";
import moment from 'moment/min/moment-with-locales';
import { Dialog } from 'primereact/dialog';

const TabelinoPartita = (props) => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [att, setAttx] = useState();
  const [daSalvare, setDaSalvare] = useState(false);
  const [daNotificare, setDaNotificare] = useState(false);
  const [evPartMin, setEvPartMin] = useState(0);
  const [dialogvis, setDialogvis] = useState(false)

  const [evPartTipo, setEvPartTipo] = useState('###');
  const [evOra, setEvOra] = useState(new Date());

  const abilTABPARTITA = datiGen.getInstance().possoaccederev2('TABPARTITA', props.gruppo);
  const abilcommPARTITA = datiGen.getInstance().possoaccederev2('COMMPARTITA', props.gruppo);
  const [listaGiocatori, setListaGiocatori] = useState([]);
  const [evPartGioc, setEvPartGioc] = useState('');
  const [evPartGioc2, setEvPartGioc2] = useState('');

  const [selElemento, setSelElemento] = useState('');
  const [indexselElemento, setIndexSelElemento] = useState('');
  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();


  const [minutolive, setMinutolive] = useState('0');

  const [ult, setUlt] = useState('');

  useEffect(() => {
    if (props.selAttivita) {

      let tmp_att = props.selAttivita;
      tmp_att.__proto__ = Attivita.prototype;
      tmp_att.to_obj();

      if (tmp_att.cronaca)
        ordina(tmp_att.cronaca);
      
      setAtt(tmp_att);
      dettAtt(props.selAttivita._id, props.gruppo)
      ultimostato(tmp_att);
      

    } else {

    }
  }, []);


  useEffect(() => {
    console.log('modifco 0');
    if (daSalvare) {
      let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 3, '1');
      setStatoDaSalvare(x);
    } else if (!daSalvare) {
      let x = statoDaSalvare.replaceAll("1", "0");
      setStatoDaSalvare(x);
    }
  }, [daSalvare]);







  const setAtt=(x)=>{
    x.__proto__ = Attivita.prototype;
    x.to_obj();
    setAttx(x);
  }


  useEffect(() => {
  }, [selElemento]);


  const modifica = async (patt) => {
    try {
      restAttivita.getInstance().modifica(patt, props.gruppo, daNotificare).then(() => {
        setDaSalvare(false);
        setDaNotificare(false);
      }
      );
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const settaevento = (valor) => {
    try {
      setEvPartTipo(valor);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const ordina = (valor) => {
    try {
      valor.sort((ele1,ele2) => (ele2.tempo*10000+ele2.minuto)-(ele1.tempo*10000+ele1.minuto) );
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const dettAtt = (pidatt, pgrp) => {
    restAttivita.getInstance().getAttivita(pidatt, pgrp).then((json) => {
      //setListaGiocatori([]);
      listaGiocatori.length = 0;
      if (json.gioc && json.gioc.length > 1) {
        json.gioc.forEach((ele) => {
          if (ele.Giocatore[0]) {
            listaGiocatori.push({ _id: ele.Giocatore[0]._id, nominativo: ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome });
          }
        });
      }
    })
  }

  const elimina = async (ele) => {
    confirmDialog({
      message: "Sei sicuro di voler eliminare l'evento?. l'evento sarà cancellato",
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        let xatt = { ...att }
        var ind = xatt.cronaca.indexOf(ele);
        xatt.cronaca.splice(ind, 1);
        setDaSalvare(true);
        ultimostato(xatt);
        setAtt(xatt);
      }),
      reject: (() => { }),
    });
  }



  const addCronacaEv = async (patt) => {

    var grp = props.gruppo;
    var esci = 0;
    if (evPartMin < 1) {
      confirmDialog({
        message: "Valorizzare correttamente il campo minuti. Sei sicuro di voler procedere?",
        header: 'Attenzione',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => { inserisci(patt, 0); }),
        reject: (() => { esci = 1; }),
      });
    }
    else if (!evPartGioc || !evPartGioc == '') {
      confirmDialog({
        message: "Valorizzare correttamente il campo Giocatore. Sei sicuro di voler procedere?",
        header: 'Attenzione',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => { inserisci(patt, 0); }),
        reject: (() => { esci = 1; }),
      });
    }
    else inserisci(patt, 0);
  }
  const inserisci = (patt, esci) => {
    if (esci < 1) {
      if (!patt.cronaca)
        patt.cronaca = [];
      setDaSalvare(true);
      setDaNotificare(true);
      let gnom; let gnom2;

      if (evPartGioc)
        gnom = listaGiocatori.find(ele => ele._id === evPartGioc._id);


      if (evPartGioc2)
        gnom2 = listaGiocatori.find(ele => ele._id === evPartGioc2._id);

      if (evPartGioc && evPartGioc2)
        patt.cronaca.push(
          {
            tipoevento: evPartTipo,
            minuto: evPartMin,
            giocid: gnom._id,
            giocnom: gnom.nominativo,
            giocid2: gnom2 ? gnom2._id : '',
            giocnom2: gnom2 ? gnom2.nominativo : '',
            tempo: parseInt(ult.split('#')[3])
          });
      else if (evPartGioc)
        patt.cronaca.push(
          {
            tipoevento: evPartTipo,
            minuto: evPartMin,
            giocid: gnom._id,
            giocnom: gnom.nominativo,
            tempo: parseInt(ult.split('#')[3])


          });
      else {
        patt.cronaca.push(
          {
            tipoevento: evPartTipo,
            minuto: evPartMin,
            tempo: parseInt(ult.split('#')[3])


          });
      }

      ordina(patt.cronaca);
      setEvPartMin(0);
      setEvPartGioc('');
      setEvPartGioc2('');
      setEvPartTipo('###');
    }
  }


  const modificariga = (ele) => {
    setIndexSelElemento(att.cronaca.indexOf(ele));
    setSelElemento(ele);
    console.log('modificariga','dataora',ele.dataora,ele)
    setDialogvis(true);
  }

const salvamodifiche = () => {
 var gnom, gnom2;


let xx={...att};
xx.cronaca[indexselElemento]=selElemento;
ordina(xx.cronaca);
setAtt(xx);

setDaSalvare(true);
setDialogvis(false);
  }


  const tempoinizio = (patt, tempo) => {
    var val = 0;
    var el = patt.cronaca.find((ele) => ele.tipoevento == tempo);
    console.log(el);
    if (el) val = el.dataora.getTime();
    return val;
  }

  const ultimostato = (patt) => {
    var val = []; var el = '';
    if (patt && patt.cronaca) {
      for (var i = 0; i < patt.cronaca.length; i++) {
        if (patt.cronaca[i].tipoevento.split('#')[1] == 'T')
          val.push(patt.cronaca[i]);
      }
      if (val && val.length > 0)
        var el = val[val.length - 1];
      else var el = { tipoevento: '' };

      setUlt(el.tipoevento);
    }
  }


  const instiming = (patt) => {
    if (!patt.cronaca)
      patt.cronaca = [];
    setDaSalvare(true);
    setDaNotificare(true);
    var d = new Date();
    if (ult == '') {
      patt.cronaca.push(
        {
          tipoevento: '#T#Inizio#1',
          minuto: 0,
          dataora: d,
          tempo: 1,
          des: 'Inizio gara'
        });
    }
    else if (ult == '#T#Intervallo#1') {
      patt.cronaca.push(
        {
          tipoevento: '#T#Inizio#2',
          minuto: 0,
          tempo: 2,
          dataora: d,
          des: 'Inizio 2° tempo'
        });
    }
    else if (ult == '#T#Intervallo#2') {
      patt.cronaca.push(
        {
          tipoevento: '#T#Inizio#3',
          minuto: 0,
          tempo: 3,
          dataora: d ,
          des: 'Inizio 3° tempo'
        });
    }
    else if (ult == '#T#Intervallo#3') {
      patt.cronaca.push(
        {
          tipoevento: '#T#Inizio#4',
          minuto: 0,
          dataora: d,
          des: 'inizio 4° tempo',
          tempo: 4,
        });
    }
    ultimostato(patt);

  }


  const stoptiming = (patt) => {
    if (!patt.cronaca)
      patt.cronaca = [];
    setDaSalvare(true);
    setDaNotificare(true);
    var d = new Date();
    if (ult == '#T#Inizio#1') {
      patt.cronaca.push(
        {
          tipoevento: '#T#Intervallo#1',
          minuto: Math.round((d.getTime() - tempoinizio(patt, '#T#Inizio#1')) / 60000),
          dataora: d,
          des: 'Fine 1° tempo',
          tempo: 1,
        });
    }
    else if (ult == '#T#Inizio#2') {
      patt.cronaca.push(
        {
          tipoevento: '#T#Intervallo#2',
          minuto: Math.round((d.getTime() - tempoinizio(patt, '#T#Inizio#2')) / 60000),
          dataora: d,
          tempo: 2,
          des: 'Fine 2° tempo'
        });
    }
    else if (ult == '#T#Inizio#3') {
      patt.cronaca.push(
        {
          tipoevento: '#T#Intervallo#3',
          minuto: Math.round((d.getTime() - tempoinizio(patt, '#T#Inizio#3')) / 60000),
          dataora: d,
          des: 'Fine 3° tempo',
          tempo: 3,
        });
    }


    ultimostato(patt);

  }

  const finegara = (patt) => {
    if (!patt.cronaca)
      patt.cronaca = [];
    setDaSalvare(true);
    setDaNotificare(true);

    if (ult.split('#')[1] == 'T') {
      patt.cronaca.push(
        {
          tipoevento: '#T#finegara#9',
          minuto: 0,
          dataora: new Date(),
          des: 'Fine gara',
          tempo: 99
        });
    }
    ultimostato(patt);

  }




  const vai_dettAtt = () => {

    if (daSalvare) {
      confirmDialog({
        message: "Sei sicuro di voler uscire e perdere le modifiche inserite?",
        header: 'Attenzione',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => {   props.updateAzione(0) }),
        reject: (() => { }),
      });

    }
    else   props.updateAzione(0) ;

  }

const esci = () => {
/*
    if (daSalvare) {
      confirmDialog({
        message: "Sei sicuro di voler uscire e perdere le modifiche inserite?",
        header: 'Attenzione',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => { navigate("/attivita"); }),
        reject: (() => { }),
      });
    }
    else navigate("/attivita");
*/

if (props.gruppo)
  navigate('/TeamPage', {state:{ gruppo: props.gruppo  ,pagina:'Attivita' }     }  )

  else navigate("/attivita");


  }





  const renderItem1 = (ele, index) => (

    <div className='listaItem' >
      <div className="horizFlexcontainer" key={index} >
      {ele.tipoevento.split('#')[1] != 'T' &&   
        <label style={{ marginHorizontal: '2rem', width: '2rem' }}>{ele.minuto}</label>
      }
 {ele.tipoevento.split('#')[1] == 'T' &&   
        <label style={{ marginHorizontal: '2rem', width: '2rem' }}></label>
      }


    {ele.tipoevento.split('#')[1] == 'G' &&
          <Icon path={mdiSoccer} size={0.8} style={{ marginRight: '1rem', width: '2rem' }} />
        }
        {ele.tipoevento.split('#')[1] == 'A' &&
          <Icon path={mdiCard} color='yellow' size={0.8} style={{ marginRight: '1rem', width: '2rem' }} />
        }
        {ele.tipoevento.split('#')[1] == 'E' &&
          <Icon path={mdiCard} color='red' size={0.8} style={{ marginRight: '1rem', width: '2rem' }} />
        }
        {ele.tipoevento.split('#')[1] == 'T' &&
          <label style={{ marginRight: '1rem', width: '2rem' }}></label>
        }

        {ele.tipoevento.split('#')[1] != 'T' &&
          <label style={{ flex: 16 }}>{ele.giocnom}</label>
        }
        {ele.tipoevento.split('#')[1] == 'G' && ele.giocnom2 &&
          <label style={{ flex: 16 }}> assist {ele.giocnom2}</label>
        }
    
        {ele.tipoevento.split('#')[1] == 'T' &&
          <>
          <label style={{ flex: 16,fontWeight:'700'}}>{ele.des}</label>
            <label style={{ flex: 16 }}>{moment(ele.dataora).format('HH:mm')}</label>

          </>
        }


        {abilTABPARTITA &&
          <div>
            <Button
              onClick={() => { modificariga(ele) }}
            >
              <Icon path={mdiPencil} size={0.8} />
            </Button>
            <Button
              onClick={() => { elimina(ele) }}
            >
              <Icon path={mdiDelete} size={0.8} />
            </Button>
          </div>}
      </div>

    </div>

  )







  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0.3em', alignItems: 'center', borderBottom: "solid #0a568d 0.1rem" }}>
        <span className="Titolo5">Tabellino Partita  </span>

        <Dialog header="Modifica Tabellino" visible={dialogvis} style={{ width: '50vw' }} onHide={() => { if (!dialogvis) return; setDialogvis(false); }}>

          <label>Minuto:</label>
          <InputNumber
            disabled={!abilTABPARTITA}
            showButtons inputStyle={{ width: "3rem", textAlign: 'center' }}

            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
            value={selElemento.minuto}
            onChange={(e)=>{setSelElemento({ ...selElemento, minuto: e.value }); }}
          />
          <label>Tempo:</label>
          <InputNumber
            disabled={!abilTABPARTITA}
            showButtons inputStyle={{ width: "3rem", textAlign: 'center' }}

            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
            value={selElemento.tempo}
            onChange={(e)=>{setSelElemento({ ...selElemento, tempo: e.value }); }}
          />
          {selElemento && selElemento.tipoevento && selElemento.tipoevento.split('#')[1] != 'T' &&
          <Dropdown
            value={listaGiocatori.find((elef)=>elef._id==selElemento.giocid   )}
            options={listaGiocatori}
            optionLabel="nominativo"
            optionValue="_id"
            disabled={!abilTABPARTITA}
            style={{ margin: "0.3em", flexGrow: 1 }}
            placeholder='Selezionare il giocatore'
            onChange={val => { setSelElemento({...selElemento, giocid:val.target.value._id,giocnom:val.target.value.nominativo     }) }}
          />}

          {selElemento && selElemento.tipoevento && selElemento.tipoevento.split('#')[1] === 'G' &&
            <Dropdown
            value={listaGiocatori.find((elef)=>elef._id==selElemento.giocid2   )}
              options={listaGiocatori}
              optionLabel="nominativo"
              optionValue="_id"
              disabled={!abilTABPARTITA}
              style={{ margin: "0.3em", flexGrow: 1 }}
              placeholder='Selezionare il giocatore assist'
              onChange={val => {  setSelElemento({...selElemento, giocid2:val.target.value._id,giocnom2:val.target.value.nominativo     }) }}
              />}


{selElemento && selElemento.tipoevento && selElemento.tipoevento.split('#')[1] === 'T' &&
            
            <Calendar className="dataora" id="dt1" value={selElemento.dataora} showIcon showTime hourFormat="24"
            disable={!abilTABPARTITA} stepMinute={5} showButtonBar={true}
            onChange={(e) => {
              let x = { ...selElemento }; x.dataora = e.value; setSelElemento(x);
     
              setDaSalvare(true);
            }} />



            }





          <Button
            onClick={() => { salvamodifiche(); }}
          >
            <Icon path={mdiPencil} size={0.8} />
          </Button>
        </Dialog>

        <div className="listazioni">
          {abilTABPARTITA && daSalvare && att._id &&
            <Button
              onClick={() => { modifica(att) }}
            >
              <Icon path={mdiContentSaveAll} size={0.8} />
            </Button>
          }
      <Button
            onClick={() => {   navigate('/giocAttPage', {state:{ gruppo: props.gruppo, selAttivita: att} })            }}
          >
            <Icon path={mdiAccountCheck} size={0.8} />
          </Button>


          <Button
            onClick={() => {vai_dettAtt()  }}
          >
            <Icon path={mdiCheckboxMarkedOutline} size={0.8} />
          </Button>

          <Button
            onClick={() => { esci() }}
          >
            <Icon path={mdiCalendarMultipleCheck} size={0.8} />
          </Button>

        </div>
      </div>
      {att && att.dataAttivita &&
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.3em', alignItems: 'baseline' }}>

          <label style={{ fontWeight: 'bold', borderBottomWidth: 0.3, borderBottomColor: '#0a568d', margin: '0.3rem' }}> {moment(att.dataAttivita).format('DD/MM/YYYY HH:mm')} {att.avversario} </label>

        </div>
      }
      <hr></hr>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem', alignItems: 'center', alignContent: 'center' }}>
        {att &&
          <div style={{ display: 'flex', flexDirection: 'column', MarginTop: '0.3rem', width: '100%' }}>
           
          

          <label style={{ fontWeight: 'bold', color:'blue', fontSize:'2.5rem', borderBottomWidth: 0.3, borderBottomColor: '#0a568d', marginBottom: 15, paddingBottom: 10 }}>Risultato: {att.getRisultato()}  </label>
          

          
            <div style={{ display: 'flex', flexDirection: 'row', marginHorizontal: 2, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginHorizontal: 2, alignItems: 'center' }}>
                <label>Gol fatti</label>
                <InputNumber
                  disabled={!abilTABPARTITA}
                  showButtons
                  inputStyle={{ width: "3rem", textAlign: 'center' }}

                  incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
                  value={att.golFatti}
                  onChange={vln => {
                    let x = { ...att }; x.golFatti = vln.value; 
                          
                    setAtt(x);
                    setDaSalvare(true); setDaNotificare(true);
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginHorizontal: 2, alignItems: 'center' }}>
                <label>Gol subiti</label>
                <InputNumber
                  disabled={!abilTABPARTITA}
                  showButtons
                  inputStyle={{ width: "3rem", textAlign: 'center' }}
                  incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
                  value={att.golSubiti}
                  onChange={vln => {
                    let x = { ...att }; x.golSubiti = vln.value;
          
                    setAtt(x);
                    setDaSalvare(true); setDaNotificare(true);
                  }}
                />
              </div>
            </div>
            {att.risTempi &&
              <div style={{ display: 'flex', flexDirection: 'row', marginHorizontal: 2, alignItems: 'center',flexWrap:'wrap' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginHorizontal: 2, alignItems: 'center' }}>
                  <label>Tempi vinti</label>
                  <InputNumber
                    disabled={!abilTABPARTITA}
                    showButtons inputStyle={{ width: "3rem", textAlign: 'center' }}

                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
                    value={att.tempiVinti}
                    onChange={vln => {
                      let x = { ...att }; x.tempiVinti = vln.value; setAtt(x);
                      setDaSalvare(true); setDaNotificare(true);
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginHorizontal: 2, alignItems: 'center' }}>
                  <label>Tempi persi</label>
                  <InputNumber
                    disabled={!abilTABPARTITA}
                    showButtons inputStyle={{ width: "3rem", textAlign: 'center' }}

                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
                    value={att.tempiPersi}
                    onChange={vln => {
                      let x = { ...att }; x.tempiPersi = vln.value; setAtt(x);
                      setDaSalvare(true); setDaNotificare(true);
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginHorizontal: 2, alignItems: 'center' }}>
                  <label>Tempi pareggiati</label>
                  <InputNumber
                    disabled={!abilTABPARTITA}
                    showButtons
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
                    value={att.tempiPareggiati} inputStyle={{ width: "3rem", textAlign: 'center' }}

                    onChange={vln => {
                      let x = { ...att }; x.tempiPareggiati = vln.value; setAtt(x);
                      setDaSalvare(true); setDaNotificare(true);
                    }}
                  />
                </div>
                   <div style={{ display: 'flex', flexDirection: 'column', marginHorizontal: 2, alignItems: 'center' }}>
                <label>Giochini vinti</label>
                <InputNumber
                  disabled={!abilTABPARTITA}
                  showButtons inputStyle={{ width: "3rem", textAlign: 'center' }}

                  incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
                  value={att.giochiniVinti}
                  onChange={vln => {
                    let x = { ...att }; x.giochiniVinti = vln.value; setAtt(x);
                    setDaSalvare(true); setDaNotificare(true);
                  }}
                />
              </div>
              </div>}

            <hr></hr>
            <label style={{ fontWeight: 'bold', borderBottomWidth: 0.3, borderBottomColor: '#0a568d', marginBottom: 15, paddingBottom: 10 }}>Cronaca</label>
            {abilTABPARTITA &&
              <div className="listazioni">
                <label style={{ textDecoration: 'underline' }} >Aggiungi {evPartTipo.split('#')[2]}</label>

                {abilTABPARTITA && ult.split('#')[2] != 'Inizio' && ult.split('#')[2] != 'finegara' &&
                  <Button
                    onClick={() => { instiming(att); }}
                  >
                    <Icon path={mdiClock} size={0.8} />
                  </Button>
                }

                {abilTABPARTITA && ult.split('#')[1] == 'T' && ult.split('#')[2] == 'Inizio' &&
                  <Button
                    onClick={() => { stoptiming(att); }}
                  >
                    <Icon path={mdiTimelapse} size={0.8} />
                  </Button>
                }

                {abilTABPARTITA && ult.split('#')[1] == 'T' && ult.split('#')[2] == 'Inizio' &&
                  <Button
                    onClick={() => { finegara(att); }}
                  >
                    <Icon path={mdiTimerOffOutline} size={0.8} />
                  </Button>
                }



                <Button
                  onClick={() => { settaevento('#G#Goal'); }}
                >
                  <Icon path={mdiSoccer} size={0.8} />
                </Button>
                <Button
                  onClick={() => { settaevento('#A#Ammonizione'); }}
                >
                  <Icon path={mdiCard} size={0.8} color='yellow' />
                </Button>
                <Button
                  onClick={() => { settaevento('#E#Espulsione'); }}
                >
                  <Icon path={mdiCard} size={0.8} color='red' />
                </Button>


              </div>
            }
            {evPartTipo && evPartTipo !== '###' &&
              <div style={{ display: 'flex', flexDirection: 'row', marginHorizontal: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                {evPartTipo.split('#')[1] != 'T' &&
                  <div>
                    <label>Minuto:</label>
                    <InputNumber
                      disabled={!abilTABPARTITA}
                      showButtons inputStyle={{ width: "3rem", textAlign: 'center' }}

                      incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
                      value={evPartMin ? evPartMin : 0}
                      onChange={val => { setEvPartMin(val.value) }}
                    />
                    <Dropdown
                      value={evPartGioc}
                      options={listaGiocatori}
                      optionLabel="nominativo"
                      optionValue="_id"
                      disabled={!abilTABPARTITA}
                      style={{ margin: "0.3em", flexGrow: 1 }}
                      placeholder='Selezionare il giocatore'
                      onChange={val => { setEvPartGioc(val.target.value) }}
                    />  </div>
                }
                {evPartTipo.split('#')[1] === 'G' &&
                  <Dropdown
                    value={evPartGioc2}
                    options={listaGiocatori}
                    optionLabel="nominativo"
                    optionValue="_id"
                    disabled={!abilTABPARTITA}
                    style={{ margin: "0.3em", flexGrow: 1 }}
                    placeholder='Selezionare il giocatore assist'
                    onChange={val => { setEvPartGioc2(val.target.value) }}
                  />}




                <Button
                  onClick={() => { addCronacaEv(att) }}
                >
                  <Icon path={mdiPlus} size={0.8} />
                </Button>
              </div>
            }



            {att.cronaca && att.cronaca.length > 0 &&
              <div style={{ flex: 1, width: '100%' }}>
                <DataView
                  value={att.cronaca}
                  itemTemplate={renderItem1}
                />


              </div>}


                {abilcommPARTITA &&
              <div style={{ display: 'flex', flexDirection: 'column', MarginTop: '0.3rem', width: '100%' }}>
              <label style={{ flex: 1, margin: "0.3rem" }} >Commento tecnico:</label>
              <InputTextarea value={att.commentopartita} autoResize style={{ flex: 1, margin: "0.3rem" }}
                disabled={!abilTABPARTITA}
                onChange={(e) => {
                  let x = { ...att }; x.commentopartita = e.target.value; setAtt(x);
                  setDaSalvare(true); setDaNotificare(true);
                }} />
            </div>
}


          </div>
          
        }
      </div>
    </div>


  );
}

export default TabelinoPartita;