// screens/Home.js
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState,useEffect ,useRef} from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from 'primereact/dialog';
import { CountryService } from '../util/CountryService';
import Icon from '@mdi/react';
import { mdiContentSave } from '@mdi/js';
import { mdiPrinter } from '@mdi/js';
import { mdiSoccerField } from '@mdi/js';
import { mdiAccountMultiple } from '@mdi/js';
import { mdiAccountMultipleCheck } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { mdiPhone } from '@mdi/js';
import { mdiCity } from '@mdi/js';
import { mdiEmail } from '@mdi/js';
import { mdiAccount } from '@mdi/js';
import  Person  from '../obj/person.js' 
import restPersona from "../restcall/restPersona";
import Visita from '../obj/Visita'
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
const Visite = (props) => {
  const navigate = useNavigate();
const [visite,setVisite] = useState();
useEffect(() => {
  if (props.selVisite){
    console.log(props.selVisite);
    console.log(Visita.prototype);
    let tmp_rec = props.selVisite;
    console.log('prima');
    tmp_rec.__proto__ = Visita.prototype;
    console.log('dopo');
    tmp_rec.to_obj(); 
    setVisite(tmp_rec);
  }
}, []);
useEffect(() => {
}, [visite]);
let [daSalvare, setDaSalvare] = useState(false);
const toast = useRef(null);
const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Success', detail:'Message Content', life: 3000});
}
const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
}
const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
}
const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
}
const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
};
const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
};
  return (
    <div>
      <Toast ref={toast} />
      {visite &&
        <>
          <div style={{ flexDirection: 'row',  display:'flex',justifyContent: 'space-between', flexWrap: 'wrap', width: '100%'}}>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Tipo</label>
                  <IconField iconPosition="left">
                  <InputIcon className="pi pi-pencil"> </InputIcon>
                  <InputText id="nome" value={visite.tipo} onChange={(e)=>{let x = {...visite}; x.tipo = e.target.value; setVisite(x); setDaSalvare(true); props.updVisita(x)}}/>
                  </IconField>
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Esito</label>
                  <IconField iconPosition="left">
                  <InputIcon className="pi pi-pencil"> </InputIcon>
                  <InputText id="nome" value={visite.esito} onChange={(e)=>{let x = {...visite}; x.esito = e.target.value; setVisite(x); setDaSalvare(true); props.updVisita(x)}}/>
                  </IconField>
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Data della visita</label>
                  <Calendar value={visite.datavisita} onChange={(e)=>{let x = {...visite}; x.datavisita = e.value; setVisite(x); setDaSalvare(true); props.updVisita(x)}}  id="date" />
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Referto</label>
                    <IconField iconPosition="left">
                    <InputIcon className="pi pi-pencil"> </InputIcon>
                    <InputText id="nome" value={visite.referto} onChange={(e)=>{let x = {...visite}; x.referto = e.target.value; setVisite(x); setDaSalvare(true); props.updVisita(x)}}/>
                    </IconField>
                  </div>
                </div>
                <div className="gruppoDati" style={{width:'100%'}}>
                <div className="dati" style={{width:'100%'}}>
                    <label htmlFor="nome">Note</label>
                  <IconField iconPosition="left">
                  <InputIcon className="pi pi-pencil"> </InputIcon>
                  <InputTextarea id="nome"  rows={2} value={visite.note} onChange={(e)=>{let x = {...visite}; x.note = e.target.value; setVisite(x); setDaSalvare(true); props.updVisita(x)}}/>
                  </IconField>
                </div>
              </div>
            </div>
        </>
      }
    </div>
    

  );
}

export default Visite;