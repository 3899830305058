// screens/Home.js
import datiGen from '../util/datiGen.js';
import moment from 'moment'
import restGruppi from '../restcall/restGruppi.js';
import gruppo from '../obj/gruppo.js';
import campionato from '../obj/Campionato.js';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link, useOutletContext } from "react-router-dom";
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';

import elenchi from '../util/elenchi.js';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiClose, mdiPencil, mdiPlus,
  mdiContentSave
} from '@mdi/js';


const DettGruppo = (props) => {
  const navigation = useNavigate();
  // datiGen.getInstance().possoaccederev2('GRUPPI');

  const abilUpd = datiGen.getInstance().possoaccederev2('GRUPPIUPD');
  const [selected, setSelected] = useState(props.grp);


  const [daSalvare, setDaSalvare] = useState(false);

  const op = useRef(null);
  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();

  useEffect(() => {
    if (props.grp) {
      let x = props.grp;
      x.__proto__ = gruppo.prototype;
      x.to_obj();
      setSelected(x);
    }
  }, [props.grp]);

  useEffect(() => {
    props.modificav(selected);
  }, [selected]);


  const Salvare = (pvar) => {
    setDaSalvare(pvar);
    props.daSalvare(pvar);
  }

  return (
    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: 500
    }}>
      <div className='horizFlexcontainerMargin' >  <label style={{ flex: 1 }} className='Titolo3'>Descrizione:</label>
        <IconField iconPosition="left" style={{ flex: 1 }}>
          {abilUpd &&
            <InputIcon>
              <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
            </InputIcon>
          }
          <InputText className='longInput' style={{ minWidth: "15rem", flexGrow: "1" }}
            value={selected.des}
            disabled={!abilUpd}
            onChange={vcf => {
              let x = { ...selected }; x.des = vcf.target.value; setSelected(x); Salvare(true);
            }}
          >
          </InputText>
        </IconField>
      </div>
      <div className='horizFlexcontainerMargin'>  <label className='Titolo3'>Ordine:</label>

        <div>
          <IconField iconPosition="left" style={{ backgroundColor: "#00adef50" }}>
            {abilUpd &&
              <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
            }
            <InputNumber showButtons buttonLayout="horizontal" step={1}
              value={selected.ordine} inputStyle={{ width: "3rem", textAlign: 'center' }}
              disabled={!abilUpd}
              incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
              onValueChange={vcf => {
                let x = { ...selected }; x.ordine = vcf.target.value; setSelected(x); Salvare(true);
              }}
            >

            </InputNumber>
          </IconField>
        </div>
      </div>
      <div className='horizFlexcontainerMargin'>  <label className='Titolo3'>Note:</label>
        <IconField iconPosition="left" style={{ flex: 1, flexGrow: 1 }}  >
          {abilUpd &&
            <InputIcon>
              <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
            </InputIcon>
          }
          <InputTextarea className='longInput' style={{ minWidth: "15rem", flexGrow: "1", }} autoResize={true}
            value={selected.note} disabled={!abilUpd}
            onChange={vcf => {
              let x = { ...selected }; x.note = vcf.target.value; setSelected(x); Salvare(true);
            }}
          >
          </InputTextarea>
        </IconField>
      </div>
      <div className='horizFlexcontainerMargin'>
        <label className='Titolo3'>Tipo:</label>
        <Dropdown
          value={selected.tipo}
          options={elenchi.getInstance().getElTipoGruppo()}
          optionLabel="label"
          optionValue="value"
          style={{ marginLeft: "0.3em", marginRight: "0.3em" }}
          disabled={!abilUpd}
          onChange={vcf => {
            let x = { ...selected }; x.tipo = vcf.target.value; setSelected(x); Salvare(true);

          }} />
      </div>

      <div className="horizFlexcontainerMargin">
        <label className='Titolo3' htmlFor="datei">Data di inizio validità</label>
        <Calendar value={new Date(selected.ival)}
          disabled={!abilUpd}
          style={{ marginLeft: "0.3em", marginRight: "0.3em" }}

          onChange={(e) => { setSelected({ ...selected, ival: e.value }); Salvare(true) }} id="datei" />

      </div>
      <div className="horizFlexcontainerMargin">
        <label className='Titolo3' htmlFor="datef">Data di fine validità</label>
        <Calendar value={new Date(selected.fineval)}
          style={{ marginLeft: "0.3em", marginRight: "0.3em" }}
          disabled={!abilUpd}

          onChange={(e) => { setSelected({ ...selected, fineval: e.value }); Salvare(true) }} id="datef" />
      </div>
      <hr></hr>
      <div className="horizFlexcontainerMargin" >
        <label className="Titolo2" >Campionati</label>
        {abilUpd &&
          <Button
            onClick={() => { let x = { ...selected }; x.campionati.push(new campionato()); setSelected(x); }}           >
            <Icon path={mdiPlus} size={0.8} />
          </Button>
        }
      </div>

      <hr></hr>

      <div style={{ flex: 1, width: '99%' }}>
        {
          selected.campionati.map((ele, i) => (
            <div key={i} className='listaItem'>

              <div className='horizFlexcontainerMargin' >
                <Dropdown
                  value={ele.stagione}
                  options={elenchi.getInstance().getElStagioni()}
                  optionLabel="label"
                  optionValue="value"
                  disabled={!abilUpd}

                  style={{ margin: "0.3em", flexGrow: 1 }}
                  placeholder='Selezionare la stagione'
                  onChange={vcf => {
                    let x = { ...selected }; ele.stagione = vcf.target.value; setSelected(x); Salvare(true);
                  }} />
                <IconField iconPosition="left"  >
                  {abilUpd &&
                    <InputIcon>
                      <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                    </InputIcon>
                  }
                  <InputText className='longInput'
                    value={ele.categoria}
                    placeholder='Indicare la categoria'
                    disabled={!abilUpd}
                    onChange={vcf => {
                      let x = { ...selected }; ele.categoria = vcf.target.value; setSelected(x); Salvare(true);
                    }}
                  >

                  </InputText>
                </IconField>

                <Dropdown
                  value={ele.tipo}
                  options={elenchi.getInstance().getElTipoCampionato()}
                  optionLabel="label"
                  optionValue="value"
                  disabled={!abilUpd}
                  style={{ margin: "0.3em", flexGrow: 1 }}
                  placeholder='Selezionare il tipo'
                  onChange={vcf => {
                    let x = { ...selected }; ele.tipo = vcf.target.value; setSelected(x); Salvare(true);
                  }} />
                <Dropdown
                  value={ele.periodo}
                  options={elenchi.getInstance().getElPeriodoCampionato()}
                  optionLabel="label"
                  optionValue="value"
                  disabled={!abilUpd}
                  style={{ margin: "0.3em", flexGrow: 1 }}
                  placeholder='Selezionare il periodo'
                  onChange={vcf => {
                    let x = { ...selected }; ele.periodo = vcf.target.value; setSelected(x); Salvare(true);
                  }} />

                <Dropdown
                  value={ele.numerogioc}
                  options={elenchi.getInstance().getElNumeroGioc()}
                  optionLabel="label"
                  optionValue="value"
                  disabled={!abilUpd}
                  style={{ margin: "0.3em", flexGrow: 1 }}
                  placeholder='Indicare il numero di giocatori'
                  onChange={vcf => {
                    let x = { ...selected }; ele.numerogioc = vcf.target.value; setSelected(x); Salvare(true);
                  }} />
                <Dropdown
                  value={ele.livello}
                  options={elenchi.getInstance().getElLivelloCamp()}
                  optionLabel="label"
                  optionValue="value"
                  disabled={!abilUpd}
                  style={{ margin: "0.3em", flexGrow: 1 }}
                  placeholder='Indicare il livello del campionato'
                  onChange={vcf => {
                    let x = { ...selected }; ele.livello = vcf.target.value; setSelected(x); Salvare(true);
                  }} />
                   <IconField iconPosition="left"  >
                  {abilUpd &&
                    <InputIcon>
                      <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                    </InputIcon>
                  }
                  <InputText className='mediumInput'
                    value={ele.idcamp}
                    placeholder="Indicare l'id  unico del campionato, univoco tra tutte le categorie"
                    disabled={!abilUpd}
                    title="Indicare l'id  unico del campionato, univoco tra tutte le categorie"
                    onChange={vcf => {
                      let x = { ...selected }; ele.idcamp = vcf.target.value; setSelected(x); Salvare(true);
                    }}
                  >

                  </InputText>
                </IconField>

              </div>

              <hr></hr>
            </div>

          ))
        }
      </div>

    </div>
  );
}
export default DettGruppo;