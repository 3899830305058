import moment from "moment";
import { getAuth } from "firebase/auth";
import datiInit from "../util/datiInit";
import datiGen from "../util/datiGen";
import { parseTwoDigitYear } from "moment";


export default class restOrdini {
  constructor(restOrdini) {
   // console.log('restOrdini');
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();
  restApp = 'ordini';
  static myInstance = null;


  static getInstance() {
    if (restOrdini.myInstance == null) {
      restOrdini.myInstance = new restOrdini();
      // Util.myInstance.inizializza();
    //  console.log('fine get instance');
    }
    return restOrdini.myInstance;
  }

  prova() {
    console.log('prova');
  }

  getOrdini = async (pcog, pnom, panno,ptest,pgrp) => {
    console.log('getOrdini',pgrp);
    var json = "";
    if (! (datiGen.getInstance().possoaccederev2('ORDINI',pgrp) )  ) {
      throw 'Non sei abilitato a svolgere la funzione ORDINI';
    }


    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var dtcond = "";
      if (panno) {
        dtcond = "&d1=" + new moment(panno + "-01-01").toDate().toISOString() + "&d2=" + new moment(panno + "-01-01").add(1, 'years').toDate().toISOString();
      }

      var testcond=ptest?'&test='+ptest:'';
      var grpcond=pgrp?'&gruppo='+pgrp:'';
     
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "?" + "cognome=" + pcog + "&nome=" + pnom + dtcond +testcond+grpcond
        , {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          }
        }
      );

      json = await response.json();
      
      if (response.status >= 300) {
        console.log("restOrdini", "getOrdini", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error("getOrdini", error);
      return "KO";
    } finally {
      //   console.log("finito getOrdini");
    }
  }

  getOrdiniPersona = async (ppers, panno, pgrp) => {
    console.log('getOrdini',pgrp, ppers);
    var json = "";
    if (! (datiGen.getInstance().possoaccederev2('ORDINI',pgrp) )  ) {
      throw 'Non sei abilitato a svolgere la funzione ORDINI';
    }


    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var dtcond = "";
      if (panno) {
        dtcond = "d1=" + new moment(panno + "-01-01").toDate().toISOString() + "&d2=" + new moment(panno + "-01-01").add(1, 'years').toDate().toISOString();
      }
      var grpcond=pgrp?'&gruppo='+pgrp:'';
      console.log(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + ppers._id + "?" + dtcond + grpcond)
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + ppers._id + "?" + dtcond + grpcond
        , {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          }
        }
        
      );

      json = await response.json();
      console.log(json, 'json');
      if (response.status >= 300) {
        console.log("restOrdini", "getOrdini", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error("getOrdini", error);
      return "KO";
    } finally {
      //   console.log("finito getOrdini");
    }
  }

  getOrdine = async (pidpers,pcateg) => {
    const user = getAuth().currentUser;
    var token = await user.getIdToken();

    if (!datiGen.getInstance().possoaccederev2('ORDINI'))
      throw 'Non sei abilitato a svolgere la funzione ORDINI';

    try {
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "?idPersona=" + pidpers + "&categoria="+pcateg, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      const json = await response.json();

      if (response.status >= 300) {
        console.log("restOrdini", "getOrdini", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      //   console.log("finito getOrdini");
    }
  }



  aggiungi = async (pord) => {

//console.log('restordine','aggiungi','inizio',pord, JSON.stringify(pord));
    if (!datiGen.getInstance().possoaccederev2('ORDINI'))
      throw 'Non sei abilitato a svolgere la funzione ORDINI';
      
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      
      const s=this.restUrlServer + '/' + this.restApp+ '/'+datiGen.getInstance().getActiveSoc().sigla;
      
      //console.log('restordine','aggiungi','fetch inizio',s);
      const response = await fetch(s
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(pord)
        });
        //console.log('restordine','aggiungi','fetch fine',response);  
        
      const json = await response.text();
      //console.log('restordine','aggiungi','fetch fine',json);
      
      if (response.status >= 300) {
        console.warn("restOrdini", "aggiungi", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      // console.log("finito aggiungi");
    }
  }

  modifica = async (pordine,gruppo) => {
    if (!datiGen.getInstance().possoaccederev2('ORDINI',gruppo))
      throw 'Non sei abilitato a svolgere la funzione ORDINI';

     console.log("Modifica",pordine);
     var json2;
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var p = { ...pordine };
      delete p._id;
      const s = this.restUrlServer + '/' + this.restApp + '/'+datiGen.getInstance().getActiveSoc().sigla+ '/' + pordine._id;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

        console.log('response  ok---------------------------------------',response);

         json2 = await response.text();
     
      console.log('arrivato ok---------------------------------------',json2);

      if (response.status >= 300) {
        console.log("restOrdini", "getOrdini", "--", response.status, json2);
        throw 'Errore ' + JSON.stringify(json2);
      }
      console.log('prima di reurn ok',json2);
      
      return json2;

    } catch (error) {
      console.error(error,json2);
    } finally {
      

    }

  }

  elimina = async (pordineId,gruppo) => {
    if (!datiGen.getInstance().possoaccederev2('ORDINI',gruppo))
      throw 'Non sei abilitato a svolgere la funzione ORDINI';

     console.log("elimina ordine",pordineId);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ '/' + pordineId;
      
      //console.log('avvio delete', pordineId);

      const response = await fetch(s
        , {
          method: 'delete',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          
        });

        //console.log('avvio delete 2');

      const json = await response.text();

      //console.log('avvio delete 3',json);

      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }


}