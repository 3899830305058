// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';
import { useLocation,useNavigate } from "react-router-dom";

import datiGen from '../util/datiGen.js';
import TestSquadra from '../comp/TestSquadra.js';

const ReportisticaTest = (props) => {
  const navigation = useNavigate();
const location=useLocation();
  const [tipo, setTipo] = useState(() => location.state.tipo);
  const [gruppop, setGruppop] = useState(() => location.state.gruppo);
  const [gruppodesp, setGruppopdesp] = useState(() => location.state.gruppodes);


  const abilRepTest = datiGen.getInstance().possoaccederev2('REPTEST', location.state.gruppo);


  useEffect(() => 
  { 
  setGruppop(location.state.gruppo); 
  
});

  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'

      }}>
{abilRepTest &&
<TestSquadra gruppo={gruppop} gruppodes={gruppodesp}  style={{flex:10 }} />
}
    </div>
  );
} 
export default  ReportisticaTest   ;