
import React, { useEffect, useState, useRef } from 'react';
import '../stili/App.css';
import '../stili/App01.css';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { Image } from 'primereact/image';
import logo from '../img/logo-vigor3.png';
import cake from '../img/happy-birthda.jpg'
import restPersona from '../restcall/restPersona';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import IosShareIcon from '@mui/icons-material/IosShare';
import MenuIcon from '@mui/icons-material/Menu';
import { Toast } from 'primereact/toast';

import { Sidebar } from 'primereact/sidebar';
import { Badge } from 'primereact/badge'
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

import { InputText } from 'primereact/inputtext';

import { Password } from 'primereact/password';


import { OverlayPanel } from 'primereact/overlaypanel';
import { Card } from 'primereact/card';


//firebase 
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup,
  GoogleAuthProvider, getAdditionalUserInfo, getApps, getApp, sendPasswordResetEmail,
  updateProfile
} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import Icon from '@mdi/react';
import {
  mdiAccountNetwork, mdiFlagCheckered, mdiMenuOpen, mdiAccountMultiple, mdiAccountGroup, mdiSoccer, mdiCakeVariant,
  mdiHome,
  mdiLogout, mdiCake,
  mdiClose,
  mdiPencil,
  mdiLogin,
  mdiAccountPlus,
  mdiCalendarStar,
  mdiCalendarClock,
  mdiCalendarMultipleCheck

} from '@mdi/js';

import { Dock } from 'primereact/dock';

import { useTranslation } from 'react-i18next';

//navigation
import { useNavigate, Link, useLocation, useSearchParams, Outlet, useBlocker } from "react-router-dom";

import { AlignHorizontalCenter, CalendarMonthSharp } from '@mui/icons-material';

import moment from 'moment/min/moment-with-locales';

import datiGen from '../util/datiGen';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

//import messaging from '@react-native-firebase/messaging';
//import VersionInfo from 'react-native-version-info';
//import restPersona from '../restcall/restPersona';

import { Dialog } from 'primereact/dialog';


//import restGruppi from '../restcall/restGruppi';

const firebaseConfig = {
  apiKey: "AIzaSyDiBSXlxFy86V-LWsieMoK-IdbhVtdibPo",
  authDomain: "vigorapp-69498.firebaseapp.com",
  projectId: "vigorapp-69498",
  storageBucket: "vigorapp-69498.appspot.com",
  messagingSenderId: "671843500668",
  appId: "1:671843500668:web:752aa2a357b83da6e33ff9",
  measurementId: "G-3GJRPT4F45"
};



const App = () => {
  const app = initializeApp(firebaseConfig);

  const analytics = getAnalytics(app);
  const auth = getAuth(app);



  const messaging = getMessaging();

  const navigate = useNavigate();
  const mostrainstallazione = false;
  const [azione, setAzione] = useState("Reg"); //Reg Login //Logged  //Logging //Registering
  const toast = useRef(null);

  const [dateAccPriv, setDateAccPriv] = useState();
  const provider = new GoogleAuthProvider();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [statoDaSalvare, setStatoDaSalvare] = useState("000000000000000"); //char 0 dettPersona 1 gruppi 2 profili 3 attivita 4 schedaoss

  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(datiGen.getInstance().getUtente());
  const [utente, setUtente] = useState(datiGen.getInstance().getUtenteCollegato());
  const [mailLoginVisible, setMailLoginVisible] = useState(false);
  const [mailRegister, setMailRegister] = useState(false);
  const [mailuser, setMailUser] = useState();
  const [mailpw, setMailpw] = useState();
  const [mailName, setMailName] = useState();
  const [anagAbil, setAnagAbil] = useState();
  const [activeSoc, setActiveSoc] = useState();
  const [abilScouting, setAbilScouting] = useState();
  const [abilLogin, setAbilLogin] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [mostraaggiorna, setMostraAggiorna] = React.useState(false);
  const [abilMyTeam, setAbilMyTeam] = useState(true);
  const [abilMyTeam2, setAbilMyTeam2] = useState(true);
  const [flagPriv, setFlagPriv] = useState(false);
  const [flagDtNonDist, setFlagDtNonDist] = useState(false);
  const [mostraPrivacy, setMostraPrivacy] = useState(false);
  const [mostraMenu, setMostraMenu] = useState(false);


  const [mostraCompleanni, setMostraCompleanni] = useState(false);
  const [compleanni, setCompleanni] = useState([]);
  const [mostralistaCompleanni, setMostraListaCompleanni] = useState(false);

  const [mostragrpPred, setMostraGrpPred] = useState(false);
  const [grpPred, setGrpPred] = useState('');

  const abilGruppi = datiGen.getInstance().possoaccederev2('GRUPPI_UI');
  const abilConf = datiGen.getInstance().possoaccederev2('CONFIGURAZIONI');
  const ovlCompleanni = useRef(null);
  const [visible, setVisible] = useState(false);
  const buttonEl = useRef(null);

  const accept = () => {
    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  };

  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };

  var authattiva = 0;
  var timostragg, ticompleanni;



  const { t, i18n } = useTranslation();


  const showNotifica = (titolo, testo) => {
    toast.current.show({ severity: 'info', summary: titolo, detail: testo });
  };
  const show = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo });
  };
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo });
  };
  const showAlert = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };
  const showError = (testo) => {
    toast.current.show({ severity: 'error', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };

  //use effect primefaces locale
  useEffect(() => {
    console.log("use effect  00980", azione);

    addLocale('it',
      {
        "accept": "Si",
        "addRule": "Aggiungi regola",
        "am": "AM",
        "apply": "Applica",
        "cancel": "Annulla",
        "choose": "Scegli",
        "chooseDate": "Seleziona Data",
        "chooseMonth": "Seleziona Mese",
        "chooseYear": "Seleziona Anno",
        "clear": "Cancella tutto",
        "completed": "Completato",
        "contains": "Contiene",
        "custom": "Personalizzato",
        "dateAfter": "La data è successiva",
        "dateBefore": "La data è precedente",
        "dateFormat": "dd/mm/yy",
        "dateIs": "La data è",
        "dateIsNot": "La data non è",
        "dayNames": [
          "Domenica",
          "Lunedi",
          "Martedì",
          "Mercoledì",
          "Giovedì",
          "Venerdì",
          "Sabato"
        ],
        "dayNamesMin": [
          "Do",
          "Lu",
          "Ma",
          "Me",
          "Gi",
          "Ve",
          "Sa"
        ],
        "dayNamesShort": [
          "Dom",
          "Lun",
          "Mar",
          "Mer",
          "Gio",
          "Ven",
          "Sab"
        ],
        "emptyFilterMessage": "Nessuna opzione disponibile",
        "emptyMessage": "Nessun risultato trovato",
        "emptySearchMessage": "Nessun risultato trovato",
        "emptySelectionMessage": "Nessun elemento selezionato",
        "endsWith": "Finisce con",
        "equals": "Equivale",
        "fileSizeTypes": [
          "B",
          "KB",
          "MB",
          "GB",
          "TB",
          "PB",
          "EB",
          "ZB",
          "YB"
        ],
        "filter": "Filtro",
        "firstDayOfWeek": 1,
        "gt": "Maggiore di",
        "gte": "Maggiore o uguale a",
        "lt": "Minore di",
        "lte": "Minore o uguale a",
        "matchAll": "Abbina tutto",
        "matchAny": "Abbina alcuni",
        "medium": "Medio",
        "monthNames": [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre"
        ],
        "monthNamesShort": [
          "Gen",
          "Feb",
          "Mar",
          "Apr",
          "Mag",
          "Giu",
          "Lug",
          "Ago",
          "Set",
          "Ott",
          "Nov",
          "Dic"
        ],
        "nextDecade": "Decade successiva",
        "nextHour": "Ora successiva",
        "nextMinute": "Minuto successivo",
        "nextMonth": "Mese successivo",
        "nextSecond": "Secondo successivo",
        "nextYear": "Anno successivo",
        "noFilter": "Senza Filtro",
        "notContains": "Non contiene",
        "notEquals": "Non uguale",
        "now": "Ora attuale",
        "passwordPrompt": "Inserisci la password",
        "pending": "In corso",
        "pm": "PM",
        "prevDecade": "Decade precedente",
        "prevHour": "Ora precedente",
        "prevMinute": "Minuto precedente",
        "prevMonth": "Mese precedente",
        "prevSecond": "Secondo precedente",
        "prevYear": "Anno precedente",
        "reject": "No",
        "removeRule": "Rimuovi regola",
        "searchMessage": "{0} risultati disponibili",
        "selectionMessage": "{0} elementi selezionati",
        "showMonthAfterYear": false,
        "startsWith": "Inizia con",
        "strong": "Forte",
        "today": "Oggi",
        "upload": "Carica",
        "weak": "Debole",
        "weekHeader": "Sett",
        "aria": {
          "cancelEdit": "Annulla modifica",
          "close": "Chiudi",
          "collapseLabel": "Riduci",
          "collapseRow": "Riduci riga",
          "editRow": "Modifica riga",
          "expandLabel": "Espandi",
          "expandRow": "Espandi riga",
          "falseLabel": "Falso",
          "filterConstraint": "Costante di filtro",
          "filterOperator": "Operatore di filtro",
          "firstPageLabel": "Prima pagina",
          "gridView": "Griglia",
          "hideFilterMenu": "Nascondi Menu filtri",
          "jumpToPageDropdownLabel": "Vai alla Dropdown delle pagine",
          "jumpToPageInputLabel": "Vai all'Input delle pagine",
          "lastPageLabel": "Ultima pagina",
          "listView": "Lista",
          "moveAllToSource": "Muovi tutto alla sorgente",
          "moveAllToTarget": "Muovi tutto all'elemento",
          "moveBottom": "Vai in fondo",
          "moveDown": "Vai sotto",
          "moveTop": "Vai in cima",
          "moveToSource": "Vai alla sorgente",
          "moveToTarget": "Vai all'elemento",
          "moveUp": "Vai sopra",
          "navigation": "Naviga",
          "next": "Successivo",
          "nextPageLabel": "Pagina successiva",
          "nullLabel": "Non selezionato",
          "otpLabel": "Inserisci il carattere della password monouso {0}",
          "pageLabel": "Pagina {page}",
          "passwordHide": "Nascondi password",
          "passwordShow": "Mostra password",
          "previous": "Precedente",
          "previousPageLabel": "Pagina precedente",
          "rotateLeft": "Ruota a sinistra",
          "rotateRight": "Ruota a destra",
          "rowsPerPageLabel": "Elementi per pagina",
          "saveEdit": "Salva modifica",
          "scrollTop": "Torna sù",
          "selectAll": "Seleziona tutti gli elementi",
          "selectLabel": "Seleziona",
          "selectRow": "Seleziona riga",
          "showFilterMenu": "Mostra Menu filtri",
          "slide": "Scorri",
          "slideNumber": "{slideNumber}",
          "star": "1 stella",
          "stars": "{star} stelle",
          "trueLabel": "Vero",
          "unselectAll": "Deseleziona tutti gli elementi",
          "unselectLabel": "Deseleziona",
          "unselectRow": "Deseleziona riga",
          "zoomImage": "Zoom Immagine",
          "zoomIn": "Ingrandisci",
          "zoomOut": "Riduci"
        }
      }
    );
    i18n.changeLanguage('IT');
    moment.locale('IT');
    locale('it');
    setStatoDaSalvare("000000000000000");
  }, []);

  //use effect onauthchange
  useEffect(() => {
    console.log("use effect  00980", azione);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("onAuthStateChanged App", azione, auth, user);



      if (user && user.providerData[0].providerId == 'google.com') {
        setAzione("Logged");
        autentica(user);
        //   console.log('onAuthStateChanged', 1, user);
        //Non autentico perchè passa in dati utente prima di creare profilo
        // setTimeout(()=> navigate("/homeUtente"),250);
      }
      else if (user && user.providerData[0].providerId == 'google.com') {
        //   console.log('onAuthStateChanged', 2, user);
        autentica(user);
      }

      else if (user && user.displayName)    //se non ha display name quando loggo con mail significa che non ha finito processo di registrazione e quindi attendo a caricare/registrare profilo
      {
        //  console.log('onAuthStateChanged',2,user);
        autentica(user);
      }

      else if (!user) {
       // setAbilLogin(false);
        autentica(user);
        // console.log('onAuthStateChanged',3,'logout');
      }
      /* else if (user && user.displayName)    //se non ha display name quando loggo con mail significa che non ha finito processo di registrazione e quindi attendo a caricare/registrare profilo
      {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
 
        // ...
        autentica(user);
 
 
      } */
      else {
        //  console.log("onAuthStateChanged", "else");
        // User is signed out
        // ...
        //setAbilLogin(false);
        console.log("User log out");
      }


      //// spsostato qui perchè nell'altra useeffect continuava a partire bob 2024/07/26
      setTimeout(() => { if (datiGen.getInstance().possoaccederev2('COMPLEANNI')) restPersona.getInstance().getCompleanni().then((x) => { setCompleanni(x); }) }, 2000);
      if (!ticompleanni) {
        setInterval(() => { restPersona.getInstance().getCompleanni().then((x) => { setCompleanni(x); }) }, (1000 * 60 * 60));
      }

      return () => unsubscribe();
    });

  }, []);



  const autentica = (async (user) => {
    if (authattiva > 0) {

      return;
    }
    authattiva = 1;
    console.log("autentica",user);

    // setUtente(datiGen.getInstance().getUtenteCollegato());

    await datiGen.getInstance().setUtente(user, mailName);
    setAnagAbil(datiGen.getInstance().possoaccederev2('ANAG'));
    setAbilLogin(datiGen.getInstance().possoaccederev2('LOGIN'));
    console.log(datiGen.getInstance().possoaccederev2('LOGIN'));
    setAbilScouting(datiGen.getInstance().possoaccederev2('SCOUTING'));
    console.log(datiGen.getInstance().possoaccederev2('SCOUTING'));
    
    authattiva = 0;

    setUser(user);
    setActiveSoc(datiGen.getInstance().getActiveSoc());


    if (user) {
      getToken(messaging, { vapidKey: 'BDWHiK6ZNf0CoiYMmhm42XvG5bPj9xyoEm0VlH7UtUhVjH2j5z6aewR6i5DIITap9YulTbaeNHpZ332iQdf04uk' }).then((tk) => {

        datiGen.getInstance().sendMsgTokenRegistration(tk);
      })
        .catch((errore) => {
          console.log('home.js', ' messagind: idtoken:', errore);
        });

    }

  });

  useEffect(() => {

    // console.log('use',mostraaggiorna);

  }, [mostraaggiorna, mostraCompleanni, compleanni]);

  useEffect(
    React.useCallback(() => {
      //console.log('home','focus effect');
      console.log('log da verificare bene perchè partte !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
      setAnagAbil(datiGen.getInstance().possoaccederev2('ANAG'));
      setAbilScouting(datiGen.getInstance().possoaccederev2('SCOUTING_NOTIFY'));

      /*   setTimeout(() => {
           setMostraAggiorna(datiGen.getInstance().getConf('buildVersion') && parseInt(VersionInfo.buildVersion) < parseInt(datiGen.getInstance().getConf('buildVersion').valore));
   
         }, 2000);
         if (!timostragg) {
           setInterval(() => {
             setMostraAggiorna(datiGen.getInstance().getConf('buildVersion') && parseInt(VersionInfo.buildVersion) < parseInt(datiGen.getInstance().getConf('buildVersion').valore));
           }, (1000 * 60 * 60 * 6));
         }*/
      setTimeout(() => { setAbilMyTeam(datiGen.getInstance().getUtenteCollegato() && datiGen.getInstance().getUtenteCollegato().gruppoSQPred && (datiGen.getInstance().getUtenteCollegato().gruppoSQPred.toString() !== '##')); }, 500);
      setTimeout(() => { setAbilMyTeam2(datiGen.getInstance().getUtenteCollegato() && datiGen.getInstance().getUtenteCollegato().gruppoSQPred2 && (datiGen.getInstance().getUtenteCollegato().gruppoSQPred2.toString() !== '##')); }, 600);
      //   setTimeout(() => { restGruppi.getInstance().getGruppiPred().then((grps) => { if (grps && grps.length > 0) { setGrpPred(grps[0]); setMostraGrpPred(true); } }) }, 700);


      /*     setTimeout(() => { if (datiGen.getInstance().possoaccederev2('COMPLEANNI')) restPersona.getInstance().getCompleanni().then((x) => { setCompleanni(x); }) }, 2000);
          if (!ticompleanni) {
            setInterval(() => { restPersona.getInstance().getCompleanni().then((x) => { setCompleanni(x); }) }, (1000 * 60 * 60));
          }
    
          setTimeout(() => {
            if (datiGen.getInstance().getUtenteCollegato() && datiGen.getInstance().getUtenteCollegato().dtPrivacy && datiGen.getInstance().getUtenteCollegato().dtNonDivulgare) {
    
              setFlagDtNonDist(true);
              setFlagPriv(true);
              setMostraPrivacy(false);
            }
            else if (datiGen.getInstance().getUtenteCollegato()) {
    
              setMostraPrivacy(true);
              setFlagDtNonDist(false);
              setFlagPriv(false);
            }
          }, 500);*/

    }, [activeSoc])
  );

  function UserLoggedAction() {

    if (user) {

      return (
        <div style={{
          flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', padding: "0.2em",
          alignContent: 'flex-start', marginLeft: "0.2rem", width: '100%', display: 'flex'
        }}>
          <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', display: 'grid' }}>

            {abilScouting &&
              <Button
                onClick={() => { navigate("/SchedeScouting") }}>
                <Icon path={mdiFlagCheckered} size={1} />
                <span className='bottoneHome-label'>Scouting</span>
              </Button>

            }
            {abilMyTeam &&
              <Button
                onClick={() => { navigate('/TeamPage',{state:{gruppo:datiGen.getInstance().getUtenteCollegato().gruppoSQPred} }   )   }}
                >
                <Icon path={mdiAccountNetwork} size={1} />
                <span className='bottoneHome-label'>My Team</span>
              </Button>
            }
            {abilMyTeam2 &&
              <Button
                onClick={() => { }  // navigation.navigate('MyTeam',{gruppo:datiGen.getInstance().getUtenteCollegato().gruppoSQPred2})
                }>
                <Icon path={mdiAccountNetwork} size={1} />
                <span className='bottoneHome-label'>My Team 2</span>
              </Button>
            }
        <Button
                onClick={() => { navigate('/LivePage',{state:{gruppo:datiGen.getInstance().getUtenteCollegato().gruppoSQPred} }   )   }}
                >
                <Icon path={mdiAccountNetwork} size={1} />
                <span className='bottoneHome-label'>Live</span>
              </Button>





          </div>


          <div style={{ justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', display: 'grid' }}>
            <Button onClick={() => setMostraMenu(!mostraMenu)} className='icobutton' >
              <Icon path={mdiMenuOpen} size={1.2} />
            </Button>
          </div>
        </div>


      );
    }
    else
      return;
  }

  function UserNotLoggedAction() {
    if (!user)
      return (
        <div style={{
          display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', marginLeft: "0.2rem", width: '100%', justifyContent: 'center'


        }}>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifycontent: "center" }}>
            <Button
              title="Entra con Google"

              onClick={() => onGoogleButtonPress().then(() => console.log('Signed in with Google!'))}

            >
              <i className="pi pi-google"></i>
              <span className='bottoneHome-label'>Entra con Google</span>
            </Button>
            <Button
              title="Entra con la mail "
              onClick={toggleOverlay}

            >
              <i className="pi pi-inbox"  ></i>
              <span className='bottoneHome-label'>Entra con E-mail</span>
            </Button>
          </div>
        </div>

      );
    else
      return;
  }

  const toggleOverlay = () => {
    setMailLoginVisible(!mailLoginVisible);
  };

  async function onResetpwButtonPress() {

    sendPasswordResetEmail(auth, mailuser)
      .then(() => {
        console.log('Controlla la tua mail');
        show("Controlla la tua casella di posta, a breve riceverai il link per il cambio password");
        toggleOverlay();
        setMailName("");
        setMailUser("");
        setMailpw("");
        setMailRegister(false);
      })
      .catch(error => {



        console.error(error);
      });
  }



  async function onGoogleButtonPress() {

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const auser = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        const aIuser = getAdditionalUserInfo(result);
        auser.profilo = aIuser;
        //console.log("Login 3", auser);





      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });


  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      statoDaSalvare.includes('1') &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const proceed = () => {
    console.log('entro');
    blocker.proceed();
    console.log('entro 2');
    let x = statoDaSalvare.replaceAll("1", "0");
    setStatoDaSalvare(x);
  }

  useEffect(() => {
    if (blocker.state == 'blocked') {
      confirm1();
      console.log('confirm1');
    }
  }, [blocker.state]);

  const confirm1 = () => {
    confirmDialog({
      message: 'Non tutte le modifiche sono state salvate, vuoi uscire comunque?',
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => { if (blocker.state == 'blocked') proceed() }),
      reject: (() => { if (blocker.state == 'blocked') blocker.reset() }),
    });
  };

  async function onMailButtonPress() {

    signInWithEmailAndPassword(auth, mailuser, mailpw)
      .then(() => {
        /*
                 datiGen.getInstance().setUtente(user,mailName).then((uc)=>{
            setAnagAbil(datiGen.getInstance().possoaccederev2('ANAG'));
            });*/

        console.log('User signed in!');
        setMailName("");
        setMailUser("");
        setMailpw("");
        setMailRegister(false);
        toggleOverlay();
      })
      .catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          console.log('That email address is already in use!');
          showAlert("l'indirizzo mail è già stato utilizzato");
        }

        else if (error.code === 'auth/invalid-email') {
          console.log('That email address is invalid!');
          showAlert("L'indirizzo mail non è corretto")
        }
        else if (error.code === 'auth/wrong-password') {
          console.log('Wrong User name or password');
          showAlert('Nome utente o password non corretti');
        }
        else if (error.code === 'auth/user-not-found') {
          console.log('Wrong User:user-not-found');
          showAlert('Nome utente o password non corretti');
        }
        else {
          console.log("Errore generico durante l'autenticazione");
          showAlert('Nome utente o password non corretti');
        }
        console.error(error);
      });

  }

  async function onCreateUserButtonPress() {


    createUserWithEmailAndPassword(auth, mailuser, mailpw)
      .then((user) => {
        updateProfile(auth.currentUser, { displayName: mailName }).then((s) => {
          datiGen.getInstance().setUtente(auth.currentUser).then((uc) => {
            setUser(auth.currentUser);
            setMailName("");
            setMailUser("");
            setMailpw("");
            setMailRegister(false);
            toggleOverlay();
          })
        })
      })
      .catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          console.log('That email address is already in use!');
        }

        if (error.code === 'auth/invalid-email') {
          console.log('That email address is invalid!');
        }

        console.error(error);
      });

  }


  return (
    <div className="App">
      <span>--{statoDaSalvare}--</span>
      <ConfirmDialog/>
      <Toast ref={toast} position='center' />
      <div className='TopBar'>
        <span style={{ color: "white" }}>VIGOR APP</span>
        <div>
          <Button onClick={() => navigate("/")} className='icobutton' >
            <Icon path={mdiHome} size={1.2} />
          </Button>
          <Button onClick={() => setMostraMenu(true)} className='icobutton' >
            <Icon path={mdiMenuOpen} size={1.2} />
          </Button>
        </div>
      </div>
      <Sidebar modal={false} visible={mostraMenu} onHide={() => setMostraMenu(false)} position='right' style={{ backgroundColor: '#FFFFFF20', boxShadow: 'none', width: '16rem' }}
        closeOnEscape={true} showCloseIcon={false}
      >
        <div style={{ borderRadius: "20px", height: "90%", display: "flex", flexDirection: 'column', justifyContent: 'flex-end' }}>
          <div style={{ backgroundColor: "#afcfdfaf", borderRadius: "20px", display: "flex", flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button onClick={() => { setMostraMenu(false); }} style={{ alignSelf: 'flex-end', margin: "1em", borderRadius: "24px" }}  >
              <Icon path={mdiClose} size={1} />
            </Button>
            {anagAbil &&
              <Button onClick={() => { navigate("/persone"); setMostraMenu(false); }} className="sideButton"  >
                <Icon path={mdiAccountMultiple} size={1.3} />
                <span className='bottoneHome-label'>Giocatori e staff</span>
              </Button>
            }
            {abilGruppi &&
              <Button onClick={() => { navigate("/gruppi"); setMostraMenu(false); }} className="sideButton">
                <Icon path={mdiAccountGroup} size={1.3} />
                <span className='bottoneHome-label'>Gruppi</span>
              </Button>
            }
            {datiGen.getInstance().getUtenteCollegato() && datiGen.getInstance().possoaccederev2('ATTIVITA') &&
              <Button onClick={() => { navigate("/attivita"); setMostraMenu(false); }} className="sideButton">
                <Icon path={mdiCalendarMultipleCheck} size={1.3} />
                <span className='bottoneHome-label'>Attività</span>
              </Button>
            }
            {4<1 && datiGen.getInstance().getUtenteCollegato() && datiGen.getInstance().possoaccederev2('ATTIVITA') && 
              <Button onClick={() => { navigate("/agenda"); setMostraMenu(false); }} className="sideButton">
                <Icon path={mdiCalendarClock} size={1.3} />
                <span className='bottoneHome-label'>Agenda</span>
              </Button>
            }
            {mostragrpPred &&
              <Button onClick={() => { navigate("/provini"); setMostraMenu(false); }} className="sideButton">
                <Icon path={mdiSoccer} size={1.3} />
                <span className='bottoneHome-label'>Provini</span>
              </Button>
            }
            {abilConf &&
              <Button onClick={() => { navigate("/Config"); setMostraMenu(false); }} className="sideButton">
                <Icon path={mdiSoccer} size={1.3} />
                <span className='bottoneHome-label'>Configurazioni</span>
              </Button>
            }
            <Button
              onClick={() => { getAuth().signOut().then(() => { console.log('User signed out!'); setOpen(!open); setMostraMenu(false); }) }} className="sideButton">
              <Icon path={mdiLogout} size={1.3} />
              <span className='bottoneHome-label'>Logout</span>
            </Button>
          </div>
        </div>

      </Sidebar>

      <Dialog visible={mailLoginVisible}
        showCloseIcon closeOnEscape dismissable={true} onHide={() => { if (!mailLoginVisible) return; setMailLoginVisible(false); }}
      >

        <span>Mail Login</span>
        <label h3>{mailRegister ? 'Registra nuovo utente' : 'Accedi'}</label>
        <div style={{ display: "flex", flexDirection: 'column' }}>

          {mailRegister &&
            <div className='horizFlexcontainerMargin' >  <label className='Titolo3'>Nome e cognome:</label>
              <IconField iconPosition="left" style={{ minWidth: "15rem" }} >

                <InputIcon>
                  <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                </InputIcon>

                <InputText
                  value={mailName}
                  onChange={vcf => { setMailName(vcf.target.value); }}
                >
                </InputText>
              </IconField>
            </div>
          }
          <div className='horizFlexcontainerMargin' >  <label className='Titolo3'>Email:</label>
            <IconField iconPosition="left" style={{ minWidth: "15rem" }} >

              <InputIcon>
                <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
              </InputIcon>

              <InputText
                value={mailuser}
                onChange={vcf => { setMailUser(vcf.target.value); }}
              >
              </InputText>
            </IconField>
          </div>
          <div className='horizFlexcontainerMargin' >  <label className='Titolo3'>Password:</label>
            <IconField iconPosition="left" style={{ minWidth: "15rem" }}  >

              <InputIcon style={{ marginLeft: '0.5rem' }}>
                <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
              </InputIcon>

              <Password inputStyle={{ paddingLeft: '1.7rem' }}
                value={mailpw} feedback={false} toggleMask
                onChange={vcf => { setMailpw(vcf.target.value); }}
              >
              </Password>
            </IconField>
          </div>
          <div style={{ flexDirection: 'row' }} >
            {!mailRegister &&
              <Button

                title="Accedi"
                onClick={() => onMailButtonPress().then(() => console.log('Signed in with Mail!'))}
              ><Icon path={mdiLogin} size={1.3} />
                <span className='bottoneHome-label'>Entra con E-mail</span>
              </Button>
            }
            {mailRegister &&
              <Button

                title="Nuovo utente"
                onClick={() => onCreateUserButtonPress().then(() => console.log('Signed in with Mail!'))}
              >
                <Icon path={mdiAccountPlus} size={1.3} />
                <span className='bottoneHome-label'>Nuovo utente</span>
              </Button>
            }
          </div>
          <div style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }} >
            {!mailRegister &&
              <label style={{ flex: 1, margin: 20, alignSelf: 'center', textAlign: 'center', minWidth: 70 }}
                onClick={() => onResetpwButtonPress().then(() => console.log('onResetpwButtonPress'))}
              >Password dimenticata</label>
            }
            {!mailRegister && <label style={{ flex: 1, margin: 20, alignSelf: 'center', textAlign: 'center', minWidth: 90, flexShrink: 0 }}
              onClick={() => setMailRegister(true)}
            >Registra Nuovo Utente</label>}
            {mailRegister && <label style={{ flex: 1, margin: 20, alignSelf: 'center', textAlign: 'center', minWidth: 90, flexShrink: 0 }}
              onClick={() => setMailRegister(false)}
            >Accedi</label>}
          </div>
        </div>
      </Dialog>



      <Dialog visible={mostralistaCompleanni} title='Compleanni di oggi' showCloseIcon closeOnEscape dismissable={true} onHide={() => { if (!mostralistaCompleanni) return; setMostraListaCompleanni(false); }} >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}            >
          <span className='Titolo5' >Compleanni di oggi</span>

          <Image src={cake} imageStyle={{ width: '20rem', borderRadius: "5em", marginBottom: '0.5rem',marginTop: '0.5rem' }}></Image>

        </div>
        <div >
          <div >
            {compleanni.map((u, i) => {
              return (
                <div key={i} style={{ minHeight: 55, marginVertical: 5, textAlign: 'center' }}>
                  <span className='Titolo5' >{u.cognome} {u.nome} {moment(u.datanascita).format('YYYY')}</span>
                </div>

              );
            }
            )}


          </div>
        </div>

      </Dialog>

      {location.pathname == '/' &&
        <>
          <header className="App-header">
          </header>
          <div className='divHome'>
            <Image src={logo} alt="Image" width="250" className='App-logo' />
            <label className='Titolo' >{datiGen.getInstance().getActiveSoc().des}</label>
            {user &&
              <div style={{ flex: 1 }}>
                <label className='Titolo2'> {user.displayName ? user.displayName : user.email}</label>

              </div>
            }
            {user && datiGen.getInstance().possoaccederev2('CAMBIASOC') &&
              <div style={{ flex: 1, flexDirection: 'row' }}>
                <Button title='VM' onClick={() => { datiGen.getInstance().setActiveSoc('VM'); setActiveSoc(datiGen.getInstance().getActiveSoc()); }}></Button>
                <Button title='AF1' onClick={() => { datiGen.getInstance().setActiveSoc('AF1'); setActiveSoc(datiGen.getInstance().getActiveSoc()); }}></Button>
              </div>
            }






            {compleanni && compleanni.length > 0 &&
              <div onClick={(e) => setMostraListaCompleanni(true)} className='p-overlay-badge' >
                <Icon path={mdiCakeVariant} size='3.5rem' color='#0a568d' />
                <Badge value={compleanni ? compleanni.length : '0'} severity='info' position />
              </div>}
            <div style={{ alignSelf: 'center', flex: 1, display: "flex", width: "100vw" }}>
              {
                UserNotLoggedAction()
              }

              {
                UserLoggedAction()

              }




            </div>



          </div>
        </>

      }
      
      {    abilLogin && 
      <Outlet context={[statoDaSalvare, setStatoDaSalvare]} />
      }




    </div>


  )



}




export default App;
