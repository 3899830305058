// screens/Home.js
import React, { useState, useEffect } from 'react';
import datiGen from '../util/datiGen.js';
import moment from 'moment';
import ElabRepTest from '../util/test/elabRepTest.js';
import restConfigurazioniTest from '../restcall/restConfigurazioniTest.js';
//import Grafico_repGioc from './Grafico_repGIoc.js';
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import Grafico_repGioc from './Grafico_repGIoc.js';
import { DataView } from 'primereact/dataview';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer, mdiContentSaveAll, mdiCloseBox,
  mdiCheckAll, mdiTagEdit,
  mdiAccount,
  mdiTabSearch,
  mdiPrinter,
  mdiAccountDetails,
  mdiCalculator
} from '@mdi/js';
const TestGioc = (props) => {
  const navigation = useNavigate();
  const [idgioc, setIdgioc] = useState(props.idgioc);
  const [person, setPerson] = useState(props.person);
  const [lista, setLista] = useState([]);
  const [gruppoFiltro, setGruppoFiltro] = useState(props.gruppo ? props.gruppo : '');
  const [show_filtro, setShow_filtro] = useState(false);
  const [show_totali, setShow_totali] = useState(false);
  const [listaTipoTest, setListaTipoTest] = useState([]);
  const [evtest, setEvtest] = useState('');
  const [date_filtroI, setDate_filtroI] = useState(datiGen.getInstance().getRicercadtDa());
  const [date_filtroF, setDate_filtroF] = useState(datiGen.getInstance().getRicercadtA());
  const [indFiltro, setindFiltro] = useState(datiGen.getInstance().getRicercaIndirizzo());
  const [tipoFiltro, setTipoFiltro] = useState('XXX');
  const [gruppoDes, setGruppoDes] = useState(props.gruppodes ? props.gruppodes : '');
  const abilTest = datiGen.getInstance().possoaccederev2('REPTESTSQUADRA', props.gruppo);
  var dataper = [];



  useEffect(() => {
    getListaTest();
    renderlista();
  }, [tipoFiltro, date_filtroI, date_filtroF, show_filtro, gruppoFiltro, indFiltro, show_totali]);

  const selezionatest = async (valo) => {
    try {

      setEvtest(valo);
      setTipoFiltro(valo._id);

    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const getListaTest = async () => {
    try {
      var json = await restConfigurazioniTest.getInstance().getAllConfigurazioniTest(true);
      setListaTipoTest(json);
      console.log("lista test", listaTipoTest);
    } catch (error) {
      console.error(error);
      setListaTipoTest([]);
    } finally {
      ;
    }
  }

  const renderItem = (item, index) => (
    <div className='listaItem' >
      {item &&
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <label style={{ flex: 3 }}>{moment(item.datainitest).format('DD-MM-YYYY HH:mm')} </label>
          <label style={{ flex: 1, textAlign: 'right' }}>{item.risval}</label>
        </div>
      }
    </div>
  )

  const renderlista = async () => {
    if (tipoFiltro != 'XXX') {
      ElabRepTest.getInstance().getListaTestdategioc(date_filtroI, date_filtroF, person._id, tipoFiltro, indFiltro, show_totali).then((outlista) => {
        if (outlista.length > 1) {
          //listaord=outlista.sort((a, b) => a.datainitest > b.datainitest );
          setLista(outlista);
        }
        //  listadef.sort(([a], [b]) => a.localeCompare(b));
      });
    }///// tipo filtro ! XXX
  }
  return (
    <div style={{
      width: '100%',
      height: '100%',
      flex: 1,
    }}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', minHeight: datiGen.getInstance().scala(30) }}>
        <label className='Titolo5' >Dati Test</label>
        <Button style={{ justifyContent: 'flex-end' }}
          onClick={() => (setShow_filtro(!show_filtro))}>
          <Icon path={mdiTabSearch} size={0.8} />
        </Button>
      </div>
      {show_filtro &&
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: '100%', flexGrow: 2, minHeight: datiGen.getInstance().scala(110) }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} htmlFor='dt1'>Da</label>
              <Calendar id="dt1" value={date_filtroI} showIcon onChange={(e) => { setDate_filtroI(e.value); datiGen.getInstance().setRicercaDtDa(e.value); }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} htmlFor='dt2' >A</label>
              <Calendar id="dt2" value={date_filtroF} showIcon onChange={(e) => { setDate_filtroF(e.value); datiGen.getInstance().setRicercaDtA(e.value); }} />
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Tipo Test:</label>
            <Dropdown
              value={evtest}
              showClear
              options={listaTipoTest}
              optionLabel="nometest"
              optionValue="_id"
              style={{ marginLeft: "0.3em", marginRight: "0.3em" }}
              onChange={vcf => {
                selezionatest(vcf.target.value);
              }} />
          </div>
        </div>
      }
      <div className='listazioni' style={{ marginTop: '0.5rem' }}>
        <label style={{ color: 'white', textAlignVertical: 'center', marginLeft: '0.3rem', fontWeight: 'bold' }} >{person.cognome}  {person.nome}</label>
        {props.gruppo &&
          <Button
            onClick={() => { renderlista() }}
          >
            <Icon path={mdiUpdate} size={0.8} />
          </Button>
        }
        <Button
          onClick={() => { setShow_totali(!show_totali) }}
        >
          <Icon path={show_totali ? mdiAccountDetails : mdiCalculator} size={0.8} />
        </Button>

      </div>
      {show_totali &&
        <div style={{ flex: 1, width: '70%', minWidth: '70%', flex: 1, flexGrow: 10 }}>
          <Grafico_repGioc dati={lista} />
        </div>
      }
      {!show_totali &&
        <div

          style={{ width: '70%', minWidth: '70%', flex: 1, flexGrow: 10, }}>
          <div style={{ padding: 24, minWidth: '100%' }}>
            <DataView
              header=
              {
                <div className='listazioni' style={{ display: 'flex', flexDirection: 'row', }}>
                  <label style={{ flex: 2, textAlign: 'start', color: 'white', textAlignVertical: 'center', fontSize: datiGen.getInstance().scala(16) }}>Data</label>
                  <label style={{ flex: 1, textAlign: 'end', color: 'white', textAlignVertical: 'center', textAlign: 'right', fontSize: datiGen.getInstance().scala(16) }}>Valore</label>
                </div>
              }
              value={lista}
              itemTemplate={renderItem}
            />
          </div>
        </div>
      }
    </div>
  );
}
export default TestGioc;