import moment from 'moment'

const tesseramentoPrototype = {
    

  to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
    if (moment(this.fineval).format('YYYYMMDD')=='20220601')
    this.fineval =moment("20220630", "YYYYMMDD").toDate();
  },

  copiaTesseramento(tipo){
    this.id=tipo.id;
    this.tipo=tipo.tipo;
    this.matricola=tipo.matricola;
    this.ival=tipo.ival;
    this.fineval=tipo.fineval; 
    this.societa=tipo.societa;
    this.note=tipo.note;
    
    this.__proto__ = tesseramento.prototype;
  }
  }
  
  function tesseramento(tipo) {
    this.id=Date.now();
    this.tipo = tipo;   //carta identità,patente,passaporto
    this.matricola="";
    this.ival=new Date();

    
    if (this.ival.getMonth()>=6 && this.ival.getMonth()<=12 )
    {
      this.ival.setDate(1);
      this.ival.setMonth(8); //settembre
    this.fineval=moment().add(1, 'years').toDate();
    this.fineval.setDate(30);
    this.fineval.setMonth(5);  //giugno
    }
else
{
  this.ival=moment().subtract(1, 'years').toDate();
  this.ival.setDate(1);
  this.ival.setMonth(8);  //settembre
  
    this.fineval=new moment().toDate();
    this.fineval.setDate(30);
    this.fineval.setMonth(5); //giugno
}


    this.societa='Vigor Milano';
    this.note="";

    this.__proto__ = tesseramento.prototype;
  }
  
  tesseramento.prototype = tesseramentoPrototype;
  tesseramento.prototype.constructor = tesseramento;
  





export default tesseramento;