import React from 'react';
import datiGen from '../util/datiGen';
import { useNavigate, useLocation, Link } from "react-router-dom";
import TestConf from '../comp/TestConf';
const TestConfPage = () => {
  const navigate = useNavigate();

    const [routes,setRoutes] = React.useState([   ]);


  return (
    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      
    }}>


<div style={{alignSelf:'flex-start'}}>       
<TestConf/>
      </div>  

 </div>
  );
} 
export default TestConfPage;