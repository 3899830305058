import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";

import datiGen from "../util/datiGen";


export default class restReportTest {
  restUrlServer = datiInit.getInstance().getRestUrlServer();
  restApp = 'ReportTest';
  static myInstance = null;

  static getInstance() {
    if (restReportTest.myInstance == null) {
      restReportTest.myInstance = new restReportTest();
      // Util.myInstance.inizializza();
      
    }
    return restReportTest.myInstance;
  }

getEsebyIdesetest = async ( gruppo,idtestese) => {
  try {
    if (!datiGen.getInstance().possoaccederev2('REPTEST',gruppo))
    throw 'Non sei abilitato a svolgere la funzione REPTEST';

    const user = getAuth().currentUser;
    var token = await user.getIdToken();

    const response = await fetch(encodeURI(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/"+idtestese ), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      }
    }
    );
    var json = await response.text();
    if (json) {
      json = JSON.parse(json);
      json.esito = 'OK';
    }
    else json = { esito: "KO2" };

    /*if (json[0].gioc)
       console.log('zzzzzzzzzzz',json[0].gioc);*/
  } catch (error) {
    console.error(error);

  } finally {

    if (!json) json = { esito: "KO3" };
    if (!json.esito) json.esito = 'KO';


    return json;

  }

}

getEsebytipotest_date = async (gruppoFiltro ,date_filtroI, date_filtroF, tipoFiltro, indFiltro) => {
  try {
    //console.log('io chiamo --------------------------0',moment(date_filtroI).format('YYYY-MM-DD'));

    if (!datiGen.getInstance().possoaccederev2('REPTEST',gruppoFiltro))
    throw 'Non sei abilitato a svolgere la funzione REPTEST';
 
  var dtcond = "d1=" + moment(date_filtroI).format('YYYY-MM-DD')+ "&d2=" +moment(date_filtroF).format('YYYY-MM-DD') ;
   
  // dtcond = "d1=A"  ;

    const user = getAuth().currentUser;
    var token = await user.getIdToken();
//console.log('io chiamo --------------------------1',this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/"+encodeURIComponent(tipoFiltro)+ "/"+gruppoFiltro+ "/testperiodogruppo"+'?'+dtcond )
    
    const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/"+encodeURIComponent(tipoFiltro)+ "/"+gruppoFiltro+ "/testperiodogruppo"+'?'+dtcond, {
      
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      }
    }
    );
    var json = await response.text();
//    console.log("risposta","__________________________________",json);
    if (json) {
      json = JSON.parse(json);
      json.esito = 'OK';
    }
    else json = { esito: "KO2" };

    /*if (json[0].gioc)
       console.log('zzzzzzzzzzz',json[0].gioc);*/
  } catch (error) {
    console.error(error);

  } finally {
//    console.log('io chiamo --------------------------3')

    if (!json) json = { esito: "KO3" };
    if (!json.esito) json.esito = 'KO';


    return json;

  }

}


getEsebyidgioc_date = async (tipoFiltro ,date_filtroI, date_filtroF, idgioc, indFiltro) => {
  try {
    //console.log('io chiamo --------------------------0',moment(date_filtroI).format('YYYY-MM-DD'));

    if (!datiGen.getInstance().possoaccederev2('REPTEST'))
    throw 'Non sei abilitato a svolgere la funzione REPTEST';
 
   var dtcond = "d1=" + moment(date_filtroI).format('YYYY-MM-DD')+ "&d2=" +moment(date_filtroF).format('YYYY-MM-DD') ;
   
  // dtcond = "d1=A"  ;

    const user = getAuth().currentUser;
    var token = await user.getIdToken();
//console.log('io chiamo -----gioc--1',idgioc,tipoFiltro,this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/"+encodeURIComponent(tipoFiltro)+"/"+idgioc+  "/testperiodogioc"+'?'+dtcond )
    
    const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/"+encodeURIComponent(tipoFiltro)+"/"+idgioc+  "/testperiodogioc"+'?'+dtcond, {
      
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      }
    }
    );
    var json = await response.text();
//    console.log("risposta","__________________________________",json);
    if (json) {
      json = JSON.parse(json);
      json.esito = 'OK';
    }
    else json = { esito: "KO2" };

    /*if (json[0].gioc)
       console.log('zzzzzzzzzzz',json[0].gioc);*/
  } catch (error) {
    console.error(error);

  } finally {
//    console.log('io chiamo --------------------------3')

    if (!json) json = { esito: "KO3" };
    if (!json.esito) json.esito = 'KO';


    return json;

  }

}









}