

const recapitiPrototype = {
  to_obj() {
   
    if (this.fineval) {
      this.fineval = new Date(this.fineval);
    }
  },

  copiaRecapito(tipo) {
    this.id=tipo.id;
    this.tipo=tipo.tipo;   //telefono,indirizzo,e-mail
    this.descrizione=tipo.descrizione;  //numero,via o mail
    this.appartenenza=tipo.appartenenza;  //ragazzo,genitore,altro
    this.descrizione2=tipo.descrizione2; //seconda riga indirizzo
    this.note=tipo.note;
    this.citta=tipo.citta;
    this.cap=tipo.cap;
    this.provincia=tipo.provincia;
    this.nazione=tipo.nazione;
    this.fineval=tipo.fineval;
    this.__proto__ = Recapito.prototype;
  }
  }

  
  function Recapito(tipo) {
    this.id=Date.now();
    this.tipo = tipo;   //telefono,indirizzo,e-mail
    this.descrizione="";  //numero,via o mail
    this.appartenenza="";  //ragazzo,genitore,altro
    this.descrizione2=""; //seconda riga indirizzo
    this.note="";
    this.citta="";
    this.cap="";
    this.provincia="MI";
    this.nazione="IT";
    this.fineval=new Date().setFullYear(2050);


    this.__proto__ = Recapito.prototype;
  }
  
  Recapito.prototype = recapitiPrototype;
  Recapito.prototype.constructor = Recapito;
  





export default Recapito;