// screens/Home.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import datiGen from '../util/datiGen.js';
import moment from 'moment'
import restGruppi from '../restcall/restGruppi.js';
import gruppo from '../obj/gruppo.js';
import DettGruppo from './DettGruppo.js';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer,mdiContentSaveAll,mdiCloseBox,
  mdiCheckAll

} from '@mdi/js';
import { OverlayPanel } from 'primereact/overlaypanel';
//import DettGruppo from './DettGruppo.js';



const ListaGruppi = (props) => {
  const navigation = useNavigate();
  // datiGen.getInstance().possoaccederev2('GRUPPI');

  const abilUpd = datiGen.getInstance().possoaccederev2('GRUPPIUPD');
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [dettGrpVisible, setDettGrpVisible] = useState(false);

  const [daSalvare, setDaSalvare] = useState(false);
  const [mostraValidi, setMostraValidi] = useState(true);

  const getListaGrp = async () => {
    
    try {
      setLoading(true);
      var json = await restGruppi.getInstance().getGruppi(mostraValidi).then((json) => {
        setData(json);
        setDaSalvare(false);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  const confirm1 = () => {
    
    confirmDialog({
      message: 'Non tutte le modifiche sono state salvate, vuoi uscire comunque   XXXX?',
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (()=>{setDettGrpVisible(false);setDaSalvare(false); }),
      reject: (()=>{return;}),
    });
  };


  ////// da ripristinare dasalvare  con blocker
  const toggleOverlayChiudi = () => {

    
      if (daSalvare  ) {
        //console.log('ListaGruppi','DaSalvare',daSalvare)
      confirm1();
      }
  else
  {
      setDettGrpVisible(false);
  }
    
  };

    ////// da ripristinare dasalvare  con blocker
    const toggleOverlayApri = () => {
      
   if (abilUpd)
        setDettGrpVisible(true);
    };



  const renderItem = (item, index) => (
    <div className='listaItem'
      onClick={() => { setSelected(item); toggleOverlayApri(); }}
    >
      <div style={{ flex: 2, flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' }}>
        <label style={{ flex: 3, textAlign: 'left' }}>{item.des} </label>
        <div style={{ flex: 2, flexDirection: 'row', alignSelf: 'flex-end', alignContent: 'flex-end', alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
        {abilUpd &&
          <Button
            onClick={() => { setSelected(item); toggleOverlayApri(); }}
          >
            <Icon path={mdiAccountGroup} size={0.8} />
          </Button>
}
          <Button
            onClick={() => navigation('/TeamPage', {state:{ gruppo: item._id }    })}
          >
            <Icon path={mdiSoccer} size={0.8} />
          </Button>

        </div>


      </div>

    </div>

  )

  const leggi = (pdata) => {


    setData(pdata);
  }


  const modifica = async (pgrp) => {
    try {
      setLoading(true);
      var json = await restGruppi.getInstance().modifica(pgrp);
      setDaSalvare(false);
      getListaGrp();
      setDettGrpVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }


  }

  const modificaval = async (pgrp) => {
    try {
      setSelected(pgrp);
    } catch (error) {
      console.error(error);
    } finally {
    }


  }



  const aggiungi = async () => {

    let t = new gruppo();
    t.idsoc = datiGen.getInstance().getActiveSoc().sigla;
    t.des = 'Nuovo Gruppo ';
    await restGruppi.getInstance().aggiungi(t);
    getListaGrp();
  }


  useEffect(() => {
    getListaGrp();
   
  }, [mostraValidi]);

  const headerElement = (
    <div className=" inline-flex align-items-center justify-content-center gap-2">
      <div className="listazioni" style={{ paddingLeft: "0.3em", justifyContent: 'space-between' }}  >
        <label style={{}} >Dettaglio: {selected && selected.des}</label>
        <div className="listazioni" style={{ paddingLeft: "0.3em" }}  >
          {abilUpd && daSalvare &&
            <Button
              onClick={() => modifica(selected).then(() => {  setDaSalvare(false); getListaGrp(); })}
            >
              <Icon path={mdiContentSaveAll} size={0.8} />
            </Button>
          }
          <Button
            onClick={() => {   toggleOverlayChiudi() }}
          >
            <Icon path={mdiCloseBox} size={0.8} />
          </Button>
        </div>
      </div>
    </div>


  );



  return (

    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#dddddd',
      width: '100%'
    }}>


      <div style={{ flex: 1, width: '100%' }}>
        <DataView
          header=
          {<div className="listazioni">
            {abilUpd &&
             <Button
              onClick={() => aggiungi()}
            > <Icon path={mdiPlus} size={0.8} />
            </Button>
          }
            <Button
              onClick={() => { setMostraValidi(!mostraValidi) }}
            > <Icon path={mdiCheckAll} size={0.8} />
            </Button>
            <Button
              onClick={() => getListaGrp()}
            >
              <Icon path={mdiUpdate} size={0.8} />
            </Button>
          </div>}
          value={data}
          itemTemplate={renderItem}
        />
      </div>

      <Dialog header={headerElement}  visible={dettGrpVisible} style={{ width: '80vw' }}
      closable={false}
      closeOnEscape={true}
      onHide={() => {if (!dettGrpVisible) return; toggleOverlayChiudi();}}>
      <DettGruppo dettGrpVisible={setDettGrpVisible} grp={selected}  modificav={modificaval} daSalvare={setDaSalvare} />
      </Dialog>



    </div>
  );
}
export default ListaGruppi;








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































