import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";
import datiGen from "../util/datiGen";





export default class restEsecTest {


  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'EsecuzioneTest';
  static myInstance = null;

  static getInstance() {
    if (restEsecTest.myInstance == null) {
      restEsecTest.myInstance = new restEsecTest();
      // Util.myInstance.inizializza();
      
    }
    return restEsecTest.myInstance;
  }



  

  modifica = async (ptest) => {
     
    var json;
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();



      if (!datiGen.getInstance().possoaccederev2('ESECTEST'))
      throw 'Non sei abilitato a svolgere la funzione ESECTEST - modifica';
      var p = { ...ptest };
      delete p._id;
      
      const s = this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + ptest._id;
   
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+ token
          },
          body: JSON.stringify(p)
        });
        if (response.status >= 300) {
          throw 'Errore ' + JSON.stringify(json);
        }

       json = await response.json();

      json.esito='OK';

    } catch (error) {
      
      console.log('rest_esecuzionetest','modifica','catch',error);
      json={esito:'KO',des:error}

    } finally {
      
      return json;
    }

  }



  elimina = async (ptest) => {
     
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();

      if (!datiGen.getInstance().possoaccederev2('ESECTEST'))
      throw 'Non sei abilitato a svolgere la funzione ESECTEST - elimina';
  
        
      
      const s = this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + ptest._id;
      
      const response = await fetch(s
        , {
          method: 'delete',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+ token
          },
          
        });

      const json = await response.text();

      
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }
    
  
    aggiungi = async (ptestese,gruppo) => {
      console.log('esectest',"aggiungi",ptestese);
      var json=null;  
      if (!datiGen.getInstance().possoaccederev2('ESECTEST',gruppo))
            throw 'Non sei abilitato a svolgere la funzione ESECTEST';
            
          try {
            const user = getAuth().currentUser;
            var token = await user.getIdToken();
            const s=this.restUrlServer + '/' + this.restApp+ '/'+datiGen.getInstance().getActiveSoc().sigla+'?gruppo='+gruppo;
            const response = await fetch(s
              , {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + token
                },
                body: JSON.stringify(ptestese)
              });
             json = await response.json();
            
            if (response.status >= 300) {
              throw 'Errore ' + JSON.stringify(json);
            }
           
              json.esito='OK';
            
            
      
          } catch (error) {
            console.error('RESTESECTES',error);
            json={esito:'KO',des:error}
          } finally {
            // console.log("finito aggiungi");
            return json;
          }
        }
 
 
        
        getEsebyidgiocese = async ( idtestese,idgioc) => {
          try {
      
            const user = getAuth().currentUser;
            var token = await user.getIdToken();
      
      
            const response = await fetch(encodeURI(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/"+idtestese+"/"+idgioc ), {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
              }
            }
            );
            var json = await response.text();
      
            if (json) {
              json = JSON.parse(json);
              json.esito = 'OK';
            }
            else json = { esito: "KO2" };
      
            /*if (json[0].gioc)
               console.log('zzzzzzzzzzz',json[0].gioc);*/
          } catch (error) {
            console.error(error);
      
          } finally {
      
            if (!json) json = { esito: "KO3" };
            if (!json.esito) json.esito = 'KO';
      

            return json;
      
          }
      
        }
      





}