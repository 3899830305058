// screens/Home.js

import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import datiGen from '../util/datiGen.js';
import { Button } from 'primereact/button';
  import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
  import {
    mdiArrowExpand,
mdiExpandAll,
 
  } from '@mdi/js';
  import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';


const EsecTestAtt = (props) => {
  const navigation = useNavigate();
  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD',props.gruppo);
  const [selGruppo, setSelGruppo] = useState(() =>props.gruppo);
  const [selAttivita, setSelAttivita] = useState(() => props.attivita);
  const [listaTest, setListaTest] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [selTest, setselTest] = useState(()=>props.pTest);
  const [mostraElenco, setMostraElenco] = useState(true);


  useEffect(
    () => {
      setselTest(props.pTest);

    },
    [selAttivita,props.ptest]
  );


useEffect((() =>{
        setselTest(props.pTest);
            }), [mostraElenco])


    const disegnaListaTest = () => {
      
      if (selAttivita.eltest && selAttivita.eltest!='undefined' && selAttivita.eltest.length>0) {
              return (
          
          selAttivita.eltest.map((ele, i) => (
            <div key={i} className='listaItem' style={{ flex:1,flexDirection:'row' ,flexGrow:1}} >
              <label style={{ flex: 1, color:props.pTest && ele.idtestese==props.pTest.idtestese? 'blue' :'black'}}
              onClick={()=>props.setTest(ele)}
              >{ele.nometest}  </label>            
            </div>
          ))
        );
      }
    }

    return (
      <div style={{
        flex: 1,        
        width: '100%',
         
      }}>
         <div className='listazioni'>
         <label style={{ color:'white',textAlignVertical:'center',fontSize:datiGen.getInstance().scala(22)}}>Elenco Test</label>
         <Button            
                onClick={() => setMostraElenco(!mostraElenco)}
              >
<Icon path={mdiArrowExpand} size ={0.8} />
              </Button>
         </div>
         <div  style={{ flex: 1,  }} >
{mostraElenco && disegnaListaTest()}
</div>
        </div>
    );
  }
  export default EsecTestAtt;