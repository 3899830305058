// screens/Home.js

import React, { Component, useEffect, useState } from 'react';
import datiGen from '../util/datiGen.js';
import moment from 'moment'
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import restSchedaGioc from '../restcall/restSchedaGioc.js';
import elenchi from '../util/elenchi.js';
import { Rating } from 'primereact/rating';
import schedaGioc from '../obj/schedaGioc.js';


import {
  mdiUpdate, mdiContentSaveAll, mdiAccountMultiple, mdiPlus, mdiAccountMinus

} from '@mdi/js';

const SchedaGiocatore = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const abilSCHGIOC = datiGen.getInstance().possoaccederev2('SCHGIOCUPD');
  const abilAnag = datiGen.getInstance().possoaccederev2('ANAG');
  
  const NavDettPersone = (ppers) => {

    return (

  abilAnag &&
      <Button
        onClick={() =>
          {
            if (props.gruppo)
            navigate('/MyTeam',{gruppo:props.gruppo})
            else
            navigate("/Persone")
          }
            
        }
      >
      <Icon path={mdiAccountMultiple} size={0.8} />

      </Button>

     
    );
  }


  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [selectedId, setSelectedId] = useState([]);
  const [selPersona, setSelPersona] = useState(() => props.selPersona);

  const getSchedeGioc = async () => {
    
    try {
      setLoading(true);
      var json=await  restSchedaGioc.getInstance().getSchedeGioc(selPersona._id);
    setData(json);
    
   } catch (error) {
     console.error(error);
   } finally {
     setLoading(false);
   }
 }

  

    const renderItem = (item, index ) => (

      item &&
      <div className='listaItem' 
      onClick={() => { console.log('dettaglio scheda',props.gruppo);   navigate('/DettSchedaGioc',{ state:{   gruppo :props.gruppo ,selschedagioc: item}})}      }
      >
     <div  style={{display:'flex',  flexDirection: 'row', flexWrap: 'wrap',justifyContent:'space-around' }}>
          <div style={{display:'flex',flexGrow:2,  flexDirection: 'column', flexWrap: 'wrap',justifyContent:'space-around' }}>

   <label   >{ item.Giocatore[0]? item.Giocatore[0].cognome:'xxx'}  { item.Giocatore[0]? item.Giocatore[0].nome:'xxx'}    </label> 
   <label>{ moment(item.dataScheda).format('DD/MM/YYYY')} </label>
   <label >{item.operatore} </label> 
    </div>  

    <div style={{ display:'flex',flex:1,padding:2,flexDirection:'column',alignItems:'center'}}
    >
    <label  >{item.valutazioneGlobale*2} </label> 
    <Rating      value={item.valutazioneGlobale}  stars={5} disabled cancel={false}  style={{}}
   />
       </div>
       </div>
        </div>     
    )

    const aggiungi = async () => {
      console.log('inizio aggiungi',t);
      var t = new schedaGioc();
      t.idsoc=datiGen.getInstance().getActiveSoc().sigla;
      t.operatore=datiGen.getInstance().getNomeUtenteCollegato();
      t.valutazioneGlobale="0";
      t.idgioc=selPersona._id;
      console.log('aggiungi schedaoss',t);
      await restSchedaGioc.getInstance().aggiungi(t);
     getSchedeGioc();
    }

    useEffect(() => {
        setSelPersona(props.selPersona);
        getSchedeGioc();
      }, []);



    return (

      <div style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#dddddd',
        width: '100%'
      }}>

{abilSCHGIOC && 
        <div style={{ flex: 1, width: '100%' }}>
          <DataView
            header=
            {<div className="listazioni" >
              {abilSCHGIOC && selPersona &&
              <Button
                
                onClick={() => aggiungi()}
                          >
                            <Icon path={mdiPlus} size={0.8} />    
              </Button>
              
              }

<Button
onClick={() => getSchedeGioc()}
>  
<Icon path={mdiUpdate} size={0.8} />
              </Button>             
              {NavDettPersone(data)}
            </div>}
            value={data}
            itemTemplate={renderItem}
          />


        </div>  }
      </div>
    );
  }
  export default SchedaGiocatore;