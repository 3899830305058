// screens/Home.js


import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { useState,useEffect, useRef } from "react";
import moment from "moment";
import Icon from '@mdi/react';
import { mdiAccountMultipleRemove, mdiCheckCircleOutline, mdiClose, mdiContentSave } from '@mdi/js';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import  Person  from '../obj/person.js' 
import restPersona from "../restcall/restPersona";
import { useOutletContext } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import datiGen from '../util/datiGen.js'
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import giocGruppoSQ from '../obj/giocGruppoSQ.js';
import restGiocGruppi from "../restcall/restGiocGruppi.js";

import { DataView } from "primereact/dataview";

const DettaglioPersonaGrp= (props) => {
  const navigate = useNavigate();
  const [daSalvare, setDaSalvare] = useOutletContext();
  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD', props.gruppo);
  const abilGiocGruppo = datiGen.getInstance().possoaccederev2('GIOCGRUPPO', props.gruppo);
 
  const [listaGrp, setListaGrp] = useState();
  const [listaGrpv, setListaGrpv] = useState();

  const [mostravalidi, setMostravalidi] = useState(true);
  
const [person, setPerson] = useState();

useEffect(() => {
console.log('parte use effet DettaglioPersonaGrp')
  setPerson(props.selPersona);
  getGrpPersona(props.selPersona);
  console.log('use effet DettaglioPersonaGrp',person)

}, []);


const getGrpPersona = async (tperson) => {
  console.log('Dettagliopersona grp','getgrpperson',tperson)
  try {
    if (tperson && tperson._id) {
      var json = await restGiocGruppi.getInstance().getGiocGruppiByIdgioc(tperson._id);
      json.forEach(ele => { ele.__proto__ =  giocGruppoSQ.prototype; ele.to_obj() });
      setListaGrp(json);       
      setListaGrpv(json);       

    }
  } catch (error) {
    setListaGrp([]);
    console.error(error);
  } finally {

  }
}

const mostranascondivalidi = ()=> {
console.log('botton',listaGrp,mostravalidi);
setMostravalidi(!mostravalidi);
let lst=listaGrp.filter(el=>el.isAttivo()==mostravalidi);
console.log('botton step 3',listaGrpv,mostravalidi);setListaGrpv(lst);
}




const toast = useRef(null);
const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Success', detail:'Message Content', life: 3000});
}
const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
}
const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
}
const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
}
const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
};
const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
};



const salva = async() => {
  let p = {...person};
  if(p._id){
    let x = await restPersona.getInstance().modifica(p, null);
    x.__proto__ = Person.prototype;
    x.to_obj(); 
    console.log(x);
    toast.current.show({severity:'success', summary: 'Salvato', detail:'salvataggio avvenuto con successo', life: 3000});
    setDaSalvare(false);
    setPerson(x);
  }else{
    console.log('non ho ne salvato ne modificato');
    toast.current.show({severity:'warn', summary: 'Attenzione', detail:'non ho ne salvato ne modificato', life: 3000});
  }
}



const confirm1 = () => {
  confirmDialog({
    message: 'Non tutte le modifiche sono state salvate, vuoi uscire comunque?',
    header: 'Attenzione',
    icon: 'pi pi-exclamation-triangle',
    defaultFocus: 'accept',
    accept: (()=>{setDaSalvare(false); }),
    reject: (()=>{;}),
  });
};


const renderItem = ( item, index ) => (
 <div className="listaItem" style={{display:'flex',alignItems:'center'}}>

 {item.isAttivo() && 
 <>
  <label  style={{fontWeight:'bold',fontSize:18,flex:2}} >{item.gruppoSQ[0].des}</label>
  <div style={{display:'flex',alignItems:'center',flex:1,flexDirection:'row'}}>
  <label >dal</label>
  <label style={{marginLeft:'0.4rem'}} >{moment(item.ival).format('DD-MM-YYYY')}</label>
  <label style={{marginLeft:'0.4rem'}} >al</label>
  <label style={{marginLeft:'0.4rem'}} >{moment(item.fineval).format('DD-MM-YYYY')}</label></div>
   <div style={{display:'flex', flex:1,   justifyContent:'right'}}>
    <Button 
   onClick={()=>{let x = { ...listaGrp }; item.fineval = new Date();restGiocGruppi.getInstance().modifica(item).then(()=>{ setDaSalvare(false);}) }}
  >
  <Icon   path={mdiAccountMultipleRemove}  size={0.8}  />
  
  </Button></div> </>
  }
{!item.isAttivo() &&
<>
<label  style={{fontWeight:'bold',fontSize:18,flex:2,color:'gray'}} >{item.gruppoSQ[0].des}</label>
<div style={{display:'flex',alignItems:'center',flex:1,flexDirection:'row'}}>
<label style={{color:'gray'}}>dal</label>
<label style={{marginLeft:'0.4rem',color:'gray'}} >{moment(item.ival).format('DD-MM-YYYY')}</label>
<label style={{marginLeft:'0.4rem',color:'gray'}} >al</label>
<label style={{marginLeft:'0.4rem',color:'gray'}} >{moment(item.fineval).format('DD-MM-YYYY')}</label></div>
<div  style={{flex:1}}></div>
</>
}
   
    
    </div>
)


  return (

<div> <Toast ref={toast} />
{person &&
<DataView 
header= {<div className='listazioni'>
    <label  style={{borderBottomWidth:1,borderBottomColor:'#0a568d',paddingBottom:10}} >Gruppi {person.cognome} {person.nome} </label>
    {   abilGiocGruppo  &&   abilAnagUPD &&  (daSalvare)  && (
    <Button
onClick={() => { restGiocGruppi.getInstance().modifica(person).then(()=>{setDaSalvare(false);})}}
>
  <Icon path={mdiContentSave} size={0.8}  />
</Button>
 )
    }
     <Button
        onClick={() => {console.log('partito botton'); mostranascondivalidi(); }}
      >
               <Icon path={mdiCheckCircleOutline} size={0.8} />
 
      </Button>

     <Button
        onClick={() => { props.updateAzione(0); }}
      >
               <Icon path={mdiClose} size={0.8} />
 
      </Button>
</div>}
value={listaGrpv}
itemTemplate={renderItem}

/>}
    </div>
  );

}


export default DettaglioPersonaGrp;