// screens/Home.js


import restPersona from '../restcall/restPersona';
import datiGen from '../util/datiGen.js';
import moment from 'moment'
import React, { useEffect, useState, useRef } from 'react';
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import { Button } from 'primereact/button';
import Icon from '@mdi/react';

import {
  mdiAccount, mdiSync

} from '@mdi/js';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { isTemplateSpan } from 'typescript';
import { DataScroller } from 'primereact/datascroller';

const RicercaPersone = (props) => {
  const navigation = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [riccog, setRiccog] = useState();
  const [ricnom, setRicnom] = useState();
  const [ricanno, setRicanno] = useState();
  const edtTimer = useRef(null);
  const getPersone = async () => {
    try {
      setLoading(true);
      console.log('get persone RicercaPersone inizio');
      var json = await restPersona.getInstance().getPersone(riccog, ricnom, ricanno, null, props.gruppo);
      setData(json);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const renderItem = (item) => (
    <div className='listaItem'  >
     
        <div style={{  flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' }}>
          <div>
            <label style={{ fontWeight: 'bold' }} >  {item.cognome}   {item.nome}</label><br />
            <label style={{ color: "gray" }}>{new moment(item.datanascita).format('DD/MM/YYYY')}</label>
          </div>
          <div style={{ flexDirection: 'row', alignSelf: 'flex-end', alignContent: 'flex-end', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {props.selGioc(item) ; props.chiudi() }}   >
              <Icon path={mdiAccount} size={0.8} />
            </Button>
          </div>
        </div>
      

    </div>
  )

  useEffect(
    React.useCallback(() => {
      return () => {
        if (edtTimer.current) clearTimeout(edtTimer.current);
      }
    }, [])
  );
  return (
    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
    }}>

      <div style={{ flexDirection: 'row' }}>
        <InputText placeholder='cognome' className="longInput"
          onChange={(vcognome) => {
            setRiccog(vcognome.target.value); datiGen.getInstance().setRicercaCog(vcognome.target.value);
            if (edtTimer.current) clearTimeout(edtTimer.current); edtTimer.current = setTimeout(() => { getPersone(vcognome.target.value); }, 500);
          }}
          onBlur={() => { if (edtTimer.current) clearTimeout(edtTimer.current); getPersone(); }}
          value={riccog}
        ></InputText>
        <InputText placeholder='nome' className="longInput"
          onChange={(vnome) => {
            setRicnom(vnome.target.value); datiGen.getInstance().setRicercaNom(vnome.target.value);
            if (edtTimer.current) clearTimeout(edtTimer.current); edtTimer.current = setTimeout(() => { getPersone(riccog, vnome.target.value); }, 500);
          }}
          value={ricnom}
          onBlur={() => { if (edtTimer.current) clearTimeout(edtTimer.current); getPersone(); }}
        ></InputText>
        <InputText placeholder='anno' className="smallInput"
          onChange={(vanno) => {
            setRicanno(vanno.target.value); datiGen.getInstance().setRicercaAnno(vanno.target.value);
            if (edtTimer.current) clearTimeout(edtTimer.current); edtTimer.current = setTimeout(() => { getPersone(riccog, ricnom, vanno.target.value); }, 500);
          }}
          onBlur={() => { if (edtTimer.current) clearTimeout(edtTimer.current); getPersone(); }}
          value={ricanno}
        ></InputText>
      </div>


      <div style={{   width: '100%',	marginTop:"1em" }} >
        <DataView loader buffer={0.4} rows={100}
          header=
          {<div className="listazioni">
            <Button
              onClick={() => getPersone()}
            >
              <Icon path={mdiSync} size={0.8} />
            </Button>
          </div>}
          value={data}
          itemTemplate={renderItem}
           />



      </div>
    </div>
  );
}
export default RicercaPersone;