import doc from "./Doc.js";
import tesseramento from "./Tesseramento.js";
import Recapito from "./Recapiti.js";
import visita from "./Visita.js";
import moment from 'moment'

const OrdinePrototype = {
  to_obj() {
    if (this. dataOrdine) {
      this.dataOrdine = new Date(this.dataOrdine);
    }
  },
  copiaOrdine(tipo) {

    //STATO:
    // 00 - Non iscritto
    // 10 - Iscritto
  
    this._id = tipo._id;
    this.idsoc = tipo.idsoc;
    this.codice = tipo.codice;
    this.categoria = tipo.categoria;
    this.stato = tipo.stato;
    this.descr = tipo.descr;
    this.totale = tipo.totale;
    
    this.dataOrdine = tipo.DataOrdine;
    this.dataIns = tipo.dataIns;
    this.dataUltmod = tipo.dataUltmod;
  
    this.note = tipo.note;
    this.idPersona = tipo.idPersona;
    
    this.cognomeNomePersona = tipo.cognomeNomePersona;
    this.opeIns = tipo.opeIns;
    this.opeUltmod = tipo.opeUltmod;
    this.righe = tipo.righe;
    this.modpag = tipo.modpag;
    this.ninvio = tipo.ninvio;
  
    this.__proto__ = Ordine.prototype;
  }
}



function Ordine() {

  //STATO:
  // 00 - Non iscritto
  // 10 - Iscritto

  this._id = "";
  this.idsoc = "";
  this.codice = "";
  this.categoria = "";
  this.stato = "00";
  this.descr = "";
  this.totale = "0";
  
  this.dataOrdine = new Date();
  this.dataIns = new Date();
  this.dataUltmod = new Date();

  this.note = "";
  this.idPersona = "";
  
  this.cognomeNomePersona = "";
  this.opeIns = "";
  this.opeUltmod = "";
  
  this.righe = [];
  this.modpag="";
  this.ninvio="";

  this.__proto__ = Ordine.prototype;
}

Ordine.prototype = OrdinePrototype;
Ordine.prototype.constructor = Ordine;






export default Ordine;