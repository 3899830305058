
import { Image } from 'primereact/image';
import logo from '../img/logo-vigor3.png';
import datiGen from '../util/datiGen';
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import packageJson from '../../package.json';
import { Button } from 'primereact/button';
import Icon from '@mdi/react';
import {
mdiRunFast,mdiAccountGroup

} from '@mdi/js';

const Config = () => {
  const navigate = useNavigate();
  const abilConf =    datiGen.getInstance().possoaccederev2('CONFIGURAZIONI');
const abilTest =    datiGen.getInstance().possoaccederev2('TESTCONF');
const abilIT= datiGen.getInstance().possoaccederev2('PROFILIUTENTE');


const appVersion = JSON.stringify(packageJson.version);


return (

  <div className='divHome'> 

       <Image src={logo} alt="Image" width="250" className='App-logo' />  
   <label className='Titolo' >Vigor APP</label> 
   


   <label className='Titolo2'>CONFIGURAZIONI APP   </label>
   <div className='Labelcontainervertical'>
   <label>Versione: {appVersion}  </label>
    
   <label> Altezzza: {window.screen.height} </label>
    
   <label>Larghezza: {window.screen.width}</label>
    
   <label> Aspect ratio: {  (window.screen.width/window.screen.height).toFixed(2)  }  </label>
   </div>
  <div > 
  {abilIT &&
    <Button  onClick={() => { navigate("/ProfiliUtenti");}} >
           
            <Icon path={mdiAccountGroup} size={1} />
                <span className='bottoneHome-label'>Profili utente</span>
                   
          </Button>
  }       
  {abilTest &&
          <Button onClick={() => { navigate("/TestConfPage");}} >
            <Icon path={mdiRunFast} size={1} />
                <span className='bottoneHome-label'>Test</span>
          </Button>
  
        
  }
  </div> 

</div>
 

);





}
  



export default Config;
