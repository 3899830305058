import moment from 'moment'

const visitaPrototype = {
    
  to_obj() {
 
    if (this.datavisita && typeof this.datavisita == 'string') {
      this.datavisita = new Date(this.datavisita);
    }
    if (!this.durata)
    this.durata=365;
  },

  getScadenza()
  {
  return new moment(this.datavisita).add(this.durata,'day');
  },
  isScaduta()
  {
    
  return this.getScadenza().isBefore( moment());
  },
  isInscadenza()
  {
    
  return this.getScadenza().isAfter(moment())  &&  this.getScadenza().isBefore(moment().add(60,'day'))   ;
  },

  copiaVisita(vis) {
    this.id=vis.id;
    this.tipo=vis.tipo;  //idoneità
    this.datavisita=vis.datavisita;
    this.esito=vis.esito;
    this.referto=vis.referto;
    this.note=vis.note;
    this.durata=vis.durata;
    this.__proto__ = visita.prototype;
  }
  }
  
  function visita() {
    this.id=Date.now();
    this.tipo = 'idoneità';   //idoneità
    this.datavisita=new Date();
    this.esito="OK";
    this.referto=""
    this.note="";
    this.durata=365;

    this.__proto__ = visita.prototype;
  }
  
  visita.prototype = visitaPrototype;
  visita.prototype.constructor = visita;
  
 




export default visita;