// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';

import datiGen from '../util/datiGen.js';
import TestGioc from '../comp/TestGioc.js';
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";


const ReportisticaTestGioc = (props) => {
  const navigation = useNavigationType();
  const location = useLocation();
  const [tipo, setTipo] = useState(() => location.state.tipo);
  const [gioc, setGioc] = useState(() => location.state.idgioc);
  const [person, setPerson] = useState(() => location.state.selpersona);
  const abilRepTest = datiGen.getInstance().possoaccederev2('REPTEST');


  useEffect(() => 
  { 
    setPerson( location.state.selpersona);
  setGioc( location.state.idgioc); 
  
});

  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'

      }}>
{abilRepTest &&
<TestGioc idgioc={gioc} person={person} style={{flex:10 }} />
}
    </div>
  );
} 
export default  ReportisticaTestGioc   ;