// screens/Home.js

import React, { Component, useEffect, useState } from 'react';

import { useNavigate, useLocation } from "react-router-dom";
import { Button } from 'primereact/button';
import datiGen from '../util/datiGen';
import ListaAttivita from '../comp/ListaAttivita';
import '../stili/App2.css';
import GiocTeam from '../comp/TeamGioc';
import StaffTeam from '../comp/TeamStaff';
import TeamGiocDett from '../comp/TeamGiocDett';
import TeamStaffDett from '../comp/TeamStaffDett';
import InfoTeam from '../comp/TeamInfo';
const TeamPage = () => {
  const navigation = useNavigate();
  const location=useLocation();
  const [grp, setGrp] = useState(() => location.state.gruppo);
  const [gioc, setGioc] = useState() ;

  const abilAtt = datiGen.getInstance().possoaccederev2('ATTIVITA', grp);
  const abilListagioc = datiGen.getInstance().possoaccederev2('ListaGioc', grp);
  

const [paginasel,setPaginasel]=useState('Info');
 
  useEffect(() => {
     setGrp(location.state.gruppo);
     if (location.state.pagina)
  setPaginasel(location.state.pagina)
else setPaginasel('Info')
  }, []);


  return (
<div>
      <div className="listazioni">
      
      <span className='Myteambmenu' onClick={() => { setPaginasel('Info');  }}>Info</span>

      <span className='Myteambmenu' onClick={() => { setPaginasel('Gioc');  }}>Gioc</span>
      
      <span className='Myteambmenu' onClick={() => { setPaginasel('Staff');  }}>Staff</span>
      
      <span className='Myteambmenu' onClick={() => { setPaginasel('Attivita');  }}>Attività</span>
      {4<1 &&
      <span className='Myteambmenu' onClick={() => { setPaginasel('Agenda');  }}>Agenda</span>
      }

    
</div>

<div>

{ (paginasel=='Info') &&
<InfoTeam  gruppo={grp} />
}
{ (paginasel=='Gioc') && abilListagioc &&
<GiocTeam gruppo={grp} selpagina={setPaginasel} selgiocatore={setGioc}/>


}
{ (paginasel=='Staff') && abilListagioc &&
<StaffTeam gruppo={grp} selpagina={setPaginasel} selgiocatore={setGioc}/>
}
{ (paginasel=='Attivita') &&
<ListaAttivita  gruppo={grp}  />
}
{ (paginasel=='Agenda') &&
//<TeamAgenda  gruppo={grp} />
<label>agenda</label>

}
{ (paginasel=='TeamGiocDett') &&
<TeamGiocDett  gruppo={grp}  gioc={gioc}  selpagina={setPaginasel} />

}
{ (paginasel=='TeamStaffDett') &&
<TeamStaffDett  gruppo={grp}  gioc={gioc}  selpagina={setPaginasel} />

}

</div>
     

      </div>
    
  );
}
export default TeamPage;