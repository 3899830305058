// screens/Home.js
import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import datiGen from '../util/datiGen.js';
import { DataView } from 'primereact/dataview';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import { Button } from 'primereact/button';
  import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
  import {
mdiExpandAll,
 
  } from '@mdi/js';
import restAttivita from '../restcall/restAttivita';




const Test_ElGioc = (props) => {
  const navigation = useNavigate();
  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD', props.gruppo);
  const [selGruppo, setSelGruppo] = useState(() => props.gruppo);
  const [selAttivita, setSelAttivita] = useState(() => props.attivita);
  const [listaGiocatori, setListaGiocatori] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [selGioc, setselGioc] = useState(()=>props.pGioc);

  const NavDettPersone = () => {

    return (
      <label style={{ color:'white',textAlignVertical:'center',fontSize:datiGen.getInstance().scala(22)}}>Giocatori</label>
    );
  }


  const renderItem = ( item, index ) => (
    <div className="listaItem">
        {item &&
        <label  style={{color:props.pGioc && item._id==props.pGioc._id? 'blue' :'black',padding:3}} 
        onClick={()=>{props.setGioc(item);}}
        >{item.nominativo}
        </label>}
      </div>
  )

  const getListaGioc = async () => {
    restAttivita.getInstance().getAttivita(selAttivita._id, selGruppo).then((json) => {
      listaGiocatori.length = 0;
      json.gioc.forEach((ele) => {
        if (ele.Giocatore[0] && ele.Giocatore[0] != 'undefined')
        listaGiocatori.push({ _id: ele.Giocatore[0]._id, nominativo: ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome });
      });
    })
  };


  useEffect(
    React.useCallback(() => {
      getListaGioc();
    }, [selAttivita])
  );
 

  useEffect(
    React.useCallback(() => {   
      
      setselGioc(props.pGioc);
    }, [props.pGioc])
  );




  return (

    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    }}>
<DataView 
header= {<div className='listazioni'>
     {NavDettPersone()}
</div>}
value={listaGiocatori}
itemTemplate={renderItem}

/>






      </div>
  );
}
export default Test_ElGioc;