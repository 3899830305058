// screens/Home.js
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState,useEffect ,useRef} from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from 'primereact/dialog';
import { CountryService } from '../util/CountryService.jsx';
import Icon from '@mdi/react';
import { mdiContentSave, mdiPlus } from '@mdi/js';
import { useOutletContext } from "react-router-dom";
import { mdiPrinter } from '@mdi/js';
import { mdiSoccerField } from '@mdi/js';
import { mdiAccountMultiple } from '@mdi/js';
import { mdiAccountMultipleCheck } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import datiGen from '../util/datiGen.js'
import { mdiPhone } from '@mdi/js';
import { mdiCity } from '@mdi/js';
import { mdiEmail } from '@mdi/js';
import { mdiAccount } from '@mdi/js';
import  Recapito  from '../obj/Recapiti.js';
import  Person  from '../obj/person.js' 
import restPersona from "../restcall/restPersona.js";
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import Visite from "./Visite.js";
import { DataView } from "primereact/dataview";
import Recapiti from '../comp/Recapiti.js'
import visita from "../obj/Visita.js";
const Listarecapiti = (props) => {
  const navigate = useNavigate();
  const abilAttUPD = datiGen.getInstance().possoaccederev2('ATTIVITAUPD', props.gruppo);
  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD', props.gruppo);
  const abilRecUPD = datiGen.getInstance().possoaccederev2('ANAGRECAPITI', props.gruppo);
  const abilPagUPD = datiGen.getInstance().possoaccederev2('ANAGPAG', props.gruppo);
  const abilIscrUPD = datiGen.getInstance().possoaccederev2('ISCRIZIONI', props.gruppo);
  const abilOrdUPD = datiGen.getInstance().possoaccederev2('ORDINI', props.gruppo);

const [person,setPerson] = useState();
const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();
const [daSalvare, setDaSalvare] = useState(false);
useEffect(() => {
  if (props.selPersona){
    let tmp_person = props.selPersona;
    tmp_person.__proto__ = Person.prototype;
    tmp_person.to_obj(); 
    setPerson(tmp_person);
    console.log(person, props)
  }
}, []);
useEffect(() => {
}, [person]);
const toast = useRef(null);
const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Success', detail:'Message Content', life: 3000});
}
const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
}
const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
}
const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
}
const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
};
const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
};
const salva = async (ppers) => {
  console.log(ppers, person);
  try {
    var json = await restPersona.getInstance().modifica(ppers,props.gruppo);
    setDaSalvare(false);
    showSuccess("Salvataggio eseguito", "La modifica è stata salvata");
    let x = statoDaSalvare.replaceAll("1", "0"); 
    setStatoDaSalvare(x);
  } catch (error) {
    console.error(error);
    showError();
  } finally {
  }
}
const salvaAgg = () => {
  setDaSalvare(true);
}
const aggiungimail = async () => {
  let z = { ...person };
  let t = new Recapito();
  t.tipo = 'MAIL';
  z.recapiti.push(t);
  setPerson(z);
  salvaAgg();
}
const aggiungitel = async () => {
  let z = { ...person };
  let t = new Recapito();
  t.tipo = 'TEL';
  if (!z.recapiti) z.recapiti = [];
  z.recapiti.push(t);
  setPerson(z);
  salvaAgg();
}
const aggiungiind = async () => {
  let z = { ...person };
  let t = new Recapito();
  t.tipo = 'IND';
  z.recapiti.push(t);
  setPerson(z);
  salvaAgg();
}
const updRecapito = (tipo) => {
  let p = {...person};
  let x = p.recapiti.find((el)=>el.id==tipo.id);
  let y = datiGen.getInstance().replaceCharAt(statoDaSalvare, 1, '1'); 
  setStatoDaSalvare(y);
  if(x){
    x.copiaRecapito(tipo);
    setPerson(p);
  }else{
    console.log('else');
  }
}
const renderItem = (item, index) => (
  <div className='listaItem'>
    <Recapiti selRecapito={item} updRecapito={updRecapito}>

    </Recapiti>
  </div>
)
useEffect(() => {
  console.log('modifico 1');
  if(daSalvare){
   let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 1, '1'); 
   setStatoDaSalvare(x);
  }else if(!daSalvare){
   let x = statoDaSalvare.replaceAll("1", "0"); 
   setStatoDaSalvare(x);
  }
 }, [daSalvare]);
  return (
    <div>
      {person &&
        <>
          <Toast ref={toast} />
          <div style={{backgroundColor: '#0a568d', border: '1px solid #e5e7eb', padding: '0.2rem 0.2rem', fontWeight: '700', marginBottom: '1em'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#0a568d'}}>
              {abilAnagUPD &&
                <>
                  <button className="p-button p-component">
                    <Icon path={mdiPhone} size={1} onClick={() => {aggiungitel()}}/>
                  </button>
                  <button className="p-button p-component">
                    <Icon path={mdiCity}n size={1} onClick={() => {aggiungiind()}}/>
                  </button>
                  <button className="p-button p-component">
                    <Icon path={mdiEmail} size={1} onClick={() => {aggiungimail()}}/>
                  </button>
                  <button className="p-button p-component" onClick={() => {salva(person)}}>
                      <Icon path={mdiContentSave} size={1.2} />
                  </button>
                </>
              }
              {statoDaSalvare.includes(1) == 0 &&
              <button className="p-button p-component" onClick={() => props.updateAzione(0)}>
                <Icon path={mdiAccount} size={1}/>
              </button>
             }
            </div>
            <div>
              <DataView value={person.recapiti} itemTemplate={renderItem} rows={20}>

              </DataView>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Listarecapiti;