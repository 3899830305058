import Person from "./person.js";
import gruppo from "./gruppo.js";
import moment from 'moment'

const GiocGruppoSQPrototype = {

  to_obj() {

    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
  },

  isAttivo() {
    const adesso = new moment();
    let out = false;
    if (moment(this.ival).isSameOrBefore(adesso) && moment(this.fineval).isSameOrAfter(adesso))
     {
        out = true; 
      }

    return out;
  }
}
  
  function giocGruppoSQ() {
this.idsoc = "";
this.idgioc = "";
this.objidgioc = "";
this.ival = new Date();
this.fineval = moment().add(7, 'years').toDate();
this.ordine = 0;
this.ruolo = '';

this.tipo='GIOC'  //GIOC - STAFF
this.note = "";
this.Giocatore = "";
this.gruppoSQ = "";
this.idgruppoSQ = "";
this.objgrupposq = "";
this.__proto__ = giocGruppoSQ.prototype;
this.Giocatore.__proto__ = Person.prototype;
this.gruppoSQ.__proto__ = gruppo.prototype;
  }

giocGruppoSQ.prototype = GiocGruppoSQPrototype;
giocGruppoSQ.prototype.constructor = giocGruppoSQ;






export default giocGruppoSQ;