
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import ListaAttivita from '../comp/ListaAttivita';



const AttivitaPage = () => {

  const navigate = useNavigate();
  
  

/*
  useEffect(() => {


  }, []);
*/

  return (
    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}>
  <ListaAttivita />
</div>

  );
}



export default AttivitaPage;
