import datiGen from "../util/datiGen";
import { getAuth } from "firebase/auth";

import datiInit from "../util/datiInit";



export default class restSchedaGioc {
  constructor(restSchedaGioc) {
   // console.log('restSchedaGioc');
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();
  restApp = 'schedegioc';
  static myInstance = null;

  static getInstance() {
    if (restSchedaGioc.myInstance == null) {
      restSchedaGioc.myInstance = new restSchedaGioc();
      // Util.myInstance.inizializza();
     // console.log('fine get instance');
    }
    return restSchedaGioc.myInstance;
  }

  prova() {
    console.log('restSchedaGioc','prova');
  }

  getSchedeGioc = async (pidgioc) => {
    if (!datiGen.getInstance().possoaccederev2('SCHGIOC'))
    throw 'Non sei abilitato a svolgere la funzione SCHGIOC';
    
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "?" + "idgioc=" + pidgioc
      , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
      });
      const json = await response.json();
      //console.log("restSchedaGioc","getSchedeGioc",json);
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      
    }
  }


  aggiungi = async (pscheda) => {
    if (!datiGen.getInstance().possoaccederev2('SCHGIOCUPD'))
    throw 'Non sei abilitato a svolgere la funzione SCHGIOCUPD';

    var vscheda={...pscheda};
    delete vscheda.Giocatore;
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp+ '/' + datiGen.getInstance().getActiveSoc().sigla
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(vscheda)
        });
      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      // console.log("finito aggiungi");
    }
  }

  modifica = async (pscheda) => {
    if (!datiGen.getInstance().possoaccederev2('SCHGIOCUPD'))
    throw 'Non sei abilitato a svolgere la funzione SCHGIOCUPD';
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var p = { ...pscheda };
      delete p._id;
      delete p.Giocatore;
      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ '/' + pscheda._id;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }


}