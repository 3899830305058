
import moment from 'moment'
const test_tipoRisutatiPrototype = {

  to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
   
  },
}
  


function test_tipoRisutati() {
this.codice = "";
this.des="";
this.ival = new Date();
this.fineval = moment().add(20, 'years').toDate();
this.capacita = []; 
this.__proto__ = test_tipoRisutati.prototype;
}

test_tipoRisutati.prototype = test_tipoRisutatiPrototype;
test_tipoRisutati.prototype.constructor = test_tipoRisutati;

export default test_tipoRisutati;