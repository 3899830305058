// screens/Home.js

import React, { useState, useEffect,useRef } from 'react';

import { useNavigate, useLocation } from "react-router-dom";
import datiGen from '../util/datiGen';
import { Button } from 'primereact/button';

import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';

import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer,mdiContentSaveAll,mdiCloseBox,
  mdiCheckAll,mdiTagEdit,mdiClose,mdiMenuOpen,
  mdiAccount,
  mdiClipboard,mdiAccountBadge,
  mdiClipboardPlus,
  mdiPencil,
  mdiDatabase,
  mdiCalendarMultipleCheck,
  mdiAccountMultiple,
  mdiCertificate

} from '@mdi/js';



const Live = (props) => {
  const navigate = useNavigate();
const location=useLocation();

  const [visibleLoader, setVisibleLoader] = useState(false);
  const [dettGruppo, setdettGruppo] = useState();
  const [datatot, setDatatot] = useState(([]));
  const [mostraMenu, setMostraMenu] = useState(false);

  const toast = useRef(null);
  const showSuccess = (titolo, testo) => {
    toast.current.show({severity:'success', summary: titolo, detail: testo, life: 3000});
  }
  const showInfo = (testo) => {
    toast.current.show({severity:'info', summary: 'Info', detail:testo, life: 3000});
  }
  const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
  }
  const showError = (testo) => {
    toast.current.show({severity:'error', summary: 'Error', detail:testo, life: 3000});
  }

  useEffect(() => {

}, []);


const getListaAttivita = async () => {

  try {
    setLoading(true);
console.log('GETLISTAATTIVITA' ,props.tipo);

    let d1 = props.tipo == 'giorno' ? moment().subtract(10, 'hours').toDate() : date_filtroI;
    let d2 = props.tipo == 'giorno' ? moment().add(1, 'hours').toDate() : date_filtroF;
    let tf = props.tipo == 'giorno' ? '' : tipoFiltro;
    let gf = props.tipo == 'giorno' ? props.gruppo : gruppoFiltro;
    let tgf = props.tipo == 'giorno' ? '--' : tipogrp_Age;
    let ifiltro = props.tipo == 'giorno' ? '' : indFiltro;
    let tcfiltro = props.tipo == 'giorno' ? '' :tipoPartitaFiltro;


    if (!elabincorso) {
      elabincorso = true;
      setTimeout(() => {
        var json = restAttivita.getInstance().getAllAttivita(d1, d2, tf, gf, ifiltro, ordinamentoAtt, tgf,tcfiltro).then((json) => {
          setData(json);
          elabincorso = false;
        }).catch((err) => {
          console.error('getListaAttivita', err);
          elabincorso = false;
        })
      }, 200);

    }
    //  console.log('getListaAttivita', json);

  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};



  return (
    <div style={{
      flex: 10,
      alignItems: 'center',
      width: '100%',
      flexShrink: 1,
      flexGrow: 100,
    }}>

<Toast ref={toast} />

ciao
 

         </div>
    
  );
}
export default Live;