import moment from 'moment'


const test_esePrototype = {

  to_obj() {
    if (this.datainitest && typeof this.datainitest == 'string') {
      this.datainitest = new Date(this.datainitest);
    }
  if (this.datafinetest && typeof this.datafinetest == 'string') {
    this.datafinetest = new Date(this.datafinetest);
  }

},
}



function test_ese() {
 this.idsoc = "";

this.idtestese="";
 this.idtest_tipo = ""; 
 this.idgioc="";
 this.datainitest = new Date();
 this.datafinetest = new Date();
 this.grezzi=[];                  /// array con descrizione valori (lettera tempo risposta) nel caso semplice
 this.risultati=[];               /// array con codice tiporis 
  // this.operatoretest=[];       compilato solo se esiste
 this.valido=true;

this.para={automatico:false};         //automatico significa con sensori affifdabili

   this.__proto__ = test_ese.prototype;
}
test_ese.prototype = test_esePrototype;
test_ese.prototype.constructor = test_ese;


export default test_ese;