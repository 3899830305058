import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";
import datiGen from "../util/datiGen";
import Attivita from '../obj/Attivita.js';



export default class restAttivita {
  constructor(restGruppi) {


  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'attivita';
  static myInstance = null;

  static getInstance() {
    if (restAttivita.myInstance == null) {
      restAttivita.myInstance = new restAttivita();
      // Util.myInstance.inizializza();

    }
    return restAttivita.myInstance;
  }



  getAllAttivita = async (pd1, pd2, ptipo, psq, pind,pord,ptipogrpage,ptipopartitafiltro) => {
const d1=new Date();

    console.log('getAllAttivita','1-tipoFiltro:',ptipo,'psq',psq,'ptipopartia:',ptipopartitafiltro);

    var ordine='D'
    var tipogrpage='--';
    /*var query =
    {
      d1:moment(pd1).format('YYYY-MM-DD'),
      d2:moment(pd2).add(1,'days').format('YYYY-MM-DD'),
      tipo:ptipo 
    }*/

    var json = { esito: "KO" };
    try {
      
      const user = getAuth().currentUser;
      
      var token = await user.getIdToken();
       


if (pord)
ordine=pord;




if (ptipogrpage)
tipogrpage=ptipogrpage;

      const response = await fetch(encodeURI(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "?d1=" + moment(pd1).format('YYYY-MM-DD') + "&d2=" + moment(pd2).add(1, 'days').format('YYYY-MM-DD') + "&tipo=" + encodeURIComponent(ptipo) + '&gruppo=' + psq + '&indirizzo=' + encodeURIComponent(pind) + '&ordine=' + encodeURIComponent(pord)+ '&tipogrp=' + encodeURIComponent(tipogrpage)+'&TipoPartita=' + encodeURIComponent(ptipopartitafiltro)), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      
      var json = await response.text();

       



      if (json) {
        json = JSON.parse(json);
        json.esito = 'OK';
      }
      else json = { esito: "KO2" };
       
      /*if (json[0].gioc)
         console.log('zzzzzzzzzzz',json[0].gioc);*/
    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';

      
      
      return json;

    }

  }
  /// get totali attivita
  getTotaliAttivitaGruppo = async (psq) => {

    // console.log('getTotaliAttivitaGruppo','inizio rest:','psq',psq);
    const user = getAuth().currentUser;
    var token = await user.getIdToken();
    /*var query =
    {
      d1:moment(pd1).format('YYYY-MM-DD'),
      d2:moment(pd2).add(1,'days').format('YYYY-MM-DD'),
      tipo:ptipo 
    }*/
    var data = new Date();
    var panno = data.getFullYear();
    var pmese = data.getMonth() + 1;

    var pd1 = new moment().add(-24, 'month').toDate().toISOString();
    var pd2 = new moment().add(+1, 'month').toDate().toISOString();
    if (pmese > 6) {
      pd1 = new moment(panno + '-07-01').toDate().toISOString();
      pd2 = new moment().toDate().toISOString();
    }
    else {
      pd1 = new moment((panno) + '-07-01').add(-1, 'Years').toDate().toISOString();
      pd2 = new moment().toDate().toISOString();
    }



    var json = { esito: "KO" };
    try {

      // console.log('getTotaliAttivitaGruppo','prima di chaimata:','psq',psq,pd1,pd2);


      const response = await fetch(encodeURI(this.restUrlServer + '/stats/TotaliAttGrpStats/' + datiGen.getInstance().getActiveSoc().sigla + '/' + psq + "?d1=" + moment(pd1).format('YYYY-MM-DD') + "&d2=" + moment(pd2).add(1, 'days').format('YYYY-MM-DD')), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      var json = await response.text();
      if (json) {
        json = JSON.parse(json);
        json.esito = 'OK';
      }
      else json = { esito: "KO2" };

      /*if (json[0].gioc)
         console.log('zzzzzzzzzzz',json[0].gioc);*/
    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';
      return json;

    }

  }




  getForPresenceStats = async (pd1, pd2, ptipo, psq, pind,ptipopartita) => {

    console.log('getAllAttivita','1-tipoFiltro:',ptipo,'psq',psq,ptipopartita);
    const user = getAuth().currentUser;
    var token = await user.getIdToken();

    var json = { esito: "KO" };
    try {

      const user = getAuth().currentUser;
      var token = await user.getIdToken();



      const response = await fetch(this.restUrlServer + '/stats/getForPresenceStats/' + datiGen.getInstance().getActiveSoc().sigla + "/" + psq + "?d1=" + moment(pd1).format('YYYY-MM-DD') + "&d2=" + moment(pd2).add(1, 'days').format('YYYY-MM-DD') + "&tipo=" + encodeURIComponent(ptipo) + '&gruppo=' + psq + '&indirizzo=' + encodeURIComponent(pind)+ '&TipoPartita=' + encodeURIComponent(ptipopartita), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      var json = await response.text();
      //console.log('getxxxForPresenceStats',json);




      if (json) {
        json = JSON.parse(json);
        json.esito = 'OK';
      }
      else json = { esito: "KO2" };


    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';



      return json;

    }

  }
  aggiungi = async (patt, pgrp) => {
    var vatt = { ...patt };
    var json = { esito: "KO" };
    var qgrp = '';
    if (pgrp)
      qgrp = '/' + pgrp;




    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + qgrp
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(vatt)
        });
      json = await response.json();

      if (response.status >= 300) {
        //  console.log("restGruppi","aggiungi","--",response.status,json);
        json.esito = 'KO'
        throw 'Errore ' + JSON.stringify(json);
      }
      else
        json.esito = 'OK'

    } catch (error) {
      console.error(error);
      json.esito = 'KO'
    } finally {
      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';
      return JSON.stringify(json);
    }
  }


  duplica = async (patt, pgrp) => {
    var vatt = { ...patt };
    var json = { esito: "KO" };
    var qgrp = '';
    if (pgrp)
      qgrp = '?gruppo=' + pgrp;




    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' +vatt._id+ '/duplica'   + qgrp
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(vatt)
        });
      json = await response.json();

      if (response.status >= 300) {
        //  console.log("restGruppi","aggiungi","--",response.status,json);
        json.esito = 'KO'
        throw 'Errore ' + JSON.stringify(json);
      }
      else
        json.esito = 'OK'

    } catch (error) {
      console.error(error);
      json.esito = 'KO'
    } finally {
      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';
      return JSON.stringify(json);
    }
  }

  modifica = async (patt, pgrp,danotificare) => {
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      var p = { ...patt };
      delete p._id;
      var qgrp = '';
      var notif='';
      if (pgrp)
        qgrp = '/' + pgrp;

        if (danotificare && danotificare==true)
        {
            
            notif='?notificare=S'
        }
        else
        {
          notif='?notificare=N'
        }


      //console.log('restatt','modifica',pgrp,qgrp);

      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + patt._id + qgrp+notif;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }

  }

  getAttivita = async (aid, pgrp) => {
    const user = getAuth().currentUser;
    var token = await user.getIdToken();

    var qgrp = '';

    if (pgrp)
      qgrp = '/' + pgrp;
    //console.log(aid);

    //  if (!datiGen.getInstance().possoaccederev2('ATTIVITA',aid))
    //   throw 'Non sei abilitato a svolgere la funzione ATTIVITA';

    try {

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/" + aid + qgrp, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      const json = await response.json();
      if (response.status >= 300) {
        // console.log("restAttivita", "getAttivita", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      //   console.log("finito getPersone");
    }
  }

  creaPresAtt = async (patt, pgrpuppo) => {
    // console.log("creaPresAtt",patt);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();


      var p = { ...patt };

      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + patt._id + '/' + pgrpuppo + "/creapres";

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();

      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }

  }

  creaPresGioc = async (patt, pgrpuppo, pidgioc) => {

    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();


      var p = { ...patt };

      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + patt._id + '/' + pgrpuppo + "/creapresgioc/" + pidgioc;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();

      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }

  }

  creaPresStaff = async (patt, pgrpuppo, pidgioc) => {

    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();


      var p = { ...patt };

      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + patt._id + '/' + pgrpuppo + "/creapresstaff/" + pidgioc;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });



//console.log("creapresstaff",response);

      const json = await response.json();

      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }

  }


  elimina = async (patt, pgrp) => {
     
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

    
      var qgrp = '';

      if (pgrp)
        qgrp = '/' + pgrp;

        patt.__proto__ = Attivita.prototype;
      
      
      const s = encodeURI( this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + patt._id + qgrp+"?des="+encodeURIComponent( patt.getDescrizione())
      +"&gruppi="+encodeURIComponent( patt.gruppi));
     
      
      const response = await fetch(s
        , {
          method: 'delete',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          
        });
        
      const json = await response.json();
      
      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }

  }

}