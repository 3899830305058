// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import SchedaOsservatore from '../comp/SchedaOsservatore';
import restPersona from '../restcall/restPersona';

const SchedaOssPage = (props) => {
  const navigation = useNavigate();
  const location=useLocation();
  const [selPerson, setSelperson] =  useState();
  

useEffect(() => {
restPersona.getInstance().getPersona(location.state.person_id).then((json)=>
    {
      setSelperson(json);
    })
}, [props.person_id]);





    return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'


      }}>

{selPerson &&
        
        <div style={{ flex: 1 ,margin:'0.5rem',justifyContent:'center',alignItems:'center',justifyItems:'center',width:'100%'}}>
        <label className='Titolo5' style={{ flex: 1,textAlign:'center',width:'100%'}} >{selPerson.cognome} {selPerson.nome}</label>
    
           </div>   
    
    
        }
        
         {selPerson &&
<SchedaOsservatore   key={props.person_id} selPersona={selPerson} person_id={props.person_id}   style={{flex:10,minHeight:'100%',width:'100%'}} tipo='Gioc' />
}
    </div>
  );
} 
export default SchedaOssPage;