import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import './stili/index.css';
import './stili/App01.css';
import App from './pagine/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createMemoryRouter,
  RouterProvider,
  redirect,
  Outlet,

} from "react-router-dom";
import './i18n';
import Persone from './pagine/Persone';
import GruppiPage from './pagine/GruppiPage';
import AttivitaPage from './pagine/AttivitaPage';
import DettPersone from './pagine/DettPersone';
import Config from './pagine/Config';
import ProfiliUtenti from './pagine/ProfiliUtenti';
import TestConfPage from './pagine/TestConfPage';
import ReportisticaTest from './pagine/ReportisticaTest';
import DettSchedaOss from './pagine/DettSchedaOss';
import SchedeScouting from './pagine/schedeScouting';
import SchedaOssPage from './pagine/schedaOssPage';
import DettAttivita from './pagine/DettAttivita';
import GiocAttPage from './pagine/GiocAttPage';
import TeamPage from './pagine/TeamPage';
import PresenzePage from './pagine/PresenzePage';
import SchedaGiocPage from './pagine/schedaGiocPage';
import DettSchedaGioc from './pagine/DettSchedaGioc';
import EsecTestPage from './pagine/EsecTestPage';
import ReportisticaTestGioc from './pagine/ReportisticaTestGioc';
import LivePage from './pagine/LivePage';
const HomeUtente  = React.lazy(() => import( './pagine/HomeUtente'));

// import i18n (needs to be bundled ;))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      
      {
        path: "/homeUtente",
        element: <Suspense fallback={<div>Loading...</div>}><HomeUtente /> </Suspense>,
      },
      {
        path: "/persone",
        element: <Suspense fallback={<div>Loading...</div>}><Persone /> </Suspense>,
      },
      {
        path: "/gruppi",
        element: <Suspense fallback={<div>Loading...</div>}><GruppiPage /> </Suspense>,
      },
      {
        path: "/attivita",
        element: <Suspense fallback={<div>Loading...</div>}><AttivitaPage /> </Suspense>,
      },
      {
        path: "/DettAttivita",
        element: <Suspense fallback={<div>Loading...</div>}><DettAttivita /> </Suspense>,
      },
      {
        path: "/DettPersone",
        element: <Suspense fallback={<div>Loading...</div>}><DettPersone /> </Suspense>,
      },
      {
        path: "/Config",
        element: <Suspense fallback={<div>Loading...</div>}><Config/> </Suspense>,
      },
      {
        path: "/ProfiliUtenti",
        element: <Suspense fallback={<div>Loading...</div>}><ProfiliUtenti/> </Suspense>,
      },
      {
        path: "/TestConfPage",
        element: <Suspense fallback={<div>Loading...</div>}><TestConfPage/> </Suspense>,
      },

      {
        path: "/DettSchedaOss",
        element: <Suspense fallback={<div>Loading...</div>}><DettSchedaOss/> </Suspense>,
      },
      {
        path: "/DettSchedaGioc",
        element: <Suspense fallback={<div>Loading...</div>}><DettSchedaGioc/> </Suspense>,
      },
      {
        path: "/schedeScouting",
        element: <Suspense fallback={<div>Loading...</div>}><SchedeScouting/> </Suspense>,
      },   
      {
        path: "/schedaOssPage",
        element: <Suspense fallback={<div>Loading...</div>}><SchedaOssPage/> </Suspense>,
      },
      {
        path: "/schedaGiocPage",
        element: <Suspense fallback={<div>Loading...</div>}><SchedaGiocPage/> </Suspense>,
      },
      {
        path: "/giocAttPage",
        element: <Suspense fallback={<div>Loading...</div>}><GiocAttPage/> </Suspense>,
      },
      {
        path: "/TeamPage",
        element: <Suspense fallback={<div>Loading...</div>}><TeamPage/> </Suspense>,
      },
      {
        path: "/PresenzePage",
        element: <Suspense fallback={<div>Loading...</div>}><PresenzePage/> </Suspense>,
      },
      {
        path: "/EsecTestPage",
        element: <Suspense fallback={<div>Loading...</div>}><EsecTestPage/> </Suspense>,
      },
      {
        path: "/ReportisticaTest",
        element: <Suspense fallback={<div>Loading...</div>}><ReportisticaTest/> </Suspense>,
      },
      {
        path: "/ReportisticaTestGioc",
        element: <Suspense fallback={<div>Loading...</div>}><ReportisticaTestGioc/> </Suspense>,
      },
      {
        path: "/LivePage",
        element: <Suspense fallback={<div>Loading...</div>}><LivePage/> </Suspense>,
      },
      
      ]
  }
]);
root.render(
  <React.StrictMode>
    
      <RouterProvider router={router} />
    
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


