import moment from 'moment/min/moment-with-locales';
import ProfiloUtente from "../obj/ProfiloUtente";
import restProfiloUtente from "../restcall/restProfiloUtente";
import datiInit from "../util/datiInit";
import restConf from '../restcall/restConf';
import jsPDF from "jspdf";
import logo from '../img/logobianco.png';
import scrittaVigor from '../img/ScrittaVigor.svg';
import scrittaVigor2 from '../img/ScrittaVigor.png';
import PartitaHome from '../img/PartitaHome.png';
import PartitaTrasferta from '../img/PartitaTrasferta.png';
import restGruppi from "../restcall/restGruppi";
import elenchi from "./elenchi";

export default class Stampe {

  constructor(Stampe) {
this.elsoc='';
    //utente
    //profiloutente
  }



  static myInstance = null;

  static getInstance() {
    if (Stampe.myInstance == null) {
      Stampe.myInstance = new Stampe();
      // Util.myInstance.inizializza();
      Stampe.myInstance.inizializza().then(() => console.log('Inizializing'));
    }



    return Stampe.myInstance;
  }


  inizializza() {
    return new Promise((resolve, reject) => {
      this.elsoc = new Map();
      this.elsoc.set('VM', { sigla: 'VM', des: 'A.S.D. Vigor Milano ' });
      this.elsoc.set('ALD', { sigla: 'ALD', des: 'Aldini' });
      this.elsoc.set('AF1', { sigla: 'AF1', des: 'Società Affiliata 1' });
      
         })
  }

 


 StampaHtlm = async (htmldoc,nomefile,caso) => {
  console.log(htmldoc,nomefile);
  if (!caso) caso=1;
var doc = new jsPDF();
let param1,param2,param3,param4
if (caso==1)
{
  param1=15;
  param2=15;
  param3=170;
  param4=650;
  }
else if (caso==2)
  {
    param1=0;
    param2=0;
    param3=210;
    param4=680;
    }
// Source HTMLElement or a string containing HTML.
doc.html(htmldoc, {
    callback: function(doc) {
        // Save the PDF
        doc.save(nomefile);
    },

    x: param1,
    y: param2,
    width: param3, //target width in the PDF document
    windowWidth: param4 //window width in CSS pixels
  });
}

 getDesGruppi = (parr,listaGruppiSQ) => {
  var outdes = "";
  //console.log('listaGruppiSQ',listaGruppiSQ);
  if (listaGruppiSQ) {  //serve diritto gruppi
    listaGruppiSQ.forEach(ele => {
      if (parr.includes(ele._id)) {
        outdes = outdes.concat(ele.des, ' ');
      }

    });
  }
  return outdes.trim();

}

//Programma
 reportConv2 = async (listaa,date_filtroI,date_filtroF,indFiltro,gruppoFiltro,listaGruppiSQ) => {
  
console.log("Programma",listaa);


  return new Promise((resolve, reject) => {
try {
    var outatt = "";
    var outatt2 = "";
    var texthtml;
    
  
  


    listaa.forEach((ele, pos) => {

      outatt2 = outatt2 + 
      '<div style="background-color:#A7C5E0;margin:0px;margin-bottom:15px;padding:5px;border-radius:10px;width:100%;display:flex;flex-direction:row">'+
      '<div style="width:30%; color:#FFF;font-size:22px;font-face:calibri;font-weight:900;padding:3px;text-transform:uppercase;	">';
      if (!gruppoFiltro)
      {
        outatt2 = outatt2 + (ele.gruppi ? this.getDesGruppi(ele.gruppi,listaGruppiSQ) :" " )+
      '<br/><div style="font-size:17px;color:#2E75B6;">'+moment(ele.dataAttivita).format('DD/MM/YYYY HH:mm')+'</div>';
      }
      else
      {
        outatt2 = outatt2 +  ele.tipo.split('#')[2] +
'<br/><div style="font-size:17px;color:#2E75B6;">'+moment(ele.dataAttivita).format('DD/MM/YYYY HH:mm')+'</div>';
      }
      outatt2 = outatt2 +       
      '</div>'+
      '<div style="width:70%;background-color:#DAE7F2;border-radius:10px;color:#2E75B6;font-face:calibri;padding:3px">';
      
      let primariga=0;
      
      if (ele.tipo &&  ele.tipo.split('#')[1] == 'P' )
      {
        primariga++;
        outatt2 = outatt2 +
       (ele.trasferta ?  ' <span style="color:#00B0F0;font-weight:700">' + (ele.avversario?ele.avversario:'??') +'</span> <span style="font-weight:700"> - Vigor Milano </span>':'<span style="font-weight:700"> Vigor Milano - </span><span style="color:#00B0F0;font-weight:700">' + (ele.avversario?ele.avversario:'??') +'</span> ')+
           ' <img src="'+  (ele.trasferta?PartitaTrasferta:PartitaHome)  + '" style="height:30px;float:right;width:auto;object-fit:contain;;margin-top:2px;margin-right:5px" />'+
           (ele.TipoPartita ? '<br/>'+(elenchi.getInstance().getElTipoPartita().find((elex) => elex.value == ele.TipoPartita).label):'');
           if (ele.campionato)
{
  let sq=listaGruppiSQ.find((elef) => elef._id == ele.gruppi[0]);
  if (sq)
  {
  
    let camp=sq.campionati.find((tcamp)=>tcamp.id==ele.campionato);

  
  outatt2 = outatt2  +(camp && camp.categoria?' '+camp.categoria:'');
  }
}
      }
      else
      {
        if (!gruppoFiltro)
        {
        outatt2 = outatt2 + (!gruppoFiltro?ele.tipo.split('#')[2] :'');
        primariga++;
        }
     }     
 
if (ele.indirizzo && ele.indirizzo.startsWith("CS Vigorello"))
{
  
  outatt2 = outatt2 +(primariga>0?'<br/>':'')+'<span style="font-size=16;color:#BBB">CS Vigorello';
  primariga++;
  if (ele.indirizzo.split('-')[1] && ele.indirizzo.split('-')[1].trim().startsWith("Campo"))
  {
    outatt2 = outatt2 +ele.indirizzo.split('-')[1];
  }

  outatt2 = outatt2 +'</span>';
}
  else if (ele.indirizzo)
  {
    outatt2 = outatt2 +(primariga>0?'<br/>':'')+'<span style="font-size=16;color:#BBB">'+ele.indirizzo+'</span>';
    primariga++;
  }

if (ele.note)
{
  outatt2 = outatt2 +(primariga>0?'<br/>':'')+ele.note;
  primariga++;
}


      outatt2 = outatt2 +'</div></div>' ;

     });
 

    texthtml = `<html>
    <head>
  <style type="text/css">
    h1 { color:#F1F1F1 },
    table,th, td {
      border: 0.5px solid #cccccc;
      border-collapse: collapse;
      padding-left:5px;
      padding-right:5px;
      font-size:13pt;
      text-align:center;
    },
      .div
      {
      
      }
 
  </style>
  </head>
    
    <body style='text-align:center;height:100%;'>
    <div style="height:100%;display:block; background-color:#2E75B6">
    <div style="width:100%;display:flex;flex-direction:row;align-items:center,padding-botton:20px">
  
  
    <img 
    src="`+
    scrittaVigor2
      + `" 
    style="height:110px;float:left;width:auto;object-fit:contain;margin-left:5px;margin-top:15px;margin-botton:35px" />

  
    </div>
    <div style="width:90%;background-color:white;margin:10px auto;border-radius:10px;">
  <div style="text-align:center;width:100%;color:#2E75B6;font-size:40px;font-face:calibri;  font-weight: 900;margin-botton:0px">PROGRAMMA ATTIVITÀ</div>
  <div style='color:#00B0F0;text-align:center;width:100%;font-size:20px;font-face:calibri;  font-weight: 900;margin-top:0'>dal `+ moment(date_filtroI).format('DD/MM/YYYY') + ` al ` + moment(date_filtroF).format('DD/MM/YYYY') + ` ` +
      (gruppoFiltro ? 'gruppo ' + this.getDesGruppi(gruppoFiltro,listaGruppiSQ) : ' ')
      + ` ` +
      (indFiltro ? 'Indirizzo/CS ' + indFiltro : ' ') + `
  </div>
</div>

<div style="width:98%;padding:5px;margin:5px auto;min-Height:695px"> 
`+
      outatt2
      + `
</div>
      
  <div style='background-color:transparent;width:100%;text-align:center;color:white;font-weight:600;font-face:calibri;font-size:14px;padding-bottom:15px;border-top:solid white 1px'>
    A.S.D. VIGOR MILANO - Via San Michele del Carso 59, Paderno Dugnano (MI)<br/>  tel: 392 985 88 51 - email:info@vigormilano.it
  </div>
</div>
    </body>
    <html>`;

    //console.log("Stampe","reportConv",texthtml);
    resolve(texthtml);

  } catch(err)
  {
   resolve( `<div>Errore<br/>` + err + ` </div>`);
  }


  })
  

}


//Programma Weekend
reportWeekend=  async (listaa,date_filtroI,date_filtroF,indFiltro,gruppoFiltro,listaGruppiSQ,tipogrp_Age) => {
  
  console.log("Programma",listaa);
  
  
    return new Promise((resolve, reject) => {
  try {
      var outatt = "";
      var outatt2 = "";
      var texthtml;
      var vgrp="";
    
      if (tipogrp_Age=='A') vgrp=" AGONISTICA"
      else if (tipogrp_Age=='A') vgrp=" PREAGONISTICA"
  
  
      listaa.forEach((ele, pos) => {
  
        outatt2 = outatt2 + 
        '<div style="background-color:#A7C5E0;margin:0px;margin-bottom:18px;padding:2px;border-radius:10px;width:100%;display:flex;flex-direction:row">'+
        '<div style="margin-left:5px;width:30%; color:#FFF; font-size:22px;font-face:Helvetica, sans-serif;font-weight:900;padding:0px;text-transform:uppercase;">';
          outatt2 = outatt2 + '<span style="color:##F8F8F8;margin:0px;padding:0px;">'+(ele.gruppi ? this.getDesGruppi(ele.gruppi,listaGruppiSQ) :'')+"</span>";
                       if (ele.campionato)
  {
    let sq=listaGruppiSQ.find((elef) => elef._id == ele.gruppi[0]);
    if (sq)
    {
    
      let camp=sq.campionati.find((tcamp)=>tcamp.id==ele.campionato);    
    outatt2 = outatt2  +'<span style="font-size:11px;color:#767171;display:block;padding:0;margin:0">'+(camp && camp.categoria?' '+camp.categoria.replaceAll(" ","&nbsp;"):'')+'</span>';
    }
  }
          //+ '<br/><div style="font-size:17px;color:#2E75B6;">'+moment(ele.dataAttivita).format('DD/MM/YYYY HH:mm')+'</div>';
         outatt2 = outatt2 +       
        '</div>'+
        '<div style="width:70%;background-color:#DAE7F2;border-radius:10px;color:#2E75B6;font-face:Helvetica, sans-serif;padding:3px;padding-left:8px">';
        
        let primariga=0;
        
        if (ele.tipo &&  ele.tipo.split('#')[1] == 'P' )
        {
          primariga++;
          outatt2 = outatt2 +
         (ele.trasferta ?  ' <span style="color:#00B0F0;font-weight:700;">' + (ele.avversario?ele.avversario.replaceAll(" ","&nbsp;"):'??') +'</span> <span style="font-weight:700"> &nbsp;&ndash; Vigor Milano </span>':'<span style="font-weight:700"> Vigor Milano &ndash; </span><span style="color:#00B0F0;font-weight:700">' + (ele.avversario?ele.avversario:'??') +'</span> ')+
             ' <img src="'+  (ele.trasferta?PartitaTrasferta:PartitaHome)  + '" style="height:30px;float:right;width:auto;object-fit:contain;;margin-top:2px;margin-right:5px" />'
            // +             (ele.TipoPartita ? '<br/>'+(elenchi.getInstance().getElTipoPartita().find((elex) => elex.value == ele.TipoPartita).label):'')
             ;
let str=moment(ele.dataAttivita).format('dddd D')+' ore '+moment(ele.dataAttivita).format('HH:mm');
  outatt2 = outatt2 +'<div style="color:#767171;font-size:11px">'+str.charAt(0).toUpperCase() + str.slice(1)+' - '+'';      
}
          
   
  if (ele.indirizzo && ele.indirizzo.startsWith("CS Vigorello"))
  {
    
    outatt2 = outatt2 +"CS&nbsp;&nbsp;Vigorello";
   // outatt2 = outatt2 +'<';
  }
    else if (ele.indirizzo)
    {
    let str2=ele.indirizzo.replaceAll(" ","&nbsp;");
      outatt2 = outatt2+str2;
      primariga++;
    }
    outatt2 = outatt2 +'</div>';

  
  
        outatt2 = outatt2 +'</div></div>' ;
  
       });
   
  
      texthtml = `<html>
      <head>
    <style type="text/css">
      h1 { color:#F1F1F1 },
      table,th, td {
        border: 0.5px solid #cccccc;
        border-collapse: collapse;
        padding-left:5px;
        padding-right:5px;
        font-size:13pt;
        text-align:center;
      },
        .div
        {
        
        }
   
    </style>
    </head>
      
      <body style='text-align:center;height:99%;'>
      <div style="height:100%;display:block; background-color:#2E75B6;">
      <div style="width:100%;display:flex;flex-direction:row;align-items:center,padding-botton:20px">
    
    
      <img 
      src="`+
      scrittaVigor2
        + `" 
      style="height:110px;float:left;width:auto;object-fit:contain;margin-left:5px;margin-top:15px;margin-botton:35px" />
  
    
      </div>
      <div style="width:90%;background-color:white;margin:10px auto;border-radius:10px;">
    <div style="text-align:center;width:100%;color:#2E75B6;font-size:40px;font-face:Helvetica', sans-serif;  font-weight: 900;margin-botton:0px">PROGRAMMA `+vgrp+` </div>
    <div style='color:#00B0F0;text-align:center;width:100%;font-size:20px;font-face:Helvetica', sans-serif;  font-weight: 900;margin-top:0'>`+ moment(date_filtroI).format('DD') + `-` + moment(date_filtroF).format('DD MMMM').toUpperCase() + ` 
      
    </div>
  </div>
  
  <div style="width:98%;padding:5px;margin:5px auto;min-Height:695px"> 
  `+
        outatt2
        + `
  </div>
        
    <div style='background-color:transparent;width:100%;text-align:center;color:white;font-weight:600;font-face:Helvetica', sans-serif;font-size:14px;padding-bottom:15px;border-top:solid white 1px'>
      A.S.D. VIGOR MILANO - Via San Michele del Carso 59, Paderno Dugnano (MI)<br/>  tel: 392 985 88 51 - email:info@vigormilano.it
    </div>
  </div>
      </body>
      <html>`;
  
      //console.log("Stampe","reportConv",texthtml);
      resolve(texthtml);
  
    } catch(err)
    {
     resolve( `<div>Errore<br/>` + err + ` </div>`);
    }
  
  
    })
    
  
  }
  
  




 reportConvocazione = async (selAttivita,listaGruppiSQ) => {
  return new Promise((resolve, reject) => {
    var outconv = '';
    var outconvstaff = '';
    var outnote = '';
    var nco = 1;
    var texthtml;
    var strsq = this.getDesGruppi(selAttivita.gruppi,listaGruppiSQ);
  /*  outconv = '<table style="width:100%;border:0"> '

    selAttivita.gioc.sort((ele1, ele2) => ele1.Giocatore[0] && ele1.Giocatore[0].cognome.localeCompare(ele2.Giocatore[0].cognome)).forEach((ele, pos) => {
      if (ele.Giocatore[0] && ele.idgioc != undefined && ele.convocato) {
        outconv = outconv + '<tr style="border:0;background-color:' + (nco % 2 ? '#e1f2f9' : '#ffffff') + ';">   <td  style="padding:5px 10px 5px;border:0;width:15px;maxwidth:15px;text-align: right;">' + (nco++) + '</td> <td style="border:0;text-align: left;">' + ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome + '</td></tr>';
        //console.log(outconv);       
      }
    });
    outconv = outconv + '</table>'*/
    selAttivita.gioc.sort((ele1, ele2) => ele1.Giocatore[0] && ele1.Giocatore[0].cognome.localeCompare(ele2.Giocatore[0].cognome)).forEach((ele, pos) => {
      if (ele.Giocatore[0] && ele.idgioc != undefined && ele.convocato) {
        outconv = outconv + '<div><div style="display: inline-block;min-width:30px;width:30px;text-align:right">'+(nco++) + '</div> '+ ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome +'</div>';
        //console.log(outconv);       
      }
    });
    if (nco%2===0)
    {
      outconv = outconv + '<div>&nbsp;</div>';
    }
    //outconv = outconv + '</table>'
    //if (selAttivita.staff.size>0)
    // {
    selAttivita.staff.forEach(ele => {
      if (ele.Giocatore[0] && ele.idgioc != undefined && ele.convocato) {
        outconvstaff = outconvstaff + ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome + ' ' + (ele.ruolo ? '(' + ele.ruolo.split('#')[2] + ')' : '') + '<br/>';
        // console.log(outconvstaff);
      }
    });
    //  }

    


    if (selAttivita.note) {
      outnote = `<tr style='height:20px;border:none;color:white'>
<th colspan='2' style="border-bottom:1px white solid;padding-top:15px">
Note
</th>
</tr>      

<tr style='height:20px;border:none;color:white'>
<td colspan='2' style='text-align:left'>
Pronti in campo all'orario indicato<br/>

</td>
</tr>
`
    }

    texthtml = ` <html>
    <head>
  <style type="text/css">
    h1 { color:#F1F1F1 },
    table,th, td {
      border: 0.5px solid #cccccc;
      border-collapse: collapse;
      padding-left:5px;
      padding-right:5px;
      font-size:13pt;
      text-align:center;
    },
      .div
      {
      
      }
 
  </style>
  </head>
    
    <body style='text-align:center;height:100%;'>
    <div style="height:100%;display:block; background-color:#2E75B6">
    <div style="width:100%;display:flex;flex-direction:row;align-items:center,padding-botton:20px">
  
  
    <img 
    src="`+
    scrittaVigor2
      + `" 
    style="height:110px;float:left;width:auto;object-fit:contain;margin-left:5px;margin-top:15px;margin-botton:35px" />

  
    </div>

   <div style="width:90%;background-color:white;margin:10px auto;border-radius:10px;">
  <div style="text-align:center;width:100%;color:#2E75B6;font-size:30px;font-face:calibri;  font-weight: 900;margin-botton:0px">Convocazione: `+ strsq + `</div>
 <div style='color:#00B0F0;text-align:center;width:100%;font-size:20px;font-face:calibri;  font-weight: 900;margin-top:0'>`

   if (selAttivita.campionato)
{
  let sq=listaGruppiSQ.find((elef) => elef._id == selAttivita.gruppi[0]);
  if (sq)
  {
  let camp=sq.campionati.find((tcamp)=>tcamp.id==selAttivita.campionato);
  texthtml = texthtml  +(camp && camp.categoria?' '+camp.categoria:'');
  }
}


texthtml=texthtml+`
 </div>
  </div>
  
    
  <div style="width:98%;padding:5px;margin:5px auto;"> 
  <div style="background-color:#A7C5E0;margin:0px;margin-bottom:15px;padding:5px;border-radius:10px;width:100%;display:flex;flex-direction:row">
        <div style="width:30%; color:#FFF;font-size:22px;font-face:calibri;font-weight:900;padding:3px;text-transform:uppercase;	">
    `;
    
    
    
    if (selAttivita.tipo &&  selAttivita.tipo.split('#')[1] == 'P' )
    {texthtml=texthtml+`
        <b>`+ (selAttivita.TipoPartita && selAttivita.TipoPartita !== undefined ? elenchi.getInstance().getElTipoPartita().find((ele) => ele.value == selAttivita.TipoPartita).label : '&nbsp;') + `</b></br/>
        <div style="font-size:17px;color:#2E75B6;"> 
      <b/>`+ moment(selAttivita.dataAttivita).format('DD/MM/YYYY HH:mm') + `</b>`     ;
      
} 
else 
{
  texthtml=texthtml+`
        <b>`+ selAttivita.tipo.split('#')[2] + `</b></br/>
        <div style="font-size:17px;color:#2E75B6;"> 
      <b/>`+ moment(selAttivita.dataAttivita).format('DD/MM/YYYY HH:mm') + `</b>`;
}
 
 texthtml=texthtml+   `
      </div>
</div>
<div style="width:70%;background-color:#DAE7F2;border-radius:10px;color:#2E75B6;font-face:calibri;padding:3px">
 `;
 if (selAttivita.tipo &&  selAttivita.tipo.split('#')[1] == 'P' ){
 texthtml=texthtml+(selAttivita.trasferta ?  ' <span style="color:#00B0F0;font-weight:700">' + (selAttivita.avversario?selAttivita.avversario:'??') +'</span> <span style="font-weight:700"> - Vigor Milano </span>':'<span style="font-weight:700"> Vigor Milano - </span><span style="color:#00B0F0;font-weight:700">' + (selAttivita.avversario?selAttivita.avversario:'??') +'</span> ');
           texthtml=texthtml+ '<div style="float:right"> <img src="'+  (selAttivita.trasferta?PartitaTrasferta:PartitaHome)  + '" style="height:30px;float:right;width:auto;object-fit:contain;;margin-top:2px;margin-right:5px" /><br/><br/>';
 }
 else
 {
  texthtml=texthtml+ '<div style="float:right"> ';
 }
           texthtml=texthtml+ `<span style="float:right;color:white;background-color:#2E75B6;padding:5px;border-radius:10px;border:white 2px solid; font-weight:900"><b>RITROVO  `+(moment(selAttivita.RitrovoDataOra).format('DD/MM/YYYY') === moment(selAttivita.dataAttivita).format('DD/MM/YYYY') ? moment(selAttivita.RitrovoDataOra).format('HH:mm') : moment(selAttivita.RitrovoDataOra).format('DD/MM/YYYY HH:mm'))+`</b></span></div>`           
           ;
           

           if (selAttivita.indirizzo && selAttivita.indirizzo.startsWith("CS Vigorello"))
            {
              
              texthtml = texthtml +'<br/><span style="font-size=16;color:#BBB">CS Vigorello';
              if (selAttivita.indirizzo.split('-')[1] && selAttivita.indirizzo.split('-')[1].trim().startsWith("Campo"))
              {
                texthtml = texthtml +selAttivita.indirizzo.split('-')[1];
              }
            
              texthtml = texthtml +'</span>';
            }
              else if (selAttivita.indirizzo)
              {
                texthtml = texthtml +'<br/><span style="font-size=16;color:#BBB">'+selAttivita.indirizzo+'</span>';
                
              }
            
            if (selAttivita.note)
            {
              texthtml = texthtml +'<br/>'+selAttivita.note.replace(/(?:\r\n|\r|\n)/g, '<br/>');
              
            }
            
            
    
           
           
           
           
            texthtml=texthtml    +`
           </div>



</div>
</div>

  
  <table style='width:98%; table-layout: auto;margin: 0 auto;text-align:center>
      
 
  <tr style='height:20px;border:none'>
      </tr>
 
   <tr style='height:20px;border:none;color:white'>
    <th colspan='2'>
    Giocatori Convocati
    </th>
  </tr>
  <tr>
  <td colspan=2 style="text-align:left;padding:10;">
 <div  class="listaConv" style="`+(nco > 30 ? 'column-count:2;-webkit-column-count: 2; -moz-column-count: 2' : nco > 11 ? 'column-count:2;-webkit-column-count: 2; -moz-column-count: 2' : 'column-count:1;-webkit-column-count: 1; -moz-column-count: 1')+`" >
  `+
      outconv
      + `
      </div>
    </td>
    </tr>
    <tr style='height:20px;border:none;color:white'>
    <th colspan='2' style="border-bottom:1px white solid">
    Staff
    </th>
  </tr>
  <tr>
  <td style='text-align:left;padding:10;'>
  <div style="width:100%;column-count:2;color:white;-webkit-column-count: 2; -moz-column-count: 2;padding-top:15px" >
  `+
      outconvstaff
      + `
      </div>
    </td>
    </tr>
    `+

      outnote

      + `


  
  </table>
  
  
  
  </div>
  
  </div>
    </body>
    <html>`;

    resolve(texthtml);

  });

}


reportListaTorneo = async (selAttivita,listaGruppiSQ) => {
  return new Promise((resolve, reject) => {
    var outconv = '';
    var outconvstaff = '';
    var outnote = '';
    var nco = 1;
    var texthtml;
    var strsq = this.getDesGruppi(selAttivita.gruppi,listaGruppiSQ);
  /*  outconv = '<table style="width:100%;border:0"> '

    selAttivita.gioc.sort((ele1, ele2) => ele1.Giocatore[0] && ele1.Giocatore[0].cognome.localeCompare(ele2.Giocatore[0].cognome)).forEach((ele, pos) => {
      if (ele.Giocatore[0] && ele.idgioc != undefined && ele.convocato) {
        outconv = outconv + '<tr style="border:0;background-color:' + (nco % 2 ? '#e1f2f9' : '#ffffff') + ';">   <td  style="padding:5px 10px 5px;border:0;width:15px;maxwidth:15px;text-align: right;">' + (nco++) + '</td> <td style="border:0;text-align: left;">' + ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome + '</td></tr>';
        //console.log(outconv);       
      }
    });
    outconv = outconv + '</table>'*/
    console.log('report lista torneo', selAttivita);
    selAttivita.gioc.sort((ele1, ele2) => ele1.Giocatore[0] && ele1.Giocatore[0].cognome.localeCompare(ele2.Giocatore[0].cognome)).forEach((ele, pos) => {
      if (ele.Giocatore[0] && ele.idgioc != undefined && ele.convocato) {
        outconv = outconv + '<div><div style="display: inline-block;min-width:30px;width:30px;text-align:right">'+(nco++) + '</div> '+ ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome +'</div>';
        //console.log(outconv);       
      }
    });
    if (nco%2===0)
    {
      outconv = outconv + '<div>&nbsp;</div>';
    }
    //outconv = outconv + '</table>'
    //if (selAttivita.staff.size>0)
    // {
    selAttivita.staff.forEach(ele => {
      if (ele.Giocatore[0] && ele.idgioc != undefined && ele.convocato) {
        outconvstaff = outconvstaff + ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome + ' ' + (ele.ruolo ? '(' + ele.ruolo.split('#')[2] + ')' : '') + '<br/>';
        // console.log(outconvstaff);
      }
    });
    //  }


    if (selAttivita.note) {
      outnote = `<tr style='height:20px;border:none;color:white'>
<th colspan='2' style="border-bottom:1px white solid;padding-top:15px">
Note
</th>
</tr>      

<tr style='height:20px;border:none;color:white'>
<td colspan='2' style='text-align:left'>
Pronti in campo all'orario indicato<br/>

</td>
</tr>
`
    }

    texthtml = ` <html>
    <head>
  <style type="text/css">
    h1 { color:#F1F1F1 },
    table,th, td {
      border: 0.5px solid #cccccc;
      border-collapse: collapse;
      padding-left:5px;
      padding-right:5px;
      font-size:13pt;
      text-align:center;
    },
      .div
      {
      
      }
 
  </style>
  </head>
    
    <body style='text-align:center;height:100%;'>
    <div style="height:100%;display:block;>
    <div style="width:100%;display:flex;flex-direction:row;align-items:center, padding-botton:20px">
   
    <img 
    src="`+
    logo
      + `" 
    style="height:110px;float:left;width:auto;object-fit:contain;margin-left:5px;margin-top:15px;margin-botton:35px" /> 
    <img 
    src="`+
    logo
      + `" 
    style="height:110px;float:center;width:auto;object-fit:contain;margin-left:5px;margin-top:15px;margin-botton:35px" /> 
    <img 
    src="`+
    logo
      + `" 
    style="height:110px;float:right;width:auto;object-fit:contain;margin-rigth:5px;margin-top:15px;margin-botton:35px" /> 
  
    </div>

   <div style="width:90%;background-color:white;margin:10px auto;border-radius:10px;">
  <div style="text-align:center;width:100%;color:black;font-size:18px;font-face:calibri; font-weight: 900;margin-botton:0px">Società Sportiva A.S.D. VIGOR MILANO</div>
 <div style='color:#00B0F0;text-align:center;width:100%;font-size:16px;font-face:calibri;  font-weight: 900;margin-top:0'>`
   if (selAttivita.campionato)
{
  let sq=listaGruppiSQ.find((elef) => elef._id == selAttivita.gruppi[0]);
  if (sq)
  {
  let camp=sq.campionati.find((tcamp)=>tcamp.id=selAttivita.campionato);
  texthtml = texthtml  +(camp && camp.categoria?' '+camp.categoria:'');
  }
}

texthtml=texthtml+`
 </div>
  </div>    
  <div style="width:98%;padding:5px;margin:5px auto;"> 
  <div style="background-color:#A7C5E0;margin:0px;margin-bottom:15px;padding:5px;border-radius:10px;width:100%;display:flex;flex-direction:row">
        <div style="width:30%; color:#FFF;font-size:22px;font-face:calibri;font-weight:900;padding:3px;text-transform:uppercase;	">
    `;
    
    
    
    if (selAttivita.tipo &&  selAttivita.tipo.split('#')[1] == 'P' )
    {texthtml=texthtml+`
        <b>`+ (selAttivita.TipoPartita && selAttivita.TipoPartita !== undefined ? elenchi.getInstance().getElTipoPartita().find((ele) => ele.value == selAttivita.TipoPartita).label : '&nbsp;') + `</b></br/>
        <div style="font-size:17px;color:#2E75B6;"> 
      <b/>`+ moment(selAttivita.dataAttivita).format('DD/MM/YYYY HH:mm') + `</b>` ;      
} 
else
{
  texthtml=texthtml+`
        <b>`+ selAttivita.tipo.split('#')[2] + `</b></br/>
        <div style="font-size:17px;color:#2E75B6;"> 
      <b/>`+ moment(selAttivita.dataAttivita).format('DD/MM/YYYY HH:mm') + `</b>`;
}
 
 texthtml=texthtml+   `
      </div>
</div>
<div style="width:70%;background-color:#DAE7F2;border-radius:10px;color:#2E75B6;font-face:calibri;padding:3px">
 `;
 if (selAttivita.tipo &&  selAttivita.tipo.split('#')[1] == 'P' ){
 texthtml=texthtml+(selAttivita.trasferta ?  ' <span style="color:#00B0F0;font-weight:700">' + (selAttivita.avversario?selAttivita.avversario:'??') +'</span> <span style="font-weight:700"> - Vigor Milano </span>':'<span style="font-weight:700"> Vigor Milano - </span><span style="color:#00B0F0;font-weight:700">' + (selAttivita.avversario?selAttivita.avversario:'??') +'</span> ');
           texthtml=texthtml+ '<div style="float:right"> <img src="'+  (selAttivita.trasferta?PartitaTrasferta:PartitaHome)  + '" style="height:30px;float:right;width:auto;object-fit:contain;;margin-top:2px;margin-right:5px" /><br/><br/>';
 }
 else
 {
  texthtml=texthtml+ '<div style="float:right"> ';
 }
           texthtml=texthtml+ `<span style="float:right;color:white;background-color:#2E75B6;padding:5px;border-radius:10px;border:white 2px solid; font-weight:900"><b>RITROVO  `+(moment(selAttivita.RitrovoDataOra).format('DD/MM/YYYY') === moment(selAttivita.dataAttivita).format('DD/MM/YYYY') ? moment(selAttivita.RitrovoDataOra).format('HH:mm') : moment(selAttivita.RitrovoDataOra).format('DD/MM/YYYY HH:mm'))+`</b></span></div>`           
           ;
           

           if (selAttivita.indirizzo && selAttivita.indirizzo.startsWith("CS Vigorello"))
            {
              
              texthtml = texthtml +'<br/><span style="font-size=16;color:#BBB">CS Vigorello';
              if (selAttivita.indirizzo.split('-')[1] && selAttivita.indirizzo.split('-')[1].trim().startsWith("Campo"))
              {
                texthtml = texthtml +selAttivita.indirizzo.split('-')[1];
              }
            
              texthtml = texthtml +'</span>';
            }
              else if (selAttivita.indirizzo)
              {
                texthtml = texthtml +'<br/><span style="font-size=16;color:#BBB">'+selAttivita.indirizzo+'</span>';
                
              }
            
            if (selAttivita.note)
            {
              texthtml = texthtml +'<br/>'+selAttivita.note.replace(/(?:\r\n|\r|\n)/g, '<br/>');
              
            }
          
           
            texthtml=texthtml    +`
           </div>



</div>
</div>

  
  <table style='width:98%; table-layout: auto;margin: 0 auto;text-align:center>
      
 
  <tr style='height:20px;border:none'>
      </tr>
 
   <tr style='height:20px;border:none;color:white'>
    <th colspan='2'>
    Giocatori Convocati
    </th>
  </tr>
  <tr>
  <td colspan=2 style="text-align:left;padding:10;">
 <div  class="listaConv" style="`+(nco > 30 ? 'column-count:2;-webkit-column-count: 2; -moz-column-count: 2' : nco > 11 ? 'column-count:2;-webkit-column-count: 2; -moz-column-count: 2' : 'column-count:1;-webkit-column-count: 1; -moz-column-count: 1')+`" >
  `+
      outconv
      + `
      </div>
    </td>
    </tr>
    <tr style='height:20px;border:none;color:white'>
    <th colspan='2' style="border-bottom:1px white solid">
    Staff
    </th>
  </tr>
  <tr>
  <td style='text-align:left;padding:10;'>
  <div style="width:100%;column-count:2;color:white;-webkit-column-count: 2; -moz-column-count: 2;padding-top:15px" >
  `+
      outconvstaff
      + `
      </div>
    </td>
    </tr>
    `+

      outnote

      + `


  
  </table>
  
  
  
  </div>
  
  </div>
    </body>
    <html>`;

    resolve(texthtml);

  });

}




RepPres =async (listaVal,filtri)=>
  {
    console.log('inizio reppres');
 var grp="",grp_json="";
 grp_json=await restGruppi.getInstance().getGruppo(filtri.gruppoFiltro);
   grp=grp_json.des;
   
    var logov=logo;
    var outconv='';
    var texthtml
  var stl="",stlr="";
    listaVal.forEach((ele,pos) => {
     stl="";
     stlr="";
     if (pos==0)
     {
 stl="font-weight:700;"
     }
     
     if (pos % 2 == 0)
     {
       stlr="background-color:#e1f2f9;";
     }
     outconv=outconv+'<tr  style="'+stlr+'" ><td  style="text-align: left;'+stl+'">'+ele[0]+'</td>';
     outconv=outconv+'<td  style="text-align: center;'+stl+'">'+ele[1]+'</td>';
     outconv=outconv+'<td  style="text-align: center;'+stl+'">'+ele[2]+'</td>';
     outconv=outconv+'<td  style="text-align: center;'+stl+'">'+ele[3]+'</td>';
     outconv=outconv+'<td  style="text-align: center;'+stl+'">'+ele[4]+'</td>';
     outconv=outconv+'<td  style="text-align: center;'+stl+'">'+(ele[5]!="NaN"?ele[5]:"--")+'</td>';
     outconv=outconv+'<td  style="text-align: center;'+stl+'">'+(ele[6]!="NaN"?ele[6]:"--")+'</td>';
     outconv=outconv+'<td  style="text-align: center;'+stl+'">'+ele[7]+'</td>';
     outconv=outconv+'</tr>';
     
    });
    
  texthtml=`<html>
  <head>
  <style type="text/css">
  h1 { color:#F1F1F1 }
  table,th, td {
    border: 0.5px solid #cccccc;
    border-collapse: collapse;
    padding-left:5px;
    padding-right:5px;
    font-size:11pt;
    text-align: justify;
    text-justify: inter-word;
    padding:4px;
  }
  </style>
  </head>
  
  <body style='text-align:center'>
  <div style="height:100%;display:block;" >
  <div style="width:100%;display:flex;flex-direction:row">
  <div style="width:60%;">
  <img 
  src=" `+
  logov
  +`" 
  style="height:150px;float:left;width:auto;object-fit:contain;" />
  </div>
  <div style='float:right;background-color:transparent;width:40%;text-align:left;font-size:10pt'>
  <h2>A.S.D. Vigor Milano</h2>
  Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
  Via IV Novembre 72, Cormano (MI)<br/>
  tel: 392 985 88 51 <br/>
  </div>
  
  </div>
  <h1 style='color:black'>Presenze gruppo  `+ grp +` </h1>
  <h3 style='color:gray'> da: `+
  
  moment(filtri.date_filtroI).format('DD/MM/YYYY')  +` a: `+ moment(filtri.date_filtroF).format('DD/MM/YYYY')  +`  `+
  (filtri.tipofiltro?'  Tipo: '+filtri.tipofiltro.split('#')[2] :''  )+
  
  `</h3>
  
  <table style='width:98%; table-layout: auto;margin: 0 auto'>
  
  
  `+
  
  outconv
  
  +`
  
  
  </table> 
 
  
  </body>
  
  <html>`;
  
  console.log('reppres html',texthtml);

  this.StampaHtlm(texthtml,'ReportPresenze.pdf')
  console.log('reppres dopo stampa');
  
  
  }










  printPDFVal =  async(ListaVal)=> {
  
     let file = await this.eseRepVal(ListaVal);
  
    }
  

  
   
  
   
   eseRepVal =(json)=>
   {
  
  
    
    //console.log(json);
     var logov;
     var outconv='';
     var texthtml
   
     logov=logo;
     json.forEach(ele => {
      let listav = ele.Valutazione.sort((ele1, ele2) => moment(ele1.dataScheda).diff(moment(ele2.dataScheda)));
      
      outconv=outconv+'<tr><td  style="text-align: center;">'+ele.Giocatore[0].cognome+' '+ele.Giocatore[0].nome+'</td>';
      outconv=outconv+'<td style="text-align: center;">';
      outconv=outconv+'<table style=border:none;width:100%;margin:0,padding:0>';
      listav.forEach((eleval,ele_index) => {
        
        outconv=outconv+"<tr>";
  
        outconv=outconv+"<td style=border:none;text-align:left;font-weight:900>"+moment(eleval.dataScheda).format('DD/MM/YYYY')+"</td>"+"<td style=border:none;text-align:center;font-weight:900>"+(eleval.valutazioneGlobale>0?"Val:"+eleval.valutazioneGlobale:"")+"</td><td style=text-align:right;font-weight:900;border:none>"+eleval.operatore+"</td>";
        outconv=outconv+"</tr></td><tr><td colspan=3 style=border:none>";
        outconv=outconv+"<span>"+eleval.note+'</span>'
        outconv=outconv+"</tr></td>";
      });
      outconv=outconv+'</table></td></tr>';
      
     });
     
   texthtml=`<html>
   <head>
   <style type="text/css">
   h1 { color:#F1F1F1 }
   table,th, td {
     border: 0.5px solid #cccccc;
     border-collapse: collapse;
     padding-left:5px;
     padding-right:5px;
     font-size:11pt;
     text-align: justify;
     text-justify: inter-word;
     padding:4px;
   }
   </style>
   </head>
   
   <body style='text-align:center'>
   <div style="height:100%;display:block;" >
   <div style="width:100%;display:flex;flex-direction:row">
   <div style="width:60%;">
   <img 
   src=" `+
   logov
   +`" 
   style="height:150px;float:left;width:auto;object-fit:contain;" />
   </div>
   <div style='float:right;background-color:transparent;width:40%;text-align:left;font-size:10pt'>
   <h2>A.S.D. Vigor Milano</h2>
   Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
   Via IV Novembre 72, Cormano (MI)<br/>
   tel: 392 985 88 51 <br/>
   </div>
   
   </div>
   <h1 style='color:black'>Valutazioni Gruppo</h1>
   
   <table style='width:98%; table-layout: auto;margin: 0 auto'>
   
   
   `+
   
   outconv
   
   +`
   
   
   </table> 
  <div>Sono considerate solo le valutazioni degli ultimi 60 giorni</div>
   
   </body>
   
   <html>`;
   
   this.StampaHtlm(texthtml,'ReportVAlutazioni.pdf');
   }
  
  
  
  


}