import datiGen from "../util/datiGen";
import { getAuth } from "firebase/auth";
import datiInit from "../util/datiInit";



export default class restSchedaOss {
  constructor(restSchedaOss) {
   // console.log('restSchedaOss');
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();
  restApp = 'schedeoss';
  static myInstance = null;

  static getInstance() {
    if (restSchedaOss.myInstance == null) {
      restSchedaOss.myInstance = new restSchedaOss();
      // Util.myInstance.inizializza();
    //  console.log('fine get instance');
    }
    return restSchedaOss.myInstance;
  }

  prova() {
    console.log('restSchedaOss','prova');
  }

  getSchedeOss = async (pidgioc) => {
    if (!datiGen.getInstance().possoaccederev2('SCHOSS'))
    throw 'Non sei abilitato a svolgere la funzione SCHOSS';
    
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      console.log('restscdhedaoos',pidgioc);
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "?" +(pidgioc? "idgioc=" + pidgioc:'')
      , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
      });
      const json = await response.json();
      console.log("restSchedaOss","getSchedeOss",json);
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      
    }
  }

  getSchedaOss = async (pidscheda) => {
    if (!datiGen.getInstance().possoaccederev2('SCHOSS'))
    throw 'Non sei abilitato a svolgere la funzione SCHOSS';
    
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "/" + pidscheda
      , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
      });
      const json = await response.json();
      //console.log("restSchedaOss","getSchedeOss",json);
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      
    }
  }


  aggiungi = async (pscheda) => {
    if (!datiGen.getInstance().possoaccederev2('SCHOSSUPD'))
    throw 'Non sei abilitato a svolgere la funzione SCHOSSUPD';

    var vscheda={...pscheda};
    delete vscheda.Giocatore;
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp+ '/' + datiGen.getInstance().getActiveSoc().sigla
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(vscheda)
        });
      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      // console.log("finito aggiungi");
    }
  }

  modifica = async (pscheda) => {
    if (!datiGen.getInstance().possoaccederev2('SCHOSSUPD'))
    throw 'Non sei abilitato a svolgere la funzione SCHOSSUPD';
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var p = { ...pscheda };
      delete p._id;
      delete p.Giocatore;
      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ '/' + pscheda._id;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }


  sendnotificaoss = async (pscheda) => {
    if (!datiGen.getInstance().possoaccederev2('SCHOSSUPD'))
    throw 'Non sei abilitato a svolgere la funzione SCHOSSUPD';
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var p = { ...pscheda };
      delete p._id;
      //delete p.Giocatore;
      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ '/' + pscheda._id+ '/SendNotifica' ;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }


}