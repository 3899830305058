// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';

import moment from "moment";
import DettaglioSchedaOss from '../comp/DettaglioSchedaOss';
import restSchedaOss from '../restcall/restSchedaOss';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';

import { Button } from 'primereact/button';
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
mdiAccount
} from '@mdi/js';

const DettSchedaOss = (props) => {
  const navigation = useNavigate();
  const location=useLocation();
  const [selSchedaOss, setSelSchedaOss] = useState();

  const getScheda = async (pid) => {
  console.log("DettSchedaOss","getScheda0",pid);
    try {
      var json=await  restSchedaOss.getInstance().getSchedaOss(pid);
      console.log("DettSchedaOss","getScheda1",json);
    return json;    
   } catch (error) {
     console.error(error);
   } finally { 
   }
  }


  useEffect(() => { 
    console.log("DettSchedaOss",'USE useEffect', props.selschedaoss); 
   console.log("DettSchedaOss",'USE useEffect22222',location.state.selschedaoss);    
    if (location.state.selschedaoss)
    {
      setSelSchedaOss(location.state.selschedaoss);
    }
    else
    {
var sch=getScheda(props.selschedaid).then((sch)=>
setSelSchedaOss(sch));
    }
 // console.log("DettSchedaOss",'USE useFocusEffect',selSchedaOss);
 });

  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
      }}>
        {selSchedaOss && (
    <div style={{  justifyContent:'space-between' ,flexDirection:'row',width:'100%',minHeight:30,display:'flex',alignItems:'center'}}>
    <label style={{marginLeft:'0.8rem'}} >{selSchedaOss.Giocatore[0].cognome} {selSchedaOss.Giocatore[0].nome} </label>
    <Button
        onClick={() => navigation('/DettPersone', { state:{ selpersona: selSchedaOss.Giocatore[0] }})}
      >
        <Icon path={mdiAccount} size={0.8}/>
      </Button>
    <label style={{textAlign:'right',marginRight:'0.8rem'}} > {moment(selSchedaOss.Giocatore[0].datanascita).format('DD/MM/YYYY')}</label> 
    </div>)}
    {selSchedaOss && (
          <div style={{justifyContent:'space-between' ,flex:20,width:'100%'}}>  
          <DettaglioSchedaOss selSchedaOss={selSchedaOss}  tipo={props.tipo} />         
          </div> )}
    </div>
  );
} 
export default DettSchedaOss;