import moment from 'moment'

const docPrototype = {
    

  to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
  },

  copiaDoc(tipo) {
    this.id=tipo.id;
    this.tipo=tipo.tipo;   //carta identità,patente,passaporto
    this.numero=tipo.numero;
    this.appartenenza=tipo.appartenenza;  //ragazzo,genitore,altro
    this.ival=tipo.ival;
    this.fineval=tipo.fineval;
    this.note=tipo.note;

    this.__proto__ = doc.prototype;
  }
  }
  
  function doc(tipo) {
    this.id=Date.now();
    this.tipo = tipo;   //carta identità,patente,passaporto
    this.numero="";
    this.appartenenza="";  //ragazzo,genitore,altro
    this.ival=new Date();
    this.fineval=moment().add(7, 'years').toDate();
this.note="";

    this.__proto__ = doc.prototype;
  }
  
  doc.prototype = docPrototype;
  doc.prototype.constructor = doc;
  





export default doc;