// screens/Home.js

import React, { useState, useEffect,useRef } from 'react';

import { useNavigate, useLocation } from "react-router-dom";
import datiGen from '../util/datiGen';
import { Button } from 'primereact/button';
import utilVisite from '../util/utilvisite';
import restGiocGruppi from '../restcall/restGiocGruppi';
import restGruppi from '../restcall/restGruppi';
import ListaAttivita from './ListaAttivita';
import restAttivita from '../restcall/restAttivita';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import Stampe from '../util/Stampe';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer,mdiContentSaveAll,mdiCloseBox,
  mdiCheckAll,mdiTagEdit,mdiClose,mdiMenuOpen,
  mdiAccount,
  mdiClipboard,mdiAccountBadge,
  mdiClipboardPlus,
  mdiPencil,
  mdiDatabase,
  mdiCalendarMultipleCheck,
  mdiAccountMultiple,
  mdiCertificate

} from '@mdi/js';



const InfoTeam = (props) => {
  const navigate = useNavigate();
const location=useLocation();

  const [visibleLoader, setVisibleLoader] = useState(false);
  const [dettGruppo, setdettGruppo] = useState();
  const [datatot, setDatatot] = useState(([]));
  const [mostraMenu, setMostraMenu] = useState(false);

  // datiGen.getInstance().possoaccedere('GRUPPI');
  // const abilAtt = datiGen.getInstance().possoaccedere('ATTIVITA') ||   datiGen.getInstance().possoaccedere('Attivita',props.gruppo);
  // const abilAttUPD = datiGen.getInstance().possoaccedere('ATTIVITAUPD') || datiGen.getInstance().possoaccedere('AttivitaUpd',props.gruppo);
  const abilPresRep = datiGen.getInstance().possoaccederev2('REPORTPRESENZE', props.gruppo);
  const abilTest = datiGen.getInstance().possoaccederev2('REPTESTSQUADRA', props.gruppo);

  const toast = useRef(null);
  const showSuccess = (titolo, testo) => {
    toast.current.show({severity:'success', summary: titolo, detail: testo, life: 3000});
  }
  const showInfo = (testo) => {
    toast.current.show({severity:'info', summary: 'Info', detail:testo, life: 3000});
  }
  const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
  }
  const showError = (testo) => {
    toast.current.show({severity:'error', summary: 'Error', detail:testo, life: 3000});
  }




 
  useEffect(() => {
getDettGruppo(props.gruppo);
getTotaliAtt();
}, []);
 
  const getDettGruppo = async (idg) => {
    //console.log('getDettGruppo', idg, props.gruppo)
    try {
      restGruppi.getInstance().getGruppo(idg).then((json) => { setdettGruppo(json); });
    } catch (error) {
      console.error(error);
    } finally {

    }
  }


  const getPersonevis = async () => {

    try {
      //setLoading(true);
      var json = await restGiocGruppi.getInstance().getVisiteGruppo(props.gruppo);

      if (json.esito === 'OK')
        utilVisite.getInstance().createPDF(json);
      else {
      console.log(json.des);    
      }

    } catch (error) {
      console.error(error);
    } finally {
      //setLoading(false);
    }
  }


  const getTotaliAtt = async () => {

    try {
      //setLoading(true);
      if (!datiGen.getInstance().possoaccederev2('ATTIVITA', props.gruppo))
        throw 'Non sei abilitato a svolgere la funzione ATTIVITA';

      var json = await restAttivita.getInstance().getTotaliAttivitaGruppo(props.gruppo);
      // console.log('teaminfo','getpegettotali',json);
      if (json.esito && json.esito === 'OK') {

        /* json.forEach(ele => {
           console.log('getTotaliAtt',ele._id.tipo,ele.count,ele);
         });*/
        setDatatot(json);
      }
      else {
        console.error('teaminfo', 'getotali', json);
showError(json.des);
       
      }

    } catch (error) {
      console.error(error);
    } finally {
      //setLoading(false);
    }
  }

  const getReportisticaTest = async (ptipo) => {
    if (ptipo==='test_squadra')
    {var nomegruppo=dettGruppo.des;
    navigate('/ReportisticaTest', {state:{ tipo: ptipo,   gruppo:props.gruppo,    gruppodes :nomegruppo }})    
    }
   };



   const printPDVal = async () => {
    var json = await restGiocGruppi.getInstance().getValutazioniGruppo(props.gruppo);
    //console.log("createPDVal",json);
   Stampe.getInstance().printPDFVal(json);
  }


  return (
    <div style={{
      flex: 10,
      alignItems: 'center',
      width: '100%',
      flexShrink: 1,
      flexGrow: 100,
    }}>

<Toast ref={toast} />

{dettGruppo && dettGruppo.des &&
  
  <div style={{ flex: 1 ,margin:'0.5rem',justifyContent:'center',alignItems:'center',justifyItems:'center',width:'100%'}}>
  <label className='Titolo5' style={{ flex: 1,textAlign:'center',width:'100%'}} >{dettGruppo.des} </label>

     </div>

}

<div style={{ flexDirection:'column',  display:'flex',backgroundColor:'#e1f2f9', alignItems: 'center'}}>

<label className='Titolo2' style={{ width:'100%',textAlign:'center',margin:'0.3rem'}}>Attività stagione</label>

<div style={{ flexDirection: 'row',  display:'flex',justifyContent: 'space-between', flexWrap: 'nowrap', 
  width: '100%', alignItems: 'center',border:'0.02rem solid',padding:'2px'      }}>
{
datatot.sort((ele1, ele2) => ele1._id.tipo.localeCompare(ele2._id.tipo)).map((riga, key) => (              
                  <div key={key}
                    style={{ flex: 1, flexDirection: 'column',  display:'flex',alignItems: 'center'}}>

                    <label className='Titolo4' style={{ flex: 1 }}>
                      {riga.des.length > 9 ? riga.des.substring(0, 3) : riga.des}</label>
                    <label style={{ flex: 1, marginTop: 5, marginHorizontal: 0, padding: 10, borderRadius: 20, backgroundColor: '#00adef', color: 'white',fontWeight:'bold' }}>
                      {riga.count}</label>
                  </div>
               
              ))
              }
</div>
</div>




          <ListaAttivita tipo='giorno' style={{ flex: 1 }} gruppo={props.gruppo} />

         


<Sidebar modal={false} visible={mostraMenu} onHide={() => setMostraMenu(false)} position='right' style={{ backgroundColor: '#FFFFFF20', boxShadow: 'none', width: '16rem' }}
        closeOnEscape={true} showCloseIcon={false}
      >
        <div style={{ borderRadius: "20px", height: "90%", display: "flex", flexDirection: 'column', justifyContent: 'flex-end' }}>
          <div style={{ backgroundColor: "#afcfdfaf", borderRadius: "20px", display: "flex", flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button onClick={() => { setMostraMenu(false); }} style={{ alignSelf: 'flex-end', margin: "1em", borderRadius: "24px" }}  >
              <Icon path={mdiClose} size={1} />
            </Button>
            {
       
       <Button           className="sideButton"
                    onClick={() => { 
        navigate('/PresenzePage', {state:{ gruppo: props.gruppo}}); setMostraMenu(false); }}
                  >
                    <Icon path={mdiAccountBadge} size={1.2} />
                    <span className='bottoneHome-label'>Presenze</span>

                  </Button>
             }
                  
        

<Button className="sideButton"
                    onClick={() => { 
        getPersonevis() ; setMostraMenu(false); }}
                  >
                <Icon path={mdiClipboardPlus} size={1.2} />
                <span className='bottoneHome-label'>Visite</span>

                  </Button>
{abilTest &&
                  <Button style={{flex:1}}
         className='sideButton'
         onClick={()=>{getReportisticaTest('test_squadra');setMostraMenu(false); }}
         title='report test'
        >
 <Icon path={mdiDatabase} size={1.2} />
 <span className='bottoneHome-label'>Report Test</span>
        </Button>

}
        <Button className="sideButton"
                    onClick={() => { 
        printPDVal() ; setMostraMenu(false); }}
                  >
                <Icon path={mdiCertificate} size={1.2} />
                <span className='bottoneHome-label'>Valutazioni</span>

                  </Button>


          </div>
        </div>
      </Sidebar>
      <div style={{ position:'absolute', right:"10px", bottom:"10px" }}>
            <Button onClick={() => setMostraMenu(!mostraMenu)} className='icobutton' >
              <Icon path={mdiMenuOpen} size={1.2} />
            </Button>

         </div>
    </div>
  );
}
export default InfoTeam;