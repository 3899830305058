// screens/Home.js

import React, { Component, useEffect, useState,useRef } from 'react';
import moment from "moment";
import { useNavigate, useLocation, Link,useOutletContext } from "react-router-dom";

import restSchedaOss from '../restcall/restSchedaOss';
import restAttivita from '../restcall/restAttivita';
import datiGen from '../util/datiGen.js';
import RicercaPersone from './RicercaPersone';
import elenchi from '../util/elenchi';
import Attivita from '../obj/Attivita';
import restGruppi from '../restcall/restGruppi.js';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { FloatLabel } from 'primereact/floatlabel';
import Stampe from '../util/Stampe';
import jsPDF from 'jspdf';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import logo from '../img/logo-vigor3.png';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';


import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiContentSaveAll, mdiCloseBox,
  mdiCheckAll, mdiClose,
  mdiPrinter,mdiShareVariant,
  mdiShare,
  mdiAccountMultiplePlus,
  mdiAccountTie,
  mdiAccountPlus,
  mdiAccountMultipleCheck,mdiCalendarMultipleCheck,mdiCheckboxMarkedOutline

} from '@mdi/js';
import { NoEncryptionGmailerrorred } from '@mui/icons-material';




const GiocAtt = (props) => {
  const navigate = useNavigate();
  const [selGruppo, setSelGruppo] = useState(() => props.gruppo);
  const [selAttivita, setSelAttivita] = useState(() => props.attivita);
  const [selectedId, setSelectedId] = useState([]);
  const [daSalvare, setDaSalvare] = useState(false);
  const [ricVisible, setRicVisible] = useState(false);
  const [listaGruppiSQ, setListaGruppiSQ] = useState([]);
  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();

  var tmingioc;

  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo, life: 3000 });
  }
  const showWarn = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: testo, life: 3000 });
  }
  const showError = () => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Message Content', life: 3000 });
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };

  const menuPrint = useRef(null);



  useEffect(() => {

    if (props.attivita) {

      let tmp_att = props.attivita;
      tmp_att.__proto__ = Attivita.prototype;
      tmp_att.to_obj();
      setSelAttivita(tmp_att);
      getListaSQ();

    } else {

    }
  }, []);


  useEffect(() => {
    console.log('modifco 0');
    if (daSalvare) {
      let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 3, '1');
      setStatoDaSalvare(x);
    } else if (!daSalvare) {
      let x = statoDaSalvare.replaceAll("1", "0");
      setStatoDaSalvare(x);
    }
  }, [daSalvare]);





  const toggleOverlay = () => {
console.log('---toggleoverlay');
    setRicVisible(!ricVisible);

  };


  useEffect(() => {
       setSelGruppo(props.gruppo);
       // setSelAttivita(route.params.attivita); 
       // getListaGiocAtt();
       if (!daSalvare)
         restAttivita.getInstance().getAttivita(selAttivita._id, selGruppo).then((json) => {
           setSelAttivita(json);

           console.log('gioctatt','useEffect --------2',json);
         })
     }, [props.gruppo, daSalvare]);




  const creaListaGiocAtt = async () => {

    var cicloatt = { ...selAttivita };
    try {
      if (selAttivita.gruppi) {
        for (const ele of selAttivita.gruppi) {
          await restAttivita.getInstance().creaPresAtt(cicloatt, ele).then((json) => {
            cicloatt = json;
          });
        };
      }
      console.log('veriifco selatt-----',cicloatt);
      if (cicloatt)
      setSelAttivita(cicloatt);
      setDaSalvare(true);

    } catch (error) {
      console.error(error);
    } finally {

    }
  }


  const selgioc = (pgioc) => {
    addGiocAtt(pgioc._id);


  }


  const addGiocAtt = async (pidgioc) => {

    var cicloatt = { ...selAttivita };


    try {


      await restAttivita.getInstance().creaPresGioc(cicloatt, selGruppo, pidgioc).then((json) => {
        cicloatt = json;


        setSelAttivita(json);
        setDaSalvare(true);


      });




    } catch (error) {
      console.error(error);
    } finally {
      //console.log('addGiocAtt','fine');
    }
  }


  const createPDF = async () => {

    const x = await Stampe.getInstance().reportConvocazione(selAttivita,listaGruppiSQ);
    console.log('create pdf-html generazione',x);

    Stampe.getInstance().StampaHtlm(x, 'Convocazione.pdf',2);

  }

  const createListatorneoPDF = async () => {

    const x = await Stampe.getInstance().reportListaTorneo(selAttivita,listaGruppiSQ);
    console.log('create pdf-html generazione',x);

    Stampe.getInstance().StampaHtlm(x, 'ListaTorneo.pdf',2);

  }




  const printPDF = async () => {
    let file = await createPDF();
    //await RNPrint.print({ filePath: file.filePath });

  }


    const sharePDF = async() => {

   
      const x = await Stampe.getInstance().reportConvocazione(selAttivita,listaGruppiSQ);
     //   const x = await reportConv();
        //console.log(x);
  
      var doc = new jsPDF();
      // Source HTMLElement or a string containing HTML.
      doc.html(x, {
          callback: function(doc) {
              try {
                const file =new File ([doc.output('blob')],"Convocazione.pdf", {type:"application/pdf"});
                navigator.share({title: "Convocazione",  files: [file]               }               );
                 // The data was shared successfully.
                 
        console.log("The file was successfully shared");
              } catch (e) {
                // The data could not be shared.
                console.error(`Error: ${e}`);
                console.error(`The file could not be shared: ${e}`);
              }
          },
          x: 15,
          y: 15,
          width: 170, //target width in the PDF document
          windowWidth: 650 //window width in CSS pixels
      });
    }
  

  const getListaSQ = async () => {


    try {

      var json = await restGruppi.getInstance().getGruppi();
      setListaGruppiSQ(json);

    } catch (error) {
      console.error(error);
      setListaGruppiSQ([]);
    } finally {

    }
  }

  const getDesGruppi = (parr) => {


    var outdes = "";

    if (listaGruppiSQ) {  //serve diritto gruppi
      listaGruppiSQ.forEach(ele => {
        if (parr.includes(ele._id)) {
          outdes = outdes.concat(ele.des, ' ');
        }

      });
    }
    return outdes.trim();

  }


/*
  let reportConv = async (ss) => {
    var logov = logo;
    return new Promise((resolve, reject) => {

      var outconv = '';
      var outconvstaff = '';
      var outintatt = '';
      var outritrovo = '';
      var outnote = '';
      var nco = 1;
      var texthtml;

      var strsq = getDesGruppi(selAttivita.gruppi);
      outconv = '<table style="width:100%;border:0"> '
      selAttivita.gioc.sort((ele1, ele2) => ele1.Giocatore[0].cognome.localeCompare(ele2.Giocatore[0].cognome)).forEach((ele, pos) => {
        if (ele.idgioc != undefined && ele.convocato) {
          outconv = outconv + '<tr style="border:0;background-color:' + (nco % 2 ? '#e1f2f9' : '#ffffff') + ';">   <td  style="padding:5px 10px 5px;border:0;width:15px;maxwidth:15px;text-align: right;">' + (nco++) + '</td> <td style="border:0;text-align: left;">' + ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome + '</td></tr>';
          //console.log(outconv);       
        }
      });
      outconv = outconv + '</table>'
      //if (selAttivita.staff.size>0)
      // {
      selAttivita.staff.forEach(ele => {
        if (ele.idgioc != undefined && ele.convocato) {
          outconvstaff = outconvstaff + ele.Giocatore[0].cognome + ' ' + ele.Giocatore[0].nome + ' ' + (ele.ruolo ? '(' + ele.ruolo.split('#')[2] + ')' : '') + '<br/>';
          // console.log(outconvstaff);
        }
      });
      //  }
      if (selAttivita.tipo && selAttivita.tipo !== 'undefined' && selAttivita.tipo.split('#')[1] === 'P') {
        outintatt = `
          <tr>
        <tr  style='border:none'>
        <td style="font-size:13pt;text-align:left;background-color:#e1f2f9;padding:20px;border:none">
        Data: <b/>`+ moment(selAttivita.dataAttivita).format('DD/MM/YYYY') + `</b><br/>
        Evento: <b>`+ (selAttivita.TipoPartita && selAttivita.TipoPartita !== undefined ? elenchi.getInstance().getElTipoPartita().find((ele) => ele.value == selAttivita.TipoPartita).label : '&nbsp;') + `</b></br/>
        Avversario: <b/>`+ (selAttivita.trasferta ? selAttivita.avversario + ' - Vigor Milano' : 'Vigor Milano - ' + selAttivita.avversario) + `</b/>
        <br/>
        Indirizzo: <span >`+ selAttivita.indirizzo + `</span>
        `+ (selAttivita.RitrovoIndirizzo ? '<br/>Indirizzo ritrovo:' + selAttivita.RitrovoIndirizzo : '') + `
        </td>
        <td style='border:none;background-color:#e1f2f9;'>
        
        <b>Ora Ritrovo</b>
        <div style='background-color:#0974ba;font-weight:700;color:white;padding:10px'>`+ (moment(selAttivita.RitrovoDataOra).format('DD/MM/YYYY') === moment(selAttivita.dataAttivita).format('DD/MM/YYYY') ? moment(selAttivita.RitrovoDataOra).format('HH:mm') : moment(selAttivita.RitrovoDataOra).format('DD/MM/YYYY HH:mm')) + `</div>
        <b>Ora Partita</b>
        <div style='background-color:#0974ba;font-weight:700;color:white;padding:10px;margin-bottom:10px'>`+ moment(selAttivita.dataAttivita).format('HH:mm') + `</div>
        </td>
    
        </tr>`;

      }
      else if (selAttivita.tipo && selAttivita.tipo !== 'undefined') {
        outintatt = `
          <tr  style=''>
          <th  style='width:75%'>
           `+ selAttivita.tipo.split('#')[2] + `
           <br/>
        <span style='color:gray'>`+ selAttivita.indirizzo + `</span>
          </th>
         
          <th style='background-color:#0974ba;font-weight:700;color:white;padding:10px'>
         
         
          `+ moment(selAttivita.dataAttivita).format('DD/MM/YYYY HH:mm') + `
          </th>
      
          </tr>`;
      }

      if (selAttivita.note) {
        outnote = `<tr style='height:20px;border:none'>
<th colspan='2'>
Note
</th>
</tr>      

<tr style='height:20px;border:none'>
<td colspan='2' style='text-align:left'>
Pronti in campo all'orario indicato<br/>
`+
          selAttivita.note.replace(/(?:\r\n|\r|\n)/g, '<br/>')
          + `
</td>
</tr>
`
      }

      texthtml = `<html>
      <head>
    <style type="text/css">
      h1 { color:#F1F1F1 }
      table,th, td {
        border: 0.5px solid #cccccc;
        border-collapse: collapse;
        padding-left:5px;
        padding-right:5px;
        font-size:12pt;
        text-align:center;
      }
    </style>
    </head>
      
      <body style='text-align:center;'>
      <div style="height:100%;display:block;" >
      <div style="width:100%;display:flex;flex-direction:row">
    <div style="width:60%;">
      <img 
      src=" `+
        logov
        + `" 
      style="height:150px;float:left;width:auto;object-fit:contain;" />
    </div>
    <div style='float:right;background-color:transparent;width:40%;text-align:left;'>
      <h2>A.S.D. Vigor Milano</h2>
      Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
      Via IV Novembre 72, Cormano (MI)<br/>
      tel: 392 985 88 51 <br/>
    </div>
    
      </div>
    <h1 style='color:black'>Convocazione: `+ strsq + ` </h1>
    
    <table style='width:98%; table-layout: auto;margin: 0 auto;text-align:center>
    `+ outintatt + `    
   
    <tr style='height:20px;border:none'>
        </tr>
   
     <tr style='height:20px;border:none'>
      <th colspan='2'>
      Giocatori Convocati
      </th>
    </tr>
    <tr>
    <td colspan=2 style='text-align:left;padding:10;will-change: transform;`+ (nco > 30 ? 'column-count:2;' : nco > 15 ? 'column-count:2;' : 'column-count:1') + `'>
   
    `+
        outconv
        + `
      </td>
      </tr>
      <tr style='height:20px;border:none'>
      <th colspan='2'>
      Staff
      </th>
    </tr>
    <tr>
    <td colspan=2 style='text-align:left;padding:10;column-count:2;' >
    
 `+

        outconvstaff

        + `
      </td>
      </tr>
      `+

        outnote

        + `


    
    </table>
    
    
    
    
    
    </div>
      </body>
      <html>`;

      resolve(texthtml);

    });

  }
*/


  const menuitemRenderer = (item) => (
    <div className='p-menuitem-content'>
        <a className="flex p-menuitem-link" style={{justifyContent:'space-between'}}>
        
            <span style={{flex:3}}>{item.label}</span>
            
            <Icon style={{flex:1}}path={mdiPrinter} size={0.8} 
            onClick={item.az1}
            />  
            <Icon style={{flex:1}}path={mdiShareVariant} size={0.8} 
             onClick={item.az2}
            />  
            
        </a>
    </div>
);
let items = [
   
    {
        label: 'Stampa',
        items: [
            {
                label: 'Convocazione',
                
                az1:()=>{printPDF()},
                az2:()=>{sharePDF()},
                template: menuitemRenderer,
                
            },
           
              {
                  label: 'Lista torneo',
                  az1:()=>{createListatorneoPDF()},
                  az2:()=>{},
                  template: menuitemRenderer,
                  
              },
              {
                label: 'Stampa altro',
                az1:()=>{},
                az2:()=>{},
                template: menuitemRenderer,
                
            },
        ]
    }
    
   
];



  const renderItem = (item, index ) => (
    <div className='listaItem' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' ,width:'100%'}}
      >
        {item && item.Giocatore && item.Giocatore[0] &&
          <div style={{display: 'flex', minWidth: '100%', width:'95%',flex: 5, flexDirection: 'row',  padding: 0 }}
          >

            <div style={{ flexDirection:'column',display:'flex' ,justifyContent:'flex-start',flexBasis:'40%' ,flexShrink:0,flexGrow:0 }}      >
              <label  >{item.Giocatore[0].cognome} {item.Giocatore[0].nome}</label>
              <label >{new moment(item.Giocatore[0].datanascita).format('DD/MM/YYYY')}</label>
            </div>


            <div style={{ flexShrink:0,flexGrow:0,  alignItems: 'center', alignItems: 'center',flexDirection:'column',display:'flex' ,justifyContent:'center',flexBasis:'10%' }}       >
              <Checkbox style={{ flex: 1}} checked={item.convocato}

                onChange={(e) => { let x = { ...selAttivita }; item.convocato = !item.convocato; setSelAttivita(x); setDaSalvare(true); }}
              ></Checkbox>
            </div>

            <div style={{flexShrink:0,flexGrow:0,  alignItems: 'center', alignItems: 'center',flexDirection:'column',display:'flex' ,justifyContent:'center',flexBasis:'10%'}}      >
              <Checkbox style={{ flex: 1}} checked={item.presente}

                onChange={(e) => { let x = { ...selAttivita }; item.presente = !item.presente;item.causaassenza = ""; setSelAttivita(x); setDaSalvare(true); }}
              ></Checkbox>
            </div>

  
            <div style={{ flexShrink:0,flexGrow:0,display:'flex' ,flexDirection:'row',flexWrap:'wrap',flexBasis:'40%' }}>

              {item && !item.presente &&
                              <div style={{ display: 'flex', flexDirection: 'column', margin: '0' }}>
                  <label style={{ flex: 1, margin: "0rem 0.3rem" ,fontSize:'0.8rem'}}>Causa</label>  
                <Dropdown

                  options={elenchi.getInstance().getElCausePres()}
                  optionLabel="label"
                  optionValue="value"
                  searchPlaceholder="Cerca..."
                  value={item.causaassenza}
                  onChange={val => { let x = { ...selAttivita }; item.causaassenza = val.value; setSelAttivita(x); setDaSalvare(true); }}
                /></div>

              }
            
               {selAttivita.tipo.split('#')[1] == 'P' && item.presente &&   
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0.1rem' }}>
                  <label style={{ flex: 1, margin: "0rem 0.3rem" ,fontSize:'0.8rem'}}>Min. giocati</label>   
                 <InputNumber
            showButtons inputStyle={{ width: "3rem", textAlign: 'center' }} style={{margin:0}}

            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
            value={item.mingiocati}
            onChange={val => { let x = { ...selAttivita }; item.mingiocati = val.value; setSelAttivita(x); setDaSalvare(true); }}
            /> </div>     }
                                 


             { item.presente &&
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '0' }}>
                  <label style={{ flex: 1, margin: "0rem 0.3rem" ,fontSize:'0.8rem'}}>Ritardo</label>
                <InputNumber 
            showButtons inputStyle={{ width: "3rem", textAlign: 'center' }} style={{margin:0}}
            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
            value={item.ritardo}
            onChange={val => { let x = { ...selAttivita }; item.ritardo = val.value; setSelAttivita(x); setDaSalvare(true); }}     />  
</div>

         }                  
          </div>
          </div>
        }
      </div>

    

  )


  const torna_att = () => {
   console.log('torna att',props.gruppo);
      navigate('/DettAttivita', {state:{ gruppo: props.gruppo  , selAttivita:selAttivita }     }  )

      }




  const esci = () => {
   
    if (props.gruppo)
      navigate('/TeamPage', {state:{ gruppo: props.gruppo  ,pagina:'Attivita' }     }  )
    
      else navigate("/attivita");
    
    
      }


  const chiudip = () => {
    setRicVisible(false);
  }

  return (


    <div style={{ flex: 2 }}    >
            <Toast ref={toast} />

      <div style={{ flex: 1, width: '100%' }}>
        {selAttivita &&
          <DataView className='giocatt-p-dataview'
          
            header=
            {<div>
              <div style={{ flex: 2, flex:'display',backgroundColor:'white'}}
              >
                <div style={{ flex: 1, justifyContent: 'flex-start' ,padding:'0.5rem'}}     >
                  <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}     >
                    <label h4 style={{ flex: 1, fontWeight: 'bold' }}>
                      {selAttivita.tipo.split('#')[2]}</label>
                    {selAttivita.tipo.split('#')[1] == 'P' &&
                      <label h4 style={{ flex: 3, fontWeight: 'bold' }}>{selAttivita.avversario}</label>}
                  </div>
                  <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}     >
                    <label style={{ flex: 3 ,fontWeight:'normal'}}>{moment(selAttivita.dataAttivita).format('DD/MM/YYYY HH:mm')}</label>
                    <label style={{ flex: 3 ,fontWeight:'normal',marginLeft:'1rem'}}>{moment(selAttivita.dataFineAttivita).format('DD/MM/YYYY HH:mm')}</label>
                  </div>
                  <div style={{ flex: 1 }}>
                    {selAttivita && selAttivita.indirizzo.trim() != '' && <label style={{ flex: 3 ,fontWeight:'normal'}}>{selAttivita.indirizzo}</label>}
                    {selAttivita.note.trim() != '' && <label style={{ flex: 3 ,fontWeight:'normal'}}>{selAttivita.note}</label>}
                  </div>
                </div>
                <div className='listazioni'>
                  <label h3 style={{ color: '#00adef' }}>Giocatori</label>
                  {daSalvare &&
                    <Button
                      onClick={() => {
                        restAttivita.getInstance().modifica(selAttivita, selGruppo).then(() => {
                          setDaSalvare(false);
                        })
                      }}
                    >
                      <Icon path={mdiContentSaveAll} size={0.8} />
                    </Button>
                  }
                  <Button
                    onClick={() => { creaListaGiocAtt(); setDaSalvare(true); }}
                  >
                  <Icon path={mdiAccountMultiplePlus} size={0.8} />
                 </Button>              

                  <Button
                    onClick={() => { toggleOverlay(); }}
                  >  
                  <Icon path={mdiAccountPlus} size={0.8} />
                  </Button>
                  <Button
                    onClick={() => { props.cambiaPagina(); }}
                  >
                    <Icon path={mdiAccountTie} size={0.8} />

                  </Button>
                  {(!daSalvare) &&
                    <Button
                      onClick={(event) => menuPrint.current.toggle(event)}

                    >               <Icon path={mdiPrinter} size={0.8} />
                    </Button>
                  }
                  
                  <Button
            onClick={() => { torna_att() }}
          >
            <Icon path={mdiCheckboxMarkedOutline} size={0.8} />
          </Button>    
          <Button
            onClick={() => { esci() }}
          >
            <Icon path={mdiCalendarMultipleCheck} size={0.8} />
          </Button>
                </div>
              </div>




         <div style={{ minWidth: '100%', display:'flex', flexDirection: 'row', justifyContent: 'center', marginHorizontal: 0, padding: 0,backgroundColor: 'white',
          
           }}
          >

            <div style={{display:'flex',flexBasis:'40%' ,flexGrow:0,flexShrink:0 }}      >
            </div>

            <div style={{ flexShrink:0,flexGrow:0,flexDirection:'column',display:'flex', alignItems: 'center',justifyContent:'center',flexBasis:'10%' }}      >
              <label style={{ flex: 1,maxHeight:datiGen.getInstance().scala(30), }}
              >
                Conv.
                </label>
                <Button
                  onClick={() => { selAttivita.gioc.forEach(ele => { let x = { ...selAttivita }; ele.convocato = true; setSelAttivita(x); setDaSalvare(true); }) }}
                >
              <Icon path={mdiAccountMultipleCheck}  size={0.8} />
                </Button>

              
            </div>
            <div style={{ flexShrink:0,flexGrow:0, minWidth: datiGen.getInstance().scala(35), alignItems: 'center',flexDirection:'column',display:'flex' ,justifyContent:'center' ,flexBasis:'10%'}}      >
              <label 
              >
                Pres.
                </label>
                <Button
                onClick={() => { selAttivita.gioc.forEach(ele => { let x = { ...selAttivita }; ele.presente = true; setSelAttivita(x); setDaSalvare(true); }) }}

             >
              <Icon path={mdiAccountMultipleCheck}  size={0.8} /></Button>

            </div>



            <div style={{flexShrink:0,flexGrow:0,flexBasis:'40%' }}      >
        

                
            </div>

       
        

          </div>







</div>





            }
            value={selAttivita.gioc}
            itemTemplate={renderItem}
          />
        }
      </div>



      <Dialog visible={ricVisible} onHide={() => {if (!ricVisible) return; setRicVisible(false); }}>    

          <div style={{ flex: 1 }}>
            <label h4 style={{ borderBottomWidth: 1, borderBottomColor: '#0a568d', paddingBottom: 10 }} >Ricerca</label>         
          </div>
          <div style={{ flex: 5, width: '100%' }}>
          <RicercaPersone style={{ flex: 10 }} selGioc={selgioc} gruppo={props.gruppo}     chiudi={()=>setRicVisible(false) }         />
          </div>
      </Dialog>







      <div className="card flex justify-content-center">
            <Menu model={items} className="w-full md:w-15rem" popup ref={menuPrint}/>
        </div>






    </div>

  )
}
export default GiocAtt;