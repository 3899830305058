// screens/Home.js

import React, { Component, useEffect, useState,useRef } from 'react';
import datiGen from '../util/datiGen.js';
import moment from 'moment'

import restGiocGruppi from '../restcall/restGiocGruppi.js';
import restGruppi from '../restcall/restGruppi.js';
import gruppo from '../obj/gruppo.js';
import { useNavigate, useLocation, Link } from "react-router-dom";
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import { DataView } from 'primereact/dataview';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer,mdiContentSaveAll,mdiCloseBox,
  mdiCheckAll,mdiTagEdit,
  mdiAccount,
  mdiClipboard,
  mdiClipboardPlus,
  mdiPencil

} from '@mdi/js';


const StaffTeam = (props) => {
  const navigation = useNavigate();

  const abilDettAnag = datiGen.getInstance().possoaccederev2('ANAGDETT', props.gruppo);
  const abilSCHGIOC = datiGen.getInstance().possoaccederev2('schedaGioc', props.gruppo) || datiGen.getInstance().possoaccederev2('SCHGIOC_UI');
  const abilGIOCGRUPPO_ASS = datiGen.getInstance().possoaccederev2('GIOCGRUPPO_ASSOCIA');
  const [datastaff, setDataStaff] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [grp, setsetGrp] = useState(() => props.gruppo);
  const [abilEdit, setAbilEdit] = useState(false);
  const [showdialog, setShowDialogo] = useState(false);
  const [daSalvare, setDaSalvare] = React.useState(false);
  const [giocVal, setGiocVal] = useState(true);
  const toast = useRef(null);
  const [isLoading, setLoading] = useState(true);

  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo, life: 3000 });
  }
  const showWarn = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: testo, life: 3000 });
  }
  const showError = (testoerr) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: testoerr, life: 3000 });
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };






  const getDettGruppo = async (idg) => {
    // console.log('getDettGruppo', idg, props.gruppo)
    try {
      restGruppi.getInstance().getGruppo(idg).then((json) => { setdettGruppo(json); });


    } catch (error) {
      console.error(error);
    } finally {

    }
  }
  const [dettGruppo, setdettGruppo] = React.useState(() => getDettGruppo(props.gruppo));

  const getListaGioc = async () => {

    try {
      setLoading(true);

      restGiocGruppi.getInstance().getGiocByIdGruppo(grp, 'STAFF', giocVal).then((json) => { setDataStaff(json); })


    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

 ////// aggiungere daSAlvare gestione





  const renderItem = (item, index ) => (
    <div className='listaItem' style={{ margin: '0', padding: "0" }} >
   
        {item.Giocatore[0] &&
 <div style={{ flex: 2, flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' ,marginLeft:'0.2rem'}}>
        <div style={{ flexDirection: 'column', display: "flex" }}> 
         <label style={{fontWeight:'bold'}} >{item.Giocatore[0].cognome} {item.Giocatore[0].nome} </label>
                <label style={{ color: "gray" }} >{new moment(item.Giocatore[0].datanascita).format('DD/MM/YYYY')}</label>
                <label style={{ color: "gray" }}>{item.ruolo ? item.ruolo.split('#')[2] : ''}</label>        
        </div>   
          
                    <div  classname='horizFlexcontainer'  >
              <Button style={{}}
                onClick={() => { setAbilEdit(!abilEdit); setSelectedItem(item); props.selgiocatore(item) ;props.selpagina('TeamStaffDett')    }}
              >
                <Icon path={mdiPencil} size={0.8}/>
              </Button>
              {abilDettAnag &&
                <Button       
                

                  onClick={() => navigation('/DettPersone', { state:{ selpersona: item.Giocatore[0],gruppo:props.gruppo }})  }

                >
                  <Icon path={mdiAccount}  size={0.8}/>
                </Button>
              }
          
            </div>

          </div>
        }
    </div>
  )



  useEffect(
    React.useCallback(() => {
      getListaGioc();
      dettGruppo.__proto__ = gruppo.prototype;
    }, [props.gruppo, daSalvare, selectedItem, giocVal])
  );

  



  return (

    <div style={{
      flex: 10,
      alignItems: 'center',
      width: '100%',
      flexShrink: 1,
      flexGrow: 100,
      justifyContent: 'flex-start',
      alignContent: 'flex-end'
    }}>

      <div style={{ flex: 1 ,margin:'0.5rem',justifyContent:'center',alignItems:'center',justifyItems:'center',width:'100%'}}>
        <label className='Titolo5' style={{ flex: 1,textAlign:'center',width:'100%'}} >{dettGruppo.des} </label>

           </div>





      <label style={{ flex: 1, width: '100%', flexGrow: 90 }}></label>

        <DataView
            header=
          {<div classname='listazioni' style={{display:'flex', flexDirection:'row'  ,placeContent:'flex-end space-between'}}>
            <label style={{ color: '#00adef', fontSize: 26, alignSelf: 'center' }}> Rosa</label>
           
                     <div  classname='horizFlexcontainer'  >

           
            <Button
              onClick={() => getListaGioc()}
            
              >
              <Icon  path={mdiUpdate} size={0.8} />
          </Button>


            <Button
              onClcik={() => { setGiocVal(!giocVal); }}
              >
              <Icon  path={mdiCheckAll} size={0.8} />
          </Button>

</div>
          </div>}
      

          value={datastaff}
          itemTemplate={renderItem}


        />

      </div>
    
  );
}
export default StaffTeam;