import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import DettaglioPersona from "../comp/DettaglioPersona";
import Recapiti from "../comp/Recapiti";
import Tesseramenti from "../comp/Tesseramenti";
import DOC from "../comp/DOC";
import Pagamenti from "../comp/Pagamenti";
import Listarecapiti from "../comp/Listarecapiti";
import ListaVisite from "../comp/ListaVisite";
import ListaTesseramenti from "../comp/ListaTesseramenti";
import DettaglioAttivita from "../comp/DettaglioAttivita";
import TabelinoPartita from "../comp/TabellinoPartita";
const DettAttivita = () => {
  const location = useLocation();
  const [azione, setAzione] = useState(0); // 0 = Attivita, 1 = Tabellino, 2 = Visite


  useEffect(() => {
   console.log('useeffect pag DettAttivita',location.state);
if (location.state.azione)
{   setAzione(location.state.azione);
console.log('dentro if--------------------',location.state.azione)
}
 else setAzione(0);



  },
    []
  );

  return (
    <div>
      
      {azione == 0 &&
        <DettaglioAttivita selAttivita={location.state.selAttivita}  updateAzione={setAzione} gruppo={location.state.gruppo}  />
      }
      {azione == 1 &&
     <TabelinoPartita selAttivita={location.state.selAttivita}  updateAzione={setAzione} gruppo={location.state.gruppo}     />
      }
      {azione == 2 &&
       <span>Azione 2</span>
      }
      {azione == 3 &&
       <span>Azione 3</span>
      }
      {azione == 4 &&
       <span>Azione 4</span>
      }
      {azione == 5 &&
        <span>Azione 5</span>
      }
    </div>
  );
};

export default DettAttivita;
