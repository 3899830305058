// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Live from '../comp/live';
import ReportisticaTest from './ReportisticaTest';
import restAttivita from '../restcall/restAttivita';

const LivePage = () => {
  const navigation = useNavigate();
  const location=useLocation();
  const [gruppop, setGruppop] = useState(() => location.state.gruppo);


  useEffect(() => 
  { 
  setGruppop(location.state.gruppo); 
 
});






  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'

      }}>
<label>Live Page</label>


<Live />
    </div>
  );
} 
export default  LivePage   ;