// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';

import moment from "moment";
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import restSchedaGioc from '../restcall/restSchedaGioc';
import { Button } from 'primereact/button';
import DettaglioSchedaGioc from '../comp/DettaglioSchedaGioc';
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
mdiAccount
} from '@mdi/js';

const DettSchedaGioc = (props) => {
  const navigation = useNavigate();
  const location=useLocation();
  const [selSchedaGioc, setSelSchedaGioc] = useState(() => location.state.selschedagioc);

  useEffect(() => { 
    // console.log("DettSchedaGioc",'USE useFocusEffect'); 
     setSelSchedaGioc(location.state.selschedagioc);
  // console.log("DettSchedaGioc",'USE useFocusEffect',selSchedaGioc);
  });
 




  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
      }}>
        {selSchedaGioc && (
    <div style={{  justifyContent:'space-between' ,flexDirection:'row',width:'100%',minHeight:30,display:'flex',alignItems:'center'}}>
    <label style={{marginLeft:'0.8rem'}} >{selSchedaGioc.Giocatore[0].cognome} {selSchedaGioc.Giocatore[0].nome} </label>
    <Button
        onClick={() => navigation('/DettPersone', { state:{ selpersona: selSchedaGioc.Giocatore[0] }})}
      >
        <Icon path={mdiAccount} size={0.8}/>
      </Button>
    <label style={{textAlign:'right',marginRight:'0.8rem'}} > {moment(selSchedaGioc.Giocatore[0].datanascita).format('DD/MM/YYYY')}</label> 
    </div>)}
    {selSchedaGioc && (
          <div style={{justifyContent:'space-between' ,flex:20,width:'100%'}}>  
          <DettaglioSchedaGioc selSchedaGioc={selSchedaGioc} gruppo={location.state.gruppo} />         
          </div> )}
    </div>
  );
} 
export default DettSchedaGioc;