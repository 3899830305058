import moment from 'moment'

const ProfiloUtentePrototype = {

  to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
    if (this.dtPrivacy && typeof this.dtPrivacy == 'string') {
      this.dtPrivacy = new Date(this.dtPrivacy);
    }
    if (this.dtNonDivulgare && typeof this.dtNonDivulgare == 'string') {
      this.dtNonDivulgare = new Date(this.dtNonDivulgare);
    }
   
   
  },

  greet() {
    console.log(`hello, my name is ${this.nome}!`);
  },
  gnome() {
    return this.nome;
  }

,

isAttivo() {
  const adesso = new moment();
  let out = false;
  if (moment(this.ival).isSameOrBefore(adesso) && moment(this.fineval).isSameOrAfter(adesso))
   {
      out = true; 
    }
  return out;
}

}


function ProfiloUtente() {
  this.id = Date.now();
  this.idsoc = "##";
    this.idutente = "";
    this.displayName = "";
    this.email = "";
    this.ruoli = [];
  //aggiungere gruppi squadra con relativi ruoli di accesso
  //aggiungere gruppo squadra predefinito
this.ruoliGruppiSquadra=[];
this.gruppoSQPred="##";
this.gruppoSQPred2="##";
  
this.idpersona="";    //idpersona da associare in ricerca per notifiche e assegnazione gruppi, soprattutto per staff

this.msg_token=[];
  this.ival = new Date();
  this.fineval = moment().add(7, 'years').toDate();
  this.note = "";
  
  //dtPrivacy         aggiunte alla conferma dell'utente
  //dtNonDivulgare   aggiunte alla conferma dell'utente
  this.__proto__ = ProfiloUtente.prototype;
}

ProfiloUtente.prototype = ProfiloUtentePrototype;
ProfiloUtente.prototype.constructor = ProfiloUtente;






export default ProfiloUtente;