// screens/Home.js
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";

import { useState,useEffect ,useRef} from "react";

import Icon from '@mdi/react';
import { mdiContentSave,mdiAccount ,mdiDetails, mdiPlus, mdiNotePlusOutline, mdiListBox, mdiDelete } from '@mdi/js';
import moment from "moment";
import { Toast } from 'primereact/toast';
import {  confirmDialog } from 'primereact/confirmdialog';
import { Button } from "primereact/button";
import { useOutletContext } from "react-router-dom";
import  Person  from '../obj/person.js' 
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import { DataView } from "primereact/dataview";
import Ordine from "../obj/Ordine.js";
import restOrdini from "../restcall/restOrdini.js";
import DettOrdini from "./DettOrdini.js";
import elenchi from "../util/elenchi.js";
import datiGen from "../util/datiGen.js";
import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
const ListaOrdini = (props) => {
  const navigate = useNavigate();
  const [person,setPerson] = useState();
  const [ListaOrdini, setListaOrdini] = useState([]);
  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();
  const [mostraDett,setMostraDett]=useState(false);
  const [selectItem,setSelectItem]=useState();
  const [daSalvare, setDaSalvare] = useState(false);
  const [dvisible, setDVisible] = useState(false);
  const [categoriasel, setCategoriasel] = useState();


  const abilOrdini = datiGen.getInstance().possoaccederev2('ORDINI', props.gruppo);
  const abilGestOrdini = datiGen.getInstance().possoaccederev2('GESTIONEORDINI', props.gruppo);


  useEffect(() => {
    console.log('modifco 0');
    if (daSalvare) {
      let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 6, '1');
      setStatoDaSalvare(x);
    } else if (!daSalvare) {
      let x = statoDaSalvare.replaceAll("1", "0");
      setStatoDaSalvare(x);
    }
  }, [daSalvare]);


  useEffect(() => {
    if (props.selPersona){
      let tmp_person = props.selPersona;
      tmp_person.__proto__ = Person.prototype;
      tmp_person.to_obj(); 
      setPerson(tmp_person);
      getOrdini(tmp_person);
    }
  }, []);
  const toast = useRef(null);
  const showSuccess = (titolo, testo) => {
    toast.current.show({severity:'success', summary: titolo, detail: testo, life: 3000});
  }
  const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
  }
  const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
  }
  const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };



  const confirmElimina = (pordine) => {

    confirmDialog({
      message: "L'oridne sarà eliminato definitivamente. Sei sicuro di voler procedere?",
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => { elimina(pordine); }),
      reject: (() => { return; }),
    });
  };


 const elimina = async (pordine) => {
  try {
    console.log('elimina iniziao');
    var json = await restOrdini.getInstance().elimina(pordine,props.gruppo);
    console.log('elimina dopo');
      setDaSalvare(false);
      showSuccess("Salvataggio eseguito", "L'ordine è stata eliminato");
      //let x = statoDaSalvare.replaceAll("1", "0"); 
      //setStatoDaSalvare(x);
    
  } catch (error) {
    console.error(error);
    showError("Errore durante il salvataggio per eliminare l'ordine", error);
  } finally {
  }
    getOrdini(person);
  }


  const renderItem = (item, index) => (
    <div className='listaItem'>
                <div style={{ flexDirection: 'row',  display:'flex',justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', alignItems: 'center'}}
                 onClick={() => {setSelectItem(item);  }}
                >
                <div className="dati" style={{width:'18%'}}   onClick={() => {mostradett()  }}>
                      <label>Categoria</label>
                      <label>{elenchi.getInstance().getEl("CATORDINE").find((el)=>el.value==item.categoria)?elenchi.getInstance().getEl("CATORDINE").find((el)=>el.value==item.categoria).label:''  }</label>
                    </div>
                  
                    <div className="dati" style={{width:'20%',marginLeft:'0.2rem'}}  onClick={() => {mostradett()  }}>
                      <label>Data ordine</label>
                      <label>{moment(item.dataOrdine).format('DD/MM/YYYY')}</label>
                    </div>
                     
                    <div className="dati" style={{width:'15%',marginLeft:'0.2rem'}}  onClick={() => {mostradett()  }}>
                      <label>Stato</label>
                      <label>{elenchi.getInstance().getEl("STATOORDINE").find((el)=>el.value==item.stato).label  }</label>
                    </div>
                  
                
                    <div className="dati" style={{width:'10%',marginLeft:'0.2rem',  textAlign:'right',marginRight:'0.2rem'   }} onClick={() => {mostradett()  }}>
                      <label>Totale</label>
                      <label>{item.totale}</label>
                    </div>

            
                    <Button 
                       onClick={() => {setSelectItem(item);   mostradett()}}
                                 >
                     <Icon path={mdiNotePlusOutline}  size={0.8} />
                                   </Button>        <Button 
                       onClick={() => {confirmElimina(item._id);   }}
                                 >
                     <Icon path={mdiDelete}  size={0.8} />
                                   </Button>

</div> 
    </div> 
    

  )


  const mostradett = () => {
  setMostraDett(true);

  }


  const salvaAgg = () => {
  
    setDaSalvare(true);
  
  }




  const aggiungi = async () => {

    let t = new Ordine();
    t.categoria=categoriasel;
  t.cognomeNomePersona=person.cognome+' '+person.nome;
  t.idPersona=person._id;
t.codice=datiGen.getInstance().creaID(   elenchi.getInstance().getEl("CATORDINE").find((el)=>el.value==categoriasel).label.replaceAll(' ','').substring(0,4) );
t.descr=elenchi.getInstance().getEl("CATORDINE").find((el)=>el.value==categoriasel).label;
t.opeIns=datiGen.getInstance().getUtenteCollegato().displayName;
t.opeUltmod=t.opeIns;
t.idsoc=datiGen.getInstance().getActiveSoc().sigla;
if (t.categoria==elenchi.getInstance().getEl("CATORDINE").find((el)=>el.label=='Iscrizione').value)
{var righeo=[];
  var riga={codice:elenchi.getInstance().getEl("TIPORIGAORDINE").find((el)=>el.label=='Iscrizione').value,
             descr:elenchi.getInstance().getEl("TIPORIGAORDINE").find((el)=>el.label=='Iscrizione').label,
    quantita:1,
    importo:elenchi.getInstance().getEl("TIPORIGAORDINE").find((el)=>el.label=='Iscrizione').prezzo,
    note:''};
  righeo.push(riga);
}
t.righe=righeo;
t.stato='01'

    inserisci(t);
    
    getOrdini(person);
  }



  const aggiungiriga = async (ordine) => {
if (!ordine.righe) ordine.righe=[];
var riga={codice:'',
             descr:'',
 quantita:1,
 importo:0,
note:''};
ordine.righe.push(riga);

setDaSalvare(true);
}

    
    

  const getOrdini = async (paraPerson) => {
    var json = await restOrdini.getInstance().getOrdiniPersona(paraPerson, 2024); 
    if(json && json.length) 
      {
        setListaOrdini(json);
      } else {
        setListaOrdini({});
      }
  }


  const salva = async (pordine) => {
    try {
      console.log('salva ordinr','utente',datiGen.getInstance().getUtenteCollegato().displayName);
      pordine.opeUltmod=datiGen.getInstance().getUtenteCollegato().displayName;
      
      var json = await restOrdini.getInstance().modifica(pordine,props.gruppo);
      if(json && json.length == 0 ){
        showWarn("Attenzione", "Nessuna riga è stata aggiornata");
      }else{
        setDaSalvare(false);
        showSuccess("Salvataggio eseguito", "La modifica è stata salvata");
        //let x = statoDaSalvare.replaceAll("1", "0"); 
        //setStatoDaSalvare(x);
      }
    } catch (error) {
      console.error(error);
      showError("Errore durante il salvataggio", error);
    } finally {
    }
  }


  const inserisci = async (pordine) => {
    try {
      var json = await restOrdini.getInstance().aggiungi(pordine,props.gruppo);
      if(json && json.length == 0 ){
        showWarn("Attenzione", "Nessuna riga è stata aggiornata");
      }else{
        setDaSalvare(false);
        showSuccess("Salvataggio eseguito", "La modifica è stata salvata");
        //let x = statoDaSalvare.replaceAll("1", "0"); 
        //setStatoDaSalvare(x);
      }
    } catch (error) {
      console.error(error);
      showError("Errore durante il salvataggio", error);
    } finally {
    }
  }


  const updateOrd = (pordine) => 
    {
  setSelectItem(pordine) ; 
}


    return (
      <div>
        
          <Toast ref={toast} />

    <Dialog header="" visible={dvisible} style={{ width: '50vw' }} onHide={() => {if (!dvisible) return; setDVisible(false); }}>
    <div>
<label className='Titolo5'>Scegli la categoria</label>

    <SelectButton value={categoriasel} onChange={(e) => setCategoriasel(e.value)} optionLabel="label" options={elenchi.getInstance().getEl("CATORDINE")}  />

    </div>
           <button className="p-button p-component">
            <Icon path={mdiPlus} size={1} onClick={() => {aggiungi()}}/>
          </button>
</Dialog>


            <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#0a568d'}}>
        {!mostraDett &&  abilOrdini &&        
          <button className="p-button p-component" onClick={() => {setDVisible(true)}}    >
            <Icon path={mdiPlus} size={1} />
          </button>
}


{mostraDett  && abilOrdini &&
          <button className="p-button p-component" onClick={() => {aggiungiriga(selectItem)}}>
                  <Icon path={mdiPlus} size={1} />
                </button>
          }



          {mostraDett && daSalvare && abilOrdini &&
          <button className="p-button p-component" onClick={() => {salva(selectItem)}}>
                  <Icon path={mdiContentSave} size={1} />
                </button>
          }
        
            {mostraDett && 
          <button className="p-button p-component" onClick={() => setMostraDett(!mostraDett)}>
                  <Icon path={mdiListBox} size={1} />
                </button>
              }

         {statoDaSalvare.includes(1) == 0 &&
          <button className="p-button p-component" onClick={() => props.updateAzione(0)}>
            <Icon path={mdiAccount} size={1}/>
          </button>
         }
         </div>
        
        
        
        <div>
          {ListaOrdini && !mostraDett &&
            <DataView value={ListaOrdini} itemTemplate={renderItem} rows={20}>
            </DataView>
          }
            {selectItem && mostraDett &&
            <DettOrdini selOrdine={selectItem} gruppo={props.gruppo}  updateOrd={updateOrd}  setDaSalvare={setDaSalvare}  >
            </DettOrdini>
          }     



          
        </div>
      </div>
       
    );
}


export default ListaOrdini;