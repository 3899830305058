
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import ListaProfili from '../comp/ListaProfili';



const ProfiliUtenti = () => {

  const navigate = useNavigate();
  
  

/*
  useEffect(() => {


  }, []);
*/

  return (
    <div>


    <div style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop:"1rem"
     
    }}>
  
  
<ListaProfili/>  
  </div>
  </div>
  );
}



export default ProfiliUtenti;
