// screens/Home.js
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState,useEffect ,useRef} from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from 'primereact/dialog';
import { CountryService } from '../util/CountryService.jsx';
import Icon from '@mdi/react';
import { mdiContentSave, mdiPlus } from '@mdi/js';
import { mdiPrinter } from '@mdi/js';
import { mdiSoccerField } from '@mdi/js';
import { mdiAccountMultiple } from '@mdi/js';
import { mdiAccountMultipleCheck } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { mdiPhone } from '@mdi/js';
import { mdiCity } from '@mdi/js';
import { mdiEmail } from '@mdi/js';
import { mdiAccount } from '@mdi/js';
import datiGen from '../util/datiGen.js'
import { useOutletContext } from "react-router-dom";
import  Person  from '../obj/person.js' 
import restPersona from "../restcall/restPersona.js";
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import Visite from "./Visite.js";
import { DataView } from "primereact/dataview";
import visita from "../obj/Visita.js";
const ListaVisite = (props) => {
  const navigate = useNavigate();
const [person,setPerson] = useState();
const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();
useEffect(() => {
  if (props.selPersona){
    let tmp_person = props.selPersona;
    tmp_person.__proto__ = Person.prototype;
    tmp_person.to_obj(); 
    setPerson(tmp_person);
    console.log(person, props)
  }
}, []);
useEffect(() => {
}, [person]);
let [daSalvare, setDaSalvare] = useState(false);
const toast = useRef(null);
const showSuccess = (titolo, testo) => {
    toast.current.show({severity:'success', summary: titolo, detail: testo, life: 3000});
}
const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
}
const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
}
const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
}
const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
};
const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
};
const salva = async (ppers) => {
  console.log(ppers, person);
  try {
    var json = await restPersona.getInstance().modifica(ppers,props.gruppo);
    setDaSalvare(false);
    showSuccess("Salvataggio eseguito", "La modifica è stata salvata");
    let x = statoDaSalvare.replaceAll("1", "0"); 
    setStatoDaSalvare(x);
  } catch (error) {
    console.error(error);
  } finally {
  }
}
const updVisita = (vis) => {
  let p = {...person};
  let x = p.visite.find((el)=>el.id==vis.id);
  let y = datiGen.getInstance().replaceCharAt(statoDaSalvare, 2, '1'); 
  setStatoDaSalvare(y);
  if(x){
    console.log('if');
    console.log(x.getScadenza());
    x.copiaVisita(vis);
    setPerson(p);
  }else{
    console.log('else');
  }
  console.log(p);
}
const renderItem = (item, index) => (
  <div className='listaItem'>
    <Visite selVisite={item} updVisita={updVisita}>

    </Visite>
  </div>
)
const salvaAgg = () => {
  setDaSalvare(true);
}
const aggiungi = async () => {
  let z = { ...person };
  let t = new visita();
  if (!z.visite)      z.visite=[];
  z.visite.push(t);
  setPerson(z);
  salvaAgg();
}
  return (
    <div>
      {person &&
        <>
          <Toast ref={toast} />
          <div style={{backgroundColor: '#0a568d', border: '1px solid #e5e7eb', padding: '0.2rem 0.2rem', fontWeight: '700', marginBottom: '1em'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#0a568d'}}>
              <button className="p-button p-component">
                <Icon path={mdiPlus} size={1} onClick={() => {aggiungi()}}/>
              </button>
              <button className="p-button p-component" onClick={() => {salva(person)}}>
                  <Icon path={mdiContentSave} size={1.2} />
              </button>
              <button className="p-button p-component" onClick={() => props.updateAzione(0)}>
                <Icon path={mdiAccount} size={1}/>
              </button>
            </div>
            <div>
              <DataView value={person.visite} itemTemplate={renderItem} rows={20}>

              </DataView>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default ListaVisite;