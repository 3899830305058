import datiGen from "../util/datiGen";
import { getAuth } from "firebase/auth";
import datiInit from "../util/datiInit";
import moment from "moment";



export default class restGiocGruppi {
  constructor(restGiocGruppi) {
   // console.log('restGiocGruppi');
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();
  restApp = 'giocgruppo';
  static myInstance = null;

  static getInstance() {
    if (restGiocGruppi.myInstance == null) {
      restGiocGruppi.myInstance = new restGiocGruppi();
      // Util.myInstance.inizializza();
   //   console.log('fine get instance');
    }
    return restGiocGruppi.myInstance;
  }

  prova() {
    console.log('restSchedaGioc','prova');
  }

  getGiocGruppi = async (pidgioc) => {
    if (!datiGen.getInstance().possoaccederev2('ANAG'))
    throw 'Non sei abilitato a svolgere la funzione ricerca visite';
    
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "?" + "idgioc=" + pidgioc
      , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
      });
      const json = await response.json();
      //console.log("restSchedaGioc","getSchedeGioc",json);
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      
    }
  }

  getGiocGruppiByIdgioc = async (pidgioc) => {
    if (!datiGen.getInstance().possoaccederev2('GIOCGRUPPO'))
    throw 'Non sei abilitato a svolgere la funzione GIOCGRUPPO';
    
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "/" +  pidgioc+"/byidgioc"
      , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
      });
      const json = await response.json();
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      
    }
  }

  getGiocByIdGruppo = async (pgruppo,ptipo,pval) => {
    //console.log('restgiocgruppi',' dentro get getGiocByIdGruppo ');
    
    if (!datiGen.getInstance().possoaccederev2('ListaGioc',pgruppo))
    throw 'Non sei abilitato a svolgere la funzione ListaGioc per il gruppo '+pgruppo;
    
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "/" +  pgruppo+"/"+ptipo+'?val='+pval
      , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
      });
      const json = await response.json();
      
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      
    }
  }


//----------------
//**** da usare solo per visite */
getVisiteGruppo = async (pgruppo) => {
    
  //console.log('restgiocgruppi',' dentro get visite gruppo ',pgruppo);
  if (!datiGen.getInstance().possoaccederev2('ANAGDETT', pgruppo))
  throw 'Non sei abilitato a svolgere la funzione Visite per il gruppo '+pgruppo;
  
  try {
    const user = getAuth().currentUser;
    var token = await user.getIdToken();

    var dtcond = "";


var data1 = new moment().add(-24,'month').toDate().toISOString();
var data2=new moment().add(+1,'month').toDate().toISOString();
//var date24 = today.getFullYear()+'-'+(today.getMonth()+2)+'-'+today.getDate();
var param='tutti';
dtcond = "&d23=" + data1+ "&d24=" +data2  ;

//pgruppo='***';



    const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "/visite/" +  pgruppo+'/'+'?'+dtcond
    , {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      },
    });
    const json = await response.json();
    json.esito='OK';
    
    return json;
  } catch (error) {
    console.error(error);
    return "KO";
  } finally {
    
  }
}


getValutazioniGruppo = async (pgruppo) => {
    
  //console.log('restgiocgruppi',' dentro get visite gruppo ',pgruppo);
  if (!datiGen.getInstance().possoaccederev2('SCHGIOC', pgruppo))
  throw 'Non sei abilitato a svolgere la funzione Valutazioni per il gruppo '+pgruppo;
  
  try {
    const user = getAuth().currentUser;
    var token = await user.getIdToken();

    var dtcond = "";


var data1 = new moment().add(-24,'month').toDate().toISOString();
var data2=new moment().add(+1,'month').toDate().toISOString();
//var date24 = today.getFullYear()+'-'+(today.getMonth()+2)+'-'+today.getDate();
var param='tutti';
dtcond = "&d23=" + data1+ "&d24=" +data2  ;

//pgruppo='***';



    const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ "/valutazioni/" +  pgruppo+'/'+'?'+dtcond
    , {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      },
    });
    const json = await response.json();
    json.esito='OK';
    
    return json;
  } catch (error) {
    console.error(error);
    return "KO";
  } finally {
    
  }
}


//-----------

  aggiungi = async (pgiocgrp) => {
    if (!datiGen.getInstance().possoaccederev2('GIOCGRUPPO'))
    throw 'Non sei abilitato a svolgere la funzione GIOCGRUPPO';

    var vgiocgrp={...pgiocgrp};
    delete vgiocgrp.Giocatore;
    delete vgiocgrp.gruppoSQ;

    
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp+ '/' + datiGen.getInstance().getActiveSoc().sigla
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(vgiocgrp)
        });
      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      // console.log("finito aggiungi");
    }
  }

  modifica = async (pgiocgrp) => {
    if (!datiGen.getInstance().possoaccederev2('GIOCGRUPPO'))
    throw 'Non sei abilitato a svolgere la funzione GIOCGRUPPO';
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var p = { ...pgiocgrp };
      delete p._id;
      delete p.Giocatore;
      delete p.gruppoSQ;
      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ '/' + pgiocgrp._id;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }


  editGiocGruppo = async (pgiocgrp,pval) => {
    //console.log('editGiocGruppo',pgiocgrp);
    if (!datiGen.getInstance().possoaccederev2('ANAGUPD',pgiocgrp.idgruppoSQ))
    throw 'Non sei abilitato a svolgere la funzione ANAGUPD';
    // console.log("Modifica",ppers,ppers.nome);ANAGUPD
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      var p = { ...pgiocgrp };
      delete p._id;
      delete p.Giocatore;
      delete p.gruppoSQ;
      const s = this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla+ '/' + pgiocgrp._id;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }

 

}