// screens/Home.js

import React, { Component, useEffect, useState, useRef } from 'react';
import Person from '../obj/person.js';
import moment from 'moment'
import datiGen from '../util/datiGen.js';
import { Rating } from 'primereact/rating';
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import elenchi from '../util/elenchi.js';
import logo from '../img/logo-vigor3.png';
import { jsPDF } from "jspdf";
import schedaGioc from '../obj/schedaGioc.js';

import Icon from '@mdi/react';
import {
  mdiSync, mdiUpdate, mdiContentSave, mdiPencil, mdiContentSaveAll,
  mdiPlus, mdiSend, mdiLayersOff, mdiSoccerField, mdiFlagCheckered,
  mdiPrinter
} from '@mdi/js';
import { InputNumber } from 'primereact/inputnumber';
import restSchedaGioc from '../restcall/restSchedaGioc.js';

const DettaglioSchedaGioc = (props) => {
  const navigation = useNavigate();
  const abilSCHGIOC = datiGen.getInstance().possoaccederev2('SCHGIOCUPD');


  const [selSchedaGioc, setselSchedaGiocatore] = useState(() => props.selSchedaGioc);
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);


  const [date, setDate] = useState(new Date(1598051730000));
  const [show, setShow] = useState(false);

  const [visible, setVisible] = useState(false);
  const [daSalvare, setDaSalvare] = React.useState(0);
  const [edtSpin, setedtSpin] = useState(false);
  const toast = useRef(null);

  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();

  var t1, t2, ti;
  var source2;

  const toggleOverlay = () => {
    setVisible(!visible);
  };


  const setselSchedaGioc = (x) => {

    setselSchedaGiocatore(x);

    salva();
  }



  useEffect(() => {
    let v = { ...props.selSchedaGioc };
    v.__proto__ = schedaGioc.prototype;
    v.to_obj();
    setselSchedaGiocatore(v);
    ///// da riprendere
  

  },
    [props.selSchedaGioc]
  );

  const salva = () => {
    let y = daSalvare;
    setDaSalvare(y + 1);
  }


  useEffect(() => {
    console.log('modifco 0');
   if(daSalvare){
    let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 4, '1'); 
    setStatoDaSalvare(x);
   }else if(!daSalvare){
     let x = statoDaSalvare.replaceAll("1", "0"); 
     setStatoDaSalvare(x);
   }
  }, [daSalvare]);

  const createPDF = async () => {
    console.log(logo);
    let a = eserep(logo);
    var doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    var HTML = a;
    doc.html(a, {
      callback: function (doc) {
        // Save the PDF
        doc.save('SchedaGioc.pdf');
      },
      x: 15,
      y: 15,
      width: 170, //target width in the PDF document
      windowWidth: 650 //window width in CSS pixels
    });
  }

 
  const eserep = (dat) => 
    {
      var logov;
        var texthtml
      logov=dat;
      texthtml=`<html>
      <head>
    <style type="text/css">
      h1 { color:#F1F1F1 }
      table,th, td {
        border: 0.5px solid #cccccc;
        border-collapse: collapse;
        padding-left:5px;
        padding-right:5px;
        
      }
    </style>
    </head>
      
      <body style='text-align:center'>
      <div style="height:100%;display:block;" >
      <div style="width:100%;display:flex;flex-direction:row">
    <div style="width:60%;">
      <img 
      src="data:image/png;base64, `+
      logov
      +`" 
      style="height:150px;float:left;width:auto;object-fit:contain;" />
    </div>
    <div style='float:right;background-color:transparent;width:40%;text-align:left'>
      <h2>A.S.D. Vigor Milano</h2>
      Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
      Via IV Novembre 72, Cormano (MI)<br/>
      Tel: 392 985 88 51 <br/>
    </div>
    
      </div>
    <h1 style='color:black'>Scheda Giocatore: `+selSchedaGioc.Giocatore[0].cognome+` `+selSchedaGioc.Giocatore[0].nome+`</h1>
    
    <table style='width:98%; table-layout: auto;margin: 0 auto;text-align:center'>
    <tr  style=''>
    <th>
    Data
    </th>
    <th>
    Cognome
    </th>
    <th>
    Nome
    </th>
    <th>
    Data di nascita
    </th>
    <th>
    Istruttore
    </th>
    </tr>
    <tr>
    <td>
    `+moment(selSchedaGioc.dataScheda).format('DD/MM/YYYY')+`
    </td>
    <td>
    `+selSchedaGioc.Giocatore[0].cognome+`
    </td>
    <td>
    `+selSchedaGioc.Giocatore[0].nome+`
    </td>
    <td>
    `+moment(selSchedaGioc.Giocatore[0].datanascita).format('DD/MM/YYYY')+`
    </td>
    <td>
    `+selSchedaGioc.operatore+`
    </td>
    </tr>
    <tr style='height:20px;border:none'>
    
    </tr>
    
    <tr  style=''>
    <th>
    Peso
    </th>
    <th>
    Altezza
    </th>
    <th>
    Ruolo
    </th>
    <th colspan='2'>
    Valutazione Globale  
    </th>
    
    </tr>
    
    <tr>
    <td>
    `+selSchedaGioc.peso+`
    </td>
    <td>
    `+selSchedaGioc.altezza+`
    </td>
    <td>
    `+(selSchedaGioc.ruolo?selSchedaGioc.ruolo.split('#')[2]:'')+`
    </td>
    <td colspan='2'>
    <div style='background-color:#cccccc;margin:0 auto;width:40px;padding:5px;border-radius:15px;margin-top:5px;margin-bottom:5px'>
    <h2 style='margin:0 auto;height:auto'> `+selSchedaGioc.valutazioneGlobale*2+`</h2>  
    </div>
    </td>
    
    </tr>
    
    
    <tr style='height:20px;border:none'>
    
    </tr>
    
    `+
    valstr()
      +`
      <tr style='height:20px;border:none'>
    
    </tr>
    `+
    rendernote()
      +`
    
    
    </table>
    
    
    
    
    
    </div>
      </body>
      <html>`;
    
      return texthtml;
    }
    

    const rendernote=()=>
      {
        var str="";
      if ((selSchedaGioc.note) && (selSchedaGioc.note).trim())
      
      {
        str=str+" <tr> ";
        str=str+" <td colspan=5 style='text-align:left'> ";
        str=str+selSchedaGioc.note.replace(/[\n\r]/g,'<BR/>')  ;
        str=str+" </td> ";
        str=str+" </tr> ";
      }
      return str;
      }
      




  const valstr = () => {
    var str = "";
    //selSchedaOss.valutazioni.map(x =>{str=str+"<tr><td> "+x.descrizione+"</td><td> "+x.val*2+"</td></tr>\n "});
    const len = selSchedaGioc.valutazioni.length;
    const len2 = parseInt((len / 2).toFixed(0));

    for (var e = 0; e < len2; e++) {

      str = str + "<tr><td style='text-align:left'> " + selSchedaGioc.valutazioni[e].descrizione + "</td><td> " + selSchedaGioc.valutazioni[e].val + "</td><td></td>";
      if (e + len2 < len) {
        str = str + "<td style='text-align:left'> " + selSchedaGioc.valutazioni[e + len2].descrizione + "</td><td> " + selSchedaGioc.valutazioni[e + len2].val + "</td>";
      }
      else {
        str = str + "<td></td><td></td>";
      }
      str = str + "</tr>\n ";
    }

    return str;
  }


  const onChange = (event, selectedDate) => {

    const currentDate = selectedDate || date;
    // setShow(Platform.OS === 'ios');
    setShow(false);
    //currentDate.setHours(0, 0, 0);
    //console.log(currentDate);
    setDate(currentDate);

    let x = { ...selSchedaGioc };

    x.dataScheda = new Date(currentDate);

    setselSchedaGioc(x);
    salva();
  };



  const modifica = async (ppers) => {
    try {
      setLoading(true);
      var json = await restSchedaGioc.getInstance().modifica(selSchedaGioc);
      setDaSalvare(0);
      toast.current.show({ severity: 'success', summary: 'Salvataggio eseguito', detail: 'La modifica è stata salvata', life: 3000 });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }


  }


  const azzeraVal = () => {
    let x = { ...selSchedaGioc };
    x.valutazioni = [];
    x.valutazioneGlobale = "0";
    setselSchedaGiocatore(x);
    salva();
  };


  const aggiungi = async (ppers) => {
    try {
      setLoading(true);
      var json = await restSchedaGioc.getInstance().aggiungi(selSchedaGioc);
      setDaSalvare(1);
      // console.log("DetPersona", "modifica", json);
      setselSchedaGiocatore(json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const send = async (ppers) => {
    try {
      var json = await restSchedaGioc.getInstance().sendnotificagioc(selSchedaGioc);
      // console.log('DettSchedaOss','send',json);
      if (json.esito == 'OK')
        toast.current.show({ severity: 'success', summary: 'Invio notifica', detail: json.des, life: 3000 });
      else
        toast.current.show({ severity: 'warning', summary: 'Invio notifica', detail: json.des, life: 3000 });

    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const renderItem = (item, index) => (
    <div className='listaItem' >
      <div
        style={{ flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end',alignItems:'center' }}>
        <label >{item.descrizione}</label>

        <InputNumber
inputStyle={{ width: "3rem", textAlign: 'center' }}
          disabled={!abilSCHGIOC}
          value={item.val}
          showButtons
          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
          onChange={vln => { let x = { ...selSchedaGioc }; item.val = vln.value; selSchedaGioc(x); }}
        />

      </div>
    </div>
  );

  var ciclo = 0;







  const confirm_azzera = (eler) => {
    confirmDialog({
      message: 'I valori saranno cancellati. Sei sicuro di voler procedere?',
      header: 'Attenzione !!',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        azzeraVal();
      }),     ///// implemetnare salva
      reject: (() => { }),    ///// implementare esci
    });

  };
  const confirm_esci1 = () => {
    if (daSalvare)
      confirmDialog({
        message: 'Le modifiche non sono state salvate. Sei sicuro di voler uscire e perdere tutte le modifiche?',
        header: 'Attenzione !!',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => {
          navigation('/SchedaGiocPage', { state: { selpersona: selSchedaGioc.Giocatore[0] } });
        }),     ///// implemetnare salva
        reject: (() => { }),    ///// implementare esci
      });
    else
      navigation('/SchedaGiocPage', { state: { selpersona: selSchedaGioc.Giocatore[0] } });
  };

  const confirm_esci2 = () => {
    if (daSalvare)
      confirmDialog({
        message: 'Le modifiche non sono state salvate. Sei sicuro di voler uscire e perdere tutte le modifiche?',
        header: 'Attenzione !!',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => {
        

          navigation('/TeamPage', {state:{ gruppo: props.gruppo  ,pagina:'Gioc' }     }    );    ///// implemetnare salva



        }),     ///// implemetnare salva
        reject: (() => { }),    ///// implementare esci
      });
    else
    navigation('/TeamPage', {state:{ gruppo: props.gruppo  ,pagina:'Gioc' }     }    ); 
  };


  return (
    <div style={{ flex: 1, padding: 0, margin: 0 }} >
      <Toast ref={toast} />


      <div className="listazioni"   >
        {selSchedaGioc && selSchedaGioc._id && abilSCHGIOC ? (

          <Button
            onClick={() => modifica(selSchedaGioc)}

          >
            <Icon path={mdiContentSaveAll} size={0.8} />
          </Button>

        ) :
          (abilSCHGIOC &&

            <Button
              onClick={() => aggiungi(selSchedaGioc)}

            >
              <Icon path={mdiPlus} size={0.8} />
            </Button>


          )
        }
        {selSchedaGioc && selSchedaGioc._id && abilSCHGIOC && (!daSalvare) && (

          <Button
            onClick={() => send(selSchedaGioc)}
          >
            <Icon path={mdiSend} size={0.8} />
          </Button>
        )
        }

        {(!daSalvare) &&
          <Button
            onClick={() => createPDF()}
          >
            <Icon path={mdiPrinter} size={0.8} />
          </Button>
        }

        {selSchedaGioc && selSchedaGioc._id && selSchedaGioc && (
          <Button
            onClick={() => confirm_azzera()}
          >
            <Icon path={mdiLayersOff} size={0.8} />
          </Button>
        )
        }
        <Button
          onClick={() => confirm_esci1()}
        >
          <Icon path={mdiSoccerField} size={0.8} />
        </Button>
        {props.gruppo &&
        <Button
          onClick={() => confirm_esci2()}
        >
          <Icon path={mdiFlagCheckered} size={0.8} />
        
        </Button>
}
      </div>   
           <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem', alignItems: 'flex-start' }}>


      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }}>Data scheda</label>
        <Calendar value={new Date(selSchedaGioc.dataScheda)}
          disabled={!abilSCHGIOC} showIcon
          
          onChange={vln => { let x = { ...selSchedaGioc }; x.dataScheda = vln.value; setselSchedaGioc(x); }}
        />
      </div>
      
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
        <label style={{ flex: 1, marginLeft: "0.6rem", marginBottom: '0.2rem' }}>Operatore</label>
        <IconField iconPosition="left"  >
          {abilSCHGIOC &&
            <InputIcon>
              <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
            </InputIcon>
          }
          <InputText className='longInput'
            value={selSchedaGioc.operatore}
            placeholder='Operatore'
            disabled={!abilSCHGIOC}
            onChange={vln => { let x = { ...selSchedaGioc }; x.operatore = vln.target.value; setselSchedaGioc(x); }}
          >
          </InputText>
        </IconField>
      </div>





      <div style={{ display: 'flex', flexDirection: 'row',flexWrap:'wrap' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
        <label style={{ flex: 1, marginLeft: "0.5rem", marginBottom: '0.2rem' }}>Peso  (Kg): {selSchedaGioc.peso}</label>
        <InputNumber
          disabled={!abilSCHGIOC}
          inputStyle={{ width: "4rem", textAlign: 'center' }}
          showButtons mode="decimal" minFractionDigits={1} step={0.50}
          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
          value={selSchedaGioc.peso}
          onChange={vln => { let x = { ...selSchedaGioc }; x.peso = vln.value; setselSchedaGioc(x); }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>

        <label style={{ flex: 1, marginLeft: "0.5rem", marginBottom: '0.2rem' }}>Altezza (cm): {selSchedaGioc.altezza}</label>
        <InputNumber
          disabled={!abilSCHGIOC}
          inputStyle={{ width: "4rem", textAlign: 'center' }}
          showButtons 
          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
          value={selSchedaGioc.altezza}
          onChange={vln => { let x = { ...selSchedaGioc }; x.altezza = vln.value; setselSchedaGioc(x); }}
        />

      </div>
</div>



      

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
        <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Ruolo</label>
        <Dropdown
          value={selSchedaGioc.ruolo}
          options={elenchi.getInstance().getElRuoli()}
          optionLabel="label"
          optionValue="value"
          disabled={!abilSCHGIOC}
          style={{ margin: "0.3em", flexGrow: 1 }}
          placeholder='Selezionare il ruolo'
          onChange={vln => { let x = { ...selSchedaGioc };x.__proto__ = schedaGioc.prototype;   x.ruolo = vln.target.value; 
          x.caricaValutazioni(datiGen.getInstance().getValutazioniRuolo(vln.target.value),0);
          setselSchedaGioc(x); }}
        />

      </div>
    


      <div style={{ width:'100%' }} >

      <div style={{ backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
      <label style={{ fontWeight: '900', fontSize: '1.2rem', color: '#0a568d', padding:'0.3rem' }}  >Valutazioni</label>
      </div>
        {
          selSchedaGioc.valutazioni.map(x => renderItem(x, ciclo++))
        }
      </div>
      
        <div style={{ display: 'flex', flex: '1', padding: '0.3rem', flexDirection: 'row',justifyContent: 'space-between', alignItems: 'center' ,width:'100%'}}
        >
          <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Valutazione globale: {selSchedaGioc.valutazioneGlobale * 2} </label>
          <Rating value={selSchedaGioc.valutazioneGlobale} stars={5} cancel={false}
            onChange={vln => { let x = { ...selSchedaGioc }; x.valutazioneGlobale = vln.target.value; setselSchedaGioc(x); }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '0.3rem' ,flexGrow:'1' ,width:'100%'}}>
        <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Note:</label>
        <InputTextarea  style={{ minWidth: "15rem", flexGrow: "1",width:'100%' }} autoResize={true}
          value={selSchedaGioc.note}
          onChange={vln => { let x = { ...selSchedaGioc }; x.note = vln.target.value; setselSchedaGioc(x); }}
        />
      </div>
      
      </div>
    </div>
  );

}

export default DettaglioSchedaGioc;
