import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";
import datiGen from "../util/datiGen";




export default class restConfigurazioniTest {
  constructor(restConfigurazioniTest) {
    
    
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'configurazioniTest';
  static myInstance = null;

  static getInstance() {
    if (restConfigurazioniTest.myInstance == null) {
      restConfigurazioniTest.myInstance = new restConfigurazioniTest();
      // Util.myInstance.inizializza();
      
    }
    return restConfigurazioniTest.myInstance;
  }



  getAllConfigurazioniTest = async (mostravalidi) => {
    var json ={esito:"KO"};
    if (!datiGen.getInstance().possoaccederev2('ELENCOTEST'))
    {
return json;
    //  throw 'Non sei abilitato a svolgere la funzione ELENCOTEST - getAllConfigurazioniTest';
    }
    

    let purl="";
    if ( mostravalidi==false)
     {
       purl="?mostravalidi=false";
     }
     else if (mostravalidi==undefined || mostravalidi==true)
     {
      purl="?mostravalidi=true";
    }
     else
     {
      console.log('restconfigurazioniTest','getAllConfigurazioniTest','else - qui non devo entrare');
     }

    const user=getAuth().currentUser;
    var token = await user.getIdToken();

    
    
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();
     // console.log("getGruppi",purl);
      const response = await fetch(this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + purl , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization':"Bearer "+ token
        }
      }
      );
      var json = await response.text();

if (json)
   json=JSON.parse(json);
   else json={esito:"KO2"};

      
    } catch (error) {
      console.error(error);
      
    } finally {
      
      if (!json) json={esito:"KO3"};
      if (!json.esito) json.esito='KO';
      
     
      return json;
      
    }
    
  }

  aggiungi = async (para) => {
    var vpara={...para};
    var json ={esito:"KO"};
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();


      if (!datiGen.getInstance().possoaccederev2('TESTCONF'))
      throw 'Non sei abilitato a svolgere la funzione TESTCONF - aggiungi';


      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla 
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+ token
          },
          body: JSON.stringify(vpara)
        });
     json= await  response.json();
     
     if (response.status>=300)
      {
       console.log("restConfigurazioniTest","aggiungi","--",response.status,json);
       json.esito='KO'
       throw 'Errore '+JSON.stringify(json);
      }
      else
      json.esito='OK'

    } catch (error) {
      console.error(error);
      json.esito='KO'
    } finally {
      if (!json) json={esito:"KO"};
      if (!json.esito) json.esito='KO';
      return JSON.stringify(json);
    }
  }

  modifica = async (para) => {
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();

      if (!datiGen.getInstance().possoaccederev2('TESTCONF'))
      throw 'Non sei abilitato a svolgere la funzione TESTCONF - modifica';
  
      
      var p = { ...para };
      delete p._id;
      
      const s = this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + para._id;
      
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+ token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }



  elimina = async (para) => {
     
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();

      if (!datiGen.getInstance().possoaccederev2('TESTCONF'))
      throw 'Non sei abilitato a svolgere la funzione TESTCONF - elimina';
  
        
      
      const s = this.restUrlServer + '/' + this.restApp  + '/' + datiGen.getInstance().getActiveSoc().sigla + '/' + para._id;
      
      const response = await fetch(s
        , {
          method: 'delete',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+ token
          },
          
        });



      const json = await response.text();

      console.log('test','elimina',json);
      return json;

    } catch (error) {
      console.error(error);
    } finally {
      

    }

  }
 
  getConfigurazioneTest = async (gid) => {
    const user = getAuth().currentUser;
    var token = await user.getIdToken();


//console.log(gid);

    if (!datiGen.getInstance().possoaccederev2('TESTCONF'))
    throw 'Non sei abilitato a svolgere la funzione getConfigurazioneTest';

    try {

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + datiGen.getInstance().getActiveSoc().sigla + "/" + gid, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      const json = await response.json();
      if (response.status >= 300) {
      console.log("restconfigurazioniTest", "getconfigurazioniTest", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      //   console.log("finito getPersone");
    }
  }


}