import datiGen from "./datiGen";


export default class elenchi {

  constructor(datiGen) {
    this.elIndirizziPred =[];
    this.elPagModalita =[];
    this.elPagCausale =[];
    this.elStagioni =[];
    this.elPeriodo =[];
    this.elTipoProfilo =[];
    this.elPeriodo =[];
    this.elLivello =[];
    this.elNumerogioc =[];
    this.elTipocampionato =[];
    this.elRuoli=[];


  }
/*
  elTipoPartita = [
    { label: 'Amichevole', value: 'A' },
    { label: 'Test Match', value: 'TM' },
    { label: 'Campionato', value: 'C' },
    { label: 'Torneo', value: 'T' },

  ];*/

/*
  elIndirizziPred =
    [{ label: 'CS Vigorello', value: 'CS Vigorello - Via San Michele del Carso, 59 Paderno Dugnano (MI)' },
    { label: 'Easy Sport', value: 'CS Easy Sport - Via IV Novembre, 72 Cormano (MI)' },
    { label: 'Iseo', value: 'CS Iseo, Via Iseo 6, Milano (MI)' },
    { label: 'Quarto Sport', value: 'CS Quarto Sport Via A. Graf 4, Milano (MI)' },
    ];*/

  elruoliStaff = [
    { label: 'Allenatore', value: '#Z01#Allenatore' },
    { label: 'Sec. Allenatore', value: '#Z02#Sec.Allenatore' },
    { label: 'Prep. Portieri', value: '#Z03#Prep.Portieri' },
    { label: 'Prep.Atletico', value: '#Z04#Prep.Atletico' },
    { label: 'Dirigente', value: '#Z31#Dirigente' },


  ];


/*
  eltipoatt = [
    { label: 'Partita', value: '#P#Partita' },
    { label: 'Allenamento', value: '#A#Allenamento' },
    { label: 'Test', value: '#T#Test' },
    { label: 'Evento', value: '#E#Evento' },
    { label: 'Altro', value: '#Z#Altro' },
    { label: 'Tutti', value: '' },

  ];
*/

  elEvPartita = [
    { label: 'Goal', value: '#G#Goal' },
    { label: 'Ammonizione', value: '#A#Ammonizione' },
    { label: 'Espulsione', value: '#E#Espulsione' },

  ];

  Area = [
    { label: 'Mentale', value: '#M#Mentale' },
    { label: 'Tecnica', value: '#T#Tecnica' },
    { label: 'Atletica', value: '#A#Atletica' },
    { label: 'Fisica', value: '#F#Fisica' },
    { label: 'Altro', value: '#Z#Altro' },
  ];

  Capacita = [
    { label: 'Controllo', value: '#01#Controllo' },
    { label: 'Passaggio', value: '#02#Passaggio' },
    { label: 'Tiro', value: '#03#Tiro' },
    { label: 'Tuffo', value: '#04#Tuffo' },
    { label: 'Presa', value: '#05#Presa' },
    { label: 'Contrasto', value: '#06#Contrasto' },

    { label: 'Resistenza', value: '#21#Resistenza' },
    { label: 'Forza', value: '#22#Forza' },
    { label: 'Velocità', value: '#23#Velocità' },
    { label: 'Accelerazione', value: '#24#Accelerazione' },
    { label: 'Flessibilità', value: '#25#Flessibilità' },
    { label: 'Cambio di direzione', value: '#26#Cambio di direzione' },

    { label: 'Equilibrio', value: '#31#Equilibrio' },
    { label: 'Gestione spazio tempo', value: '#32#Gestione spazio tempo' },
    { label: 'Reazione', value: '#33#Reazione' },
    { label: 'Visione', value: '#34#Visione' },
    { label: 'Struttura fisica', value: '#41#Struttura fisica' },
  ];

  eltipoRisultati = [
    { label: 'Tempo risposta', value: '#01#Tempo risposta' },
    { label: 'Errori', value: '#02#Errori' },
    { label: 'Numero', value: '#03#Numero' },
    { label: 'Tempo totale', value: '#04#Tempo totale' },
    { label: 'Corretti', value: '#05#Corretti' },
    { label: 'Peso', value: '#11#Peso' },
    { label: 'Altezza', value: '#12#Altezza' },
    { label: 'BMI', value: '#13#BMI' },
    { label: 'Flessibilità colonna', value: '#18#Flessibilità colonna' },
    { label: '10 metri', value: '#31#10 metri' },
    { label: 'Navetta 50-5', value: '#32#Navetta 50-5' },
    { label: '80 metri', value: '#33#80 metri' },
    { label: '100 metri', value: '#34#100 metri' },
    { label: '1000 metri', value: '#35#1000 metri' },
    { label: '3000 metri', value: '#36#3000 metri' },
    { label: 'Step 1', value: '#37#Step 1' },
    { label: 'Step 2', value: '#38#Step 2' },
    { label: 'Step 3', value: '#39#Step 3' },
    { label: 'Step 4', value: '#40#Step 4' },

  ];


  elTipoGruppo = [
    { label: '--', value: '--' },
    { label: 'Agonistica', value: 'A' },
    { label: 'PreAgonistica', value: 'P' },

  ];



  static myInstance = null;

  static getInstance() {
    if (elenchi.myInstance == null) {
      elenchi.myInstance = new elenchi();
      // Util.myInstance.inizializza();
      //elenchi.myInstance.inizializza().then(() => console.log('Inizializing'));
    }
    return elenchi.myInstance;
  }

  inizializza() {
  /*  return new Promise((resolve, reject) => {
    }, (error) => {
      reject(error);
    })*/
  }


  getEl(para) {
    let x =  datiGen.getInstance().getElencoConf(para);
    return x;
  }

  

  getElTipoPartita() {
    this.elTipoPartita =  datiGen.getInstance().getElencoConf('TIPOPARTITA');
    return this.elTipoPartita;
  }

  getElIndirizziPred() {
    this.elIndirizziPred =  datiGen.getInstance().getElencoConf('INDIRIZZI_PRED');
    return this.elIndirizziPred;
  }

  getElPagModalita() {
    this.elPagModalita =  datiGen.getInstance().getElencoConf('PAG_MODALITA');
    return this.elPagModalita;
  }

  getElPagCausale() {
    this.elPagCausale =  datiGen.getInstance().getElencoConf('PAG_CAUSALE');
    return this.elPagCausale;
  }
  getElStagioni() {
    this.elStagioni =  datiGen.getInstance().getElencoConf('STAGIONE');
    return this.elStagioni;
  }

  getElNumeroGioc() {
    this.elNumerogioc =  datiGen.getInstance().getElencoConf('NUMERO_GIOC');
    return this.elNumerogioc;
  }

  getElLivelloCamp() {
    this.elLivello =  datiGen.getInstance().getElencoConf('LIVELLO_CAMPIONATO');
    return this.elLivello;
  }


  getElPeriodoCampionato() {
    this.elPeriodo =  datiGen.getInstance().getElencoConf('PERIODO_CAMPIONATO');
    return this.elPeriodo;
  }
  getElTipoCampionato() {
    this.elTipocampionato =  datiGen.getInstance().getElencoConf('TIPO_CAMPIONATO');
    return this.elTipocampionato;
  }
  getTipoProfilo() {
    this.elTipoProfilo =  datiGen.getInstance().getElencoConf('TIPOPROFILO');
    return this.elTipoProfilo;
  }



  getElCausePres() {
    this.elLivello =  datiGen.getInstance().getElencoConf('ELCAUSAPRES');
    return this.elLivello;
  }


  getElruoliStaff() {
    return this.elruoliStaff;
  }


  getElTipoatt() {
    this.eltipoatt =  datiGen.getInstance().getElencoConf('TIPOATT');
    return this.eltipoatt;
  }

  getElEvPartita() {
    return this.elEvPartita;
  }

  getArea() {
    return this.Area;
  }

  getCapacita() {
    return this.Capacita;
  }
  getElRisultati() {
    return this.eltipoRisultati;
  }
  getElTipoGruppo() {
    return this.elTipoGruppo;
  }

getElRuoli()
{this.elRuoli =  datiGen.getInstance().getElencoConf('RUOLI');
  return this.elRuoli;
}
getRuoliStaff()
{
  this.elRuoliStaff =  datiGen.getInstance().getElencoConf('RUOLISTAFF');
  return this.elRuoliStaff;
 
}
getRuolo()
{

  return this.elRuoli;
 
}


}