// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';
import moment from "moment";
import { useLocation,useNavigate } from "react-router-dom";


import Test_ElGioc from '../comp/EsecTest_ElGioc';
import EsecTestAtt from '../comp/EsecTest_Att';
import EsecTestRun from '../comp/EsecTest_run';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';

const EsecTestPage = () => {
  const navigation = useNavigate();
  const location=useLocation();
  const [selGruppo, setSelGruppo] = useState(() => location.state.gruppo);
  const [selAttivita, setSelAttivita] = useState(() => location.state.attivita);
  const [selTest, setSelTest] = useState();
  const [selGioc, setSelGioc] = useState();



  useEffect(
    () => {
      setSelGruppo(location.state.gruppo); 
      setSelAttivita(location.state.attivita); 
      console.log('use effect page esectest','selgruppo',selGruppo)
    },
    [location.state.gruppo,location.state.attivita,selTest,selGioc]
  );

const backsetTest=(pselt)=>
{
  setSelTest(pselt);
}
const backsetGioc=(pgioc)=>
{
  
  setSelGioc(pgioc);
}

  return (

    <div style={{
      width:'100%',
      height:'100%',
      minHeight:'100%',
      flex:1,
      minWidth:'100%',
      maxWidth:'100%',
      justifyContent:'flex-start'


    }}>
      <label style={{flex:1}} h4>Esecuzione Test</label>
<div style={{display:'flex',flexDirection:'row'}}>

<div style={{flex:1}}>
<Test_ElGioc  gruppo={selGruppo} attivita={selAttivita} setGioc={backsetGioc} pGioc={selGioc}/>
</div>

<div style={{flex:2,marginLeft:'0.3rem'}}>
<EsecTestAtt style={{width:'100%',flex:1}} gruppo={selGruppo} attivita={selAttivita} setTest={backsetTest} pTest={selTest}   />
{selTest &&
<EsecTestRun style={{width:'100%',flex:10}} gruppo={selGruppo} attivita={selAttivita} pTest={selTest} pGioc={selGioc}  />
}
</div>
     </div>
    </div>
    
  )
} 
export default EsecTestPage;