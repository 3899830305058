import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";





export default class restConf {
  constructor(restGruppi) {
    
    
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'conf';
  static myInstance = null;

  static getInstance() {
    if (restConf.myInstance == null) {
      restConf.myInstance = new restConf();
      // Util.myInstance.inizializza();
      
    }
    return restConf.myInstance;
  }

  getConfs = async (pidsoc) => {
    let purl=pidsoc+'/';
        const user=getAuth().currentUser;
    var token = await user.getIdToken();   
    var json ={esito:"KO"};
    try {
      const user=getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp  + '/' + purl , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization':"Bearer "+ token
        }
      }
      );
      var json = await response.text();


if (json)
{   
json=JSON.parse(json);
console.log('restconf','R51',json);
if (!json.esito)
json.esito='OK';
console.log('restconf','R53',json);

}
   else json={esito:"KO2"};  
      
    } catch (error) {
      console.error("getConfs",error);
      
    } finally {
      
      if (!json) json={esito:"KO3"};
      if (!json.esito) json.esito='KO';
   
      
      return json;
      
    }
    
  }

 

}