// screens/Home.js

import React, { Component, useEffect, useState,useRef } from 'react';
import datiGen from '../util/datiGen.js';
import moment from 'moment'
import { useNavigate, useLocation, Link ,useOutletContext} from "react-router-dom";
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import restGiocGruppi from '../restcall/restGiocGruppi.js';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import elenchi from '../util/elenchi.js';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer,mdiContentSaveAll,mdiCloseBox,
  mdiCheckAll,mdiTagEdit,
  mdiAccount,
  mdiClipboard,
  mdiClipboardPlus,
  mdiPencil,
  mdiAccountMultiple,
  mdiAccountPlus

} from '@mdi/js';
import { InputNumber } from 'primereact/inputnumber';

const TeamGiocDett = (props) => {
  const navigation = useNavigate();
  const abilListaGioc = datiGen.getInstance().possoaccederev2('ListaGioc', props.gruppo);
  const [selectedItem, setSelectedItem] = useState(props.gioc);
  const [grp, setsetGrp] = useState(() => props.gruppo);
  const [daSalvare, setDaSalvare] = React.useState(false);
  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();

  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo, life: 3000 });
  }
  const showWarn = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: testo, life: 3000 });
  }
  const showError = (testoerr) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: testoerr, life: 3000 });
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };

 
  useEffect(() => {
    console.log('use effetct dettgiocteam',selectedItem);
   setSelectedItem(props.gioc);
  }, []);


  useEffect(() => {
    console.log('modifco 0');
    if (daSalvare) {
      let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 3, '1');
      setStatoDaSalvare(x);
    } else if (!daSalvare) {
      let x = statoDaSalvare.replaceAll("1", "0");
      setStatoDaSalvare(x);
    }
  }, [daSalvare]);

  const torna_elgioc = () => {
    if (daSalvare) {
      confirmDialog({
        message: "Sei sicuro di voler uscire e perdere le modifiche inserite?",
        header: 'Attenzione',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => {   props.updateAzione(0) }),
        reject: (() => { }),
      });
    }
    else  props.selpagina('Gioc') ;
  }

 
  return (

<div>

<div style={{  flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' }}>
    <label  style={{fontWeight:'bold'}}  >{selectedItem.Giocatore[0].cognome}  {selectedItem.Giocatore[0].nome}</label> 
   <div>
    { daSalvare &&
    <Button
               onClick={() => {console.log('prima di salva',selectedItem); restGiocGruppi.getInstance().editGiocGruppo(selectedItem).then((json) => { setDaSalvare(false); console.log('salvataggio avvenuto',json); }); }}
            >
              <Icon path={mdiContentSaveAll} size={0.8} />
            </Button>
}
            <Button
              onClick={() => torna_elgioc()}
            >
              <Icon path={mdiAccountPlus} size={0.8} />
            </Button>

</div>
</div>
            
            <hr></hr>
            <div style={{  flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' ,flexWrap: 'wrap' ,alignItems:'flex-start'}}>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
    
        <label style={{  margin: "0rem 0.3rem" ,fontSize:'1rem'}} >Ordine</label>
        <InputNumber
          inputStyle={{ width: "3rem", textAlign: 'center' }}
          disabled={!abilListaGioc}
          value={selectedItem.ordine}
          showButtons
          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
          onChange={vln => { let x = { ...selectedItem }; x.ordine = vln.value; setSelectedItem(x);setDaSalvare(true) }}
        />

      </div>

      <div style={{ display: 'flex', flexDirection: 'column', margin:'0.3rem'}}>
                  <label style={{  margin: "0rem 0.3rem" ,fontSize:'1rem'}}>Ruolo</label>  
                <Dropdown
                style={{marginTop:"0.5rem"}}
                  options={elenchi.getInstance().getElRuoli()}
                  optionLabel="label"
                  optionValue="value"
                  value={selectedItem.ruolo}
                  onChange={val => { let x = { ...selectedItem }; x.ruolo = val.value; setSelectedItem(x); setDaSalvare(true)  }}
                /></div>
         
           <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
           <label style={{ flex: 1, margin: "0rem 0.3rem" ,fontSize:'1rem'}}>Numero lista predefinito</label>
        <InputNumber
          inputStyle={{ width: "3rem", textAlign: 'center' }}
          disabled={!abilListaGioc}
          value={selectedItem.numeropred?selectedItem.numeropred:0}
          showButtons
          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" buttonLayout="horizontal"
          onChange={vln => { let x = { ...selectedItem }; x.numeropred = vln.value; setSelectedItem(x); setDaSalvare(true) }}
        />

      </div>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem', alignItems: 'flex-start', alignContent: 'center' }}>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
            <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Inizio validità:</label>
            <Calendar  value={new Date(selectedItem.ival)} showIcon 
              disabled={!abilListaGioc} showButtonBar={true}
              onChange={(e) => {console.log('onchange ival----',e.value,selectedItem.ival);
                let x = { ...selectedItem }; x.ival = e.value; setSelectedItem(x);console.log('onchange dopo',x.ival,e.value);
                
                setDaSalvare(true); 
              }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Fine validità:</label>
            <Calendar  value={new Date(selectedItem.fineval)} showIcon 
              disabled={!abilListaGioc} showButtonBar={true}
              onChange={(e) => {
                let x = { ...selectedItem }; x.fineval = e.value; setSelectedItem(x);          
                setDaSalvare(true); 
              }} />
          </div>


          </div>
</div>
</div>








      
    )
}
export default TeamGiocDett;