import moment from 'moment'

const campionatoPrototype = {

   
  }
  
  function campionato() {
    this.id=Date.now();
    if (moment().month()>=6 && moment().month()<=12 )
    {
    this.stagione=moment().format('YYYY')+"-"+moment().add(1, 'years').format('YYYY');
    }
    else
    {
      this.stagione=moment().subtract(1,'years').format('YYYY')+"-"+moment().format('YYYY');
    }
    this.categoria="Under 0";  // under 12 13 .....
    this.tipo = "Prov";   //tipo campionato provinciale regionale nazionale
    this.zona="Milano";     //Milano,Lombardia,....
    this.periodo='anno';       ///autunnale, primaverile, annuale
    this.numerogioc="11";  // a 11 7 9 5
    this.livello='alto';       // alto medio basso
    this.__proto__ = campionato.prototype;
  }
  
  campionato.prototype = campionatoPrototype;
  campionato.prototype.constructor = campionato;

export default campionato;