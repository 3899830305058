// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
   /* text-align: center;*/
    display: flex;
    overflow: hidden auto;
    height: 99vh;
    flex-direction: column;
    margin: 0 auto;
  }`, "",{"version":3,"sources":["webpack://./src/stili/App.css"],"names":[],"mappings":"AAAA;GACG,uBAAuB;IACtB,aAAa;IACb,qBAAqB;IACrB,YAAY;IACZ,sBAAsB;IACtB,cAAc;EAChB","sourcesContent":[".App {\n   /* text-align: center;*/\n    display: flex;\n    overflow: hidden auto;\n    height: 99vh;\n    flex-direction: column;\n    margin: 0 auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
