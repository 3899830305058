import doc from "./Doc.js";
import tesseramento from "./Tesseramento.js";
import Recapito from "./Recapiti.js"; 
import visita from "./Visita.js"; 
import pagamenti from "./Pagamento.js"
import moment from 'moment'

const personPrototype = {
  to_obj() {
    if (this.datanascita && typeof this.datanascita == 'string') {
      this.datanascita = new Date(this.datanascita);
    }

    if (this.docs) {
      this.docs.forEach(ele => { ele.__proto__ = doc.prototype; ele.to_obj() });
    };
    if (this.tesseramenti) {
      this.tesseramenti.forEach(ele => { ele.__proto__ =  tesseramento.prototype; ele.to_obj() });
    };
    if (this.recapiti) {
      this.recapiti.forEach(ele => { ele.__proto__ = Recapito.prototype; ele.to_obj() });
    };
      if (this.recapiti) {
      this.recapiti.forEach(ele => { ele.__proto__ = Recapito.prototype; ele.to_obj() });
    };
    if (this.visite) {
      this.visite.forEach(ele => { ele.__proto__ = visita.prototype; ele.to_obj() });
    };
    if (this.pagamenti) {
      this.pagamenti.forEach(ele => { ele.__proto__ = pagamenti.prototype; ele.to_obj() });
    };
  },

getTelMadre()
 {
  var out;
  if (this.recapiti) {
    {
    out =this.recapiti.find( el => el.tipo==='TEL' && el.appartenenza === 'M01');
    if (out) out=out.descrizione;
  //  console.log("getTelMadre()","out:",out,this.recapiti);
    }
 }
 return out;
 }
,

getTelPadre()
 {
  var out;
  if (this.recapiti) {
    {
    out =this.recapiti.find( el => el.tipo==='TEL' && el.appartenenza === 'P01');
    if (out) out=out.descrizione;
   // console.log("getTelPadre()","out:",out,this.recapiti);
    }
 }
 return out;
 },

 getIndirizzo()
 {
  var out;
  if (this.recapiti) {
    {
    out =this.recapiti.find( el => el.tipo==='IND');
   // console.log("getTelPadre()","out:",out,this.recapiti);
    }
 }
 return out;
 },
 getMail()
 {
  var out;
  if (this.recapiti) {
    {
    out =this.recapiti.find( el => el.tipo==='MAIL');
    if (out) out=out.descrizione;
   // console.log("getTelPadre()","out:",out,this.recapiti);
    }
 }
 return out;
 },

  getDataultimavisitaidoneita()
{
var dt=moment().subtract(10,'years').toDate();
var trovato=0;
if (this.visite) 
{
this.visite.sort((ele1, ele2) => moment(ele2.datavisita).diff(moment(ele1.datavisita))).forEach(el => {
  if (el.tipo=='idoneità' && trovato==0) 
  {
  dt=el.datavisita;
  trovato=1;
  
  }
    
  });

}
return dt;
},
getScadenzaultimavisitaidoneita()
{
var dt=moment().subtract(10,'years').toDate();
var trovato=0;
if (this.visite) 
{
this.visite.sort((ele1, ele2) => moment(ele2.datavisita).diff(moment(ele1.datavisita))).forEach(el => {
  if (el.tipo=='idoneità' && trovato==0) 
  {
  dt=el.getScadenza();
  trovato=1;
  
  }
    
  });

}
return dt;
}





}

  
  
  function 
  Person(idsoc,cognome,nome) {
    this.idsoc=idsoc;
    this.nome = nome;
    this.cognome=cognome;
    this.codicefiscale="";
    this.sesso="M";
    this.luogoNascita="";
    this.datanascita=new Date();
    this.nazionalita="Italiana";
    this.matricola="";
    this.recapiti=[];
    this.docs=[];
    this.tesseramenti=[];
    this.visite=[];
    this.pagamenti=[];
    this.note="";
    this.genitori='';
    this.cognomeNomeMadre='';
    this.cognomeNomePadre='';
    this.iban='';
    this.__proto__ = Person.prototype;
  }
  
  Person.prototype = personPrototype;
  Person.prototype.constructor = Person;
  





export default Person;