
// screens/Home.js

import React, { useState } from 'react';

import datiGen from '../util/datiGen.js';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { ScrollPanel } from 'primereact/scrollpanel';




const PresenzeRept = (props) => {
  const navigation = useNavigate();
  // datiGen.getInstance().possoaccedere('GRUPPI');
 /* const abilAtt = datiGen.getInstance().possoaccedere('ATTIVITA') || datiGen.getInstance().possoaccedere('ATTIVITA', props.gruppo);
  const abilAttUPD = datiGen.getInstance().possoaccedere('ATTIVITAUPD') || datiGen.getInstance().possoaccedere('ATTIVITAUPD', props.gruppo);
  const abilAllPres = datiGen.getInstance().possoaccedere('ALLGIOCATTIVITAUPD');
  const abilGiocAtt = datiGen.getInstance().possoaccedere('GiocAttivita', props.gruppo);
  const abilPresRep = datiGen.getInstance().possoaccedere('REPORTPRESENZE', props.gruppo);*/
  const [lista, setLista] = useState(props.lista);
  return (
    <div  style={{
     width:'100%',
    flex:1
   
    }}>
       
<div style={{ flexDirection:'row',display:'flex', width:'100%',minWidth:'100%'}}>     
<div style={{flex:1,margin:0,padding:0,overflowX: 'auto'}}>
{
props.lista.map((riga,key) => (
<div key={key} style={{display:'flex',flexDirection:'row',borderBottom:'0.1rem solid',borderBottomColor:'gray'}}>
  {riga.map((col,keycol) => (
      <label key={keycol} style={{flex:keycol<1?3:1,minWidth:'7rem',
        fontWeight:key==0?'bold':'normal',   margin:0,padding:0,textAlign:keycol<1?'left':'center'
      ,backgroundColor:keycol%2?'#eeeeee':'#e1f2f9', marginHorizontal:0
      }}>
        {key==0 && keycol==0 ?col:col instanceof Object ?'':(col==NaN || col==Infinity)?'-':col}</label>))
  }
    </div>
))
}
</div>
</div>
</div> 

  );
}
export default PresenzeRept;