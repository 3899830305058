// screens/Home.js


import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState,useEffect, useRef } from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from 'primereact/dialog';
import { CountryService } from '../util/CountryService';
import Icon from '@mdi/react';
import { mdiContentSave } from '@mdi/js';
import { mdiPrinter } from '@mdi/js';
import { mdiSoccerField } from '@mdi/js';
import { mdiAccountMultiple } from '@mdi/js';
import { mdiAccountMultipleCheck } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { mdiPencil } from "@mdi/js";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import  Person  from '../obj/person.js' 
import restPersona from "../restcall/restPersona";
import { useOutletContext } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import datiGen from '../util/datiGen.js'
import { PrimeIcons } from 'primereact/api';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';


const DettaglioPersona = (props) => {
  const navigate = useNavigate();
  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();
  const abilAttUPD = datiGen.getInstance().possoaccederev2('ATTIVITAUPD', props.gruppo);
  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD', props.gruppo);
  const abilRecUPD = datiGen.getInstance().possoaccederev2('ANAGRECAPITI', props.gruppo);
  const abilPagUPD = datiGen.getInstance().possoaccederev2('ANAGPAG', props.gruppo);
  const abilIscrUPD = datiGen.getInstance().possoaccederev2('ISCRIZIONI', props.gruppo);
  const abilOrdUPD = datiGen.getInstance().possoaccederev2('ORDINI', props.gruppo);
  
const [person, setPerson] = useState();
useEffect(() => {
  if (props.selPersona){
    let tmp_person = props.selPersona;
    tmp_person.__proto__ = Person.prototype;
    tmp_person.to_obj(); 
    setPerson(tmp_person);
    console.log(person, props)
  }else{
  }
}, []);

useEffect(() => {
  console.log("parto");
}, [person]);
const options = [
  {sesso: 'Maschio',valore: "M"},
  {sesso: 'Femmina',valore: "F"}
];
const [value, setValue] = useState(options[0]);
const [date, setDate] = useState(null);
const [countries, setCountries] = useState([]);
const [selectedCountry, setSelectedCountry] = useState(null);
const [filteredCountries, setFilteredCountries] = useState(null);
const [visible, setVisible] = useState(false);
const menuLeft = useRef(null);
const [items, setItems] = useState([
  {
    label: 'Dettagli Persona',
    items: [
      { label: 'Recapiti', icon: PrimeIcons.SEND, command: () => props.updateAzione(1) },
      { label: 'Visite', icon: PrimeIcons.SEND, command: () => props.updateAzione(2) },
      { label: 'Tesseramenti', icon: PrimeIcons.SEND, command: () => props.updateAzione(3) },
      { label: 'DOC', icon: PrimeIcons.SEND, command: () => props.updateAzione(4) },
      { label: 'Pagamenti', icon: PrimeIcons.SEND, command: () => props.updateAzione(5) },
      { label: 'Iscrizioni', icon: PrimeIcons.SEND, command: () => props.updateAzione(6) }
    ]
  }
]);
let [daSalvare, setDaSalvare] = useState(false); 

useEffect(() => {
  console.log('modifco 0');
 if(daSalvare){
  let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 0, '1'); 
  setStatoDaSalvare(x);
 }else if(!daSalvare){
   let x = statoDaSalvare.replaceAll("1", "0"); 
   setStatoDaSalvare(x);
 }
}, [daSalvare]);

const hideFunc = (items, abilRecUPD, abilPagUPD, abilIscrUPD) => {
  return items.map(group => {
    if (group.label === 'Dettagli Persona') {
      group.items = group.items.filter(item => {
        if (item.label === 'Recapiti' && !abilRecUPD) return false;
        if (item.label === 'Pagamenti' && !abilPagUPD) return false;
        if (item.label === 'Iscrizioni' && !abilIscrUPD) return false;
        return true;
      });
    }
    return group;
  });
};

useEffect(() => {
  const updatedItems = hideFunc(items, abilRecUPD, abilPagUPD, abilIscrUPD);
  setItems(updatedItems);
}, [props.abilRecUPD, props.abilPagUPD, props.abilIscrUPD]);
const toast = useRef(null);
const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Success', detail:'Message Content', life: 3000});
}
const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
}
const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
}
const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
}
const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
};
const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
};

const search = (event) => {
    setTimeout(() => {
        let _filteredCountries;

        if (!event.query.trim().length) {
            _filteredCountries = [...countries];
        }
        else {
            _filteredCountries = countries.filter((country) => {
                return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }

        setFilteredCountries(_filteredCountries);
    }, 250);
}
const itemTemplate = (item) => {
  return (
      <div style={{alignItems: 'center', flexDirection: 'row', display: 'flex',}}>
          <img
              alt={item.name}
              src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
              className={`flag flag-${item.code.toLowerCase()} mr-2`}
              style={{width: '18px'}}
          />
          <div style={{marginLeft: '1em',}}>{item.name}</div>
      </div>
  );
};

useEffect(() => {
  CountryService.getCountries().then((data) => setCountries(data));
}, []);

const nameControl = (personNome) => {
  let out = false;
  if ((personNome && personNome.nome.length < 2) || (personNome && personNome.cognome.length < 2)) {
    out = false;
  }else{
    out =  true;
  }
  return out;
}

const  countryControl = (personCountry) => {
  const countries = CountryService.getData().map(country => country.name);
  if (personCountry && !countries.includes(personCountry.nazionalita)) {
    personCountry.nazionalita = 'Italiana';
  }
}

const salva = async() => {
  let p = {...person};
  countryControl(p);
  if(!nameControl(person)){
    toast.current.show({severity:'warn', summary: 'Attenzione', detail:'Il nome ed il cognome devono avere almeno due lettere', life: 3000});
    return;
  }
  if(p._id){
    let x = await restPersona.getInstance().modifica(p, null);
    x.__proto__ = Person.prototype;
    x.to_obj(); 
    console.log(x);
    toast.current.show({severity:'success', summary: 'Salvato', detail:'salvataggio avvenuto con successo', life: 3000});
    setDaSalvare(false);
    setPerson(x);
  }else if(!p._id){
    restPersona.getInstance().aggiungi(p, null);
    toast.current.show({severity:'success', summary: 'Salvato', detail:'modifica avvenuta con successo', life: 3000});
    setDaSalvare(false);
  }else{
    console.log('non ho ne salvato ne modificato');
    toast.current.show({severity:'warn', summary: 'Attenzione', detail:'non ho ne salvato ne modificato', life: 3000});
  }
}

const ricaricaPersona = async() => {
  let x = await restPersona.getInstance().getPersona(person._id,null);
  x.__proto__ = Person.prototype;
  x.to_obj(); 
  setPerson(x);
}

const confirm1 = () => {
  confirmDialog({
    message: 'Non tutte le modifiche sono state salvate, vuoi uscire comunque?',
    header: 'Attenzione',
    icon: 'pi pi-exclamation-triangle',
    defaultFocus: 'accept',
    accept: (()=>{setDaSalvare(false); setVisible(true); ricaricaPersona()}),
    reject: (()=>{;}),
  });
};
const viaschedagioc = () => {
//  console.log('per scheda gioc');
navigate("../SchedaGiocPage", {state:{ selpersona: person} })
}; 
const vaidettgrup = () => {
  //  console.log('per scheda gioc');
  props.updateAzione(7);
  }; 

  return (
    <div>
      {person &&
      <>
     <span>--{person._id}--</span> 
      <Toast ref={toast} />
      
      <div style={{backgroundColor: '#0a568d', border: '1px solid #e5e7eb', padding: '0.2rem 0.2rem', fontWeight: '700', marginBottom: '1em'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#0a568d'}}>
          {daSalvare &&
            <button className="p-button p-component" onClick={() => {salva()}} disabled={!abilAnagUPD}>
                <Icon path={mdiContentSave} size={1.2} />
            </button>
          }
          <button className="p-button p-component">
              <Icon path={mdiPrinter} size={1.2} />
          </button>
          <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
          <Button className="mr-2" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup><Icon path={mdiMenu} size={1.2} /></Button>
          <button className="p-button p-component"  onClick={() =>viaschedagioc()}  >
              <Icon path={mdiSoccerField} size={1.2} />
          </button>
          <button className="p-button p-component"  onClick={() =>vaidettgrup()} >
              <Icon path={mdiAccountMultipleCheck} size={1.2} />
          </button>
          <button className="p-button p-component" onClick={()=>navigate("../persone")}>
              <Icon path={mdiAccountMultiple} size={1.2} />
          </button>
        </div>
      </div>
          <div className="dati">
            <label htmlFor="nome">Nome</label>
            <IconField iconPosition="left">
            <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText  id="nome" value={person.nome} onChange={(e)=>{setPerson({ ...person, nome: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div>
       
          <div className="dati">
            <label htmlFor="cognome">Cognome</label>
            <IconField iconPosition="left">
            <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="Cognome" value={person.cognome} onChange={(e)=>{setPerson({ ...person, cognome: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div>
         <div className="dati">
            <label htmlFor="select">Sesso</label>
            <SelectButton value={person.sesso} options={options} onChange={(e)=>{setPerson({ ...person, sesso: e.value }); setDaSalvare(true)}} optionLabel="sesso" optionValue="valore" id="select" disabled={!abilAnagUPD}/>
          </div>
       
         
       
          <div className="dati">
            <label htmlFor="date">Data di nascita</label>
            <Calendar value={new Date(person.datanascita.setHours(0, 12, 0, 0))} onChange={(e)=>{setPerson({ ...person, datanascita: e.value }); setDaSalvare(true)}}  id="date" disabled={!abilAnagUPD}/>
          </div>
          <div className="dati">
            <label htmlFor="luogo">Luogo di nascita</label>
            <IconField iconPosition="left">
            <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText value={person.luogoNascita} onChange={(e)=>{setPerson({...person, luogoNascita: e.target.value}); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div> 
         <div className="dati">
            <label htmlFor="codice">Codice fiscale</label>
            <IconField iconPosition="left">
            <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="codice" value={person.codicefiscale} onChange={(e)=>{setPerson({ ...person, codicefiscale: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div>
          
       
         

          <div className="dati">
            <label htmlFor="nazionalità">Nazionalità</label>
            <AutoComplete id="nazionalità" field="name" value={person.nazionalita} suggestions={filteredCountries} completeMethod={search} onChange={(e)=>{setPerson({ ...person, nazionalita: e.value}); setDaSalvare(true)}} itemTemplate={itemTemplate} showEmptyMessage={false} optionValue="code" disabled={!abilAnagUPD}/>
          </div><div className="dati">
            <label htmlFor="genitori">Genitori</label>
            <IconField iconPosition="left">
            <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="genitori" value={person.genitori} onChange={(e)=>{setPerson({ ...person, genitori: e.target.value }); setDaSalvare(true)}} readOnly disabled={!abilAnagUPD}/>
            </IconField>
          </div><div className="dati">
            <label htmlFor="madre">Cognome e nome della madre</label>
            <IconField iconPosition="left">
            <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="madre" value={person.cognomeNomeMadre} onChange={(e)=>{setPerson({ ...person, cognomeNomeMadre: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div>
        
        

        <div className="dati">
            <label htmlFor="codMadre">Codice fiscale della madre</label>
            <IconField iconPosition="left">
             <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="codMadre" value={person.codicefiscaleMadre} onChange={(e)=>{setPerson({ ...person, codicefiscaleMadre: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div>
          
          <div className="dati">
            <label htmlFor="padre">Cognome e nome del padre</label>
            <IconField iconPosition="left">
             <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="padre" value={person.cognomeNomePadre} onChange={(e)=>{setPerson({ ...person, cognomeNomePadre: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div><div className="dati">
            <label htmlFor="codPadre">Codice fiscale del padre</label>
            <IconField iconPosition="left">
             <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="codPadre" value={person.codicefiscalePadre} onChange={(e)=>{setPerson({ ...person, codicefiscalePadre: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div><div className="dati">
            <label htmlFor="iban">Iban per addebiti</label>
            <IconField iconPosition="left">
             <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputText id="addebiti" value={person.iban} onChange={(e)=>{setPerson({ ...person, iban: e.target.value }); setDaSalvare(true)}} disabled={!abilAnagUPD}/>
            </IconField>
          </div>
        
          <div className="dati" style={{width: '100%'}}>
            <label htmlFor="iban">Note</label>
            <IconField iconPosition="left">
             <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
            <InputTextarea style={{width: '100%'}} autoResize value={person.note} onChange={(e)=>{setPerson({ ...person, note: e.target.value }); setDaSalvare(true)}} rows={5} cols={30} disabled={!abilAnagUPD}/>
            </IconField>
          </div>

        </>
      }
    </div>
  );
}


export default DettaglioPersona;