import moment from 'moment'
import test_tipoRisutati from '../obj/test_tipoRisutati.js';

const test_tipoPrototype = {

  to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
  },
}

function test_tipo() {
 this.idsoc = "";
 this.idtest="";
 this.nometest = "";
 this.descrizione = "";
 this.area="";
 this.giornival=0;
 this.tipo_elaborazione="";
 this.run_componente="InputValore";
 this.ival = new Date();
 this.fineval = moment().add(20, 'years').toDate();
 this.tipoRisultati = [];
   this.__proto__ = test_tipo.prototype;
}

test_tipo.prototype = test_tipoPrototype;
test_tipo.prototype.constructor = test_tipo;


export default test_tipo;