import React, { useEffect, useState, useRef } from 'react';

import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';

import { Button } from 'primereact/button';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import moment from 'moment'
import restProfiloUtente from '../restcall/restProfiloUtente.js';
import restGruppi from '../restcall/restGruppi.js';
import datiGen from '../util/datiGen.js';
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import elenchi from '../util/elenchi';
import restPersona from '../restcall/restPersona';
import Person from '../obj/person';
import {
  mdiUpdate, mdiContentSaveAll, mdiAccountSwitchOutline, mdiPlus, mdiAccountMinus,
  mdiAccountDetails,
  mdiCloseBox,
  mdiPencil,
  mdiSelectSearch,
  mdiAccountMultipleCheck

} from '@mdi/js';
import RicercaPersone from './RicercaPersone';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Label } from '@mui/icons-material';


const ListaProfili = (props) => {
  const navigate = useNavigate();
  const abilPU = datiGen.getInstance().possoaccederev2('PROFILIUTENTE');
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [listatot, setListatot] = useState();
  
  const [selectedId, setSelectedId] = useState([]);
  const [selProfilo, setSelProfilo] = useState();
  const [daSalvare, setDaSalvare] = React.useState(false);
  const [dettProfiloVisible, setDettProfiloVisible] = useState(false);
  const [listaGruppiSQ, setListaGruppiSQ] = useState();
  const [selezGruppo, setSelezGruppo] = useState();
  const [selezGruppo2, setSelezGruppo2] = useState();
  const [textric, setTextric] = useState();
  const [validi, setValidi] = useState(false);
  const [desAnagAss, setDesAnagAss] = useState();


  const datad = datiGen.getInstance().getruolifunz();
  const toast = useRef(null);
  const dlg = useRef(null);
  const op = useRef(null);
  const opatleti = useRef(null);

  const confirm1 = () => {
    if (daSalvare) {
      confirmDialog({
        message: 'Non tutte le modifiche sono state salvate. Sei sicuro di voler cambiare pagina e perdere le modifiche?',
        header: 'Attenzione !!',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept: (() => { setDettProfiloVisible(false); setDaSalvare(false) }),     ///// implemetnare salva
        reject: (() => { setDettProfiloVisible(true) }),    ///// implementare esci
      });
    }
    else {
      setDettProfiloVisible(false);
    }
  };


  const confermaeli = (squadra) => {
    confirmDialog({
      message: 'Sei sicuro di voler eliminare i diritti per questo gruppo?',
      header: 'Attenzione !!',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => {
        let x = { ...selProfilo }; x.ruoliGruppiSquadra.splice(x.ruoliGruppiSquadra.findIndex(item => item.grupposq === squadra), 1);
        setSelProfilo(x);; setDaSalvare(true);
      }),  ///// implemetnare salva
      reject: (() => { setDaSalvare(false) }),    ///// implementare esci
    });
  };


  useEffect(() => {
    getListaProfili(); getListaSQ();
    setDesAnagAss([]);
  }, []);


  useEffect(() => {
  console.log('useeffet',desAnagAss);
  }, [desAnagAss]);

  useEffect(() => {
    console.log('useeffet','per aggiornamento lista');
    }, [data]);
  
  const getListaProfili = async () => {
    try {
      setLoading(true);
      var json = await restProfiloUtente.getInstance().getListaProfiliUtente();
      setData(json);
      setListatot(json);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const getListaSQ = async () => {
    try {
      setLoading(true);
      var json = await restGruppi.getInstance().getGruppi();
      setListaGruppiSQ(json);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const grupposelez = async (val) => {
    try {
      const found = listaGruppiSQ.find((element) => element._id == val);
      setSelezGruppo(found);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  const grupposelez2 = async (val) => {
    try {
      const found = listaGruppiSQ.find((element) => element._id == val);
      setSelezGruppo2(found);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  const selezProfilo = (item) => {
    if (!item.ruoliGruppiSquadra)
      item.ruoliGruppiSquadra = [];
    setSelProfilo(item);
    grupposelez(item.gruppoSQPred);
    grupposelez2(item.gruppoSQPred2);
    confirm1();
  }

  const renderItem = (item, index) => (
    <div className='listaItem' style={{}} >
      <div className='horizFlexcontainer' key={item.id}>
        <div style={{ marginLeft: "1rem", flex: 1 }}
          onClick={() => { selezProfilo(item) }}
        >
          {item && item.displayName && item.displayName != '' &&
            <label style={{ fontWeight: 600 }}  >{item.displayName ? item.displayName : ''}</label>
          }
          <div style={{ flex: 2, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%', }}
          >
            <label style={{ flex: 1, color: "gray" }}  >{item.email}</label>
            <label style={{ flex: 1, color: "gray", marginLeft: "0.2rem" }}> {item.idsoc} </label>
          </div>
        </div>
        {abilPU &&
        <div>
          <Button
            onClick={() => { selezProfilo(item); setDettProfiloVisible(true) }}
          >
            <Icon path={mdiAccountDetails} size={0.8} />
          </Button>
        </div>}

      </div>
    </div>
  )


  const selgioc = (pgioc) => {
    if (selProfilo) {
      let x = { ...selProfilo }
      x.idPersona = pgioc._id;
      setSelProfilo(x);
      setDaSalvare(true);
    }
  }

  const chiudip = () => {

    op.current.hide();
  }

  const chiudiopatleti = () => {

    opatleti.current.hide();
  }


  useEffect(() => {
  }, [selProfilo]);

  const headerElement = (
    <div>
            <div className="listazioni" style={{ paddingLeft: "0.3em", justifyContent: 'space-between' }}  >
        <label style={{}} >{selProfilo && selProfilo.displayName}</label>
        <div className="listazioni" style={{ paddingLeft: "0.3em" }}  >
          {abilPU && daSalvare &&
            <Button
              onClick={() => restProfiloUtente.getInstance().modifica(selProfilo).then(() => { setDaSalvare(false); getListaProfili(); })}
            >
              <Icon path={mdiContentSaveAll} size={0.8} />
            </Button>
          }
          <Button
            onClick={() => { confirm1(); }}
          >
            <Icon path={mdiCloseBox} size={0.8} />
          </Button>
        </div>
      </div>
    </div>
  );


  const filtraProfili = (pval) => {
   let x_ini=[... listatot];
   let valoriutili=[];
  if (pval)
  var x=x_ini.filter((ele)=>ele.displayName.toUpperCase().includes(pval.toUpperCase()) );
else x=x_ini;

x.forEach(ele => {
  
var a;
var b=false;
if (!validi) b=true;
else {
if (ele.ruoli.filter((ele)=>ele.idsoc=='VM')[0])  
    a=ele.ruoli.filter((ele)=>ele.idsoc=='VM')[0].gruppi;
if (a)
    b=ele.ruoli.filter((ele)=>ele.idsoc=='VM')[0].gruppi.includes('LOGINPWA');
}
if ( b==true)
  valoriutili.push(ele);

});



  setData(valoriutili);
  }

  const selgioc_atleti = (pgioc) => {
    if (selProfilo) {
      let x = { ...selProfilo }
      if (!x.atleti) x.atleti=[];
      x.atleti.push(pgioc._id);
      setSelProfilo(x);
      setDaSalvare(true);
      atleticognom(pgioc._id);
    }

  }


  const atleticognom = async (val) => {


    let x = await restPersona.getInstance().getPersona(val,null);
    x.__proto__ = Person.prototype;
    x.to_obj(); 
   
//return x.cognome+' '+x.nome;
let mp=Array.from(desAnagAss);
mp.push({chiave:val,valore:x.cognome+' '+x.nome});
setDesAnagAss(mp);
  }



  const renderItemAtleti = (item, index) => (
    <div className='listaItem' key={item}>
{desAnagAss && desAnagAss.find((el)=>el.chiave==item) &&
     <label>{ desAnagAss.find((el)=>el.chiave==item).valore}</label>
}
    </div>
  )






  return (
    <div>
<div style={ { display:'flex', flex:1,flexDirection:'row' ,justifyContent:'space-between',margin:'0.3rem'  }}>
<InputText placeholder='cognome' className="longInput"
          onChange={(e) => {setTextric(e.target.value);filtraProfili(e.target.value); }}         
          value={textric}
        ></InputText>      
           <Button
                      onClick={(e) => {setValidi(!validi);  }}
                    >
                      <Icon path={mdiAccountMultipleCheck} size="1.2rem" />
                    </Button>
        <Button
                      onClick={(e) => {filtraProfili(textric);  }}
                    >
                      <Icon path={mdiSelectSearch} size="1.2rem" />
                    </Button>
                    </div>

      <OverlayPanel ref={op} onHide={() => chiudip()}   >
        <div style={{ width: '100%', height: "50vh" }}>
          <span style={{ marginLeft: 0, marginTop: "0.2rem", marginBottom: '2rem' }} className='Titolo2'>Associazione Anagrafica Profilo</span>
          <RicercaPersone style={{}} selGioc={selgioc} chiudi={chiudip} />
        </div>
      </OverlayPanel>

      <OverlayPanel ref={opatleti} onHide={() => chiudiopatleti()}   >
        <div style={{ width: '100%', height: "50vh" }}>
          <span style={{ marginLeft: 0, marginTop: "0.2rem", marginBottom: '2rem' }} className='Titolo2'>Associazione Atleti</span>
          <RicercaPersone style={{}} selGioc={selgioc_atleti} chiudi={chiudiopatleti} />
        </div>
      </OverlayPanel>


      {data &&
        <div style={{ flex: 1, width: '100%' }}>

          {selProfilo && selProfilo.displayName &&
            <Dialog ref={dlg} header={headerElement} visible={dettProfiloVisible} style={{ width: '95vw', maxHeight: '95%', minHeight: '50%' }} closable={false}
              closeOnEscape={true}
             onHide={() => { if (!dettProfiloVisible) return; setDettProfiloVisible(false) }}>
                               <div style={{ backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
                               <label style={{ fontWeight: '900', fontSize: '1.2rem', color: '#0a568d', }}  >Dati generali</label>
                               </div>
              <div style={{fontSize:"1rem"}}>
                <div >
                  <div className='horizFlexcontainer'>  <label style={{ fontWeight: 600 }}>Società principale:</label>
                    <IconField iconPosition="left"  >
                      <InputIcon style={{left:"1rem"}}>
                        <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                      </InputIcon>
                      <InputText className='smallInput'
                        value={selProfilo.idsoc} style={{width:"6rem"}}
                        onChange={vcf => { let x = { ...selProfilo }; x.idsoc = vcf.target.value; setSelProfilo(x); setDaSalvare(true); }}
                      >

                      </InputText>
                    </IconField>
                  </div>


                  <div className='horizFlexcontainer' >
                    <label style={{ fontWeight: 600 }}>Associazione Anagrafica :</label>
                    <label style={{ fontSize:'0.8rem',  }} >{selProfilo.email}</label>
                    <label style={{ fontSize:'0.8rem',  }}>{selProfilo.idPersona}</label>

                    <Button
                      onClick={(e) => { op.current.toggle(e) }}
                    >
                      <Icon path={mdiAccountSwitchOutline} size="1rem" />
                    </Button>

  
                </div>

                    <div className='horizFlexcontainer' >
                    <label style={{ fontWeight: 600 }}>Associazione Atleti :</label>
                    <div style={{ width: '100%' }}>
            <DataView
              value={selProfilo.atleti} itemTemplate={renderItemAtleti} >
            </DataView>
          </div>

                    <Button
                      onClick={(e) => { opatleti.current.toggle(e) }}
                    >
                      <Icon path={mdiAccountSwitchOutline} size="1rem" />
                    </Button>






                  </div>
                  <div className='horizFlexcontainer' style={{}}>
                    <label style={{ fontWeight: 600 }} >Tipo Profilo:</label>
                    <Dropdown
                      value={selProfilo.TipoProfilo}
                      options={elenchi.getInstance().getTipoProfilo()}
                      optionLabel="label"
                      optionValue="value"
                      style={{ marginLeft: "0.3em", marginRight: "0.3em" }}

                      onChange={(item) => { let x = { ...selProfilo }; x.TipoProfilo = item.value; setSelProfilo(x); setDaSalvare(true); }} />
                  </div>



                  <div className='horizFlexcontainer' style={{}}>
                    
                      <label style={{ fontWeight: 600 }} >Gruppo Principale:</label>
                      <Dropdown value={selezGruppo} options={listaGruppiSQ} optionLabel="des"
                        style={{}}
                        onChange={(item) => {
                          let x = { ...selProfilo }; x.gruppoSQPred = item.value._id; setSelezGruppo(item.value);
                          setSelProfilo(x); setDaSalvare(true);
                        }} />
                    </div>
                    <div className='horizFlexcontainer' style={{}}>
                      <label style={{ fontWeight: 600 }} >Gruppo secondario:</label>
                      <Dropdown value={selezGruppo2} options={listaGruppiSQ} optionLabel="des"
                        style={{ }}
                        onChange={(item) => { let x = { ...selProfilo }; x.gruppoSQPred2 = item.value._id; setSelezGruppo2(item.value); setSelProfilo(x); setDaSalvare(true); }} />
                    </div>
                  
                </div>

                <div key={selProfilo.ruoli.length} >
                  <div style={{ backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
                    <label style={{ fontWeight: '900', fontSize: '1.2rem', color: '#0a568d', }}  >Ruoli</label>
                    <Button
                      onClick={() => { selProfilo.ruoli.push({ idsoc: datiGen.getInstance().getActiveSoc().sigla, gruppi: [] }); setDaSalvare(true); }}>
                      <Icon path={mdiPlus} size={0.8} />
                    </Button>
                  </div>
                  {
                    selProfilo.ruoli.map((ele, i) => (
                      <div key={i} style={{ paddingBottom: 3 }}>
                        <div>

                          <label style={{ flex: 1, fontWeight: '600', color: '#0a568d' }} >Società:{ele.idsoc}</label>
                        </div>
                        <MultiSelect
                          options={datad}
                          optionLabel="label"
                          optionValue="value"
                          placeholder="Select item"
                          value={ele.gruppi}
                          onChange={(item) => { let x = { ...selProfilo }; ele.gruppi = item.value; setSelProfilo(x); setDaSalvare(true); }}
                          display="chip"
                          style={{}}
                        />
                      </div>
                    ))
                  }
                </div>

                <div style={{ backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
                  <label style={{ fontWeight: '900', fontSize: '1.2rem', color: '#0a568d', }}  >Gruppi/Squadre</label>
                  <Button
                    onClick={() => { selProfilo.ruoliGruppiSquadra.push({ idsoc: datiGen.getInstance().getActiveSoc().sigla, grupposq: '', diritti: [] }); setDaSalvare(true); }}
                  >
                    <Icon path={mdiPlus} size={0.8} />
                  </Button>
                </div>
                {
                  selProfilo.ruoliGruppiSquadra.map((ele, i) => (
                    <div >
                      <div style={{ margin: 2 }}><label style={{ flex: 1, fontWeight: '600', color: '#0a568d' }} >Società/Squadra:{ele.idsoc}</label></div>

                      <div style={{ flex: 1, flexDirection: 'row', display: "flex", margin: 2 }}>


                        <Dropdown

                          options={listaGruppiSQ}
                          optionLabel="des"
                          optionValue="_id"
                          value={listaGruppiSQ.find((el) => el._id == ele.grupposq)}
                          onChange={item => {
                            let x = { ...selProfilo };
                            ele.grupposq = item.value._id; setSelProfilo(x); setDaSalvare(true);
                          }}
                          style={{}}

                        />
                        <Button
                          style={{ height: "2rem" }}

                          onClick={() => { confermaeli(ele.grupposq); }}
                        >
                          <Icon path={mdiAccountMinus} size={0.8} />
                        </Button>
                      </div>


                      <MultiSelect
                        options={datiGen.getInstance().getruolifunzGruppoSQ(ele.grupposq)}
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select item"
                        value={ele.diritti}
                        onChange={item => { let x = { ...selProfilo }; ele.diritti = item.value; setSelProfilo(x); setDaSalvare(true); }}
                        display="chip"
                        className="w-full md:w-20rem"
                        style={{}}
                      />


                    </div>
                  ))}
              </div>

            </Dialog>
          }


          
          <DataView
            header=
            {<div className="listazioni"  >
              <span style={{ color: 'white' }}>Lista Profili</span>
              {abilPU &&
                <Button
                  onClick={() => getListaProfili(datiGen.getInstance().getActiveSoc().sigla)} >
                  <Icon path={mdiUpdate} size={0.8} />
                </Button>
              }

            </div>}
            value={data}
            itemTemplate={renderItem}
            rows={25}
          />

        </div>
      }
    </div>
  );
}
export default ListaProfili;