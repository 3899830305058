// screens/Home.js


import { useNavigate, useOutletContext } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputSwitch } from 'primereact/inputswitch';

import { Calendar } from 'primereact/calendar';

import Icon from '@mdi/react';
import { mdiContentSave, mdiDelete, mdiPencil, mdiPhone,mdiGamepadVariantOutline,mdiAccountCheck } from '@mdi/js';

import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import {  mdiCalendarMultipleCheck, mdiPlus } from '@mdi/js';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import Attivita from "../obj/Attivita";
import restAttivita from "../restcall/restAttivita";
import datiGen from "../util/datiGen";
import elenchi from '../util/elenchi.js';
import restGruppi from "../restcall/restGruppi";
import moment from 'moment/min/moment-with-locales';
import gruppo from "../obj/gruppo";
import restConfigurazioniTest from "../restcall/restConfigurazioniTest";
const DettaglioAttivita = (props) => {
  const navigate = useNavigate();

  const [att, setAtt] = useState();
  const [daSalvare, setDaSalvare] = useState(false);
  const [abilEditOrg, setAbilEditOrg] = useState('false');
  const abilAtt = datiGen.getInstance().possoaccederev2('ATTIVITA', props.gruppo);
  const abilAttUPD = datiGen.getInstance().possoaccederev2('ATTIVITAUPD', props.gruppo);
  const abilGiocAtt = datiGen.getInstance().possoaccederev2('GiocAttivita', props.gruppo);
  const abilAllPres = datiGen.getInstance().possoaccederev2('ALLGIOCATTIVITAUPD');
  const abilGruppi = datiGen.getInstance().possoaccederev2('GRUPPI');
  const abilElencoTest = datiGen.getInstance().possoaccederev2('ELENCOTEST');
  const abilTABPARTITA = datiGen.getInstance().possoaccederev2('TABPARTITA', props.gruppo);
  const abilcommPARTITA = datiGen.getInstance().possoaccederev2('COMMPARTITA', props.gruppo);
  const abilEsecTest = datiGen.getInstance().possoaccederev2('ESECTEST', props.gruppo);
  const abilMostraID = datiGen.getInstance().possoaccederev2('MOSTRAID');

  const [daNotificare, setDaNotificare] = useState(false);
  const [listaGruppiSQ, setListaGruppiSQ] = useState([]);
  const [listaCampionati, setListaCampionati] = useState([]);
  const [edtInd, setEdtInd] = useState(false);
  const [tmpInd, setTmpInd] = useState();
  const [provenienza, setProvenienza] = useState(null);

  const [evtest, setEvtest] = useState('');
  const [listaTipoTest, setListaTipoTest] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();

  useEffect(() => {
    if (props.selAttivita) {
      let tmp_att = props.selAttivita;
      tmp_att.__proto__ = Attivita.prototype;
      tmp_att.to_obj();
      setAtt(tmp_att);
      fAbilEditOrg(tmp_att);
      getListaSQ();   
      getListaTest();   
//non caricare qui campionati in quanto non è ancora finita getlistasq
    } else {

    }
  }, []);

  useEffect(() => {
    console.log("useEffect att", att);
   
  }, [att]);

  useEffect(() => {
    
    caricacampionati(1);
    
  }, [listaGruppiSQ]);

  useEffect(() => {
    console.log('modifco 0');
    if (daSalvare) {
      let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 3, '1');
      setStatoDaSalvare(x);
    } else if (!daSalvare) {
      let x = statoDaSalvare.replaceAll("1", "0");
      setStatoDaSalvare(x);
    }
  }, [daSalvare]);

  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }
  const showInfo = () => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: 'Message Content', life: 3000 });
  }
  const showWarn = () => {
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Message Content', life: 3000 });
  }
  const showError = () => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Message Content', life: 3000 });
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };

  const confirmElimina = (patt) => {

    confirmDialog({
      message: "L'attività sarà eliminata definitivamente. Sei sicuro di voler procedere?",
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => { elimina(patt); }),
      reject: (() => { return; }),
    });
  };

  const elimina = async (patt) => {

    var grp = props.gruppo;

    restAttivita.getInstance().elimina(patt, grp).then(() => {
      setDaSalvare(false);
      setAbilEditOrg(false);
      navigate("/attivita");

    });

  }


  const salvaNuovo = async (patt) => {
    var grp = props.gruppo;

    restAttivita.getInstance().aggiungi(patt, grp).then(() => {
      setDaSalvare(false);
      setAbilEditOrg(false);
      setDaNotificare(false);
      setEdtInd(false);
    });

  }


  const modifica = async (patt) => {
    try {

      restAttivita.getInstance().modifica(patt, props.gruppo, daNotificare).then(() => {
        setDaSalvare(false);
        setDaNotificare(false);
        setAbilEditOrg(false);
        setEdtInd(false);
      }


      );


      //console.log("DettSchedaGioc", "modifica", json);
    } catch (error) {
      console.error(error);
    } finally {

    }


  }


  const fAbilEditOrg = (papp) => {
    let x = false;
    let caso = 0;
    const lu = datiGen.getInstance().getElencoConf('USERS_ORG_AGE');
    const u = datiGen.getInstance().getUtenteCollegato().email;

    var incl = false;

    papp.gruppi.forEach((ele) => { if (lu.includes(ele + "#" + u)) { console.log((ele + "#" + u)); incl = true; } else { console.log((ele + "#" + u)); } })


    if (!papp.opecreaz || lu.includes(papp.opecreaz) == false) {
      caso = 1;
      x = true;
      setAbilEditOrg(true);    //se l'attività non è stata creata dall'organizzazione posso modificarla
    }
    else if (lu.includes(u) || incl) {
      x = true;
      caso = 2;
      setAbilEditOrg(true);  //se l'attività  è stata creata dall'organizzazione  e l'utente fa parte dell'organizzazione posso modificarla 
    }
    else {
      caso = 3;
      x = false;
      setAbilEditOrg(false);  //altrimenti non si può modificare
    }


  }

  const getListaSQ = async () => {

    try {

      var json = await restGruppi.getInstance().getGruppi();
      setListaGruppiSQ(json);

    } catch (error) {
      console.error(error);
      setListaGruppiSQ([]);
    } finally {

    }
  }

  const caricacampionati = (para) => {
    let tmp_camp = [];
    let gr;
    let gr2;
    if (att) {
      
      att.gruppi.forEach((ele) => {
        
        gr=null;gr2=null;
        gr = listaGruppiSQ.find((elef) => elef._id == ele);
        gr2={...gr};
        
        gr2.__proto__ = gruppo.prototype;
        gr2.to_obj();
        
        tmp_camp.push(...gr2.getCampionatoAttivo());
      });
}

setListaCampionati(tmp_camp);
  }


  const torna_lista = async () => {
          console.log(props.gruppo);
    if (props.gruppo)
    { 
      navigate('/TeamPage', {state:{ gruppo: props.gruppo  ,pagina:'Attivita' }     }  )
    }
    
    else  navigate("/attivita" );



  }

  const getListaTest = async () => {
    //  console.log("lista test",listaTipoTest);

    try {
      setLoading(true);
      var json = await restConfigurazioniTest.getInstance().getAllConfigurazioniTest(true);
      setListaTipoTest(json);
      //    console.log("lista test",listaTipoTest);
    } catch (error) {
      console.error(error);
      setListaTipoTest([]);
    } finally {
      setLoading(false);
    }
  }


  const aggiungitest = async (valo) => {
    try {
      
      console.log('aggingi test',valo);
      if (!att.eltest)
        att.eltest = [];
      const idtestese =  performance.now().toString(36).toUpperCase();
         var arrele = { id: valo._id, nometest: valo.nometest, idtestese: idtestese };
      let x = { ...att };
      x.eltest.push(arrele);
      setAtt(x); setDaSalvare(true);

      //  console.log('aggiugni test',att.eltest);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }




  const eliminatest = async (index) => {
    try {
      let x = { ...att };
      x.eltest.splice(index, 1);
      setAtt(x); setDaSalvare(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }





  return (
    <div>
      <Toast ref={toast} />
      <div >
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0.2rem', alignItems: 'center', borderBottom: "solid #0a568d 0.1rem" }}>

          <span className="Titolo5">Dettaglio Attività</span>


          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.3em', alignItems: 'center' }}>
            {abilAttUPD && daSalvare && !att._id &&
              <button className="p-button p-component" onClick={() => { salvaNuovo(att) }}>
                <Icon path={mdiPlus} size={1} />
              </button>
            }
            {abilAttUPD && daSalvare && att._id &&
              <button className="p-button p-component" onClick={() => { modifica(att) }}>
                <Icon path={mdiContentSave} size={1} />
              </button>
            }


{       ((props.gruppo && abilGiocAtt) || abilAllPres) &&
                <button className="p-button p-component"
          onClick={() => {  navigate('/giocAttPage', {state:{ gruppo: props.gruppo, selAttivita: att} });} }>
          <Icon path={mdiAccountCheck} size={1} />
        </button>


        }



            {abilAttUPD && abilEditOrg && daSalvare == false && att && att._id &&
              <Button onClick={() => confirmElimina(att)}
              >
                <Icon path={mdiDelete} size={1} />
              </Button>
            }

{abilTABPARTITA && daSalvare == false && att && att._id && att.tipo.split('#')[1] == 'P' &&
                    <Button             
                    onClick={()=> {props.updateAzione(1)  }}>
             <Icon path={mdiGamepadVariantOutline} size ={1} />
                </Button>
        
}
        
            <Button onClick={() => { torna_lista() }} >
              <Icon path={mdiCalendarMultipleCheck} size={1} />
            </Button>
          </div>
        </div>
      </div>
      {att &&
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem', alignItems: 'flex-start' }}>
          {att && listaGruppiSQ && listaGruppiSQ !== 'undefined' &&
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
              <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Gruppo:</label>

              <MultiSelect
                placeholder='Seleziona uno o più gruppo'
                value={att.gruppi}
                options={listaGruppiSQ} optionLabel="des" optionValue='_id'
                style={{}}
                display="chip" filter
                disabled={!abilEditOrg || !abilAttUPD}
                onChange={(item) => {
                  let x = { ...att }; x.gruppi = item.value; setAtt(x); setDaSalvare(true); setDaNotificare(true);setTimeout(caricacampionati(4),300);
                }} />

            </div>
          }
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Tipo Attività:</label>
            <Dropdown
              value={att.tipo} showClear
              options={elenchi.getInstance().getElTipoatt()}
              style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
              disabled={!abilEditOrg || !abilAttUPD}
              onChange={val => { let x = { ...att }; x.tipo = val.target.value; setAtt(x); setDaSalvare(true); setDaNotificare(true); }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Inizio Attività:</label>
            <Calendar className="dataora" id="dt1" value={att.dataAttivita} showIcon showTime hourFormat="24"
              disabled={!abilEditOrg || !abilAttUPD} stepMinute={5} showButtonBar={true}
              onChange={(e) => {
                let x = { ...att }; x.dataAttivita = e.value; setAtt(x);
                x.dataFineAttivita = moment(x.dataAttivita).add(90, 'minutes').toDate();
                x.RitrovoDataOra = moment(x.dataAttivita).subtract(60, 'minutes').toDate();
                setDaSalvare(true); setDaNotificare(true);
              }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Fine prevista:</label>
            <Calendar className="dataora" id="dt1" value={att.dataFineAttivita} showIcon showTime hourFormat="24"
              disabled={!abilEditOrg || !abilAttUPD} stepMinute={5} showButtonBar={true}
              onChange={(e) => { let x = { ...att }; x.dataFineAttivita = e.value; setAtt(x); setDaSalvare(true); setDaNotificare(true); }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
          <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Pubblicata:</label>
          <InputSwitch checked={att.pubblicata?att.pubblicata:false} 
           onChange={(e) => { let x = { ...att }; x.pubblicata = e.value; setAtt(x); setDaSalvare(true); setDaNotificare(true); }} />
          </div>







          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Spogliatoio:</label>
            <MultiSelect
              value={att.spogliatoi} showClear
              options={elenchi.getInstance().getEl("Spogliatoi")}
              style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
              disabled={!abilEditOrg || !abilAttUPD}
              display="chip" filter
              onChange={val => { let x = { ...att }; x.spogliatoi = val.target.value; setAtt(x); setDaSalvare(true); }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: "100%", marginTop: '0.3rem' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", justifyItems: 'flex-start', marginBottom: '0.2rem' }}>
              <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Indirizzo:</label>

              {(abilEditOrg || abilAttUPD) &&
                <Icon path={mdiPencil} size={1} color={"#00adef"} onClick={() => setEdtInd(!edtInd)}
                  style={{ backgroundColor: (edtInd || att.indirizzo == null || att.indirizzo == 'undefined' || att.indirizzo.length < 4) ? 'green' : 'gray', borderRadius: "0.7rem" }}
                />
              }
            </div>
            <InputTextarea style={{ margin: "0.3rem" }}
              value={att.indirizzo} autoResize={true} rows={1}

              disabled={!(abilEditOrg && abilAttUPD && (edtInd || att.indirizzo == null || att.indirizzo == 'undefined' || att.indirizzo.length < 4))}
              onChange={vcf => {
                let x = { ...att }; x.indirizzo = vcf.target.value; setAtt(x); setDaSalvare(true);
              }}
            >
            </InputTextarea>

            <Dropdown
              value={att.indirizzo}
              options={elenchi.getInstance().getEl("INDIRIZZI_PRED")}
              style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
              disabled={!abilEditOrg || !abilAttUPD}
              onChange={val => { let x = { ...att }; x.indirizzo = val.target.value; setAtt(x); setDaSalvare(true); setDaNotificare(true); }}
            />

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', MarginTop: '0.3rem', width: '100%' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Note:</label>
            <InputTextarea value={att.note} autoResize
              disabled={!abilEditOrg || !abilAttUPD}
              onChange={(e) => {
                console.log("note:", e.target.value); let x = { ...att }; x.note = e.target.value; setAtt(x);
                setDaSalvare(true); setDaNotificare(true);
              }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Operatore creazione:</label>
            <InputText value={att.opecreaz}
              disabled={!abilMostraID}
              onChange={(e) => {
                console.log("note:", e.target.value); let x = { ...att }; x.opecreaz = e.target.value; setAtt(x);
                setDaSalvare(true); setDaNotificare(false);
              }} />
          </div>

          {att.tipo && att.tipo.split('#')[1] == 'A' &&
            <label>Allenamento</label>
          }

          {att.tipo && att.tipo.split('#')[1] == 'P' &&
            <div style={{ borderTop: 'solid 0.1rem #0a568d', marginTop: "0.3rem" }}>
              <label style={{ borderBottomWidth: 1, borderBottomColor: '#0a568d', paddingBottom: 10, marginBottom: 10, fontWeight: '700', color: '#0a568d' }} >Dati Partita</label>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem', alignItems: 'flex-start', alignContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                  <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }}>Avversario</label>
                  <IconField iconPosition="left" >
                    {abilEditOrg && abilAttUPD &&
                      <InputIcon>
                        <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                      </InputIcon>
                    }
                    <InputText className='longInput' style={{ minWidth: "15rem", flexGrow: "1" }}
                      disabled={!abilEditOrg || !abilAttUPD}
                      value={att.avversario}
                      placeholder='Avversario'
                      onChange={(e) => {
                        let x = { ...att }; x.avversario = e.target.value; setAtt(x); setDaSalvare(true); setDaNotificare(true);
                      }}
                    >
                    </InputText>
                  </IconField>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                  <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }}>Trasferta</label>
                  <InputSwitch
                    style={{ marginTop: '0.3rem' }}
                    disabled={!abilEditOrg || !abilAttUPD}
                    checked={att.trasferta}
                    onChange={(e) => { let x = { ...att }; x.trasferta = e.value; setAtt(x); setDaSalvare(true); setDaNotificare(true); }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                  <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }}>Competizione</label>
                  <Dropdown
                    value={att.TipoPartita}
                    placeholder='Seleziona il tipo di competizione'
                    options={elenchi.getInstance().getElTipoPartita()}
                    optionLabel="label"
                    optionValue="value"
                    style={{ marginLeft: "0.3em", marginRight: "0.3em" }}
                    disabled={!abilEditOrg || !abilAttUPD}
                    onChange={(e) => { let x = { ...att }; x.TipoPartita = e.target.value; setAtt(x); setDaSalvare(true); caricacampionati(5); }}

                  />

                </div>

                {att.TipoPartita == 'C' && listaCampionati &&
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                    <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }}>Campionato </label>
                                        <Dropdown
                      
                      value={listaCampionati.find((el) => el.id == att.campionato)}
                      placeholder='Seleziona il campionato'
                      options={listaCampionati}
                      optionLabel="categoria"
                      optionValue="id"
                      style={{ marginLeft: "0.3em", marginRight: "0.3em" }}
                      disabled={!abilEditOrg || !abilAttUPD}
                      onChange={(e) => { let x = { ...att }; x.campionato = e.target.value.id; setAtt(x); setDaSalvare(true); }}

                    />
                  </div>
                }


                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                  <label>Risultato a tempi</label>
                  <InputSwitch
                    disabled={!abilEditOrg || !abilAttUPD}
                    checked={att.risTempi}
                    style={{ marginTop: '0.3rem' }}
                    onChange={(e) => { let x = { ...att }; x.risTempi = e.value; setAtt(x); setDaSalvare(true); }}
                  />
                </div>

                <div style={{ backgroundColor: '#f5f5f5', width: '100%', borderTop: 'solid 0.1rem #0a568d', marginTop: "0.3rem" }}>
                  <label style={{ fontWeight: '700', color: '#0a568d' }}>Dati ritrovo</label>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                    <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Indirizzo ritrovo (se diverso):</label>

                    <IconField iconPosition="left" >
                      {abilEditOrg && abilAttUPD &&
                        <InputIcon>
                          <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                        </InputIcon>
                      }
                      <InputText className='longInput' style={{ minWidth: "15rem", flexGrow: "1" }}
                        disabled={!abilEditOrg || !abilAttUPD}
                        placeholder='Indirizzo se diverso'
                        value={att.RitrovoIndirizzo}
                        onChange={(e) => {
                          let x = { ...att }; x.RitrovoIndirizzo = e.target.value; setAtt(x); setDaSalvare(true); setDaNotificare(true);
                        }}
                      >
                      </InputText>
                    </IconField>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>

                    <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Data ritrovo:</label>

                    <Calendar id="dt4" value={att.RitrovoDataOra} showIcon showTime hourFormat="24"
                      disabled={!abilEditOrg || !abilAttUPD} stepMinute={5}
                      showButtonBar={true} inputStyle={{ width: "10rem", maxWidth: "10rem" }} style={{ width: "13rem", maxWidth: "13rem" }}
                      onChange={(e) => { let x = { ...att }; x.RitrovoDataOra = e.value; setAtt(x); setDaSalvare(true); setDaNotificare(true); }} />
                  </div>

                </div>


                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                  <label>Dati referente:</label>
                  <IconField iconPosition="left" >
                    {abilEditOrg && abilAttUPD &&
                      <InputIcon>
                        <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                      </InputIcon>
                    }
                    <InputText className='longInput' style={{ minWidth: "15rem", flexGrow: "1" }}
                      disabled={!abilEditOrg || !abilAttUPD}
                      value={att.referente}
                      placeholder='Nome referente'
                      onChange={(e) => {
                        let x = { ...att }; x.referente = e.target.value; setAtt(x); setDaSalvare(true);
                      }}
                    >
                    </InputText>
                  </IconField >
                  <IconField iconPosition="left" >
                    {abilEditOrg && abilAttUPD &&
                      <InputIcon>
                        <Icon path={mdiPhone} size={0.8} color={"#00adef"} />
                      </InputIcon>
                    }
                    <InputText className='longInput' style={{ minWidth: "15rem", flexGrow: "1", marginTop: '0.3rem' }}
                      disabled={!abilEditOrg || !abilAttUPD}
                      value={att.nrreferente}
                      placeholder='tel referente'
                      keyfilter='int'
                      onChange={(e) => {
                        let x = { ...att }; x.nrreferente = e.target.value; setAtt(x); setDaSalvare(true);
                      }}
                    >
                    </InputText>
                  </IconField>
                </div>
              </div>

            </div>

          }

{abilElencoTest && abilEditOrg && abilAttUPD && att.tipo && att.tipo.split('#')[1] == 'T' &&
<div style={{width:'100%'}}>
{abilEditOrg &&
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.3rem' }}>
                  <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }} >TEST</label>
                  <Dropdown
                    placeholder='Seleziona test'
                    options={listaTipoTest}
                    optionLabel="nometest"
                    optionValue="_id"
                    value={evtest}
                    style={{ marginLeft: "0.3em", marginRight: "0.3em" }}
                    onChange={val => { aggiungitest(val.target.value) }}                
                  />
                </div>
              }

              {        
                att.eltest && att.eltest.map((ele, i) => (
                  <div className='listaItem'>
                  <div style={{ flex: 2, flexDirection: 'row', display: "flex", justifyContent: 'space-between', alignContent: 'flex-end' }}>


                      <label  >{ele.nometest}</label>
                      <div style={{  flexDirection: 'row', alignSelf: 'flex-end', alignContent: 'flex-end', alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>

                    {abilEditOrg && abilAttUPD &&
                     <Button
                        style={{  marginRight: '0.3rem' }}                       
                       onClick={() => {
                          confirmDialog({
                            message: 'Il test sarà eliminato. Sei sicuro di voler procedere?',
                            header: 'Attenzione',
                            icon: 'pi pi-exclamation-triangle',
                            defaultFocus: 'accept',
                            accept: (()=>{eliminatest(i)}),
                            reject: (()=>{;}),
                          });

                        }}
                      >
                      <Icon path={mdiDelete} size={0.8} />
                      </Button>
                    }</div>
                  </div>
                  
                  </div>
                ))}
</div>}                




        </div>

}
    </div>

  );
}

export default DettaglioAttivita;