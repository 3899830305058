import datiGen from './datiGen.js';
import moment from 'moment';
import restAttivita from '../restcall/restAttivita.js';


export default class utilPresenze {

  constructor() {
  }
  static myInstance = null;

  static getInstance() {
    if (utilPresenze.myInstance == null) {
      utilPresenze.myInstance = new utilPresenze();
      // Util.myInstance.inizializza();
     // utilVisite.myInstance.inizializza().then(() => console.log('Inizializing'));
    }

    return utilPresenze.myInstance;
  }

/*
  inizializza() {
    return new Promise((resolve, reject) => {

     
    

    }, (error) => {

      reject(error);
    })
  }
*/




getListaAttivitaPres = async (date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro,indFiltro,totali) => {
  return new Promise((resolve, reject) => {
    var lista='k11';
    if (!totali)
    {
      totali=false;
    }
    else
    {
totali='t';
    }


    try {

     // console.log('TeamAttivita',abilAtt,abilAttUPD);
 


//console.log('prima di chiamata',date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro);
        restAttivita.getInstance().getForPresenceStats(date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro,indFiltro).then((json) => {
     //   console.log('utilpresenze','getListaAttivitaPres',json);
      if ( json.esito!=='undefined' && json.esito==='ko' )
            {
     console.log(' errore nel json')
      }
      else
 {       
   this.crealista(json,totali).then((plista)=> {
   // console.log('TeamInfo','getListaAttivitaPres',json)
  // console.log('3345565',plista);
  lista=plista;
  
//console.log('utilpresenze','listafinalex41' ,lista);
    resolve(lista);
/* utilPresenze.getInstance().disegnalista(lista).then((x)=>{    
      console.log('teaminfo','lista',lista,x)});*/
  }).catch(err => { console.error('utilpresenze','getlista',err) });
 }

      
      });
    } catch (error) {
      console.error(error);
    } finally {
     ;
    }


  });




  };


  

  
crealista = async (json,tipot) =>
  {
   return new Promise((resolve, reject) => {


  var lista=[];

//console.log('crealista',json.length);
//console.log('crealista','json',json[0].gioc);


  var elemento=[];
  //var posAtt=new Map();
  var posGioc=new Map();      
       
          var col=0;
  var i=0;
  var num=0;
  
  for (var conta=0;conta<json.length+1;conta++)
  elemento[conta]='';

  elemento[0]='Nome';
  lista[0]=elemento;

  var pgg=-1;
  var el;
  //lista[0]=elemento;
  num=1;
  var dataAttivitaC='';
  var element;
      try {
        if (!datiGen.getInstance().possoaccederev2('ATTIVITA')) {
          throw 'Non sei abilitato a svolgere la funzione ATTIVITA';
        }



        json.forEach((ele,pos) => {
         // console.log('Attività','-----------------------------------------------') ;
          lista[0][pos+1]={data:ele.dataAttivita,tipo:ele.tipo,id:ele._id};



            ele.gioc.forEach((gg,pgioc )=> {
          //    console.log('Giocatore',gg) ;
             // console.log('lista',lista) ;
               pgg= (posGioc.get(gg.idgioc));
              if (!pgg || pgg==='undefined')
              {
                

                if (gg.Giocatore && gg.Giocatore[0])
                {
                   posGioc.set(gg.idgioc,posGioc.size+1);
                 pgg= (posGioc.get(gg.idgioc));
                 lista.push([]);
                 for (var conta=0;conta<json.length+1;conta++)
{
  
                 lista[pgg][conta]='';
}                
                 lista[pgg][0]={idgioc:gg.idgioc,cognome:gg.Giocatore && gg.Giocatore[0]?gg.Giocatore[0].cognome:'',nome:gg.Giocatore && gg.Giocatore[0]?gg.Giocatore[0].nome:''}

                }
                else
                {
               //   console.log('crealista','problema giocatore',ele);
                  return;
                }

                 
                 
              }

           //   console.log('crealista','O10');
              if (gg.Giocatore) {                
                


var pres='';
//console.log('utilpresenze','controllo presenza',gg.presente,gg);
if (gg.presente==true  )
{//console.log('utilpresenze', 'caso true',gg.presente);
pres='P';}
else if ((gg.presente==false || gg.presente==0)&& gg.convocato==true)
{//console.log('utilpresenze', 'caso false',gg.presente,gg.convocato);
pres='A';
}
else if ((gg.presente==false || gg.presente==0)&& gg.convocato==false)
{//console.log('utilpresenze', 'caso 0',gg.presente);
pres='NC';
}
else pres='Z';

        
lista[pgg][pos+1]={idattivita:ele._id,idgioc:gg.idgioc,presentedes:pres,presente:gg.presente,convocato:gg.convocato,causaassenza:gg.causaassenza,mingioc:gg.mingiocati};

               
          
  
  
         
 // console.log('creattività','Fine ciclo');
  
  
  }
            }); 
            
            
 //console.log('creaattività','fine ------------------------------------------------');
        });
      } catch (error) {
        console.error('x121',error);
      } finally {
       ;
      }
 
  //****  
  var ind=0;   var lung=-1;
 lista.forEach(elem =>{ 
  if (lung<0) lung=elem.length;
     // console.log('x122',' conrtrollo lista',elem[0],elem[1],elem[2],elem[3],elem[4],elem[5],elem[6],elem[7],elem.length)
    for (ind=0;ind<lung;ind=ind+1)
    {
      if (elem[ind]==undefined)
            elem[ind]=0;
    }
    
    });  
 

 //console.log("tipot",tipot);
 if (tipot==='t') lista=this.creatotali(lista);

 //lista.forEach(elem =>{ 
  //console.log('x172',' conrtrollo lista',elem)});  


resolve(lista);
}).catch(err => { console.error('utilpresenze','crealista',err) });

  }


  //// funzione per creare totali da lista
creatotali= (plista)=>{
var numeroall=0,numeropartite=0,numeroeventi=0,numerotest=0;
var sommapres=0;
var sommaass=0;
var listatot=[];
var numlis=0; 
var contaatt=0;
var convocato=0;
var mingiocati=0;
//console.log('creatotali',plista);
listatot[0]=['Nome','Attività Gruppo','Convocazioni','Presenze','Assenze', '% Presenze','% Assenze','Minuti giocati'];
plista.forEach(elem =>{ 
  sommapres=0;
  sommaass=0;
  contaatt=0;
  convocato=0;
  mingiocati=0;
if (numlis>0)  
{
for (var i = 1; i < elem.length; i++) 
{
  contaatt++;
if (elem[i].presentedes=='P') 
  {
    sommapres=sommapres+1;
    mingiocati=mingiocati+(elem[i].mingioc?elem[i].mingioc:0);
  }
else if (elem[i].presentedes=='A') sommaass=sommaass+1;


if (elem[i].convocato===true) convocato++;


}
//console.log('elem321',sommapres/(elem.length-1)*100,sommapres,(elem.length-1),sommapres/(elem.length-1)   );  
listatot[numlis]=[elem[0].cognome+' '+elem[0].nome ,contaatt,convocato ,sommapres,sommaass,(sommapres*100/(convocato)).toFixed(2),(sommaass*100/(convocato)).toFixed(2),mingiocati];
}

numlis=numlis+1;
});
//console.log('creatotali',listatot);
return listatot;

}




disegnalista= (gruppo) =>
{
  return new Promise((resolve, reject) => {
 this.getListaAttivitaPres(gruppo).then((outlista)=>{

  resolve(

    <div style={{
      flex: 10,
      alignItems: 'center',
      width: '100%',
flexShrink:1,
flexGrow:100,
justifyContent:'flex-start',
alignContent:'flex-end'
    }}>

<div style={{ flex: 1,maxHeight:datiGen.getInstance().scala(200),minHeight:datiGen.getInstance().scala(200)}}>
        <label h2 style={{ flex: 1  }} >TESTO DI PROVA</label>
   
        
           </div>

</div>
     
  );


  });
});
}




}
