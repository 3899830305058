
import React  from 'react';
import moment, { max } from 'moment';
import restReportTest from '../../restcall/restReportTest';
import logo from '../../img/logobianco.png';
import Stampe from '../Stampe';
export default class ElabRepTest {
  constructor() {  
}
  static myInstance = null;
  
  static getInstance() {
    if (ElabRepTest.myInstance == null) {
      ElabRepTest.myInstance = new ElabRepTest();
      // Util.myInstance.inizializza();
     // utilVisite.myInstance.inizializza().then(() => console.log('Inizializing'));
    }
    return ElabRepTest.myInstance;
  }

  getListaTestdate = async (date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro,indFiltro,totali) => {
    return new Promise((resolve, reject) => {
      var lista='k11';      var lista2=[];

      if (!totali)
      {
        totali=false;
      }
      else
      {
  totali='t';
      }
      try {
 // console.log('prima di chiamata',date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro);
          restReportTest.getInstance().getEsebytipotest_date(gruppoFiltro,date_filtroI, date_filtroF, tipoFiltro,indFiltro).then((json) => {
        // console.log('elab test rep','getLista',json);
        if ( json.esito!=='undefined' && json.esito==='ko' )
              {
                console.log('ELABREPTEST','ERRORE',json.des);
        }
        else
   {       
     lista=json;
  //console.log('elab rep test','lista22' ,lista);
//console.log('elab rep test','lista29' ,lista2);
resolve(lista);
  /* utilPresenze.getInstance().disegnalista(lista).then((x)=>{    
        console.log('teaminfo','lista',lista,x)});*/
   }
        });
      } catch (error) {
        console.error(error);
      } finally {
       ;
      }
    });
      };


    getListaTestdategioc = async (date_filtroI, date_filtroF, idgioc, tipoFiltro,indFiltro,totali) => {
      return new Promise((resolve, reject) => {
        var lista='k11';   var lista2=[];
  
        if (!totali)
        {
          totali=false;
        }
        else
        {
    totali='t';
        }
        try {
         // console.log('getListaTestdategioc 21')
            restReportTest.getInstance().getEsebyidgioc_date(tipoFiltro,date_filtroI, date_filtroF, idgioc,indFiltro).then((json) => {
           if ( json.esito!=='undefined' && json.esito==='ko' )
                {console.log('ELABREPTEST','ERRORE',json.des);
     
          }
          else
     {       
       lista=json;
    //console.log('elab rep test','lista22' ,lista);
  //console.log('elab rep test','lista29' ,lista2);
  resolve(lista);
    /* utilPresenze.getInstance().disegnalista(lista).then((x)=>{    
          console.log('teaminfo','lista',lista,x)});*/
     } 
          });
        } catch (error) {
          console.error(error);
        } finally {
         ;
        } 
      });
       };




      elabTestgrp =(tipotest,listatest,dataper,gruppo)=>
      {
        var logov=logo;
        var outconv='';
        var line1='';  
        var texthtml;var p1,p2,p3;var oldy1;
        var p1p=-9999,p2p=-9999,p3p=-9999;var oldy2;var oldy3;
var conta=0;
       
try{
        outconv=outconv+ '<tr><td style="text-align:left;">Nominativo</td><td  style="text-align:left;"> '+dataper[0].substr(4,2)+"/"+dataper[0].substr(2,2)+"/"+dataper[0].substr(0,2)
       if (dataper[1]!=undefined && dataper[1]!=null)
        outconv=outconv+'</td><td> '+dataper[1].substr(4,2)+"/"+dataper[1].substr(2,2)+"/"+dataper[1].substr(0,2)
        if (dataper[2]!=undefined && dataper[2]!=null )
               outconv=outconv+'</td><td>'+dataper[2].substr(4,2)+"/"+dataper[2].substr(2,2)+"/"+dataper[2].substr(0,2)
        +'</td></tr> ';

      var max1=  Math.max(...listatest.map(o => Number(o.primoperiodo)));
      var max2=  Math.max(...listatest.map(o => Number(o.secondoperiodo)));
      var max3=  Math.max(...listatest.map(o => Number(o.terzoperiodo)));
      var min1=  Math.min(...listatest.filter(o=>o.primoperiodo!=null).map(o => Number(o.primoperiodo)));
      var min2=  Math.min(...listatest.filter(o=>o.secondoperiodo!=null).map(o =>Number(o.secondoperiodo)));
      var min3=  Math.min(...listatest.filter(o=>o.terzoperiodo!=null).map(o => Number(o.terzoperiodo)));

//console.log('minimo2',min2,...listatest.filter(o=>o.secondoperiodo!=null) );
var maxgr=Math.max(max1,max2,max3)+2;
var mingr=Math.min(min1,min2,min3)-2;




        listatest.forEach(ele => {        
         if (ele.primoperiodo!=null) p1=(ele.primoperiodo); else p1='';
         if (ele.secondoperiodo!=null) p2=(ele.secondoperiodo); else p2='';
         if (ele.terzoperiodo!=null) p3=ele.terzoperiodo; else p3='';
          outconv=outconv+ '<tr><td style="text-align:left;">'+ ele.nominativo+'</td><td  style="text-align:left;"> '+p1+'</td><td > '+p2+'</td><td > '+p3+'</td></tr> ';
          line1=line1+'<text y="'+((conta*30)+50) +'" x="5"  >'+ ele.nominativo+'</text>'
 
          //line1=line1+'<text y="'+((conta*30)+50) +'" x="180" transform="rotate(-180, 180,'+((conta*30)+50)+')" >'+ ele.nominativo+'</text>'
          //if (conta>0 )
          
          if (p1!='') 
          {
            if (p1p>-9999)
              line1= line1 + '<line x1="'+p1p + '" y1="'+ oldy1 + '" x2="'+parseInt((Number(p1)-mingr)*(400/(maxgr-mingr))+200 )+ '" y2="'+((conta*30)+50)+ '" style="stroke:rgb(0,255,0);stroke-width:1"/>   ';
            p1p=parseInt((Number(p1)-mingr)*(400/(maxgr-mingr))+200);
            oldy1=((conta*30)+50);
       
          }
        if (p2!='') 
        {
          if (p2p>-9999)
            line1= line1 + '<line x1="'+p2p + '" y1="'+ oldy2 + '" x2="'+parseInt((Number(p2)-mingr)*(400/(maxgr-mingr))+200 ) + '" y2="'+((conta*30)+50)+ '" style="stroke:rgb(255,255,0);stroke-width:1"/>   ';
          p2p=parseInt((Number(p2)-mingr)*(400/(maxgr-mingr))+200 );
          oldy2=((conta*30)+50);
      }
      
      if (p3!='') 
      {
        if (p3p>-9999)
          line1= line1 + '<line x1="'+p3p + '" y1="'+ oldy3 + '" x2="'+parseInt((Number(p3)-mingr)*(400/(maxgr-mingr))+200 )+ '" y2="'+((conta*30)+50)+ '" style="stroke:rgb(0,0,255);stroke-width:1"/>   ';
        p3p=parseInt((Number(p3)-mingr)*(400/(maxgr-mingr))+200 );
        oldy3=((conta*30)+50);
    }

         //}
        conta=conta+1;
        }      
        );
      
var yfin=(conta*30)+90;

      texthtml=`<html>
      <head>
      <style type="text/css">
      h1 { color:#F1F1F1 }
      table,th, td {
        border: 0.5px solid #cccccc;
        border-collapse: collapse;
        padding-left:5px;
        padding-right:5px;
        font-size:18pt;
        text-align:center;
      }
      </style>
      </head>
      <body style='text-align:center'>
      <div style="height:100%;display:block;" >
      <div style="width:100%;display:flex;flex-direction:row">
      <div style="width:60%;">
      <img 
      src=" `+
      logov
      +`" 
      style="height:150px;float:left;width:auto;object-fit:contain;" />
      </div>
      <div style='float:right;background-color:transparent;width:40%;text-align:left;'>
      <h2>A.S.D. Vigor Milano</h2>
      Via San Michele del Carso 59,<br/> Paderno Dugnano (MI)<br/>
      Via IV Novembre 72, Cormano (MI)<br/>
      tel: 392 985 88 51 <br/>
      </div>
      
      </div>
      <h1 style='color:blue'>      
      `+    gruppo
            +`</h1>
      <h1 style='color:black'>Report Test      
      `+
            tipotest
            +`</h1>
      <table style='width:98%; table-layout: auto;margin: 0 auto'>
            
      `+
      outconv
      +`
            
      </table> 
      </div>
      <div style="display:block;">
      <svg style='width: 620px; height: 900px;  '>
      <text y="10" x="200" transform="rotate(90, 200,10)" > `+
      mingr
      +`</text>
      <text y="10" x="600" transform="rotate(90, 600,10)" > `+
      maxgr
      +`</text>
      <text y="10" x="250" transform="rotate(90, 250,10)" > `+
      parseInt(   mingr+(maxgr-mingr)/8)
      +`</text>
        <text y="10" x="300"  transform="rotate(90, 300,10)"> `+
      parseInt(   mingr+2*(maxgr-mingr)/8)
      +`</text>
           <text y="10" x="350" transform="rotate(90, 350,10)" > `+
      parseInt(   mingr+3*(maxgr-mingr)/8)
      +`</text>
      <text y="10" x="400" transform="rotate(90, 400,10)" > `+
      parseInt(   mingr+4*(maxgr-mingr)/8)
      +`</text>
      <text y="10" x="450" transform="rotate(90, 450,10)" > `+
      parseInt(   mingr+5*(maxgr-mingr)/8)
      +`</text>
      <text y="10" x="500"  transform="rotate(90, 500,10)"> `+
      parseInt(   mingr+6*(maxgr-mingr)/8)
      +`</text>
      <text y="10" x="550"  transform="rotate(90, 550,10)"> `+
      parseInt(   mingr+7*(maxgr-mingr)/8)
      +`</text>
      <line x1="200" y1="40" x2="200" y2=" `+
      yfin
      +`"
      style="stroke:rgb(0,0,0);stroke-width:1"/> 
      <line x1="200" y1="40" x2="600" y2="40"
      style="stroke:rgb(0,0,0);stroke-width:1"/> 
      <line x1="250" y1="40" x2="250" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 
      <line x1="300" y1="40" x2="300" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 
      <line x1="350" y1="40" x2="350" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 

      <line x1="400" y1="40" x2="400" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 
      <line x1="450" y1="40" x2="450" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 

      <line x1="500" y1="40" x2="500" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 
      <line x1="550" y1="40" x2="550" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 
      <line x1="600" y1="40" x2="600" y2="`+
      yfin
      +`"
      style="stroke:rgb(230,230,230);stroke-width:0.2"/> 
      `+
      line1
      +`
        </svg>
      </div>
      
      <p style="position: fixed; bottom: 0; width:100%; text-align: left;font-size:12">
      Stampato il `+ moment(new Date()).format('GG/MM/YYYY HH:mm') +`
      </p>
            </body>
     
      <html>`;
   console.log('texthtml pdf elabb reptest',texthtml);
      return (texthtml  );
      }
      catch(err)
      {
        console.log("errore generazione report - ",err);
       texthtml=`<html> <p>errore generazione file</p><html>`

        return texthtml;
      }
      }










}
