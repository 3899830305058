// screens/Home.js
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState,useEffect ,useRef} from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from 'primereact/dialog';
import { CountryService } from '../util/CountryService';
import Icon from '@mdi/react';
import { mdiContentSave } from '@mdi/js';
import { mdiPencil } from "@mdi/js";
import { mdiPrinter } from '@mdi/js';
import { mdiSoccerField } from '@mdi/js';
import { mdiAccountMultiple } from '@mdi/js';
import { mdiAccountMultipleCheck } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { mdiPhone } from '@mdi/js';
import { mdiCity } from '@mdi/js';
import { mdiEmail } from '@mdi/js';
import { mdiAccount } from '@mdi/js';
import  Person  from '../obj/person.js' 
import restPersona from "../restcall/restPersona";
import datiGen from '../util/datiGen.js'
import elenchi from "../util/elenchi";
import { useOutletContext } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
const Recapiti = (props) => {
  const navigate = useNavigate();
  const [statoDaSalvare, setStatoDaSalvare] = useOutletContext();
const [recapito,setRecapito] = useState();
useEffect(() => {
  console.log('xxx127', props.selRecapito);
  if (props.selRecapito){
    let tmp_rec = props.selRecapito;
    tmp_rec.__proto__ = Recapiti.prototype;
    tmp_rec.to_obj(); 
    setRecapito(tmp_rec);
    console.log(recapito, tmp_rec);
  }
}, []);
useEffect(() => {
}, [recapito]);
const [daSalvare, setDaSalvare] = useState(false);
const toast = useRef(null);
const [selectedItem, setSelectedItem] = useState(null);
const items = Array.from({ length: 3 }).map((_, i) => {
  let customLabel;
  switch (i) {
    case 0:
      customLabel = "Altro";
      break;
    case 1:
      customLabel = "Madre";
      break;
    case 2:
      customLabel = "Padre";
      break;
    default:
      customLabel = `Non selezionato`;
  }
  return { label: customLabel, value: i };
});

const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Success', detail:'Message Content', life: 3000});
}
const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
}
const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
}
const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
}
const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
};
const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
};
/*useEffect(() => {
  if(daSalvare){
   let x = datiGen.getInstance().replaceCharAt(statoDaSalvare, 1, '1'); 
   setStatoDaSalvare(x);
  }else if(!daSalvare){

  }
 }, [daSalvare]);*/
  return (
    <div>
      <Toast ref={toast} />
      {recapito && recapito.tipo == 'IND' &&
        <>
          <div style={{ flexDirection: 'row',  display:'flex',justifyContent: 'space-between', flexWrap: 'wrap', width: '100%'}}>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Indirizzo</label>
                  <IconField iconPosition="left">
                  <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
                  <InputText id="nome" value={recapito.descrizione} onChange={(e)=>{let x = {...recapito}; x.descrizione = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
                  </IconField>
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Città</label>
                  <IconField iconPosition="left">
                  <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
                  <InputText id="nome" value={recapito.citta} onChange={(e)=>{let x = {...recapito}; x.citta = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
                  </IconField>
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Cap</label>
                  <IconField iconPosition="left">
                  <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
                  <InputText id="nome" value={recapito.cap} onChange={(e)=>{let x = {...recapito}; x.cap = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
                  </IconField>
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Sigla provincia</label>
                    <IconField iconPosition="left">
                    <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
                    <InputText id="nome" value={recapito.provincia} onChange={(e)=>{let x = {...recapito}; x.provincia = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
                    </IconField>
                  </div>
                </div>
                <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Nazione</label>
                    <IconField iconPosition="left">
                    <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
                    <InputText id="nome" value={recapito.nazione} onChange={(e)=>{let x = {...recapito}; x.nazione = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
                    </IconField>
                  </div>
                </div>
                <div className="gruppoDati">
                <div className="dati">
                    <label htmlFor="nome">Madre o Padre</label>
                    <Dropdown
                      value={recapito.appartenenza} 
                      options={elenchi.getInstance().getEl("APPARTENENZA")}       
                      onChange={(e) => { let x = { ...recapito }; x.appartenenza = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x); console.log(e)}}
                    />
                </div>
              </div>
               <div className="gruppoDati">
                  <div className="dati">                    
                    <label htmlFor="nome">note</label>
                  <IconField iconPosition="left">
                  <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
                  <InputText id="nome" value={recapito.note} onChange={(e)=>{let x = {...recapito}; x.note = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
                  </IconField>
                </div>
              </div>
            </div>
        </>
      }
      {recapito && recapito.tipo == 'TEL' &&
       <div style={{ flexDirection: 'row',  display:'flex',justifyContent: 'space-between', flexWrap: 'wrap', width: '100%'}}>
           <div className="gruppoDati">
             <div className="dati">
               <label htmlFor="nome">Numero di telefono</label>
               <IconField iconPosition="left">
               <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
               <InputText keyfilter="int" id="nome" value={recapito.descrizione} onChange={(e)=>{let x = {...recapito}; x.descrizione = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
               </IconField>
             </div>
           </div>
           <div className="gruppoDati">
           <div className="dati">
                    <label htmlFor="nome">Madre o Padre</label>
                    <Dropdown
                      value={recapito.appartenenza} 
                      options={elenchi.getInstance().getEl("APPARTENENZA")}       
                      onChange={(e) => { let x = { ...recapito }; x.appartenenza = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x); console.log(e)}}
                    />
                </div>
            </div>
           <div className="gruppoDati">
             <div className="dati">
               <label htmlFor="nome">Note</label>
               <IconField iconPosition="left">
               <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
               <InputText id="nome" value={recapito.note} onChange={(e)=>{let x = {...recapito}; x.note = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
               </IconField>
             </div>
             </div>
             </div>
      }
      {recapito && recapito.tipo == 'MAIL' &&
       <div style={{ flexDirection: 'row',  display:'flex',justifyContent: 'space-between', flexWrap: 'wrap', width: '100%'}}>
           <div className="gruppoDati">
             <div className="dati">
               <label htmlFor="nome">Mail</label>
               <IconField iconPosition="left">
               <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
               <InputText id="nome" value={recapito.descrizione} onChange={(e)=>{let x = {...recapito}; x.descrizione = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
               </IconField>
             </div>
           </div>
           <div className="gruppoDati">
           <div className="dati">
                    <label htmlFor="nome">Madre o Padre</label>
                    <Dropdown
                      value={recapito.appartenenza} 
                      options={elenchi.getInstance().getEl("APPARTENENZA")}       
                      onChange={(e) => { let x = { ...recapito }; x.appartenenza = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x); console.log(e)}}
                    />
                </div>
           </div>
           <div className="gruppoDati">
             <div className="dati">
               <label htmlFor="nome">Note</label>
               <IconField iconPosition="left">
               <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>
               <InputText id="nome" value={recapito.note} onChange={(e)=>{let x = {...recapito}; x.note = e.target.value; setRecapito(x); setDaSalvare(true); props.updRecapito(x)}}/>
               </IconField>
             </div>
             </div>
             </div>
      }
    </div>
    

  );
}

export default Recapiti;