// screens/Home.js
import React from 'react';
import moment from 'moment';
import ElabRepTest from '../util/test/elabRepTest.js';
import restConfigurazioniTest from '../restcall/restConfigurazioniTest.js';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import datiGen from '../util/datiGen';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataView } from 'primereact/dataview';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import restGruppi from '../restcall/restGruppi.js';
import Stampe from '../util/Stampe.js';
import Grafico_rep from './Grafico_rep.js';
import {
  mdiUpdate, mdiPlus, mdiAccountGroup, mdiSoccer,mdiContentSaveAll,mdiCloseBox,
  mdiCheckAll,mdiTagEdit,
  mdiAccount,
  mdiClipboard,mdiAccountBadge,
  mdiClipboardPlus,
  mdiPencil,
  mdiDatabase,
  mdiTabSearch,
  mdiPrinter,
  mdiAccountDetails,
  mdiCalculator

} from '@mdi/js';





const TestSquadra = (props) => {
  const navigation = useNavigate();

  const [lista, setLista] = useState([]);
  const [lista2, setLista2] = useState([]);
  const [gruppoFiltro, setGruppoFiltro] = useState(props.gruppo ? props.gruppo : '');
  const [show_filtro, setShow_filtro] = useState(false);
  const [show_totali, setShow_totali] = useState(false);
  const [listaTipoTest, setListaTipoTest] = useState([]);
  const [evtest, setEvtest] = useState('');
  const [date_filtroI, setDate_filtroI] = useState(datiGen.getInstance().getRicercadtDa());
  const [show_filtroI, setShow_filtroI] = useState(false);
  const [date_filtroF, setDate_filtroF] = useState(datiGen.getInstance().getRicercadtA());
  const [show_filtroF, setShow_filtroF] = useState(false);
  const [indFiltro, setindFiltro] = useState(datiGen.getInstance().getRicercaIndirizzo());
  const [tipoFiltro, setTipoFiltro] = useState('XXX');
  const [selectedId, setSelectedId] = useState('XXX');
  const [dateperiodo,setDateperiodo]=useState([]);
  const [listaGruppiSQ, setListaGruppiSQ] = useState([]);

  const [gruppoDes, setGruppoDes] = useState(props.gruppodes ? props.gruppodes : '');
  const abilTest = datiGen.getInstance().possoaccederev2('REPTESTSQUADRA', props.gruppo);


  var dataper=[];

  useEffect(() => {
    getListaTest();
     
    getListaSQ();
    
 
  }, [ ]);




  useEffect(() => {

    
    renderlista();
  }, [ tipoFiltro, date_filtroI, date_filtroF, show_filtro, gruppoFiltro,indFiltro,show_totali ]);

  const selezionatest = async (valo) => {
    try {

     setEvtest(valo);
     setTipoFiltro(valo._id);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const getListaTest = async () => {
    try {   
      var json = await restConfigurazioniTest.getInstance().getAllConfigurazioniTest(true);
      setListaTipoTest(json);
          console.log("lista test",listaTipoTest);
    } catch (error) {
      console.error(error);
      setListaTipoTest([]);
    } finally {
      ;
    }
  }
  const getListaSQ = async () => {
    try {
      console.log("lista SQ");
      var json = await restGruppi.getInstance().getGruppi();
      setListaGruppiSQ(json);

    } catch (error) {
      console.error(error);
      setListaGruppiSQ([]);
    } finally {
      
    }
  }


  const renderItem = (item, index) => (
    <div className='listaItem' style ={{width:'100%'}}>
      {item && 
      <div key={index} style={{ flexDirection: 'row',  display:'flex', justifyContent:'space-between',  width: '100%'}}>
        <label style={{flex:2,textAlign:'start'}}>{item.nominativo} </label>
        <label style={{flex:1,textAlign:'end'}}>{item.primoperiodo}</label>
        <label style={{flex:1,textAlign:'end'}}>{item.secondoperiodo}</label>
        <label style={{flex:1,textAlign:'end'}}>{item.terzoperiodo}</label>
        </div>
}
    </div>
  )

const renderlista =async () => {
console.log('report test','renderlista 1______________________________________________');
if (tipoFiltro!='XXX')
{   ElabRepTest.getInstance().getListaTestdate(date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro,indFiltro,show_totali).then((outlista)=>{
var listaord=[];
if (outlista.length>1)
 {   
//listaord=outlista.sort((a, b) => a.datainitest > b.datainitest );
listaord=outlista;
 setLista2(listaord );
 
 var i=0;var periodo=15;var fini=0;var index=-1;
 var listadef=[];
 dataper=[];
listaord.forEach((ele) => {
  if (fini==0) {fini=moment(ele.datainitest);
              dataper.push(fini.format('YYMMDD'));
              } 
  if ((fini.diff(moment(ele.datainitest))/(1000*60*60*24)) >periodo )
       {i=i+1; 
        fini=moment(ele.datainitest);
        dataper.push(fini.format('YYMMDD'));
      }
   //   console.log(ele,fini);
        if (i==0) 
      {  
        index=listadef.findIndex(a => a.idgioc === ele.idgioc);
       
       if (index<0)
        listadef.push(
         {nominativo:ele.cognome+" "+ele.nome,tipotest:ele.riscodice,idgioc:ele.idgioc,primoperiodo: ele.risval,secondoperiodo:null,terzoperiodo:null});
        else
        listadef[index].primoperiodo= ele.risval;
      }
      else if (i==1) 
      {
 //   console.log('periodo2',{nominativo:ele.cognome+" "+ele.nome,tipotest:ele.riscodice,idgioc:ele.idgioc,primoperiodo: ele.risval,secondoperiodo:null,terzoperiodo:null});

        index=listadef.findIndex(a => a.idgioc === ele.idgioc);
       if (index<0)
       listadef.push( {nominativo:ele.cognome+" "+ele.nome,tipotest:ele.riscodice,idgioc:ele.idgioc,primoperiodo: null,secondoperiodo:ele.risval,terzoperiodo:null});
        else
      {
        listadef[index].secondoperiodo= ele.risval;
      }
      }
      else if (i==2)
      {
        index=listadef.findIndex(a => a.idgioc === ele.idgioc);
        if (index<0)
        listadef.push({nominativo:ele.cognome+" "+ele.nome,tipotest:ele.riscodice,idgioc:ele.idgioc,primoperiodo: null,secondoperiodo:null,terzoperiodo:ele.risval});
         else
         listadef[index].terzoperiodo= ele.risval;
      }
     // console.log(i);
      });

 //  listadef.sort(([a], [b]) => a.localeCompare(b));

 if (listadef.length>1)
 {
 listadef.sort((a,b)=>{    
  if (a.nominativo.toString()>b.nominativo.toString()  )
    return 1;
  else if (a.nominativo.toString()<b.nominativo.toString()  )
    return -1;
  else 
  return 0;
  });
 }  
  else return 0;
 }
setDateperiodo(dataper);
console.log('TestSquadra','listadef',listadef);
console.log(' test Squadra','FINE 333');
  setLista(listadef);
    });
  }///// tipo filtro ! XXX
     }
  
  
  
     const stamparep = async (nometest,lista,dateperiodo,gruppoDes) => {
     
    const x=ElabRepTest.getInstance().elabTestgrp(nometest,lista,dateperiodo,gruppoDes);
    console.log('testsquadra stamparep', x);
    Stampe.getInstance().StampaHtlm(x,'report.pdf');
    

  }
  
  
  
  
  
     return (
    <div  style={{
     width:'100%',
     height:'100%',
   flex:1,
    }}>
      
      <div style={ { display:'flex', flex:1,flexDirection:'row' ,justifyContent:'space-between' ,   minHeight: datiGen.getInstance().scala(30) }}>
        <label className='Titolo5' >Dati Test</label>

                 <Button style={{ justifyContent:'flex-end' }} 
            onClick={() => (setShow_filtro(!show_filtro))}>
          <Icon path={mdiTabSearch} size={0.8}/>
          </Button>
      </div>
      
      {show_filtro &&
        <div style={{display: 'flex',flexDirection: 'row',flexWrap: 'wrap',flex:1,width:'100%',flexGrow:2, minHeight: datiGen.getInstance().scala(110) }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} htmlFor='dt1'>Da</label>
          <Calendar id="dt1" value={date_filtroI} showIcon onChange={(e) => { setDate_filtroI(e.value); datiGen.getInstance().setRicercaDtDa(e.value); }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} htmlFor='dt2' >A</label>
          <Calendar id="dt2" value={date_filtroF} showIcon onChange={(e) => { setDate_filtroF(e.value); datiGen.getInstance().setRicercaDtA(e.value); }} />
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Tipo Test:</label>
            <Dropdown
              value={evtest} 
              showClear
             options={listaTipoTest}
              optionLabel="nometest"
              optionValue="_id"
             style={{ marginLeft: "0.3em", marginRight: "0.3em" }}
              onChange={vcf => {
            selezionatest(vcf.target.value);
              }} />
          </div>

       
          {!props.gruppo &&
            
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginLeft: "0.3rem", marginBottom: '0.2rem' }} >Gruppo:</label>

            <Dropdown
              value={gruppoFiltro}
              options={listaGruppiSQ} 
              optionLabel="des" 
              optionValue='_id'
              style={{}} showClear
              onChange={vcf => {
                setGruppoFiltro(vcf.target.value);
                  }} />
          </div>
     
          }
        </div>
      }
   <div className='listazioni' style={{ marginTop: '0.5rem' }}>
   {props.gruppo &&
   <Button
                  onClick={() => { renderlista()}}
                >
                  <Icon path={mdiUpdate} size={0.8} />

                </Button>
                
          }
          {lista && lista!=null &&lista.length>0 &&
                  <Button
                  onClick={() => { stamparep(evtest.nometest,lista,dateperiodo,gruppoDes)}}
                >
                <Icon path={mdiPrinter} size={0.8} />
                </Button>
          }     
           <Button
                  onClick={() => { setShow_totali(!show_totali)}}
                >
               <Icon path={show_totali?mdiAccountDetails:mdiCalculator} size={0.8}/>

                </Button>
   </div>
   {show_totali &&
   <div style={{flex:1,width:'100%',minWidth:'100%',flex:1,flexGrow:10}}>
       <Grafico_rep dati={lista2} />
</div>
}
{!show_totali &&
<div

 style={{width:'100%',minWidth:'100%',flex:1,flexGrow:10,}}>  
<div style={{ padding: 24, minWidth: '100%'}}>   
          <DataView
            header=
            {
              <div  className='listazioni' style={{display:'flex', flexDirection: 'row',}}>
        <label style={{flex:2,textAlign:'start',color:'white',textAlignVertical:'center',fontSize:datiGen.getInstance().scala(16)}}>Nominativo</label>
        <label style={{flex:1,textAlign:'end',color:'white',textAlignVertical:'center',fontSize:datiGen.getInstance().scala(16)}}>{dateperiodo[0]}</label>
        <label style={{flex:1,textAlign:'end',color:'white',textAlignVertical:'center',fontSize:datiGen.getInstance().scala(16)}}>{dateperiodo[1]}</label>
        <label style={{flex:1,textAlign:'end',color:'white',textAlignVertical:'center',fontSize:datiGen.getInstance().scala(16)}}>{dateperiodo[2]}</label>

        </div>
      
            }
             value={lista}
             itemTemplate={renderItem}
          />
      </div>
</div>
}

    </div>

    

  );
}
export default TestSquadra;