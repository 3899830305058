// screens/Home.js
import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import datiGen from '../util/datiGen.js';
import { Button } from 'primereact/button';
  import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
  import {
    mdiChartLine,
mdiExpandAll,
 
  } from '@mdi/js';
import restAttivita from '../restcall/restAttivita';
import EsecTestRun_InputValore from './EsecTest_run_InputValore.js';

const EsecTestRun = (props) => {
  const navigate = useNavigate();
  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD',props.gruppo);
  const [selGruppo, setSelGruppo] = useState(() =>props.gruppo);
  const [selAttivita, setSelAttivita] = useState(() => props.attivita);
  const [selTest, setselTest] = useState(()=>props.pTest);
  const [selTestTipo, setselTestTipo] = useState();
  const [selGioc, setselGioc] = useState(()=>props.pGioc);
  const [selectedId, setSelectedId] = useState([]);


  const abilRepTest = datiGen.getInstance().possoaccederev2('REPTEST', props.gruppo);
    useEffect(() => {
      setselGioc(props.pGioc);
      },
    [props.pGioc]
  );


  useEffect(() => {
    setselTest(props.pTest);
  },
  [props.pTest]
);
    return (

      <div style={{
        flex: 5,
        flexGrow:1,
        width: '100%'

      }}
      containerStyle={{
        flex: 1,
        maxHeight:'100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexGrow:1,
        
        width: '100%'}}
      >



        <div style={{ flex: 1, width: '100%',  alignItems:'flex-start',justifyContent:'flex-start',alignContent:'flex-start',flexGrow:10,height:'100%'}} key={selTest.idtestese}>
    
        <label   className='listazioni' style={{ color:'white',paddingHorizontal:5, textAlignVertical:'center',fontSize:datiGen.getInstance().scala(22)} }>{selTest.nometest}</label>
         <EsecTestRun_InputValore style={{flex: 10, }}  gruppo={selGruppo} attivita={selAttivita} pTest={selTest} pGioc={selGioc} pTestTipo={selTestTipo} />

         
     {abilRepTest && (
            <Button style={{ flex: 1 }}
            onClick={() => navigate('GraficoPage', { pgruppo: {selGruppo}, ptest: {selTest} })}
          >
            <Icon path={mdiChartLine} size={0.8} />
          </Button>
          
          )}
          
        </div>
    
      </div>
      
    );
  }
  export default EsecTestRun;