// screens/Home.js
import React, { Component, useRef,useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import datiGen from '../util/datiGen.js';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
  import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
  import {
    mdiChartLine,
    mdiContentSave,
mdiExpandAll,
 
  } from '@mdi/js';
  import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';

import restConfigurazioniTest from '../restcall/restConfigurazioniTest.js';
import test_esecuzione from '../obj/test_esecuzione.js';
import restEsecTest from '../restcall/restEsecTest.js';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

const EsecTestRun_InputValore = (props) => {
  const navigation = useNavigate();
  const abilAnagUPD = datiGen.getInstance().possoaccederev2('ANAGUPD', props.gruppo);
  const [selGruppo, setSelGruppo] = useState(() => props.gruppo);
  const [selAttivita, setSelAttivita] = useState(() => props.attivita);
  const [selTest, setselTest] = useState(() => props.pTest);
  const [selTestTipo, setselTestTipo] = useState();
  const [selGioc, setselGioc] = useState(() => props.pGioc);
  const [selectedId, setSelectedId] = useState([]);
  const [esecT, setEsecT] = useState();
  const [daSalvare, setDaSalvare] = React.useState(false);
  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo, life: 3000 });
  }
  const showWarn = (testo,tit) => {
    toast.current.show({ severity: 'warn', summary: tit, detail: testo, life: 3000 });
  }
  const showError = () => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Message Content', life: 3000 });
  }
  const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };


    useEffect(() => {
      creaesec();
    },
    [props.pGioc]
  );


  useEffect(() => {
    creaesec();
  },
  [props.pTest]
);
 
const creaesec = async () => {
  
    
    var vrestese=null;
    if (props.pGioc && selTest)
    {
    vrestese=await restEsecTest.getInstance().getEsebyidgiocese(selTest.idtestese,props.pGioc._id);
        }
    if (vrestese  && vrestese.esito==='OK')
    {
      delete vrestese.esito;
      setEsecT(vrestese);
    }
else
{
  
    if (props.pTest && props.pGioc)
          {
         restConfigurazioniTest.getInstance().getConfigurazioneTest(props.pTest.id).then ((out)=>
         {
          setselTestTipo(out);
          if ( props.pGioc && selAttivita && selTest && out) {
           let v = new test_esecuzione(); 
            v.idsoc = selAttivita.idsoc;
            v.idtestese = selTest.idtestese;
            v.idtest_tipo = selTest._id;
            v.idgioc = props.pGioc._id;
            out.tipoRisultati.forEach(ele => {
              v.risultati.push({ codice: ele.codice, des: ele.des, valore: '' });
            });
            setEsecT(v);        
      
            
          }
          }
         )
        }
      }   
    }
  

    const salva_test = async (ptest) => {
      var json=null;
      try {
        let x=ptest;
        console.log('salva_test',x);
      if (!x._id)
      {
         json = await restEsecTest.getInstance().aggiungi(x,props.gruppo);
        setEsecT(json);
        
        setDaSalvare(false);
       
      showInfo("Inserimento effettuato la modifica è stata salvata")
        }
        else
      {
         json = await restEsecTest.getInstance().modifica(x,props.gruppo);
     
        if (json.esito==='OK')
        {
          delete json.esito;
          setEsecT(json);
          
          setDaSalvare(false);
    
          showInfo("la modifica è stata salvata "+json._id);
        }
        else
        {
    
            showWarn(json.des.toString(),"Salvataggio NON eseguito")
        }
      }
      } catch (error) {
        console.error(error);
      } finally {
        //console.log('FINE',esecT);
      }

    }

const disegnaListaRis = () => {
    if (esecT && esecT.risultati && esecT.risultati.length > 0) {
     return (

<div style={{alignItems:'flex-start',justifyContent:'flex-start',alignContent:'flex-start',padding:0,flex:1,}}>
<Toast ref={toast} />

      {  esecT.risultati.map((eler, i) => (
          <div key={i} style={{ flex: 1,width:'100%',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',alignContent:'flex-start',padding:0,flexShrink:2,marginTop:'0.3rem'}} >
            <label style={{ flex: 1,padding:5 }} >{eler.codice ?eler.codice.trim().split('#')[2] : ''}</label>

            <InputNumber value={eler.valore} mode="decimal" maxFractionDigits={4}
        // onChangeText={val => { let x = { ...esecT }; x.risultati[i].valore = val; setEsecT(x); }}
           onChange={val => { let x = { ...esecT }; eler.valore = val.value; setEsecT(x); }}  
            >
            </InputNumber>       
          </div>
        ))
        }
     <Button style={{flex:1}} 
            onClick={() => salva_test(esecT)   }
          >
                <Icon path={mdiContentSave} size={0.8} />
          </Button>
        </div>
      );
    }
  }

  return (

    <div style={{
      flex: 8,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height:'100%',
      
    }}>
      <div style={{ flex: 1, width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start', alignContent: 'flex-start' }}>
      
        {disegnaListaRis()}
      </div>

    </div>
  );
}
export default EsecTestRun_InputValore;