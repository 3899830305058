
import moment from 'moment'
import datiGen from '../util/datiGen'; 


const gruppoPrototype = {

  to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
   
  },


  getCampionatoAttivo() {
    const cstag = datiGen.getInstance().getStagioneAttiva();
    var catt = [];
    if (this.campionati)

      this.campionati.forEach(ele => {
        
        if (ele.stagione == cstag) {
      
          if (ele.periodo == 'anno')
          {
           
            catt.push(ele);
          }
          else if (ele.periodo == 'Aut' && moment().month() >= 7 && moment().month() < 12)
          {
            
            catt.push(ele);
          }
          else if (ele.periodo == 'Prim' && moment().month() >= 0 && moment().month() <= 7)
          {
            catt.push(ele);
          }
         
        }
      }

      );
     
      return catt;
  }
}


function gruppo() {
  this.idsoc = "";
  this.des = "";
    this.note = "";
  this.campionati = [];
  this.giocatori = [];
  this.staff = [];
  this.ival = new Date();
  this.fineval = moment().add(7, 'years').toDate();
  this.tipogruppo='--' ;  //-- nessuno, A Agonistica P Preagonistica
  this.ordine=0;
  this.__proto__ = gruppo.prototype;
}

gruppo.prototype = gruppoPrototype;
gruppo.prototype.constructor = gruppo;






export default gruppo;