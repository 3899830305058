// screens/Home.js

import React, { Component, useEffect, useState } from 'react';
import restSchedaOss from '../restcall/restSchedaOss.js';
import datiGen from '../util/datiGen.js';
import moment from 'moment'
import schedaOss from '../obj/schedaOss.js';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import elenchi from '../util/elenchi.js';
import { Rating } from 'primereact/rating';
import DettSchedaOss from '../pagine/DettSchedaOss.js';
import {
  mdiUpdate, mdiContentSaveAll, mdiAccountMultiple, mdiPlus, mdiAccountMinus
} from '@mdi/js';

const SchedaOsservatore = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const abilSCHOss = datiGen.getInstance().possoaccederev2('SCHOSSUPD');
  const abilSCHOssupd = datiGen.getInstance().possoaccederev2('SCHOSSUPD');
  const abilAnag = datiGen.getInstance().possoaccederev2('ANAG');
  const NavDettPersone = (ppers) => {

    return (
  abilAnag &&
      <Button
        onClick={() =>
          {
            if (props.gruppo)
            navigate('/MyTeam',{gruppo:props.gruppo})
            else
            navigate("/Persone")
          }
        }
      >
      <Icon path={mdiAccountMultiple} size={0.8} />
      </Button>
    );
  }

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [selectedId, setSelectedId] = useState([]);
  const [selPersona, setSelPersona] = useState(() => props.selPersona);
  const getSchedeOss = async () => {
   
    try {
      setLoading(true);
      
     if (props.tipo=='Gioc')  
     {
     var  json=await  restSchedaOss.getInstance().getSchedeOss(location.state.person_id).then((json)=>
       {
        setData(json);
       }
       ) ;
     }
     else if (props.tipo=='Soc') 
     {
      var json=await  restSchedaOss.getInstance().getSchedeOss().then((json)=>
       {
        setData(json);
       }
       ) ;
     }
       else
       {
        console.console.warn('SchedaOsservatore','getschedeoss','3','Questo caso non deve capitare');
       setData([]);
       }     
   } catch (error) {
     console.error(error);
   } finally {
     setLoading(false);
   }
 }

    const renderItem = (item, index ) => (
      <div className='listaItem' 
      onClick={() => {    navigate('/DettSchedaOss',{ state:{selschedaoss: item ,tipo:props.tipo}})}      }
      >
     <div  style={{display:'flex',  flexDirection: 'row', flexWrap: 'wrap',justifyContent:'space-around' }}>
          <div style={{display:'flex',flexGrow:2,  flexDirection: 'column', flexWrap: 'wrap',justifyContent:'space-around' }}>
   <label>{item.Giocatore[0]? item.Giocatore[0].cognome:'xxx'}  {item.Giocatore[0]? item.Giocatore[0].nome:'xxx'}    </label> 
   <label>{ moment(item.dataScheda).format('DD/MM/YYYY')} </label>
   <label >{item.operatore} </label> 
    </div>  
    <div style={{ display:'flex',flex:1,padding:2,flexDirection:'column',alignItems:'center'}}
    >
    <label  >{item.valutazioneGlobale*2} </label> 
    <Rating      value={item.valutazioneGlobale}  stars={5} disabled cancel={false}  style={{}}
   />
       </div>
       </div>
        </div>     
      
    )

    const aggiungi = async () => {
      console.log('inizio aggiungi',t);
      var t = new schedaOss();
      t.idsoc=datiGen.getInstance().getActiveSoc().sigla;
      t.operatore=datiGen.getInstance().getNomeUtenteCollegato();
      t.valutazioneGlobale="0";
      t.idgioc=selPersona._id;
      console.log('aggiungi schedaoss',t);
      await restSchedaOss.getInstance().aggiungi(t);
     getSchedeOss();

    }

    useEffect(() => {
            setSelPersona(props.selPersona);
        getSchedeOss();
      }, []);



    return (

      <div style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#dddddd',
        width: '100%'
      }}>

{abilSCHOss && data &&  data.length>0 &&
        <div style={{ flex: 1, width: '100%' }}>
          <DataView
            header=
            {<div className="listazioni" >
              {abilSCHOssupd && selPersona &&
              <Button
                
                onClick={() => aggiungi()}
                          >
                            <Icon path={mdiPlus} size={0.8} />    
              </Button>
              
              }

<Button
onClick={() => getSchedeOss()}
>  
<Icon path={mdiUpdate} size={0.8} />
              </Button>             
              {NavDettPersone(data)}
            </div>}
            value={data}
            itemTemplate={renderItem}
          />


        </div>  }
      </div>
    );
  }
  export default SchedaOsservatore;