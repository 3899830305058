import Person from "./person.js";

const schedaOssPrototype = {
    
  to_obj() {
 
    if (this.dataScheda && typeof this.dataScheda == 'string') {
      this.dataScheda = new Date(this.dataScheda);
    }
  },


    caricaValutazioni(pval,azzera)
    {
    if (azzera==1)
      {
    this.valutazioni=[];
      }
    
      pval.forEach(element => {if (this.valutazioni.findIndex(el => el.codice === element.codice)<0) 
        {this.valutazioni.push(element);
    //    console.log('caricaValutazioni','aggiunto',element);
        }
      /*  else
        {
          console.log('caricaValutazioni','NON aggiunto',element);
        }*/
       } );
    
      },

    
  }
  
  function schedaOss() {
    this.idsoc="";
    this.idgioc="";
    this.objidgioc="";
    this.dataScheda=new Date();
    this.peso=0;
    this.altezza=0;
    this.valutazioni=[];
    this.operatore="";
    this.valutazioneGlobale=0;
    this.note="";
    this.ruolo="";
    this.Giocatore="";
    this.soc_appartenenza="";
    this.partita="";
    this.indirizzopartita="";
    this.risultato="";
    this.difficoltapartita="";
    this.condizioniterreno="";
    this.durataosservazione="";
    

    this.__proto__ = schedaOss.prototype;
    this.Giocatore.__proto__=Person.prototype;
  }
  
  schedaOss.prototype = schedaOssPrototype;
  schedaOss.prototype.constructor = schedaOss;
  





export default schedaOss;