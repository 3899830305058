import Person from "./person.js";
import gruppo from "./gruppo.js";
import moment from 'moment/min/moment-with-locales';

const AttivitaPrototype = {

  to_obj() {


    if (this.dataAttivita && typeof this.dataAttivita == 'string') {
      this.dataAttivita = new Date(this.dataAttivita);
    }
    if (this.dataFineAttivita && typeof this.dataFineAttivita == 'string') {
      this.dataFineAttivita = new Date(this.dataFineAttivita);
    }
    if (this.RitrovoDataOra && typeof this.RitrovoDataOra == 'string') {
      this.RitrovoDataOra = new Date(this.RitrovoDataOra);
    }
  },

  getDescrizione()
  {
    
    var s="";
if (this.tipo)
{
  
if (this.tipo.split('#')[1]=='P')
{
  s="Tipo:"+this.tipo.split('#')[2]+ "\nData:"+moment(this.dataAttivita).tz("Europe/Rome").format('DD/MM/YYYY HH:mm') +"\nAvversario:"+this.avversario+"\n"+this.note;
}
else
{
  s="Tipo:"+this.tipo.split('#')[2]+ "\nData:"+moment(this.dataAttivita).tz("Europe/Rome").format('DD/MM/YYYY HH:mm') +"\n"+this.note;
}
 
}
else
{
  
  s="data:"+moment(this.dataAttivita).tz("Europe/Rome").format('DD/MM/YYYY HH:mm');
}

return s;
  },
  
getRisultato()
{var ris='';
  if (!this.golFatti) this.golFatti=0;
    if (! this.golSubiti) this.golSubiti=0;
    if (this.trasferta)
      ris=this.golSubiti+"-"+this.golFatti;
    else ris=this.golFatti+"-"+this.golSubiti;
    return ris;
}



 
}
  
  function Attivita() {
this.idsoc = "";
this.dataAttivita = moment().minute(moment().minute()>20 && moment().minute()<45?30:0).toDate();
this.dataFineAttivita = moment(this.dataAttivita).add(90,'minutes').toDate();


this.ordine = 0;
this.tipo='Partita'  // Partita,Allenamento,Test,Evento
this.note = "";
this.gruppi=[];
this.staff=[];
this.gioc=[];
this.indirizzo="";
this.spogliatoi=[];
this.opecreaz="";
this.opeultmod="";
this.datautlmod=moment().toDate();
this.datacreaz=moment().toDate();

this.__proto__ = Attivita.prototype;


//partita
  //TipoPartita   Amichevole,Campionato,Torneo
    //RitrovoIndirizzo
    //RitrovoDataOra
    //avversario
    //casaTrasferta C T
    //GOAL   
    //GOAL AVVERSARI
    //risTempi   Risultato a tempi per la preagonistica
    //Tempi Vinti 
    //Tempi Persi
    //Tempi Pareggiati
    //Vittoria Giochini S/N

    //referente nrreferente

//cronaca partita
    //minuto
    //evento
    //gioc1    //gol
    //gioc2    //assist 

//test
//
//eltest   Array   solo campi nome test e _id id_Ese




  }

Attivita.prototype = AttivitaPrototype;
Attivita.prototype.constructor = Attivita;






export default Attivita;