import moment from 'moment'

const pagamentoPrototype = {
    

  to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
    if (this.datapagamento && typeof this.datapagamento == 'string') {
      this.datapagamento = new Date(this.datapagamento);
    }
  },
  copiaPagamento(tipo) {
    this.id=tipo.id;
    this.datapagamento=tipo.datapagamento;
    this.modalita=tipo.modalita;   //contante,bonifico,carta di credito,rid
    this.causale=tipo.causale;  //iscrizione, evento,camp
    this.importo=tipo.importo;
    this.operatore=tipo.operatore;
    this.note=tipo.note;

    this.__proto__ = pagamento.prototype;
  }
  }
  
  function pagamento(tipo) {
    this.id=Date.now();
    this.datapagamento=Date.now();
    this.modalita = "";   //contante,bonifico,carta di credito,rid
    this.causale="";  //iscrizione, evento,camp
 this.importo=0;
 this.operatore="";
this.note="";

    this.__proto__ = pagamento.prototype;
  }
  
  pagamento.prototype = pagamentoPrototype;
  pagamento.prototype.constructor = pagamento;
  





export default pagamento;