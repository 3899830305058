import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";




export default class restProfiloUtente {
  constructor(restProfiloUtente) {

    this.caricaprofiloincorso = 0;
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'profiliutente';
  static myInstance = null;

  static getInstance() {
    if (restProfiloUtente.myInstance == null) {
      restProfiloUtente.myInstance = new restProfiloUtente();
      // Util.myInstance.inizializza();

    }
    return restProfiloUtente.myInstance;
  }

  prova() {
    console.log('restProfiloUtente', 'prova');
  }




  getListaProfiliUtente = async (idsoc) => {

    //console.log('RESTCALL','ProfiliUTENTE');

    /*non test per evitare riciclo, è testata sul backend
       if (!datiGen.getInstance().possoaccederev2('PROFILIUTENTE'))
       throw 'Non sei abilitato a svolgere la funzione PROFILIUTENTE';
   */
       
    const user = getAuth().currentUser;
    var token = await user.getIdToken();


    var json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '?idsoc:' + idsoc, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      var json = await response.json();
      //console.log('restcall','getListaProfiliUtente',json);


    } catch (error) {
      console.error("getListaProfiliUtente",error);

    } finally {

      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';

      this.caricaprofiloincorso = 0;
      return json;

    }

  }


  getProfiloUtente = async (pidutente) => {
    if (this.caricaprofiloincorso > 0) return;

    const user = getAuth().currentUser;
    var token = await user.getIdToken();

    this.caricaprofiloincorso = 1;
    var json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + "/profilout/" + pidutente, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      var json = await response.text();
     // console.log('getProfiloutente', json);
      if (json)
        json = JSON.parse(json);
      else json = { esito: "KO" };


    } catch (error) {
      console.error("getProfiloUtente",error);

    } finally {

      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';

      this.caricaprofiloincorso = 0;
      return json;

    }

  }

  aggiungi = async (profilo) => {
    var vprofilo = { ...profilo };
    var json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(vprofilo)
        });
      //  console.log('restprofiloutente','XXXXXXXXXXXXXXX');
       

      if (response.status >= 300) {
      //  console.log("restProfiloUtente", "aggiungi", "--", response.status, json);
        json.esito = 'KO'

       // throw 'Errore ' + JSON.stringify(json);
      }
      else
      {
        json = await response.json();
        json.esito = 'OK'
        
      }
    } catch (error) {
      console.error('restProfiloutente',error);
      json.esito = 'KO'
    } finally {
      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';
      return JSON.stringify(json);
    }
  }

  modifica = async (profilo) => {
    // console.log("Modifica",ppers,ppers.nome);
    try {
      const user = getAuth().currentUser;
      var token = await user.getIdToken();

      var p = { ...profilo };
      delete p._id;

      const s = this.restUrlServer + '/' + this.restApp + '/' + profilo._id;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error("modifica profilo utente",error);
    } finally {


    }

  }
  sendMsgTokenRegistration = async (msgtoken) => {
    // console.log("sendMsgTokenRegistration",msgtoken);
    try {
      const user = getAuth().currentUser;
      if (!user) {
        //   console.log('sendMsgTokenRegistration','utente non definito esco');
        return;

      }
      var token = await user.getIdToken();
      //  console.log("sendMsgTokenRegistration",msgtoken,user.uid,user.displayName);


      const s = this.restUrlServer + '/' + this.restApp + '/' + user.uid + '/tokenMsgRegistration?token=' + msgtoken;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },

        });

      const json = await response.json();
      return json;

    } catch (error) {
      console.error("sendMsgTokenRegistration",error);
    } finally {


    }

  }


}