// comp/Lista_Dati_acc.js

import React, { Component, useRef, useState,useEffect } from 'react';
import moment from "moment";
import { mdiUpdate } from '@mdi/js';
import ReactECharts from 'echarts-for-react';
import { Button } from 'primereact/button';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';

import { useNavigate, useLocation } from "react-router-dom";
import { LineStyle } from '@mui/icons-material';

  const Grafico_repGioc = (props) => {
    const navigation = useNavigate();
    const tempoaggiornamento = 500;
  const [datiX,setDatiX]=useState();
  const [datiY,setDatiY]=useState();
  const [datiZ,setDatiZ]=useState();
  const [maxy,setMaxy]=useState();
  const [miny,setMiny]=useState();
    const option = {
      title: {
          text: 'GRAFICO TEST'
      },
      tooltip: {},
      xAxis: {
          type:'category',
         
          axisLabel: {
            rotate: 30  // Rotate the labels by 45 degrees
        },
  
      },
      yAxis: {
        max:maxy+2,
        min:miny-2,
      },
  
      series: [
          {
              type: 'line',
              data: datiX,
              lineStyle:{color:'green'}
  
            },       
            {
                type: 'line',
                data: datiY,
                lineStyle:{color:'yellow'}
            }
            , 
            {
                type: 'line',
                data: datiZ,
                lineStyle:{color:'blue'}
            }
          ]
    };
  


  const [valoredes, setValoredes] = useState();



 
    useEffect(
      React.useCallback(() => {
        setTimeout(() => { carica(props.dati)} , tempoaggiornamento);    
        console.log('graficoRep','useFocusEffect');
  
      }, [])
    );

  const carica = (vrestese) => {
    var m1 = new moment();
    console.log()
    try {
     // console.log('vrestese',vrestese);
   var lst=vrestese;
    }
    catch (error) {
      console.error(error);

      return;
    }
   
var mese=0;
    var dati_x = []; var dati_y = []; var dati_z = [];
var i=0;var periodo=15;var fini=0;var max=-9999; var min=50000;
   //if (this.iniziografico.isAfter(lst[0].tempo)) this.iniziografico = lst[0].tempo;
  // console.log('GRAFICO INI', 'RICARICA',lst);
var index=-1;
    lst.forEach((ele) => {
      if (ele.risval> max) max=parseInt(ele.risval);
      if (ele.risval< min) min=parseInt(ele.risval);
      var dataf=moment(ele.datainitest).format('YYYY-MM-DD');
  dati_x.push([dataf,ele.risval]);

    });




setDatiX(dati_x);
setDatiY(dati_y);
setDatiZ(dati_z);
setMaxy(max);setMiny(min);





  }
    return (

<div style={{ flex: 1,height:'85%',margin:5 ,width:'95%',}} >
     
<Button style={{flex:1}}
            onClick={() => carica(props.dati)}
          >
      <Icon path={mdiUpdate}  size={0.8} />
          </Button>
                  <ReactECharts option={option}/>
   




      </div >
    );
  }


export default Grafico_repGioc;