// screens/Home.js



import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import ListaGruppi from '../comp/ListaGruppi';


const GruppiPage = () => {
  const navigation = useNavigate();


  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'


      }}>

    <div style={{ flex: 1 ,margin:'0.5rem',justifyContent:'center',alignItems:'center',justifyItems:'center',width:'100%'}}>
    <label className='Titolo5' style={{ flex: 1,textAlign:'center',width:'100%'}} >Gruppi</label>

       </div>    

<ListaGruppi  style={{flex:10,minHeight:'100%',width:'100%'}} />

    </div>
  );
} 
export default GruppiPage;