// screens/Home.js

import React, { Component ,useEffect,useState,useRoute } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";



import SchedaOsservatore from '../comp/SchedaOsservatore';
import restPersona from '../restcall/restPersona';

const SchedeScouting = () => {
  const navigation = useNavigate();
  

  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'


      }}>
 <div style={{ flex: 1 ,margin:'0.5rem',justifyContent:'center',alignItems:'center',justifyItems:'center',width:'100%'}}>
        <label className='Titolo5' style={{ flex: 1,textAlign:'center',width:'100%'}} >Scouting</label>

           </div>

{
<SchedaOsservatore  style={{flex:10,minHeight:'100%',width:'100%'}} tipo='Soc' selPersona={null} />
}
    </div>
  );
} 
export default SchedeScouting;