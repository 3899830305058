// screens/Home.js
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState,useEffect ,useRef} from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from 'primereact/dialog';
import { CountryService } from '../util/CountryService';
import { mdiPencil } from "@mdi/js";
import Icon from '@mdi/react';
import { mdiContentSave } from '@mdi/js';
import { mdiPrinter } from '@mdi/js';
import { mdiSoccerField } from '@mdi/js';
import { mdiAccountMultiple } from '@mdi/js';
import { mdiAccountMultipleCheck } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { mdiPhone } from '@mdi/js';
import { mdiCity } from '@mdi/js';
import { mdiEmail } from '@mdi/js';
import { mdiAccount } from '@mdi/js';
import  Person  from '../obj/person.js' 
import restPersona from "../restcall/restPersona";
import Doc from '../obj/Doc'
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
const DOC = (props) => {
  const navigate = useNavigate();
const [doc,setDoc] = useState();
useEffect(() => {
  if (props.selDoc){
    console.log(props.selVisite);
    console.log(Doc.prototype);
    let tmp_rec = props.selDoc;
    console.log('prima');
    tmp_rec.__proto__ = Doc.prototype;
    console.log('dopo');
    tmp_rec.to_obj(); 
    setDoc(tmp_rec);
  }
}, []);
useEffect(() => {
}, [doc]);
let [daSalvare, setDaSalvare] = useState(false);
const toast = useRef(null);
const showSuccess = () => {
    toast.current.show({severity:'success', summary: 'Success', detail:'Message Content', life: 3000});
}
const showInfo = () => {
    toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
}
const showWarn = () => {
    toast.current.show({severity:'warn', summary: 'Warning', detail:'Message Content', life: 3000});
}
const showError = () => {
    toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
}
const showSecondary = () => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
};
const showContrast = () => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
};
  return (
    <div>
      <Toast ref={toast} />
      {doc &&
        <>
          <div style={{ flexDirection: 'row',  display:'flex',justifyContent: 'space-between', flexWrap: 'wrap', width: '100%'}}>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Tipo</label>
                  <IconField iconPosition="left">
                  <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>

                  <InputText id="nome" value={doc.tipo} onChange={(e)=>{let x = {...doc}; x.tipo = e.target.value; setDoc(x); setDaSalvare(true); props.updDoc(x)}}/>
                  </IconField>
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Numero</label>
                  <IconField iconPosition="left">
                  <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>

                  <InputText id="nome" value={doc.numero} onChange={(e)=>{let x = {...doc}; x.numero = e.target.value; setDoc(x); setDaSalvare(true); props.updDoc(x)}}/>
                  </IconField>
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Appartenenza</label>
                  <Calendar value={doc.appartenenza} onChange={(e)=>{let x = {...doc}; x.appartenenza = e.value; setDoc(x); setDaSalvare(true); props.updDoc(x)}}  id="date" />
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="date">Inizio validità</label>
                    <Calendar value={doc.ival} onChange={(e)=>{let x = {...doc}; x.ival = e.value; setDoc(x); setDaSalvare(true); props.updDoc(x)}}  id="date" />
                  </div>
                </div>
                <div className="gruppoDati">
                <div className="dati">
                    <label htmlFor="date">Fine validità</label>
                    <Calendar value={doc.fineval} onChange={(e)=>{let x = {...doc}; x.fineval = e.value; setDoc(x); setDaSalvare(true); props.updDoc(x)}}  id="date" />
                </div>
              </div>
              <div className="gruppoDati">
                <div className="dati">
                  <label htmlFor="nome">Note</label>
                  <IconField iconPosition="left">
                  <InputIcon><Icon  path={mdiPencil} size={0.8} ></Icon></InputIcon>

                  <InputText id="nome" value={doc.note} onChange={(e)=>{let x = {...doc}; x.note = e.target.value; setDoc(x); setDaSalvare(true); props.updDoc(x)}}/>
                  </IconField>
                </div>
              </div>
            </div>
        </>
      }
    </div>
    

  );
}

export default DOC;