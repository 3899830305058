

import React, { useState, useEffect } from 'react';
import datiGen from '../util/datiGen.js';
import moment from 'moment';
import utilPresenze from '../util/utilpresenze.js';
import elenchi from '../util/elenchi.js';
import PresenzeRept from './ReportPresenzet.js';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { useNavigate, useLocation } from "react-router-dom";
import Stampe from '../util/Stampe.js';

import { Icon } from '@mdi/react';
import {
  mdiContentSaveAll, mdiCheckboxMarkedOutline,

  mdiAccountPlus, mdiPencil,
  mdiAccountMultipleCheck, mdiAccountMultiple, mdiCalendarMultipleCheck,
  mdiAccountCancel,
  mdiTable, mdiAccountCheck,
  mdiTableSearch,
  mdiPrinter,
  mdiShare,
  mdiUpdate,
  mdiCalculator,
  mdiAccountDetails

} from '@mdi/js';

const PresenzeRep = (props) => {
  const navigation = useNavigate();
  // datiGen.getInstance().possoaccederev2('GRUPPI');
  const abilAtt = datiGen.getInstance().possoaccederev2('ATTIVITA') || datiGen.getInstance().possoaccederev2('ATTIVITA', props.gruppo);
  const abilAttUPD = datiGen.getInstance().possoaccederev2('ATTIVITAUPD') || datiGen.getInstance().possoaccederev2('ATTIVITAUPD', props.gruppo);
  const abilAllPres = datiGen.getInstance().possoaccederev2('ALLGIOCATTIVITAUPD');
  const abilGiocAtt = datiGen.getInstance().possoaccederev2('GiocAttivita', props.gruppo);
  const abilPresRep = datiGen.getInstance().possoaccederev2('REPORTPRESENZE', props.gruppo);
  const [lista, setLista] = useState([]);
  const [gruppoFiltro, setGruppoFiltro] = useState(props.gruppo ? props.gruppo : '');
  const [show_filtro, setShow_filtro] = useState(false);
  const [show_totali, setShow_totali] = useState(false);

  const [date_filtroI, setDate_filtroI] = useState(datiGen.getInstance().getRicercadtDa());
  const [show_filtroI, setShow_filtroI] = useState(false);
  const [date_filtroF, setDate_filtroF] = useState(datiGen.getInstance().getRicercadtA());
  const [show_filtroF, setShow_filtroF] = useState(false);
  const [indFiltro, setindFiltro] = useState(datiGen.getInstance().getRicercaIndirizzo());
  const [tipoFiltro, setTipoFiltro] = useState(datiGen.getInstance().getRicercaTipoAtt());

  useEffect(() => {
    renderlista();

  }, [tipoFiltro, date_filtroI, date_filtroF, show_filtro, gruppoFiltro, indFiltro, show_totali]);



  const renderlista = async () => {
    utilPresenze.getInstance().getListaAttivitaPres(date_filtroI, date_filtroF, tipoFiltro, gruppoFiltro, indFiltro, show_totali).then((outlista) => {
      //console.log('report presenz','renderlista',outlista);
      setLista(outlista);
    });

  }
  const disegnapresenza = (para) => {

    if (!para) return;

    if (para.presentedes == 'P') {
      return (
        <div style={{justifyContent:'center'}}>
          <Icon path={mdiAccountCheck} size={'1.8rem'} style={{ color: 'green' }} />
        </div>
      );
    }
    else if (para.presentedes == 'A') {
      return (
        <div style={{justifyContent:'center'}}>
          <Icon path={mdiAccountCancel} size={'1.8rem'} style={{ color: 'gray' }} />

        </div>
      )
      
    }
 
       }


  return (

    <div style={{
      width: '100%',
      height: '100%',
      flex: 1,


    }}>

      <div style={{ flex: 1, flexDirection: 'row' ,display:'flex' ,justifyContent:'space-between'}}>
        <label className='Titolo5' >Dati Presenze</label>
   

        <Button
          onClick={() => (setShow_filtro(!show_filtro))}

        >
          <Icon path={mdiTableSearch} size={0.8} />
        </Button>
      </div>

      {show_filtro &&
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'gray', fontWeight: '500', margin: '0.1rem' }}>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Da</label>

            <Calendar value={date_filtroI} showIcon
              disable={!abilAllPres}
              onChange={(e) => { setDate_filtroI(e.value) }} />

          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            <label>A</label>
            <Calendar value={date_filtroF} showIcon
              disable={!abilAllPres}
              onChange={(e) => { setDate_filtroF(e.value) }} />
          </div>




          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ flex: 1, marginLeft: "0.3rem", marginBottom: '0.2rem' }}>Tipo Attività</label>
            <Dropdown
              value={tipoFiltro} showClear placeholder='Tipo attività'
              options={elenchi.getInstance().getElTipoatt()}
              style={{ marginLeft: "0.3em", marginRight: "0.3em", }}
              disabled={!abilAllPres}
              onChange={val => { setTipoFiltro(val.target.value); datiGen.getInstance().setRicercaTipoAtt(val.target.value); }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label>Indirizzo/CS</label>

            <IconField iconPosition="left" >
              {abilPresRep &&
                <InputIcon>
                  <Icon path={mdiPencil} size={0.8} color={"#00adef"} />
                </InputIcon>
              }
              <InputText className='longInput' style={{ minWidth: "15rem", flexGrow: "1" }}
                disabled={!abilAllPres}
                value={indFiltro}
                placeholder='Avversario'
                onChange={(e) => { setindFiltro(e.value); datiGen.getInstance().setRicercaIndirizzo(e.value); }}
              >
              </InputText>
            </IconField>
          </div>
        </div>
      }
      <div className='listazioni'>
        {props.gruppo && show_totali && lista && lista.length > 0 &&
          <Button
            
          onClick={() => {console.log('click bottone');
            Stampe.getInstance().RepPres(lista,{tipofiltro:tipoFiltro, date_filtroI:date_filtroI, date_filtroF:date_filtroF,  gruppoFiltro:gruppoFiltro,indFiltro:indFiltro});
          }}
>
<Icon path={mdiPrinter} size={0.8}/>
</Button>
          
          }
        {props.gruppo && show_totali &&
               <Button
            
               onClick={() => {   }}
   >
   <Icon path={mdiShare} size={0.8}/>
   </Button>
        }
        {props.gruppo &&
         <Button
            
          onClick={() => {  renderlista() }}
>
<Icon path={mdiUpdate} size={0.8}/>
</Button>

        }

     {!show_totali &&
<Button
   onClick={() => {  setShow_totali(!show_totali)  }}
  >
  <Icon path={mdiCalculator} size={0.8}/>
  </Button>
}
{show_totali &&
<Button
   onClick={() => {  setShow_totali(!show_totali)  }}
  >
  <Icon path={mdiAccountDetails} size={0.8}/>
  </Button>
}



      </div>

      {show_totali &&
      
        <div style={{ flex: 1, width: '100%', minWidth: '100%', flex: 1, flexGrow: 10 }}>
          <PresenzeRept gruppo={props.gruppo} lista={lista} style={{ flex: 10 }} />
        </div>
      }
      {!show_totali &&

        <div style={{ flex: 1, margin: 0.1, padding: 0.1,  overflowX: 'auto' }}>
          {
            lista.map((riga, key) => (
              <div>
              <div key={key} style={{ display: 'flex', flex: 1, flexDirection: 'row', padding: 0, margin: 0,borderBottom:'0.1rem solid',borderBottomColor:'gray',width:'100%' }}>
                {riga.map((col, keycol) => (
                  <div key={keycol} style={{  flexDirection: 'row', display: 'flex', padding: 0, margin: 0,width:'3rem',minWidth:'3rem',
                   marginHorizontal: 0, borderBottomColor: 'silver', borderBottomWidth: 1, flex: keycol < 1 ? 3 : 1, backgroundColor: keycol % 2 ? '#eeeeee' : '#e1f2f9',
                  justifyContent: keycol < 1 ? 'left' : 'center'}}>
                    <label style={{
                      marginHorizontal: 0, fontWeight:(key == 0 && keycol<1)? 'bold' : 'normal', margin: 0, padding: 0,
                      textAlign: keycol < 1 ? 'left' : 'center'
                    }}>
                      {(key == 0 && keycol < 1) ? col :
                        (key == 0 && keycol >= 1) ? moment(col.data).format('DD/MM HH:mm') + ' ' + (col.tipo ? col.tipo[1] : '') :
                          (key > 0 && keycol < 1) ? col.cognome + ' ' + col.nome : disegnapresenza(col)
                      }

                    </label>  
                     </div>   
                  
                ))
                }
</div>
              </div>
            ))
          }

        </div>
      }
    </div>



  );
}
export default PresenzeRep;