// comp/Lista_Dati_acc.js

import React, { Component, useRef, useState,useEffect } from 'react';
import moment from "moment";
import { mdiUpdate } from '@mdi/js';
import ReactECharts from 'echarts-for-react';
import { Button } from 'primereact/button';
import { Icon, InputIcon as mdiInputIcon } from '@mdi/react';

import { useNavigate, useLocation } from "react-router-dom";
import { LineStyle } from '@mui/icons-material';

  const Grafico_rep = (props) => {
    const navigation = useNavigate();
  const tempoaggiornamento = 500;
const [datiX,setDatiX]=useState();
const [datiY,setDatiY]=useState();
const [datiZ,setDatiZ]=useState();
const [maxy,setMaxy]=useState();
const [miny,setMiny]=useState();
  const option = {
    title: {
        text: 'GRAFICO TEST'
    },
    tooltip: {},
    xAxis: {
        type:'category',
       
        axisLabel: {
          rotate: 30  // Rotate the labels by 45 degrees
      },

    },
    yAxis: {
      max:maxy+2,
      min:miny-2,
    },

    series: [
        {
            type: 'line',
            data: datiX,
            lineStyle:{color:'green'}

          },       
          {
              type: 'line',
              data: datiY,
              lineStyle:{color:'yellow'}
          }
          , 
          {
              type: 'line',
              data: datiZ,
              lineStyle:{color:'blue'}
          }
        ]
  };


  const [valoredes, setValoredes] = useState();

  /*React.useEffect(() => {
      inizia();
      console.log('graficoini','useEffect');
    },[]);*/

//componentdidmount

 
//////        non funziona aggiornamento disegno grafico
useEffect(() => {
  carica(props.dati);
    
}, [ props.dati ]);



    useEffect(
      React.useCallback(() => {
        setTimeout(() => { carica(props.dati)} , tempoaggiornamento);    
        console.log('graficoRep','useFocusEffect');
  
      }, [])
    );



  const carica = (vrestese) => {
    var m1 = new moment();
    try {
     // console.log('vrestese',vrestese);
   var lst=vrestese;
    }
    catch (error) {
      console.error(error);

      return;
    }
   
var mese=0;
    var dati_x = []; var dati_y = []; var dati_z = [];
var i=0;var periodo=15;var fini=0;var max=-9999; var min=50000;
   //if (this.iniziografico.isAfter(lst[0].tempo)) this.iniziografico = lst[0].tempo;
  // console.log('GRAFICO INI', 'RICARICA',lst);
var index=-1;
    lst.forEach((ele) => {
      if (ele.risval> max) max=parseInt(ele.risval);
      if (ele.risval< min) min=parseInt(ele.risval);
     

if (fini==0) fini=moment(ele.datainitest);
if ((moment(fini).diff(ele.datainitest)/(1000*60*60*24))  >periodo ) 
     {i=i+1; fini=ele.datainitest; }
    if (i==0) 
    {
      index=dati_x.findIndex(a => a[0] === ele.cognome);
     if (index<0)
      dati_x.push([ele.cognome, ele.risval]);
      else
      dati_x[index][1]= ele.risval;
    }
    else if (i==1) 
    {
      index=dati_y.findIndex(a => a[0] === ele.cognome);
     if (index<0)
           dati_y.push([ele.cognome, ele.risval]);
      else
      dati_y[index][1]= ele.risval;
    }
    else if (i==2)
    {
      index=dati_z.findIndex(a => a[0] === ele.cognome);
     if (index<0)
      dati_z.push([ele.cognome, ele.risval]);
      else
      dati_z[index][1]= ele.risval;
    }
    });

//// aggiungo nominativi, da valutare se farlo con query in mongo oppure prendendo tutti i nomi da giocgruppi
//// sistemare cognome=
var ind1=-1;
for (var i=0;i<dati_y.length;i++)
{
  var el=dati_y[i]
ind1=dati_x.findIndex(a=>a[0]===el[0]);
if (ind1<0)
dati_x.push([dati_y[i][0],null]);
}
var ind1=-1;
for (var i=0;i<dati_z.length;i++)
{
  var el=dati_z[i]
ind1=dati_x.findIndex(a=>a[0]===el[0]);
if (ind1<0)
dati_x.push([dati_z[i][0],null]);
}


dati_x.sort(([a], [b]) => a.localeCompare(b))
dati_y.sort(([a], [b]) => a.localeCompare(b))
dati_z.sort(([a], [b]) => a.localeCompare(b))


  /*  const run2 = `
// specify chart configuration item and data
var option = myChart.getOption();
option.animation=true;
option.xAxis[0].type='category';
option.xAxis[0].axisLabel.rotate='30';
option.series[0].lineStyle.color='green';
option.series[1].lineStyle.color='yellow';
option.series[2].lineStyle.color='blue';
option.series[0].data=`+ JSON.stringify(dati_x) + `;
option.series[1].data=`+ JSON.stringify(dati_y) + `;
option.series[2].data=`+ JSON.stringify(dati_z) + `;
option.xAxis[0].type='category';
option.yAxis[0].max=`+ (max+3) + `;
option.yAxis[0].min=`+ (min-3) + `;
myChart.setOption(option);
`;*/
setDatiX(dati_x);
setDatiY(dati_y);
setDatiZ(dati_z);
setMaxy(max);setMiny(min);
  }




    return (
      <div style={{ flex: 1,height:'85%',margin:5 ,width:'95%',}} >
             <Button style={{flex:1}}
            onClick={() => carica(props.dati)}
          >
      <Icon path={mdiUpdate}  size={0.8} />
          </Button>
        <ReactECharts option={option}/>

    
       
      </div >
    );
  }


export default Grafico_rep;