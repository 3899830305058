// screens/Home.js
import { useNavigate, Link, useLocation, useSearchParams, Outlet } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState, useEffect, useRef } from "react";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { Toast } from 'primereact/toast';
import elenchi from "../util/elenchi.js";
import { Button } from "primereact/button";
import Icon from '@mdi/react';
import { InputTextarea } from "primereact/inputtextarea";
import Ordine from '../obj/Ordine.js';
import restOrdini from "../restcall/restOrdini.js";
import { Dropdown } from "primereact/dropdown";
import datiGen from "../util/datiGen.js";
import '../stili/App.css';
import '../stili/App01.css';
import '../stili/App2.css';
import '../stili/flag.css';
import { DataView } from "primereact/dataview";
import { mdiContentSave, mdiDelete, mdiPlus } from "@mdi/js";
const DettOrdini = (props) => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState();
  const [espandi, setEspandi] = useState(false);
  const [statoOrdine, setStatoOrdine] = useState();
  const [categoriaOrdine, setCategoriaOrdine] = useState([]);



  const abilOrdini = datiGen.getInstance().possoaccederev2('ORDINI', props.gruppo);
  const abilGestOrdini = datiGen.getInstance().possoaccederev2('GESTIONEORDINI', props.gruppo);


  useEffect(() => {
    var ricalcola=false;
    if (props.selOrdine) {
      let tmp_rec = props.selOrdine;
      tmp_rec.__proto__ = Ordine.prototype;
      tmp_rec.to_obj();
      if (tmp_rec && tmp_rec.righe) {
        tmp_rec.righe.forEach(ele => {
  if (!ele.quantita)
    { 
    ele.quantita=1;
    ricalcola=true;
  } 
  });
      }

if (ricalcola)
{
  let tot = 0;
  tmp_rec.righe.forEach(ele => {
if (!ele.quantita) ele.quantita=1;
    tot = tot + (ele.quantita * ele.importo);
  });
  tmp_rec.totale = tot;
  props.setDaSalvare(true);
      }    
      
 
      setOrders(tmp_rec);
    }
  }, []);

useEffect(() => {
if (orders)
  {  props.updateOrd(orders);
  }
  }, [orders]);




const calcolatot = () => {
let x= { ...orders };
    if (x && x.righe) {
      let tot = 0;
      x.righe.forEach(ele => {
        console.log('dentro calcola tot',typeof ele.quantita,typeof ele.importo,ele.importo,ele.quantita);
if (!ele.quantita) ele.quantita=1;
        tot = tot + (ele.quantita * ele.importo);
      });
      x.totale = tot;
    }
    setOrders(x);
  }

const toast = useRef(null);
  const showSuccess = (titolo, testo) => {
    toast.current.show({ severity: 'success', summary: titolo, detail: testo, life: 3000 });
  }
  const showInfo = (titolo, testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: 'Message Content', life: 3000 });
  }
  const showWarn = (titolo, testo) => {
    toast.current.show({ severity: 'warn', summary: titolo, detail: testo, life: 3000 });
  }
  const showError = (titolo, testo) => {
    toast.current.show({ severity: 'error', summary: titolo, detail: testo, life: 3000 });
  }
  const showSecondary = (titolo, testo) => {
    toast.current.show({ severity: 'secondary', summary: 'Secondary', detail: 'Message Content', life: 3000 });
  };
  const showContrast = (titolo, testo) => {
    toast.current.show({ severity: 'contrast', summary: 'Contrast', detail: 'Message Content', life: 3000 });
  };



  const cancella = (ele) => {
    let ind = orders.righe.findIndex(el => el === ele);
    if (ind >= 0) orders.righe.splice(ind, 1);
    props.setDaSalvare(true);
  }

  const renderItem = (item, index) => (

    <div className='listaItem'>
      {orders.righe &&
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', alignItems: 'center' }}>

          <div className="dati">
            <label>Prodotto</label>
            <Dropdown value={item.codice} onChange={(e) => { let x = { ...orders }; item.codice = e.target.value; item.descr = e.target.label; setOrders(x); props.setDaSalvare(true); }}
              options={elenchi.getInstance().getEl("TIPORIGAORDINE")} placeholder="Inserisci la descrizione" />
          </div>

          {abilGestOrdini &&
            <div className="dati">
              <label>codice</label>
              <label>{item.codice}</label>
            </div>
          }


          <div className="dati">
            <label>Quantita</label>
            <InputText value={item.quantita?item.quantita:1} onChange={(e) => { let x = { ...orders }; item.quantita = e.target.value; setOrders(x); calcolatot(); props.setDaSalvare(true); }} />  
          </div>

          <div className="dati">
            <label>Importo</label>
            <InputText value={item.importo} onChange={(e) => { let x = { ...orders }; item.importo = e.target.value; setOrders(x); calcolatot(); props.setDaSalvare(true); }} />

          </div>
          <div className="dati" style={{ textAlign:'right'}}>
            <label>Totale</label>
            <label>{(item.importo*(item.quantita))}</label>
          </div>


          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', alignItems: 'center' }}>


            <Button
              onClick={() => { cancella(item) }}
            >
              <Icon path={mdiDelete} size={0.8} />
            </Button>
          </div>
        </div>}
    </div>


  )


  return (
    <div>
      <Toast ref={toast} />
      {orders &&

        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', alignItems: 'center' }}>

          <div className="dati">
            <label htmlFor="nome">Cognome e nome</label>
            <IconField iconPosition="left">
              <InputIcon className="pi pi-pencil"> </InputIcon>
              <InputText id="nome" value={orders.cognomeNomePersona} onChange={(e) => { let x = { ...orders }; x.cognomeNomePersona = e.target.value; setOrders(x); props.setDaSalvare(true); /*props.updPagamenti(x)*/ }} />
            </IconField>
          </div>


          <div className="dati">
            <label htmlFor="nome">Data ordine</label>
            <Calendar value={orders.dataOrdine} onChange={(e) => { let x = { ...orders }; x.dataOrdine = e.value; setOrders(x); props.setDaSalvare(true); /*props.updPagamenti(x)*/ }} id="date" />
          </div>



          <div className="dati">
            <label htmlFor="nome">Categoria</label>
            <IconField iconPosition="left">
              <Dropdown value={orders.categoria} onChange={(e) => { let x = { ...orders }; x.categoria = e.value; setOrders(x); props.setDaSalvare(true); }} options={elenchi.getInstance().getEl("CATORDINE")} placeholder="Inserisci la categoria" />
            </IconField>
          </div>

          <div className="dati">
            <label htmlFor="nome">Descrizione</label>
            <IconField iconPosition="left">
              <InputIcon className="pi pi-pencil"> </InputIcon>
              <InputText id="nome" value={orders.descr} onChange={(e) => { let x = { ...orders }; x.descr = e.target.value; setOrders(x); props.setDaSalvare(true); /*props.updPagamenti(x)*/ }} />
            </IconField>
          </div>


          <div className="dati">
            <label htmlFor="nome">Stato</label>
            <Dropdown value={orders.stato} disabled={!abilGestOrdini}
              onChange={(e) => { let x = { ...orders }; x.stato = e.target.value; setOrders(x); props.setDaSalvare(true); }} options={elenchi.getInstance().getEl("STATOORDINE")} placeholder="Inserisci stato ordine" />
          </div>


          <div className="dati"  >
            <label htmlFor="nome">Totale</label>
            <InputText style={{ textAlign:'right'}} id="nome" value={(orders.totale)} disabled={true} />
            </div>


          {abilGestOrdini &&
            <div className="dati">
              <label htmlFor="nome">Data inserimento</label>
              <InputText id="nome" value={moment(orders.dataIns).format("DD/MM/YYYY")} disabled={true} />
            </div>
          }
          {abilGestOrdini &&
            <div className="dati">
              <label htmlFor="nome">Data ultima modifica</label>
              <InputText id="nome" value={moment(orders.dataUltmod).format("DD/MM/YYYY")} disabled={true} />
            </div>

          }

          <div className="dati">
            <label htmlFor="nome">Invio</label>
            <IconField iconPosition="left">
              <InputIcon className="pi pi-pencil"> </InputIcon>
              <InputText id="nome" value={orders.nInvio} onChange={(e) => { let x = { ...orders }; x.nInvio = e.target.value; setOrders(x); props.setDaSalvare(true); /*props.updPagamenti(x)*/ }} />
            </IconField>
          </div>




          <div className="dati">
            <label htmlFor="nome">Modalità Pagamento</label>
            <Dropdown value={orders.modpag}
              onChange={(e) => { let x = { ...orders }; x.modpag = e.value;   setOrders(x);   props.setDaSalvare(true); }}
              options={elenchi.getInstance().getEl("PAG_MODALITA")} placeholder="Inserisci modalità pagamento" />        

          </div>




          <div className="dati" style={{ width: '100%' }}>
            <label htmlFor="nome">Note</label>
            <IconField iconPosition="left">
              <InputIcon className="pi pi-pencil"> </InputIcon>
              <InputTextarea id="nome" rows={2} value={orders.note} onChange={(e) => { let x = { ...orders }; x.note = e.target.value; setOrders(x); props.setDaSalvare(true); /*props.updPagamenti(x)*/ }} />
            </IconField>
          </div>
          <div style={{ width: '100%' }}>
            <DataView

              value={orders.righe} itemTemplate={renderItem} >
            </DataView>
          </div>


        </div>

      }
    </div>
  );
}

export default DettOrdini;