// screens/Home.js

import React, { Component ,useEffect,useState } from 'react';
import PresenzeRep from '../comp/ReportPresenze';
import datiGen from '../util/datiGen.js';
import { useNavigate, useLocation } from "react-router-dom";

const PresenzePage = () => {
  const navigation = useNavigate();
  const location=useLocation();
  const [gruppop, setGruppop] = useState(() => location.state.gruppo);

  const abilPresRep =  datiGen.getInstance().possoaccederev2('REPORTPRESENZE',location.state.gruppo);

  useEffect(() => 
  { 
  setGruppop(location.state.gruppo); 
 
});

  return (
    <div style={{
        width:'100%',
        height:'100%',
        minHeight:'100%',
        flex:1,
        minWidth:'100%',
        maxWidth:'100%',
        justifyContent:'flex-start'

      }}>
{abilPresRep &&
<PresenzeRep gruppo={location.state.gruppo}  style={{flex:10 }} />
}
    </div>
  );
} 
export default  PresenzePage   ;