import Person from "./person.js";
const schedaGiocPrototype = {
   
 
  to_obj() {
 
    if (this.dataScheda && typeof this.dataScheda == 'string') {
      this.dataScheda = new Date(this.dataScheda);
    }
  },

    greet() {
      console.log(`hello, my name is ${this.nome}!`);
    },
    gnome()
    {
        return this.nome;
    },

    caricaValutazioni(pval,azzera)
    {
    if (azzera==1)
      {
    this.valutazioni=[];
      }
    
      pval.forEach(element => {if (this.valutazioni.findIndex(el => el.codice === element.codice)<0) 
        {this.valutazioni.push(element);
    //    console.log('caricaValutazioni','aggiunto',element);
        }
      /*  else
        {
          console.log('caricaValutazioni','NON aggiunto',element);
        }*/
       } );
    
      },

    
  }
  
  function schedaGioc() {
    this.idsoc="";
    this.idgioc="";
    this.objidgioc="";
    this.dataScheda=new Date();
    this.peso=0;
    this.altezza=0;
    this.valutazioni=[];
    this.operatore="";
    this.valutazioneGlobale=0;
    this.note="";
    this.ruolo="";
    this.Giocatore="";
    this.__proto__ = schedaGioc.prototype;
    this.Giocatore.__proto__=Person.prototype;
  }
  
  schedaGioc.prototype = schedaGiocPrototype;
  schedaGioc.prototype.constructor = schedaGioc;
  





export default schedaGioc;